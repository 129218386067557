/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from "@mui/material";
import { protectedResources, siteData } from "authConfig";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { MaterialReactTable } from "material-react-table";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import React, { useEffect, useState, useCallback } from "react";
import "./protect.css";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit, Save, Cancel } from "@mui/icons-material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SettingCustomTable from "assets/theme/components/table/SettingCustomTable";

const CustomTable = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          width: "950px",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: "none",
          fontFamily: "red hat display",
          // width: "200px",
        },
      },
    },
  },
});
function AssuranceSizing() {
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  // Taxonomy lisst
  const [TaxonomyList, setTaxonomyList] = useState(null);
  const [SingleTaxonomy, setSingleTaxonomy] = useState("");
  const [selectedTaxonomyId, setSelectedTaxonomyId] = useState("");
  const [taxonomyId, setTaxonomyId] = useState("");
  const [updatedData, setUpdatedData] = useState([]);
  const [updatedDialogData, setUpdatedDialogData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState();
  const [IsNew, setIsNew] = useState(false);
  const [IsNewDialog, setIsNewDialog] = useState(false);
  const [editRow, setIsEdit] = useState();
  const [editDialogRow, setIsEditDialog] = useState("");
  const [AssurType, setAssurType] = useState("");

  useEffect(() => {
    if (!TaxonomyList) {
      execute("GET", `${protectedResources.apiTodoList.endpoint}/taxonomy/listall`).then(
        (response) => {
          if (response && response.success === true && response.data.length > 0) {
            setTaxonomyList(response.data);
            setSingleTaxonomy(response.data[0].name);
          }
        }
      );
    }
  }, [execute, TaxonomyList]);

  useEffect(() => {
    setSelectedTaxonomyId(
      TaxonomyList && TaxonomyList.find((taxonomy) => taxonomy.name === SingleTaxonomy)
    );
  }, [SingleTaxonomy]);

  //   const [TableData, setTableData] = useState(null);

  useEffect(() => {
    setTaxonomyId(selectedTaxonomyId && selectedTaxonomyId.id);
  }, [selectedTaxonomyId]);

  const TaxonomyName = TaxonomyList && TaxonomyList.map((nme) => nme.name);
  const handleInputChange = (event) => {
    setSingleTaxonomy(event.target.value);
  };

  const getTableData = () => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/assurancesizing/list/${taxonomyId}`
    ).then((response) => {
      if (response && response.success === true) {
        // setTableData(response.data);
        setUpdatedData(response.data);
      }
    });
  };

  // const [Assurancetype, setAssurancetype] = useState(["Overall", "Individual"]);
  // useEffect(() => {
  //   const hasOverall = updatedData.some((obj) => obj.assuranceSizingType === "Overall");
  //   const hasIndividual = updatedData.some((obj) => obj.assuranceSizingType === "Individual");

  //   if (hasOverall && !hasIndividual) {
  //     // Assurancetype = ["Individual"];
  //     setAssurancetype(["Individual"]);
  //   } else if (!hasOverall && hasIndividual) {
  //     // Assurancetype = ["Overall"];
  //     setAssurancetype(["Overall"]);
  //   } else if (hasOverall && hasIndividual) {
  //     // Assurancetype = [];
  //     // setAssurancetype([]);
  //   } else if (!hasOverall && !hasIndividual) {
  //     setAssurancetype(["Overall", "Individual"]);
  //     // Assurancetype = ["Overall", "Individual"];
  //   }
  // }, [updatedData]);
  // eslint-disable-next-line no-unused-vars
  const [Assurancetype, setAssurancetype] = useState(["Overall", "Individual"]);

  // add  dialog controls
  const [open, setOpen] = useState(false);
  const handleAdd = (cell) => {
    // console.log("cell", cell);
    setSelectedRowId(cell.row.original.id);
    setOpen(true);
  };
  // console.log("selectedRowId", selectedRowId);
  const handleClose = () => {
    setOpen(false);
  };

  //    get data from api
  useEffect(() => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/assurancesizing/list/${taxonomyId}`
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        // setTableData(response.data);
        setUpdatedData(response.data);
      }
    });
  }, [execute, taxonomyId]);

  //    get data from api dialog
  useEffect(() => {
    if (selectedRowId) {
      const rowData = updatedData && updatedData.find((row) => row.id === selectedRowId);
      setUpdatedDialogData(rowData && rowData.assuranceFactorData);
      setAssurType(rowData && rowData.assuranceSizingType);
    }
  }, [updatedData, selectedRowId]);

  // handle input change for adding/editing row in main table
  const [validationErrors, setValidationErrors] = useState({});
  const validateRequired = (value) => value.trim() !== ""; // Check for non-empty and non-blank input
  const [isSuccess, setisSuccess] = useState(false);

  const getCommonEditTextFieldProps = useCallback(
    (cell) => ({
      error: !!validationErrors[cell.id],
      helperText: validationErrors[cell.id],

      onBlur: (event) => {
        const { row, column } = cell;
        const rowIndex = row.index; // Get the index of the row element being updated
        const updatedRowElement = updatedData[rowIndex]; // Find the array element using the index

        // Update the edited data for the specific row element
        const isValid = validateRequired(event.target.value);
        if (updatedRowElement !== undefined) {
          const updatedRowData = {
            ...updatedRowElement,
            [column.id]: event.target.value,
          };

          // Save the updatedRowData in the editObject state

          setUpdatedData((prevData) =>
            prevData.map((item, index) => (index === rowIndex ? updatedRowData : item))
          );
          if (!isValid) {
            // set validation error for cell if invalid
            setisSuccess(true);
            if (cell.column.id === "assuranceSizingName") {
              setValidationErrors({
                ...validationErrors,

                [cell.id]: `${cell.column.columnDef.header} is required`,
              });
            }
          } else {
            // remove validation error for cell if valid
            delete validationErrors[cell.id];
            setisSuccess(false);

            setValidationErrors({
              ...validationErrors,
            });
          }
        }
      },
    }),
    [updatedData, validationErrors]
  );

  // handle input change for adding/editing row in dialog table
  const [dialogvalidationErrors, setDialogValidationErrors] = useState({});
  // const dialogvalidateRequired = (value) => value.trim() !== ""; // Check for non-empty and non-blank input
  const [isdialogSuccess, setisdialogSuccess] = useState(false);
  const validateMaxLength = (value, maxLength) => value.length >= maxLength;

  const getDialogCommonEditTextFieldProps = useCallback(
    (cell) => ({
      error: !!dialogvalidationErrors[cell.id],
      helperText: dialogvalidationErrors[cell.id],
      onBlur: (event) => {
        const { row, column } = cell;
        const rowId = row.original._id;

        const updatedRowElement = updatedDialogData.find((item) => item._id === rowId);

        if (updatedRowElement) {
          const { value } = event.target;
          const columnName = column.id;

          if (
            columnName === "assurenceFactor" ||
            columnName === "title"
            // ||
            // columnName === "description"
          ) {
            const isValueValid = value.trim() !== ""; // Check if non-empty

            const updatedRowData = {
              ...updatedRowElement,
              [columnName]: value,
            };

            setUpdatedDialogData((prevData) =>
              prevData.map((item) => (item._id === rowId ? updatedRowData : item))
            );

            if (!isValueValid) {
              setisdialogSuccess(true);

              setDialogValidationErrors({
                ...dialogvalidationErrors,
                [cell.id]: `${cell.column.columnDef.header} is required`,
              });
            } else {
              delete dialogvalidationErrors[cell.id];
              setisdialogSuccess(false);

              setDialogValidationErrors({
                ...dialogvalidationErrors,
              });
            }
          } else {
            const isValueValid = validateMaxLength(value, 50); // Length validation

            const updatedRowData = {
              ...updatedRowElement,
              [columnName]: value,
            };

            // setEditObject(updatedRowData);

            setUpdatedDialogData((prevData) =>
              prevData.map((item) => (item._id === rowId ? updatedRowData : item))
            );

            if (!isValueValid) {
              setisdialogSuccess(true);

              setDialogValidationErrors({
                ...dialogvalidationErrors,
                [cell.id]: `${cell.column.columnDef.header} should be greater than 50 characters`,
              });
            } else {
              delete dialogvalidationErrors[cell.id];
              setisdialogSuccess(false);

              setDialogValidationErrors({
                ...dialogvalidationErrors,
              });
            }
          }
        }
      },
    }),
    [updatedDialogData, dialogvalidationErrors]
  );

  // main table
  const columns = [
    {
      id: "assuranceSizingName",
      accessorKey: "assuranceSizingName",
      header: "Assurance Sizing Type",
      size: 260,
      Cell: ({ cell }) => (
        <Button
          onClick={() => handleAdd(cell)}
          style={{ textDecoration: "underline", textTransform: "none" }}
        >
          {cell && cell.row.original.assuranceSizingName}
        </Button>
      ),
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      id: "assuranceSizingDescription",
      accessorKey: "assuranceSizingDescription",
      header: "Description",
      size: 260,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      id: "assuranceSizingType",
      accessorKey: "assuranceSizingType",
      header: "Type",
      size: 260,
      editable: false,
      muiTableBodyCellEditTextFieldProps: {
        editable: false,
        disabled: true,
      },
    },
  ];

  //  add new row for main table
  const handleAddButtonClick = () => {
    const newEmptyRow = {
      id: uuidv4(),
      isnew: true,
      assuranceSizingName: "",
      assuranceSizingDescription: "",
      assuranceSizingType: "",
    };

    setUpdatedData([...updatedData, newEmptyRow]);
    setIsNew(true);
    setIsEdit(newEmptyRow.id);
    setisSuccess(true);
  };

  //  add new row for dialog table
  const handleDialogAddButtonClick = () => {
    const newEmptyRow = {
      _id: uuidv4(),
      isnew: true,
      assurenceFactor: "",
      title: "",
      description: "",
    };

    setUpdatedDialogData([...updatedDialogData, newEmptyRow]);
    setIsNewDialog(true);
    setIsEditDialog(newEmptyRow._id);
  };

  // cancel editing row
  const handleCancelRowEdits = () => {
    getTableData();
    setIsNew(false);

    setValidationErrors({});
  };

  // Function to check if a string is empty
  const isEmpty = (str) => {
    const isSucc = str.trim() !== "";
    setisSuccess(isSucc);
    return !isSucc;
  };

  const IsEmptyRowEntry = () => {
    const hasEmptyNames = updatedData.some(
      (obj) => isEmpty(obj.assuranceSizingDescription) || isEmpty(obj.assuranceSizingName)
    );
    return hasEmptyNames;
  };

  // save the main table row
  const handleSaveRow = async (row) => {
    const rowData = updatedData[row.index];

    if (rowData.isnew) {
      if (!Object.keys(validationErrors).length) {
        try {
          const requestBody = {
            assuranceSizingName: rowData.assuranceSizingName,
            assuranceSizingDescription: rowData.assuranceSizingDescription,
            taxonomyId,
            assuranceSizingType: rowData.assuranceSizingType,
            assuranceFactorData: [],
          };
          // Make the POST API call
          execute(
            "POST",
            `${protectedResources.apiTodoList.endpoint}/assurancesizing/create`,
            requestBody
          ).then(() => {
            getTableData();
            setIsEdit(null);
          });
        } catch (error) {
          /* empty */
        }
      }
    } else if (!Object.keys(validationErrors).length) {
      try {
        const requestBody = {
          assuranceSizingName: rowData.assuranceSizingName,
          assuranceSizingDescription: rowData.assuranceSizingDescription,
          taxonomyId,
          assuranceSizingType: rowData.assuranceSizingType,
          assuranceFactorData: rowData.assuranceFactorData,
        };
        execute(
          "PUT",
          `${protectedResources.apiTodoList.endpoint}/assurancesizing/update/${rowData.id}`,
          requestBody
        ).then(() => {
          getTableData();
          setIsEdit(null);
        });
      } catch (error) {
        /* empty */
      }
    }
  };

  const IsEmptyEntry = () => {
    const hasEmptyNames = updatedDialogData.some(
      (obj) => isEmpty(obj.title) || isEmpty(obj.description) || isEmpty(obj.assurenceFactor)
    );
    return hasEmptyNames;
  };

  // save the dialog table row
  const handleDialogSaveRow = async () => {
    const rowData = updatedData.find((item) => item.id === selectedRowId);

    const newDataArray =
      updatedDialogData && updatedDialogData.map(({ _id, isnew, ...rest }) => rest);

    if (!Object.keys(dialogvalidationErrors).length) {
      try {
        const requestBody = {
          assuranceSizingName: rowData.assuranceSizingName,
          assuranceSizingDescription: rowData.assuranceSizingDescription,
          taxonomyId,
          assuranceSizingType: AssurType,
          assuranceFactorData: newDataArray,
        };
        execute(
          "PUT",
          `${protectedResources.apiTodoList.endpoint}/assurancesizing/update/${selectedRowId}`,
          requestBody
        ).then(() => {
          getTableData();
          setIsEditDialog(null);
        });
      } catch (error) {
        /* empty */
      }
    }
  };

  // save the type changed
  const handleTypeChange = (event) => {
    setAssurType(event.target.value);
    // handleDialogSaveRow();
  };

  useEffect(() => {
    handleDialogSaveRow();
  }, [AssurType]);

  // delete a row main table
  const handleDeleteRow = (row) => {
    const { id } = row.original;
    if (row.original.isnew) {
      handleCancelRowEdits();
    } else {
      try {
        execute(
          "DELETE",
          `${protectedResources.apiTodoList.endpoint}/assurancesizing/delete/${id}`
        ).then(() => {
          getTableData();
        });
      } catch {
        /* empty */
      }
    }
  };

  // delete a row dialog table
  const handleDialogDeleteRow = (row) => {
    const { id } = row;

    const rowData = updatedData.find((item) => item.id === selectedRowId);

    // Find the index of the element to be deleted
    const indexToDelete = updatedDialogData.findIndex((item, index) => index === id) ? id : 0;

    // Remove the element from the array
    const updatedDataCopy = [...updatedDialogData];
    updatedDataCopy.splice(indexToDelete, 1);

    try {
      const requestBody = {
        assuranceSizingName: rowData.assuranceSizingName,
        assuranceSizingDescription: rowData.assuranceSizingDescription,
        taxonomyId,
        assuranceSizingType: rowData.assuranceSizingType,
        assuranceFactorData: updatedDataCopy,
      };
      execute(
        "PUT",
        `${protectedResources.apiTodoList.endpoint}/assurancesizing/update/${selectedRowId}`,
        requestBody
      ).then(() => {
        getTableData();
        setIsEditDialog(null);
      });
    } catch (error) {
      /* empty */
    }
  };

  // dialog box table columns
  const Dialogcolumns = [
    {
      id: "assurenceFactor",
      accessorKey: "assurenceFactor",
      header: "Assurance Factor",
      size: 2,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getDialogCommonEditTextFieldProps(cell),
      }),
    },
    {
      id: "title",
      accessorKey: "title",
      header: "Title",
      size: 100,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getDialogCommonEditTextFieldProps(cell),
      }),
    },
    {
      id: "description",
      accessorKey: "description",
      header: "Description",
      size: 180,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getDialogCommonEditTextFieldProps(cell),
      }),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        style={{ borderRadius: "5px", padding: "15px", backgroundColor: "white", height: "520px" }}
      >
        <Grid container spacing={2} md={12} sm={12} xs={12}>
          <Grid item md={12} sm={12} xs={12}>
            <Typography sx={{ pb: "5px", fontWeight: 500 }} fontSize="21px">
              Assurance Sizing Setting
            </Typography>
          </Grid>
          <Grid item sx={{ mr: "12px" }}>
            <FormControl required>
              <InputLabel id="taxonomy">Taxonomy</InputLabel>
              <Select
                sx={{
                  width: "280px",
                  height: "44px",
                }}
                label="Taxonomy"
                name="Taxonomy"
                onChange={handleInputChange}
                value={(SingleTaxonomy && SingleTaxonomy) || ""}
              >
                {TaxonomyName &&
                  TaxonomyName.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            {updatedData && updatedData.length < 2 && (
              <MDButton
                variant="contained"
                BackgroundColor="#52009b"
                color="#52009b"
                sx={{
                  color: "#f7f4fb",
                }}
                className="add-button"
                onClick={() => handleAddButtonClick()}
              >
                + Assurance Rating
              </MDButton>
            )}
          </Grid>
          <Grid item md={12}>
            <ThemeProvider theme={SettingCustomTable}>
              <MaterialReactTable
                columns={columns}
                data={updatedData || ""}
                editingMode="row" // Use "row" editing mode for inline editing
                //   enableColumnOrdering
                enableColumnFilters={false}
                enableColumnActions={false}
                enableEditing
                enableTopToolbar={false}
                muiTableHeadCellProps={{
                  sx: {
                    backgroundColor: "#F7F8FF !important",
                  },
                }}
                //   onEditingRowCancel={handleCancelRowEdits}
                positionActionsColumn="last"
                renderRowActions={({ row, table }) => {
                  const isRowEditing = row.original.id === editRow;
                  if (IsNew === true) {
                    table.setEditingRow(row);
                    // setIsEdit(row.id);
                  }
                  return (
                    <Box sx={{ display: "flex" }}>
                      {isRowEditing ? (
                        <>
                          <Tooltip arrow title="Save">
                            <IconButton
                              onClick={() => {
                                const a = IsEmptyRowEntry();
                                if (a === false) {
                                  handleSaveRow(row);
                                  if (!isSuccess) {
                                    setIsEdit(null);
                                    table.setEditingRow(null);
                                  }
                                  // table.setEditingRow(null);
                                  setIsNew(false);
                                }
                                // handleSaveRow(row);
                                // if (!isSuccess) {
                                //   setIsEdit(null);
                                //   table.setEditingRow(null);
                                // }
                                // // table.setEditingRow(null);
                                // setIsNew(false);
                              }}
                            >
                              <Save />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Cancel">
                            <IconButton
                              onClick={() => {
                                handleCancelRowEdits();
                                setIsEdit(null);
                                table.setEditingRow(null);
                                setIsNew(false);
                              }}
                            >
                              <Cancel />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip arrow title="Edit">
                          <IconButton
                            onClick={() => {
                              table.setEditingRow(row);
                              setIsEdit(row.original.id);
                              setIsNew(false);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip arrow title="Delete">
                        <IconButton onClick={() => handleDeleteRow(row)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  );
                }}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog open={open} maxWidth="lg">
        <Grid container sx={{ m: "15px" }}>
          <Grid
            item
            md={12}
            style={{ display: "flex", justifyContent: "flex-end", paddingRight: "30px" }}
          >
            <CancelOutlinedIcon
              fontSize="large"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
              sx={{ width: "24px", height: "24px", color: "#7354CB" }}
            />
          </Grid>

          <Grid item md={12} sx={{ mb: "15px" }}>
            <FormControl required>
              <InputLabel id="type">Assurance Type</InputLabel>
              <Select
                sx={{
                  width: "280px",
                  height: "44px",
                }}
                label="Assurance Type"
                name="AssurType"
                onChange={handleTypeChange}
                value={(AssurType && AssurType) || ""}
                disabled={AssurType !== null && AssurType !== undefined && AssurType !== ""}
              >
                {Assurancetype &&
                  Assurancetype.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <ThemeProvider theme={CustomTable}>
              <MaterialReactTable
                columns={Dialogcolumns}
                data={updatedDialogData || ""}
                editingMode="row" // Use "row" editing mode for inline editing
                //   enableColumnOrdering
                enableColumnFilters={false}
                enableColumnActions={false}
                enableEditing
                enableTopToolbar={false}
                //   onEditingRowCancel={handleCancelRowEdits}
                positionActionsColumn="last"
                muiTableHeadCellProps={{
                  sx: {
                    backgroundColor: "#F7F8FF !important",
                  },
                }}
                // enableColumnResizing
                displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }}
                enableRowActions
                renderRowActions={({ row, table }) => {
                  const isRowEditing = row.original._id === editDialogRow;

                  if (IsNewDialog === true) {
                    table.setEditingRow(row);
                  }
                  return (
                    <Box sx={{ display: "flex" }}>
                      {isRowEditing ? (
                        <>
                          <Tooltip arrow title="Save">
                            <IconButton
                              onClick={() => {
                                // (row);
                                const a = IsEmptyEntry();

                                if (a === false) {
                                  if (!isdialogSuccess) {
                                    handleDialogSaveRow(row);
                                    setIsEditDialog(null);
                                    table.setEditingRow(null);
                                  }
                                  // table.setEditingRow(null);
                                  setIsNewDialog(false);
                                }
                              }}
                            >
                              <Save />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Cancel">
                            <IconButton
                              onClick={() => {
                                handleCancelRowEdits();
                                setIsEditDialog(null);
                                table.setEditingRow(null);
                                setIsNewDialog(false);
                              }}
                            >
                              <Cancel />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip arrow title="Edit">
                          <IconButton
                            onClick={() => {
                              table.setEditingRow(row);
                              setIsEditDialog(row.original._id);
                              setIsNewDialog(false);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip arrow title="Delete">
                        <IconButton onClick={() => handleDialogDeleteRow(row)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  );
                }}
              />
            </ThemeProvider>
          </Grid>
          <Grid item md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            {IsNewDialog === false && (
              <Button
                variant="outlined"
                onClick={() => handleDialogAddButtonClick()}
                sx={{
                  color: "#402899",
                  marginTop: "20px",
                  borderColor: "#402899",
                  borderRadius: "4px",
                  mr: "40px",
                }}
              >
                +Add New
              </Button>
            )}
          </Grid>
        </Grid>
      </Dialog>
    </DashboardLayout>
  );
}

export default AssuranceSizing;
