/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { Button, TextField, IconButton, Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function DynamicTextBoxes() {
  const [textFields, setTextFields] = useState([""]); // Initial state with one empty text field

  const handleAddTextField = () => {
    setTextFields([...textFields, ""]); // Add a new empty text field
  };

  const handleRemoveTextField = (index) => {
    const updatedTextFields = [...textFields];
    updatedTextFields.splice(index, 1); // Remove the text field at the specified index
    setTextFields(updatedTextFields);
  };

  const handleTextFieldChange = (index, value) => {
    const updatedTextFields = [...textFields];
    updatedTextFields[index] = value; // Update the value of the text field at the specified index
    setTextFields(updatedTextFields);
  };

  return (
    <div>
      {textFields.map((text, index) => (
        <Box key={index} display="flex" alignItems="center" mb={1}>
          <MDTypography variant="body2" style={{ marginRight: "8px" }}>
            Search Criteria {index + 1}:
          </MDTypography>
          <TextField
            // label={`Text ${index + 1}`}
            value={text}
            onChange={(e) => handleTextFieldChange(index, e.target.value)}
            width="300"
          />
          <IconButton onClick={() => handleRemoveTextField(index)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}

      <MDButton variant="outline" startIcon={<AddIcon />} onClick={handleAddTextField}>
        Add New Criteria
      </MDButton>
    </div>
  );
}

export default DynamicTextBoxes;
