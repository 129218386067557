/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import React, { useState, useCallback, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { protectedResources, siteData } from "authConfig";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import { Link } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function KibanaEmbed() {
  return (
    <div className="App">
      <iframe
        src="https://thsenseriskelastic.kb.australiaeast.azure.elastic-cloud.com:9243/app/dashboards?auth_provider_hint=anonymous1#/view/fc39cfe0-511e-11ee-a8a5-4d9c68a2f940?embed=true&_g=(refreshInterval:(pause:!t,value:60000),time:(from:'2023-10-01',to:now))&_a=(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,field:keyword,index:e7186168-8078-44fd-b820-1b332c64e4f6,key:keyword,negate:!f,params:(query:'Incentives'),type:phrase),query:(match_phrase:(keyword:'Incentives')))),query:(language:kuery,query:''))"
        height="800"
        width="100%"
      />
    </div>
  );
}

function AnticipateHorizonScan() {
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  const [TableData, setTableData] = useState(null);
  // get appetite type for column heading

  //  get data from api
  useEffect(() => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/anticipateHorizonScan/list`).then(
      (response) => {
        if (response && response.success === true && response.data.length > 0) {
          setTableData(response.data);
        }
      }
    );
  }, [execute]);

  // for save button rendering

  // Update the edited data for each row using the index as the key
  const [updatedData, setUpdatedData] = useState([]);
  const [value, setValue] = React.useState(0);
  const formatDate = (date) => {
    console.log(date);
    const ye = String(date).substring(0, 4);
    const mo = date.substring(4, 6);
    const da = date.substring(6, 8);
    return `${da}-${mo}-${ye}`;
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tableData = TableData
    ? TableData.map((data) => ({
        id: data.id,
        title: (
          <Grid container>
            <Grid item xs={10}>
              <MDTypography
                variant="body2"
                color="info"
                fontWeight="bold"
                textTransform="uppercase"
              >
                {data.title}
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                variant="body2"
                color="info"
                fontWeight="bold"
                textTransform="uppercase"
              >
                {formatDate(data.date)}
              </MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body2" color="info" fontWeight="regular">
                <a href={data.link} rel="noreferrer" target="_blank">
                  {data.link}
                </a>
              </MDTypography>
              <MDTypography variant="body2" color="text" fontWeight="regular">
                Summary : {data.summary}
              </MDTypography>
            </Grid>
            <Grid item xs={8}>
              <MDBadge
                badgeContent={data.subCategory}
                color="#52009b"
                variant="gradient"
                size="sm"
              />
            </Grid>
            <Grid item xs={2}>
              <MDBadge badgeContent={data.source} color="#52009b" variant="gradient" size="sm" />
            </Grid>
            <Grid item xs={2}>
              <MDBadge badgeContent={data.sentiment} color="#52009b" variant="gradient" size="sm" />
            </Grid>
          </Grid>
        ),
      }))
    : [];
  // useEffect to initialize the state once TableData is available
  useEffect(() => {
    setUpdatedData(tableData);
  }, [TableData]);

  // handle input change for editing row
  // const [validationErrors, setValidationErrors] = useState({});
  const [, setEditObject] = useState(null);
  // add new appetite
  // const [isSuccess, setisSuccess] = useState(false);
  // const validateRequired = (value) => !!value.length;
  const getCommonEditTextFieldProps = useCallback(
    (cell) => ({
      onBlur: (event) => {
        const { row, column } = cell;
        const rowId = row.original.id; // Get the ID of the row element (array element ID) being updated
        const updatedRowElement = updatedData.find((item) => item.id === rowId); // Find the array element with the matching ID in updatedData

        if (updatedRowElement) {
          // Update the edited data for the specific row element
          const updatedRowData = {
            ...updatedRowElement,
            [column.id]: event.target.value,
          };

          // Save the updatedRowData in the editObject state
          setEditObject(updatedRowData);

          setUpdatedData((prevData) =>
            prevData.map((item) => (item.id === rowId ? updatedRowData : item))
          );
        }
      },
    }),
    [updatedData, setEditObject]
  );

  const columns = [
    {
      id: "title",
      accessorKey: "title",
      header: "Horizon Scan",
      size: 140,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
  ];

  // dialog box
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container style={{ borderRadius: "5px", padding: "15px" }} spacing={2}>
        <Grid item md={12}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Detail" {...a11yProps(0)} />
                <Tab label="Dashboard" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <MaterialReactTable
                columns={columns}
                data={updatedData || ""}
                initialState={{ density: "spacious" }}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <KibanaEmbed />
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AnticipateHorizonScan;
