/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */

import React, { useState, useCallback, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Grid, Button, createTheme, ThemeProvider } from "@mui/material";
import { Edit, Save, Cancel } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import "./protect.css";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { protectedResources, siteData } from "authConfig";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
// import SettingCustomTable from "assets/theme/components/table/SettingCustomTable";

const CustomTableTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: { root: { border: "0.2px solid #B5BFD0", fontFamily: "red hat display" } },
    },
  },
});

function RiskType({ selectedOption, taxonomyId, columnChange }) {
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  const [impact, setImpact] = useState(null);
  const [updatedData, setUpdatedData] = useState([]);
  const [modifiedImpactData, setModifiedImpactData] = useState([]);
  const [TableData, setTableData] = useState([]);

  // get column headings and data from api
  useEffect(() => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/sizing/list/${taxonomyId}/${selectedOption}`
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setImpact(response.data);
      }
    });

    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/sizingcriteria/list/${taxonomyId}/${selectedOption}`
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setTableData(response.data);
      }
    });
  }, [execute, taxonomyId, columnChange, selectedOption]);

  // column heading
  const [impactType, setImpactType] = useState([]);

  const getTableData = () => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/sizingcriteria/list/${taxonomyId}/${selectedOption}`
    ).then((response) => {
      if (response && response.success === true) {
        setTableData(response.data);
      }
    });
  };

  useEffect(() => {
    if (impact && impact.length > 0 && Array.isArray(impact[0].impactLevel)) {
      setImpactType(impact[0].impactLevel.map((item) => ({ name: item.name, level: item.level })));
    } else {
      setImpactType([]);
    }
  }, [impact, TableData]);

  // assign data from api to obj in converted form
  useEffect(() => {
    if (TableData) {
      const tableData = TableData
        ? TableData.map((data) => ({
            id: data.id,
            name: data.name,
            impactCategory: data.impactCategory,
            taxonomyId: data.taxonomyId,
            ...data.impactData[0],
          }))
        : [];
      setModifiedImpactData(tableData);
      setUpdatedData(tableData);
    }
  }, [TableData]);

  // for save button rendering
  const [editRow, setIsEdit] = useState();
  const [IsNew, setIsNew] = useState(false);
  const [isSuccess, setisSuccess] = useState(true);

  const handleAddButtonClick = () => {
    const newEmptyRow = {
      id: uuidv4(),
      name: "",
      isnew: true,
    };
    impactType.forEach((impactItem) => {
      newEmptyRow[`${impactItem.name}_desc`] = ""; // Add dynamic property for description
      newEmptyRow[`${impactItem.name}_value`] = ""; // Add dynamic property for value
    });

    // Update the state of modifiedImpactData
    setModifiedImpactData((prevData) => [...prevData, newEmptyRow]);
    setUpdatedData((prevData) => [...prevData, newEmptyRow]);
    setIsNew(true);
    setisSuccess(true);
    setIsEdit(newEmptyRow.id);
  };
  const [validationErrors, setValidationErrors] = useState({});

  const validateRequired = (value) => value.trim() !== ""; // Check for non-empty and non-blank input

  const regex = /^[0-9\-+\\.,<>]*$/;
  const getCommonEditTextFieldProps = useCallback(
    (cell) => ({
      error: !!validationErrors[cell.id],
      helperText: validationErrors[cell.id],
      onBlur: (event) => {
        const { row, column } = cell;
        const isValid = validateRequired(event.target.value);
        const isNumber = regex.test(event.target.value);
        const columnName = column.columnDef.header;
        const rowIndex = row.index; // Get the index of the row element being updated

        const updatedRowElement = updatedData[rowIndex]; // Find the array element using the index

        const updatedRowData = {
          ...updatedRowElement,
          [column.id]: event.target.value,
        };
        setUpdatedData((prevData) =>
          prevData.map((item, index) => (index === rowIndex ? updatedRowData : item))
        );

        if (!isValid) {
          // set validation error for cell if invalid

          setisSuccess(true);

          setValidationErrors({
            ...validationErrors,

            [cell.id]: `${cell.column.columnDef.header} is required`,
          });
        } else {
          // remove validation error for cell if valid

          delete validationErrors[cell.id];
          setisSuccess(false);

          setValidationErrors({
            ...validationErrors,
          });
        }
        if (columnName === "Quantitative($)") {
          if (!isNumber) {
            // set validation error for cell if invalid
            setisSuccess(true);

            setValidationErrors({
              ...validationErrors,

              [cell.id]: `${cell.column.columnDef.header} only numbers and (-, <, >) is allowed`,
            });
          } else {
            // remove validation error for cell if valid

            delete validationErrors[cell.id];
            setisSuccess(false);

            setValidationErrors({
              ...validationErrors,
            });
          }
        }
      },
    }),
    [setUpdatedData, updatedData, validationErrors]
  );

  const fcolumns = [
    {
      id: "name",
      name: "name",
      accessorKey: "name",
      header: "Impact Type",
      size: 140,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
  ];

  const [conditionalColumns, setConditionalColumns] = useState([]);

  useEffect(() => {
    if (impactType && impactType.length > 0) {
      const mappedColumns = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < impactType.length; i++) {
        const riskType = impactType[i];
        mappedColumns.push({
          id: riskType.name,
          header: (
            <div>
              <span>{riskType.name ? riskType.name : "abc"}</span>
            </div>
          ),
          columns: [
            {
              id: `${riskType.name ? riskType.name : "abc"}_desc`,
              name: `${riskType.name}_desc`,
              accessorKey: `${riskType.name}_desc`,
              header: `Qualitative`,
              muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
              }),
            },
            {
              id: `${riskType.name ? riskType.name : "abc"}_value`,
              name: `${riskType.name}_value`,
              accessorKey: `${riskType.name}_value`,
              header: `Quantitative($)`,
              // Cell: ({ cell }) => <Button onClick={handleAdd}>Dollar Value</Button>,
              muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
              }),
            },
          ],
        });
      }
      setConditionalColumns(mappedColumns);
    } else {
      setConditionalColumns([]);
    }
  }, [impactType, getCommonEditTextFieldProps]);

  // Concatenate the initial columns array with the conditional columns
  // const columns = [...fcolumns, ...conditionalColumns];
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (columnChange === false) {
      setConditionalColumns([]);
      setColumns([]);
    } else {
      setColumns([...fcolumns, ...conditionalColumns]);
    }
  }, [conditionalColumns, fcolumns, columnChange]);

  const isEmpty = (value) => {
    if (typeof value === "string") {
      const isSucc = value.trim() !== "";
      return !isSucc;
    }
    return false;
  };

  const hasEmptyValues = (obj) => {
    for (const key in obj) {
      if (!key.endsWith("_value") && isEmpty(obj[key])) {
        return true;
      }
    }
    return false;
  };

  const IsEmptyEntry = () => {
    const hasEmptyValuesInData = updatedData.some((obj) => hasEmptyValues(obj));

    return hasEmptyValuesInData;
  };

  // saving the edited row
  const handleSaveRow = async (row) => {
    const rowData = updatedData[row.index];

    if (!Object.keys(validationErrors).length) {
      if (rowData.isnew) {
        const dataToExclude = ["id", "name", "isnew"]; // Properties to exclude
        try {
          const requestBody = {
            name: rowData.name,
            taxonomyId,
            impactCategory: selectedOption,
            impactData: [
              Object.keys(rowData).reduce((acc, key) => {
                if (!dataToExclude.includes(key)) {
                  acc[key] = rowData[key];
                }
                return acc;
              }, {}),
            ],
          };

          // Make the POST API call
          execute(
            "POST",
            `${protectedResources.apiTodoList.endpoint}/sizingcriteria/create`,
            requestBody
          ).then(() => {
            getTableData();
            setIsEdit(null);
          });
        } catch (error) {
          /* empty */
        }
      } else {
        const dataToExclude = ["name"]; // Properties to exclude

        try {
          const requestBody = {
            name: rowData.name,
            taxonomyId,
            impactCategory: selectedOption,
            impactData: [
              Object.keys(rowData).reduce((acc, key) => {
                if (!dataToExclude.includes(key)) {
                  acc[key] = rowData[key];
                }
                return acc;
              }, {}),
            ],
          };

          execute(
            "PUT",
            `${protectedResources.apiTodoList.endpoint}/sizingcriteria/update/${rowData.id}`,
            requestBody
          ).then(() => {
            getTableData();
            setIsEdit(null);
          });
        } catch (error) {
          /* empty */
        }
      }
    }
  };

  // cancel editing row
  const handleCancelRowEdits = () => {
    getTableData();
    setIsNew(false);
    // setIsEdit(null);
    setValidationErrors({});
  };
  // delete a row
  const handleDeleteRow = (row) => {
    const { id } = row.original;
    if (row.original.isnew) {
      handleCancelRowEdits();
    } else {
      try {
        execute(
          "DELETE",
          `${protectedResources.apiTodoList.endpoint}/sizingcriteria/delete/${id}`
        ).then(() => {
          getTableData();
        });
      } catch {
        /* empty */
      }
    }
  };

  return impactType && impactType.length > 0 ? (
    <Grid container>
      <ThemeProvider theme={CustomTableTheme}>
        {conditionalColumns && conditionalColumns.length > 0 && columns.length > 1 && (
          <MaterialReactTable
            columns={columns || []}
            data={modifiedImpactData || ""}
            editingMode="row" // Use "row" editing mode for inline editing
            enableEditing
            enableTopToolbar={false}
            onEditingRowCancel={handleCancelRowEdits}
            positionActionsColumn="last"
            enableColumnActions={false}
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: "#F7F8FF !important",
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                backgroundColor: "white !important",
              },
            }}
            renderRowActions={({ row, table }) => {
              const isRowEditing = row.original.id === editRow;

              if (IsNew === true) {
                table.setEditingRow(row);
              }
              return (
                <Box sx={{ display: "flex" }}>
                  {isRowEditing ? (
                    <>
                      <Tooltip arrow title="Save">
                        <IconButton
                          onClick={() => {
                            const a = IsEmptyEntry();

                            if (a === false) {
                              handleSaveRow(row);
                              if (isSuccess === false) {
                                table.setEditingRow(null);
                                setIsNew(false);
                              }
                            }
                          }}
                        >
                          <Save />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title="Cancel">
                        <IconButton
                          onClick={() => {
                            handleCancelRowEdits();
                            setIsEdit(null);
                            table.setEditingRow(null);
                            setIsNew(false);
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <Tooltip arrow title="Edit">
                      <IconButton
                        onClick={() => {
                          table.setEditingRow(row);
                          setIsEdit(row.original.id);
                          setIsNew(false);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip arrow title="Delete">
                    <IconButton
                      // color="error"
                      onClick={() => handleDeleteRow(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            }}
          />
        )}
      </ThemeProvider>
      <Grid item md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        {IsNew === false && (
          <Button
            variant="outlined"
            onClick={() => handleAddButtonClick()}
            sx={{
              color: "#402899",
              marginTop: "20px",
              borderColor: "#402899",
              borderRadius: "4px",
            }}
          >
            +Add New
          </Button>
        )}
      </Grid>
    </Grid>
  ) : (
    <span>Add Impact level Setting</span>
  );
}

export default RiskType;
