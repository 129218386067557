import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";

function DashboardPage() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox bgColor="white" padding="15px" borderRadius="8px" height="520px">
        <Grid container sx={{ pt: "216px", pl: "155px" }}>
          <h2>Welcome to 6th Sense Risk Intelligent Platform</h2>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default DashboardPage;
