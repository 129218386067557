import React, { useEffect } from "react";
// import MDButton from "./MDButton";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

function Welcome() {
  const { instance } = useMsal();

  useEffect(() => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  }, []);

  return (
    <div className="jumbotron">
      <h1>Hello </h1>
      <p className="lead">Welcome to 6th Sense Risk!</p>
      {/*       <p>
        <MDButton onClick={() => KcUserService.doLogin()}>Login</MDButton>
      </p> */}
    </div>
  );
}

export default Welcome;
