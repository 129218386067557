/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Breadcrumbs, Button, Grid, ThemeProvider } from "@mui/material";
import CustomHeightTable from "assets/theme/components/table/CustomHeightTable";
import { protectedResources, siteData } from "authConfig";
import MDBox from "components/MDBox";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { MaterialReactTable } from "material-react-table";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

function ExposureDetSubCat() {
  const location = useLocation();

  //   const { catName } = location.state.cat.categoryName;

  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });
  const [TaxonomyId, setTaxonomyId] = useState(null);
  const [catId, setCatId] = useState(null);
  const [expType, setExpType] = useState(null);
  const [subCatId, setSubCatId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [catName, setCatName] = useState(null);

  useEffect(() => {
    setTaxonomyId(location.state.TaxonomyId);
    setCatId(location.state.cat.categoryId);
    setSubCatId(location.state.subCat.subCategoryId);
    setCatName(location.state.subCat.subCategoryName);
    setExpType(location.state.expType);
  }, []);

  useEffect(() => {
    // subcategory page call

    if (expType === "exposure") {
      try {
        execute(
          "GET",
          `${protectedResources.apiTodoList.endpoint}/protect/influentialfactor/exposure/${TaxonomyId}/${catId}/${subCatId}`
        ).then((response) => {
          if (response && response.success === true && response.data.length > 0) {
            setTableData(response.data);
          }
        });
      } catch {
        /* empty */
      }
    }
    if (expType === "assurance") {
      try {
        execute(
          "GET",
          `${protectedResources.apiTodoList.endpoint}/protect/influentialfactor/assurance/${TaxonomyId}/${catId}/${subCatId}`
        ).then((response) => {
          if (response && response.success === true && response.data.length > 0) {
            setTableData(response.data);
          }
        });
      } catch {
        /* empty */
      }
    }
    if (expType === "incident") {
      try {
        execute(
          "GET",
          `${protectedResources.apiTodoList.endpoint}/protect/influentialfactor/incident/${TaxonomyId}/${catId}/${subCatId}`
        ).then((response) => {
          if (response && response.success === true && response.data.length > 0) {
            setTableData(response.data);
          }
        });
      } catch {
        //   console.log("error");
      }
    }
  }, [TaxonomyId, catId, expType, subCatId]);

  // details card view table column definition
  const Detailscolumns = [
    {
      id: "subCategoryName",
      accessorKey: "subCategoryName",
      header: "Risk Sub Categories",
      //   size: 400,
    },
    {
      id: "riskName",
      accessorKey: "riskName",
      size: 10,
      header: "Risk Name",
    },
    {
      id: "level",
      accessorKey: "level",
      header: "Level",
      size: 10,
    },
    {
      id: "dataSource",
      accessorKey: "dataSourceFileName",
      header: "Data Source",
      size: 20,
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ cell }) => (
        <a
          href={cell.row.original.dataSource}
          download={cell.row.original.dataSourceFileName}
          target="_blank"
          rel="noreferrer"
          className="underline-on-hover"
        >
          {cell.row.original.dataSourceFileName}
        </a>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        bgColor="white"
        padding="15px"
        borderRadius="8px"
        MinHeight="520px"
        paddingTop="10px"
        sx={{ MinHeight: "520px" }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: "10px" }}>
          <Link to="/overview" underline="hover" color="inherit">
            <span className="breadcrumb-span"> Overview </span>
          </Link>
          <Link to="/overview/category" state={location.state.cat}>
            <span className="breadcrumb-span"> {location.state.cat.categoryName} </span>
          </Link>
          <Link
            to="/overview/category/subcategory"
            state={{ cat: location.state.cat, subCategory: location.state.subCat }}
          >
            <span className="breadcrumb-span"> {catName} </span>
          </Link>
          <span style={{ fontSize: "15px", color: "#8090AA" }}>
            {expType === "exposure" && `Exposure Details`}
            {expType === "assurance" && `Assurance Details`}
            {expType === "incident" && `Incident Experience Details`}
          </span>
        </Breadcrumbs>
        <Grid container>
          <span style={{ fontSize: "21px", fontWeight: "bold" }}>
            {expType === "exposure" && `Exposure Details of`}
            {expType === "assurance" && `Assurance Details of`}
            {expType === "incident" && `Incident Experience Details of`} {catName}
          </span>
        </Grid>
        <ThemeProvider theme={CustomHeightTable}>
          <MaterialReactTable
            columns={Detailscolumns}
            data={tableData || ""}
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: "#F7F8FF !important",
              },
            }}
            // muiTableBodyCellProps={{
            //   sx: {
            //     backgroundColor: "#F7F8FF !important",
            //   },
            // }}
          />
        </ThemeProvider>
      </MDBox>
    </DashboardLayout>
  );
}
export default ExposureDetSubCat;
