/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import React, { useState, useCallback, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { protectedResources, siteData } from "authConfig";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import S3FileUpload from "react-s3";
import { PolarArea, Radar } from "react-chartjs-2";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import { Link } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AWS from "aws-sdk";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { lab } from "chroma-js";
import Plot from "react-plotly.js";
import Scenario from "./scenario.component";
import DynamicTextBoxes from "./DynamicTextBoxes";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, ArcElement);
const S3_BUCKET = "6thsenseriskinsights";
const REGION = "ap-southeast-2";
const ACCESS_KEY = "AKIAUUNF7R2PXJF4AVBU";
const SECRET_ACCESS_KEY = "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";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};
function PolarScatterChart() {
  const data = [
    {
      type: "scatterpolar",
      mode: "markers", // Use 'markers' to create a scatter plot without connecting the points
      r: [1, 2, 3, 4, 5],
      theta: ["A", "B", "C", "D", "A"],
      name: "Category 1",
      marker: {
        symbol: "circle", // Set the marker symbol (optional)
        size: 12, // Set the marker size (optional)
      },
    },
  ];

  const layout = {
    polar: {
      radialaxis: {
        visible: true,
        range: [0, 5],
      },
    },
    showlegend: true,
  };

  return <Plot data={data} layout={layout} />;
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function AnticipateEmergingRisk() {
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  const [TableData, setTableData] = useState(null);
  // get appetite type for column heading

  //  get data from api

  // for save button rendering
  const steps = ["Risk Details", "Sign Posts"];

  // Update the edited data for each row using the index as the key
  const [updatedData, setUpdatedData] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [RiskDetails, setRiskDetails] = useState({});
  const [SignPost, setSignPost] = useState({});
  const [allSubCats, setAllSubCats] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedSignPost, setSelectedSignPost] = useState("");
  const [baseVelocity, setBaseVelocity] = useState("");
  const [fromDt, setFromDt] = React.useState(dayjs("2022-04-17"));
  const [selectedFile, setSelectedFile] = useState(null);
  const [createRisk, setCreateRisk] = useState(false);
  const [reload, setReload] = useState(false);
  const [riskDetailsData, setRiskDetailsData] = useState([]);
  const [showSignPost, setShowSignPost] = useState(false);
  const [value, setValue] = React.useState(0);
  const [radarData, setRadardata] = useState({});
  const [radarOptions, setRadarOptions] = useState({});
  const [scenario, setScenario] = useState("Base");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleUpload = async (file) => {
    // Function to upload file to s3
    // S3 Bucket Name

    // S3 Credentials
    AWS.config.update({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Files Parameters

    const params = {
      Bucket: S3_BUCKET,
      Key: file.name,
      Body: file,
    };

    // Uploading file to s3

    const upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        console.log(`Uploading ${parseInt((evt.loaded * 100) / evt.total)}%`);
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
      // Fille successfully uploaded
      alert("File uploaded successfully.");
    });
  };
  const handleModifyRiskDetails = (id) => {
    console.log(RiskDetails);
    const requestBody = {
      Title: RiskDetails.Title,
      Description: RiskDetails.Description,
      Category: RiskDetails.Category,
      SubCategory: RiskDetails.SubCategory,
      baseConsequenceAmt: RiskDetails.baseConsequenceAmt,
      baseVelocity: RiskDetails.baseVelocity,
      baseQualitative: RiskDetails.baseQualitative,
      baseResponseStatus: RiskDetails.baseResponseStatus,
      pessimisticConsequenceAmt: RiskDetails.pessimisticConsequenceAmt,
      pessimisticVelocity: RiskDetails.pessimisticVelocity,
      pessimisticQualitative: RiskDetails.pessimisticQualitative,
      pessimisticResponseStatus: RiskDetails.pessimisticResponseStatus,
      optimisticConsequenceAmt: RiskDetails.optimisticConsequenceAmt,
      optimisticVelocity: RiskDetails.optimisticVelocity,
      optimisticQualitative: RiskDetails.optimisticQualitative,
      optimisticResponseStatus: RiskDetails.optimisticResponseStatus,
      baseThreatOpp: RiskDetails.baseThreatOpp,
      pessimisticThreatOpp: RiskDetails.pessimisticThreatOpp,
      optimisticThreatOpp: RiskDetails.optimisticThreatOpp,
    };
    try {
      execute(
        "POST",
        `${protectedResources.apiTodoList.endpoint}/anticipateEmergingRisks/modifyRiskDetails/${id}`,
        requestBody
      ).then(() => {
        alert("Risk modified succesfully");
        setShowSignPost(false);
        setReload(true);
        setCreateRisk(false);
        setRiskDetails({});
      });
    } catch {
      /* empty */
    }
  };
  const handleDeleteRiskDetails = (id) => {
    console.log(RiskDetails);
    try {
      execute(
        "DELETE",
        `${protectedResources.apiTodoList.endpoint}/anticipateEmergingRisks/deleteRiskDetails/${id}`
      ).then(() => {
        setShowSignPost(false);
        setReload(true);
        setCreateRisk(false);
      });
    } catch {
      /* empty */
    }
  };
  //
  const handleSignPost = (item) => {
    setRiskDetails(item);
    setShowSignPost(true);
    console.log("RiskDetails", item, RiskDetails);
  };
  const handleScenarioChange = (event, newValue) => {
    setScenario(newValue);
  };
  const handleSelectSignPost = (event, label, newValue) => {
    console.log(newValue);
    console.log("Risk Cate id here", RiskDetails.id);
    setSelectedSignPost(newValue);
    setSignPost({});
    // setType(event.target.value);
    setSignPost((prevState) => ({
      ...prevState,
      type: label,
    }));
    setSignPost((prevState) => ({
      ...prevState,
      riskCategoryId: RiskDetails.id,
    }));
    /* setSignPost((prevState) => ({
      ...prevState,
      desc: label,
    })); */
    setSignPost((prevState) => ({
      ...prevState,
      typeValue: newValue,
    }));
    console.log(SignPost);
  };
  //
  // useEffect to initialize the state once TableData is available
  // handle input change for editing row
  // const [validationErrors, setValidationErrors] = useState({});
  const [, setEditObject] = useState(null);
  // add new appetite
  // const [isSuccess, setisSuccess] = useState(false);
  // const validateRequired = (value) => !!value.length;
  const [formErrors, setFormErrors] = useState({
    Title: "",
    Description: "",
    Taxo_curr: "",
    baseConsequenceAmt: "",
    baseVelocity: "",
    baseQualitative: "",
  });
  const radarconfig = {
    displayModeBar: false,
    responsive: true,
    displaylogo: false,
  };
  useEffect(() => {
    const labels = [];
    setRadardata([]);
    execute("GET", `${protectedResources.apiTodoList.endpoint}/getAllCategoriesSubCategories`).then(
      (response) => {
        if (response) {
          setAllSubCats(response);
          console.log(response);
          response.map((item) => labels.push(item.Category));
          response.map((item) => labels.push(item.Category));

          execute(
            "GET",
            `${protectedResources.apiTodoList.endpoint}/anticipateEmergingRisks/getAllRiskDetails`
          ).then(async (response) => {
            console.log(response);
            if (response?.data) {
              const radarvalues = [];
              const traces = [];
              let r = [];
              let theta = [];
              let marker = {};
              // response.data.map((item) => labels.push(item.Category));
              response.data.map(async (item, index) => {
                r = [];
                theta = [];
                marker = {};
                console.log(item);
                if (scenario === "Base") {
                  if (item.baseVelocity === "Near Term + High") {
                    r.push(50 + index * 5);
                    // theta.push(90 + index * 10);
                  } else if (item.baseVelocity === "Near Term + Low") {
                    r.push(50 - index * 5);
                    // theta.push(210 + index * 10);
                  } else if (item.baseVelocity === "Long Term + High") {
                    r.push(150 + index * 5);
                    // theta.push(190 + index * 10);
                  } else if (item.baseVelocity === "Long Term + Low") {
                    r.push(150 - index * 5);
                    // theta.push(100 + index * 10);
                  }
                  if (item.baseThreatOpp === "gain") theta.push(labels.indexOf(item.Category) * 18);
                  else theta.push(180 + labels.indexOf(item.Category) * 18);
                } else if (scenario === "Pessimistic") {
                  if (item.pessimisticVelocity === "Near Term + High") {
                    r.push(50 + index * 5);
                    // theta.push(90 + index * 10);
                  } else if (item.pessimisticVelocity === "Near Term + Low") {
                    r.push(50 - index * 5);
                    // theta.push(210 + index * 10);
                  } else if (item.pessimisticVelocity === "Long Term + High") {
                    r.push(150 + index * 5);
                    // theta.push(190 + index * 10);
                  } else if (item.pessimisticVelocity === "Long Term + Low") {
                    r.push(150 - index * 5);
                    // theta.push(100 + index * 10);
                  }
                  if (item.pessimisticThreatOpp === "gain")
                    theta.push(labels.indexOf(item.Category) * 18);
                  else theta.push(180 + labels.indexOf(item.Category) * 18);
                } else if (scenario === "Optimistic") {
                  if (item.optimisticVelocity === "Near Term + High") {
                    r.push(50 + index * 5);
                    // theta.push(90 + index * 10);
                  } else if (item.optimisticVelocity === "Near Term + Low") {
                    r.push(50 - index * 5);
                    // theta.push(210 + index * 10);
                  } else if (item.optimisticVelocity === "Long Term + High") {
                    r.push(150 + index * 5);
                    // theta.push(190 + index * 10);
                  } else if (item.optimisticVelocity === "Long Term + Low") {
                    r.push(150 - index * 5);
                    // theta.push(100 + index * 10);
                  }
                  if (item.optimisticThreatOpp === "gain")
                    theta.push(labels.indexOf(item.Category) * 18);
                  else theta.push(180 + labels.indexOf(item.Category) * 18);
                }

                if (item.baseResponseStatus === "Prepared and Tested") {
                  marker = {
                    symbol:
                      "M 0 -6 A 6 6 0 1 1 0 6 A 6 6 0 1 1 0 -6 Z M 0 -9 A 9 9 0 1 0 0 9 A 9 9 0 1 0 0 -9 Z",
                    color: "#D6B70A",
                    size: 20,
                  };
                } else {
                  marker = {
                    symbol:
                      "M 0 -6 A 6 6 0 1 1 0 6 A 6 6 0 1 1 0 -6 Z M 0 -9 A 9 9 0 1 0 0 9 A 9 9 0 1 0 0 -9 Z",
                    color: "green",
                    size: 20,
                  };
                }

                traces.push({
                  type: "scatterpolar",
                  mode: "markers",
                  r,
                  theta,
                  hoverinfo: "text",
                  hovertext: `Risk Details: ${item.Title}`,
                  marker,
                });
                radarvalues.push(item.baseConsequenceAmt);
              });
              setRadarOptions({
                polar: {
                  radialaxis: {
                    tickvals: [0, 50, 100, 150, 200], // Adjust these values based on your categories
                    ticktext: ["Current", "Near Term", "Mid Term", "Long Term", "Distant"],
                    angle: 90, // Rotate the radial axis to align with the straight line
                    tickangle: "90", // Automatically adjust tick angles
                    tickmode: "array",
                    tickfont: {
                      size: 10,
                    },
                  },
                  angularaxis: {
                    tickvals: [
                      0, 18, 36, 54, 72, 90, 108, 126, 144, 162, 180, 198, 216, 234, 252, 270, 288,
                      306, 324, 342,
                    ], // Adjust these values based on your categories
                    ticktext: labels,
                    categoryorder: "array",
                    tickmode: "array",
                    tickangle: "0", // Automatically adjust tick angles
                    tickfont: {
                      size: 10,
                    },
                  },
                },
                showlegend: false,
                width: 800, // Adjust the width of the chart
                height: 600, // Adjust the height of the chart
                annotations: [
                  {
                    text: "OPPORTUNITY",
                    x: 0.5,
                    y: 1.2,
                    xref: "paper",
                    yref: "paper",
                    showarrow: false,
                    font: {
                      size: 16,
                    },
                  },
                  {
                    text: "THREAT",
                    x: 0.5,
                    y: -0.2,
                    xref: "paper",
                    yref: "paper",
                    showarrow: false,
                    font: {
                      size: 16,
                    },
                  },
                ],
              });
              /*         setRadardata([
          {
            type: "scatterpolar",
            mode: "markers",
            r: [50, 80],
            theta: [90, 210],
            hoverinfo: "text",
            hovertext: "<a href='https://example.com' target='_blank'>Click me!</a>",
            marker: {
              symbol:
                "M 0 -6 A 6 6 0 1 1 0 6 A 6 6 0 1 1 0 -6 Z M 0 -9 A 9 9 0 1 0 0 9 A 9 9 0 1 0 0 -9 Z",
              color: "#D6B70A",
              size: 20,
            },
          },
          {
            type: "scatterpolar",
            mode: "markers",
            r: [120, 170, 200],
            theta: [330, 100, 150],
            hovertext: "text",
            marker: {
              symbol:
                "M 0 -6 A 6 6 0 1 1 0 6 A 6 6 0 1 1 0 -6 Z M 0 -9 A 9 9 0 1 0 0 9 A 9 9 0 1 0 0 -9 Z",
              color: "green",
              size: 20,
            },
          },
        ]); */
              console.log("traces", traces);
              setRadardata(traces);
              console.log("radardata", radarData);
              setRiskDetailsData(response.data);
            }
          });
        }
      }
    );
  }, [execute, createRisk, scenario, reload]);
  const validateRiskDetailsFields = () => {
    let isValid = true;
    const errors = {};

    // Validate Taxonomy Name
    if (!RiskDetails.Title) {
      isValid = false;
      errors.Title = "Title is required";
    }

    setFormErrors(errors);
    return isValid;
  };
  const handleSignPostsChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setSignPost((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log("SignPost", SignPost);
  };
  const handleRiskDetailsChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setRiskDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(RiskDetails);
  };
  const handleNext = () => {
    let isValid = true;

    if (activeStep === 0) {
      isValid = validateRiskDetailsFields();
    }

    if (activeStep === 1) {
      isValid = validateRiskDetailsFields();
    }

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
    const selectedData = allSubCats.find((item) => item.Category === newValue);
    if (selectedData) {
      setSubCategories(selectedData.SubCategory);
      setSelectedSubCategory();
    } else {
      setSubCategories([]);
    }
  };
  const handleSubCategoryChange = (event, newValue) => {
    setSelectedSubCategory(newValue);
  };
  const handleBaseVelocityChange = (name, newValue) => {
    console.log(newValue, name);
    setRiskDetails((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };
  const handleSignPostChange = (name, newValue) => {
    console.log(newValue);
    setSignPost((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep === 0) {
      setCreateRisk(false);
    }
  };

  const saveSignPost = async () => {
    // Create the request body with the form data
    let parameters = {};
    try {
      // send req
      console.log("SignPost", SignPost);

      if (SignPost.typeValue === "3") {
        parameters = {
          file: selectedFile.name,
          date_row: 1,
          value_row: 1,
        };
      } else if (SignPost.typeValue === "1") {
        parameters = {
          stock: SignPost.stockCode,
          from_dt: SignPost.fromDate,
        };
      } else if (SignPost.typeValue === "4") {
        parameters = {
          url: SignPost.url,
          date_col: SignPost.date_col,
          value_col: SignPost.value_col,
          filter_col: SignPost.filter_col,
          filter_value: SignPost.filter_value,
        };
      } else if (SignPost.typeValue === "5") {
        parameters = {
          ticker: SignPost.ticker,
          tickerFreq: SignPost.tickerFreq,
        };
      } else {
        parameters = {
          fx: SignPost.fx,
          from_dt: SignPost.fromDt,
        };
      }
      console.log("parameters", parameters);
      const data = {
        id: uuidv4(),
        riskCategoryId: RiskDetails.id,
        type: SignPost.typeValue,
        parameters,
        noofiterations: SignPost.iterations,
        timeframe: SignPost.timeframe,
        date_next_run: new Date().toUTCString(),
        optValue: SignPost.optValue,
        pessValue: SignPost.pessValue,
        desc: SignPost.desc,
      };
      console.log(data);
      await execute(
        "POST",
        `${protectedResources.apiTodoList.endpoint}/anticipateEmergingRisks/createSignPost`,
        data
      ).then(() => {
        setSelectedSignPost("");
        setSignPost({});
        setRiskDetails({});
        setCreateRisk(false);
      });
    } catch (err) {
      // Handle error
    }
  };

  const postRiskData = async () => {
    // Create the request body with the form data
    const requestBody = {
      Title: RiskDetails.Title,
      Description: RiskDetails.Description,
      Category: selectedCategory,
      SubCategory: selectedSubCategory,
      baseConsequenceAmt: RiskDetails.baseConsequenceAmt,
      baseVelocity: RiskDetails.baseVelocity,
      baseQualitative: RiskDetails.baseQualitative,
      baseResponseStatus: RiskDetails.baseResponseStatus,
      pessimisticConsequenceAmt: RiskDetails.pessimisticConsequenceAmt,
      pessimisticVelocity: RiskDetails.pessimisticVelocity,
      pessimisticQualitative: RiskDetails.pessimisticQualitative,
      pessimisticResponseStatus: RiskDetails.pessimisticResponseStatus,
      optimisticConsequenceAmt: RiskDetails.optimisticConsequenceAmt,
      optimisticVelocity: RiskDetails.optimisticVelocity,
      optimisticQualitative: RiskDetails.optimisticQualitative,
      optimisticResponseStatus: RiskDetails.optimisticResponseStatus,
      baseThreatOpp: RiskDetails.baseThreatOpp,
      pessimisticThreatOpp: RiskDetails.pessimisticThreatOpp,
      optimisticThreatOpp: RiskDetails.optimisticThreatOpp,
    };
    handleNext();
    try {
      // send req
      console.log(RiskDetails);
      console.log(requestBody);
      await execute(
        "POST",
        `${protectedResources.apiTodoList.endpoint}/anticipateEmergingRisks/createRiskDetails`,
        requestBody
      ).then(async (response) => {
        console.log(response);
        setSelectedSignPost("");
        setRiskDetails((prevState) => ({
          ...prevState,
          riskCategoryId: response.data.id,
        }));
        setRiskDetails((prevState) => ({
          ...prevState,
          id: response.data.id,
        }));
        setSignPost({});
      });
    } catch (err) {
      // Handle error
    }
  };
  // dialog box
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <form>
        {createRisk ? (
          <Grid
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "15px",
              minHeight: "520px",
            }}
          >
            {" "}
            <Grid item md={12}>
              <MDBox alignItems="center" justifyContent="space-between" px={2} py={2}>
                <MDTypography variant="h5" gutterBottom>
                  Create Risks
                </MDTypography>
                <MDTypography variant="body2" gutterBottom>
                  Enter emerging risk summary here, and attach files for detailled information
                </MDTypography>
                <Divider />
                <MDTypography variant="h5" gutterBottom>
                  Scenario Details
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "15px",
                minHeight: "520px",
              }}
            >
              <Stepper
                activeStep={activeStep}
                sx={{ p: "20px", pb: "35px", pl: "5%", pr: "12%", textAlign: "center" }}
              >
                {steps.map((label) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === 0 ? (
                <Grid container spacing={4} sx={{ pl: "8px", pb: "90px" }}>
                  <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                    <TextField
                      label="Title"
                      variant="outlined"
                      name="Title"
                      sx={{ width: "30%", marginRight: "10px" }}
                      value={RiskDetails.Title || ""}
                      onChange={handleRiskDetailsChange}
                      required
                      error={!!formErrors.Title}
                      helperText={formErrors.Title}
                    />
                    <TextField
                      label="Description"
                      variant="outlined"
                      name="Description"
                      sx={{ width: "60%" }}
                      value={RiskDetails.Description || ""}
                      onChange={handleRiskDetailsChange}
                      required
                      error={!!formErrors.Description}
                      helperText={formErrors.Description}
                    />
                    <br />
                  </Grid>
                  <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                    {allSubCats ? (
                      <MDBox display="flex">
                        <Autocomplete
                          sx={{ width: "30%", marginRight: "10px" }}
                          value={selectedCategory}
                          onChange={(event, newValue) => handleCategoryChange(event, newValue)}
                          options={allSubCats.map((item) => item.Category)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Category"
                              variant="outlined"
                              width="30%"
                            />
                          )}
                        />
                        <Autocomplete
                          sx={{ width: "30%" }}
                          value={selectedSubCategory}
                          onChange={(event, newValue) => handleSubCategoryChange(event, newValue)}
                          options={subCategories}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sub Category"
                              variant="outlined"
                              width="30%"
                            />
                          )}
                        />
                      </MDBox>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item md={12}>
                    <Divider
                      sx={{
                        m: "10px",
                        borderWidth: "1.5px",
                        color: "black",
                        mt: "15px",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid container columns={12} spacing={2}>
                    <Grid item md={4} sm={4} xs={4} lg={4}>
                      <Card sx={{ padding: 2 }}>
                        <CardContent>
                          {" "}
                          <MDTypography variant="h4" sx={{ color: "#52009b" }}>
                            Base Scenario
                          </MDTypography>
                        </CardContent>
                        <MDTypography variant="h6" sx={{ color: "#52009b" }}>
                          Time Horizon and Velocity
                        </MDTypography>
                        <Autocomplete
                          sx={{ pd: 2 }}
                          value={RiskDetails.baseVelocity || ""}
                          onChange={(event, newValue) =>
                            handleBaseVelocityChange("baseVelocity", newValue?.label ?? "")
                          }
                          options={[
                            { label: "Near Term + High", value: "1" },
                            { label: "Near Term + Low", value: "2" },
                            { label: "Long Term + High", value: "3" },
                            { label: "Long Term + Low", value: "4" },
                          ]}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                        <MDTypography variant="h6" sx={{ pd: 2, color: "#52009b" }} gutterBottom>
                          Consequence
                        </MDTypography>
                        <FormGroup>
                          <FormControlLabel
                            sx={{ pd: 2 }}
                            control={<Checkbox defaultChecked />}
                            label="Dollar"
                          />
                        </FormGroup>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue="loss"
                            value={RiskDetails.baseThreatOpp || ""}
                            onChange={(event, newValue) => {
                              console.log("Fired radio byton", newValue ?? "");
                              handleBaseVelocityChange("baseThreatOpp", newValue ?? "");
                            }}
                            sx={{ pd: 2 }}
                          >
                            <FormControlLabel
                              value="loss"
                              control={<Radio />}
                              label="Threat/Loss"
                            />
                            <FormControlLabel
                              value="gain"
                              control={<Radio />}
                              label="Oppurtunity/Gain"
                            />
                          </RadioGroup>
                        </FormControl>
                        <TextField
                          label="Enter Amount($)"
                          variant="outlined"
                          name="baseConsequenceAmt"
                          sx={{ width: "60%" }}
                          value={RiskDetails.baseConsequenceAmt || ""}
                          onChange={(event, newValue) => handleRiskDetailsChange(event, newValue)}
                          required
                          error={!!formErrors.baseConsequenceAmt}
                          helperText={formErrors.baseConsequenceAmt}
                        />
                        <FormGroup>
                          <FormControlLabel
                            sx={{ pd: 2 }}
                            control={<Checkbox defaultChecked />}
                            label="Qualitative"
                          />
                        </FormGroup>
                        <Autocomplete
                          sx={{ pd: 2 }}
                          value={RiskDetails.baseQualitative || ""}
                          onChange={(event, newValue) =>
                            handleBaseVelocityChange("baseQualitative", newValue?.label ?? "")
                          }
                          options={[
                            { label: "Level 1", value: "1" },
                            { label: "Level 2", value: "2" },
                            { label: "Level 3", value: "3" },
                            { label: "Level 4", value: "4" },
                            { label: "Level 5", value: "5" },
                          ]}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                        <MDTypography variant="h6" gutterBottom sx={{ pd: 2, color: "#52009b" }}>
                          Response Status
                        </MDTypography>
                        <Autocomplete
                          sx={{ pd: 2 }}
                          value={RiskDetails.baseResponseStatus || ""}
                          onChange={(event, newValue) =>
                            handleBaseVelocityChange("baseResponseStatus", newValue?.label ?? "")
                          }
                          options={[
                            { label: "Prepared and Tested", value: "1" },
                            { label: "Not yet Prepared", value: "2" },
                            { label: "Passive Pursuit", value: "3" },
                          ]}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                      </Card>
                    </Grid>
                    <Grid item md={4} sm={4} xs={4} lg={4}>
                      <Card sx={{ padding: 2 }}>
                        <CardContent>
                          {" "}
                          <MDTypography variant="h4" sx={{ color: "#52009b" }}>
                            Pessimistic Scenario
                          </MDTypography>
                        </CardContent>
                        <MDTypography variant="h6" sx={{ color: "#52009b" }}>
                          Time Horizon and Velocity
                        </MDTypography>
                        <Autocomplete
                          sx={{ pd: 2 }}
                          value={RiskDetails.pessimisticVelocity || ""}
                          onChange={(event, newValue) =>
                            handleBaseVelocityChange("pessimisticVelocity", newValue?.label ?? "")
                          }
                          options={[
                            { label: "Near Term + High", value: "1" },
                            { label: "Near Term + Low", value: "2" },
                            { label: "Long Term + High", value: "3" },
                            { label: "Long Term + Low", value: "4" },
                          ]}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                        <MDTypography variant="h6" gutterBottom sx={{ pd: 2, color: "#52009b" }}>
                          Consequence
                        </MDTypography>
                        <FormGroup>
                          <FormControlLabel
                            sx={{ pd: 2 }}
                            control={<Checkbox defaultChecked />}
                            label="Dollar"
                          />
                        </FormGroup>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue="loss"
                            value={RiskDetails.pessimisticThreatOpp || ""}
                            onChange={(event, newValue) =>
                              handleBaseVelocityChange("pessimisticThreatOpp", newValue ?? "")
                            }
                            sx={{ pd: 2 }}
                          >
                            <FormControlLabel
                              value="loss"
                              control={<Radio />}
                              label="Threat/Loss"
                            />
                            <FormControlLabel
                              value="gain"
                              control={<Radio />}
                              label="Oppurtunity/Gain"
                            />
                          </RadioGroup>
                        </FormControl>
                        <TextField
                          label="Enter Amount($)"
                          variant="outlined"
                          name="pessimisticConsequenceAmt"
                          sx={{ width: "60%" }}
                          value={RiskDetails.pessimisticConsequenceAmt || ""}
                          onChange={(event, newValue) => handleRiskDetailsChange(event, newValue)}
                          required
                          error={!!formErrors.pessimisticConsequenceAmt}
                          helperText={formErrors.pessimisticConsequenceAmt}
                        />
                        <FormGroup>
                          <FormControlLabel
                            sx={{ pd: 2 }}
                            control={<Checkbox defaultChecked />}
                            label="Qualitative"
                          />
                        </FormGroup>
                        <Autocomplete
                          sx={{ pd: 2 }}
                          value={RiskDetails.pessimisticQualitative || ""}
                          onChange={(event, newValue) =>
                            handleBaseVelocityChange(
                              "pessimisticQualitative",
                              newValue?.label ?? ""
                            )
                          }
                          options={[
                            { label: "Level 1", value: "1" },
                            { label: "Level 2", value: "2" },
                            { label: "Level 3", value: "3" },
                            { label: "Level 4", value: "4" },
                            { label: "Level 5", value: "5" },
                          ]}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                        <MDTypography sx={{ pd: 2, color: "#52009b" }} variant="h6" gutterBottom>
                          Response Status
                        </MDTypography>
                        <Autocomplete
                          sx={{ pd: 2 }}
                          value={RiskDetails.pessimisticResponseStatus || ""}
                          onChange={(event, newValue) =>
                            handleBaseVelocityChange(
                              "pessimisticResponseStatus",
                              newValue?.label ?? ""
                            )
                          }
                          options={[
                            { label: "Prepared and Tested", value: "1" },
                            { label: "Not yet Prepared", value: "2" },
                            { label: "Passive Pursuit", value: "3" },
                          ]}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                      </Card>
                    </Grid>
                    <Grid item md={4} sm={4} xs={4} lg={4}>
                      <Card sx={{ padding: 2 }}>
                        <CardContent>
                          {" "}
                          <MDTypography variant="h4" sx={{ color: "#52009b" }}>
                            Optimistic Scenario
                          </MDTypography>
                        </CardContent>
                        <MDTypography variant="h6" sx={{ color: "#52009b" }}>
                          Time Horizon and Velocity
                        </MDTypography>
                        <Autocomplete
                          sx={{ pd: 2 }}
                          value={RiskDetails.optimisticVelocity || ""}
                          onChange={(event, newValue) =>
                            handleBaseVelocityChange("optimisticVelocity", newValue?.label ?? "")
                          }
                          options={[
                            { label: "Near Term + High", value: "1" },
                            { label: "Near Term + Low", value: "2" },
                            { label: "Long Term + High", value: "3" },
                            { label: "Long Term + Low", value: "4" },
                          ]}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                        <MDTypography sx={{ pd: 2, color: "#52009b" }} variant="h6" gutterBottom>
                          Consequence
                        </MDTypography>
                        <FormGroup>
                          <FormControlLabel
                            sx={{ pd: 2 }}
                            control={<Checkbox defaultChecked />}
                            label="Dollar"
                          />
                        </FormGroup>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue="loss"
                            sx={{ pd: 2 }}
                            value={RiskDetails.optimisticThreatOpp || ""}
                            onChange={(event, newValue) =>
                              handleBaseVelocityChange("optimisticThreatOpp", newValue ?? "")
                            }
                          >
                            <FormControlLabel
                              value="loss"
                              control={<Radio />}
                              label="Threat/Loss"
                            />
                            <FormControlLabel
                              value="gain"
                              control={<Radio />}
                              label="Oppurtunity/Gain"
                            />
                          </RadioGroup>
                        </FormControl>
                        <TextField
                          label="Enter Amount($)"
                          variant="outlined"
                          name="optimisticConsequenceAmt"
                          sx={{ width: "60%" }}
                          value={RiskDetails.optimisticConsequenceAmt || ""}
                          onChange={(event, newValue) => handleRiskDetailsChange(event, newValue)}
                          required
                          error={!!formErrors.optimisticConsequenceAmt}
                          helperText={formErrors.optimisticConsequenceAmt}
                        />
                        <FormGroup>
                          <FormControlLabel
                            sx={{ pd: 2 }}
                            control={<Checkbox defaultChecked />}
                            label="Qualitative"
                          />
                        </FormGroup>
                        <Autocomplete
                          sx={{ pd: 2 }}
                          value={RiskDetails.optimisticQualitative || ""}
                          onChange={(event, newValue) =>
                            handleBaseVelocityChange("optimisticQualitative", newValue?.label ?? "")
                          }
                          options={[
                            { label: "Level 1", value: "1" },
                            { label: "Level 2", value: "2" },
                            { label: "Level 3", value: "3" },
                            { label: "Level 4", value: "4" },
                            { label: "Level 5", value: "5" },
                          ]}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                        <MDTypography sx={{ pd: 2, color: "#52009b" }} variant="h6" gutterBottom>
                          Response Status
                        </MDTypography>
                        <Autocomplete
                          sx={{ pd: 2 }}
                          value={RiskDetails.optimisticResponseStatus || ""}
                          onChange={(event, newValue) =>
                            handleBaseVelocityChange(
                              "optimisticResponseStatus",
                              newValue?.label ?? ""
                            )
                          }
                          options={[
                            { label: "Prepared and Tested", value: "1" },
                            { label: "Not yet Prepared", value: "2" },
                            { label: "Passive Pursuit", value: "3" },
                          ]}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {activeStep === 1 ? (
                <Grid container spacing={4} sx={{ pl: "8px", pb: "90px" }}>
                  {selectedSignPost === "" ? (
                    <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                      <DynamicTextBoxes />
                      <MDTypography sx={{ pd: 2, color: "#52009b" }} variant="h6" gutterBottom>
                        Search SignPost
                      </MDTypography>
                      <Autocomplete
                        sx={{ pd: 2 }}
                        value={RiskDetails.selectedSignPost || ""}
                        width="60%"
                        onChange={(event, newValue) =>
                          handleSelectSignPost(event, newValue.label, newValue.value)
                        }
                        options={[
                          { label: "Stock Tracker(Source: Yahoo)", value: "1" },
                          { label: "FX Tracker(Source: Alpha Vantage)", value: "2" },
                          { label: "Custom Excel", value: "3" },
                          { label: "Online Excel", value: "4" },
                          { label: "EconDB(Source: www.econdb.com)", value: "5" },
                        ]}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                        <TextField
                          label="Data Source"
                          variant="outlined"
                          name="signPost"
                          sx={{ width: "60%" }}
                          value={SignPost.type || ""}
                          disabled
                        />
                      </Grid>
                      <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                        <TextField
                          required
                          sx={{ py: 1, pr: 1 }}
                          id="outlined-required"
                          label="Desc"
                          name="desc"
                          value={SignPost.desc || ""}
                          onChange={(event, newValue) => handleSignPostsChange(event, newValue)}
                          error={!!formErrors.desc}
                          helperText={formErrors.desc}
                        />
                        {SignPost.typeValue === "1" ? (
                          <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                            <MDBox display="flex" sx={{ my: 2 }}>
                              <TextField
                                required
                                sx={{ py: 1, pr: 1 }}
                                id="outlined-required"
                                label="Stock Code"
                                name="stockCode"
                                value={SignPost.stockCode || ""}
                                onChange={(event, newValue) =>
                                  handleSignPostsChange(event, newValue)
                                }
                                error={!!formErrors.stockCode}
                                helperText={formErrors.stockCode}
                              />
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    label="From Date"
                                    value={fromDt}
                                    onChange={(newValue) => {
                                      console.log(
                                        "Date change",
                                        new Date(newValue).toISOString().split("T")[0]
                                      );
                                      handleSignPostChange(
                                        "fromDate",
                                        new Date(newValue).toISOString().split("T")[0]
                                      );
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </MDBox>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {SignPost.typeValue === "2" ? (
                          <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                            <MDBox display="flex" sx={{ my: 2 }}>
                              <TextField
                                required
                                sx={{ py: 1, pr: 1 }}
                                id="outlined-required"
                                label="FX indicator"
                                name="fx"
                                value={SignPost.fx || ""}
                                onChange={(event, newValue) =>
                                  handleSignPostsChange(event, newValue)
                                }
                                error={!!formErrors.fx}
                                helperText={formErrors.fx}
                              />
                            </MDBox>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {SignPost.typeValue === "3" ? (
                          <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                            <MDBox display="flex" sx={{ my: 2 }}>
                              <input type="file" onChange={handleFileInput} />
                              <button onClick={() => handleUpload(selectedFile)}> Upload</button>
                            </MDBox>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {SignPost.typeValue === "4" ? (
                          <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                            <MDBox display="flex" sx={{ my: 2 }}>
                              <TextField
                                required
                                sx={{ py: 1, pr: 1 }}
                                id="outlined-required"
                                label="URL"
                                name="url"
                                value={SignPost.url || ""}
                                onChange={(event, newValue) =>
                                  handleSignPostsChange(event, newValue)
                                }
                                error={!!formErrors.url}
                                helperText={formErrors.url}
                              />
                              <TextField
                                required
                                sx={{ py: 1, pr: 1 }}
                                id="outlined-required"
                                label="Date Column"
                                name="date_col"
                                value={SignPost.date_col || ""}
                                onChange={(event, newValue) =>
                                  handleSignPostsChange(event, newValue)
                                }
                                error={!!formErrors.date_col}
                                helperText={formErrors.date_col}
                              />
                              <TextField
                                required
                                sx={{ py: 1, pr: 1 }}
                                id="outlined-required"
                                label="Value Colum"
                                name="value_col"
                                value={SignPost.value_col || ""}
                                onChange={(event, newValue) =>
                                  handleSignPostsChange(event, newValue)
                                }
                                error={!!formErrors.value_col}
                                helperText={formErrors.value_col}
                              />
                              <TextField
                                required
                                sx={{ py: 1, pr: 1 }}
                                id="outlined-required"
                                label="Filter Column"
                                name="filter_col"
                                value={SignPost.filter_col || ""}
                                onChange={(event, newValue) =>
                                  handleSignPostsChange(event, newValue)
                                }
                                error={!!formErrors.filter_col}
                                helperText={formErrors.filter_col}
                              />
                              <TextField
                                required
                                sx={{ py: 1, pr: 1 }}
                                id="outlined-required"
                                label="Filter Value"
                                name="filter_value"
                                value={SignPost.filter_value || ""}
                                onChange={(event, newValue) =>
                                  handleSignPostsChange(event, newValue)
                                }
                                error={!!formErrors.filter_value}
                                helperText={formErrors.filter_value}
                              />
                            </MDBox>
                          </Grid>
                        ) : (
                          ""
                        )}
                        {SignPost.typeValue === "5" ? (
                          <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                            <TextField
                              required
                              sx={{ py: 1, pr: 1 }}
                              id="outlined-required"
                              label="Ticker"
                              name="ticker"
                              value={SignPost.ticker || ""}
                              onChange={(event, newValue) => handleSignPostsChange(event, newValue)}
                              error={!!formErrors.ticker}
                              helperText={formErrors.ticker}
                            />
                            <Autocomplete
                              sx={{ width: "30%", pr: 2 }}
                              value={SignPost.tickerFreq || ""}
                              onChange={(event, newValue) =>
                                handleSignPostChange("tickerFreq", newValue.label)
                              }
                              options={[
                                { label: "Monthly", value: "Monthly" },
                                { label: "Quarterly", value: "Quarterly" },
                                { label: "Yearly", value: "Yearly" },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} label="Ticker Freq" variant="outlined" />
                              )}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                        <MDTypography sx={{ pd: 2, color: "#52009b" }} variant="h6" gutterBottom>
                          Consequence
                        </MDTypography>
                        <TextField
                          label="Optimistic Value"
                          variant="outlined"
                          name="optValue"
                          sx={{ width: "30%", pr: 2 }}
                          value={SignPost.optValue || ""}
                          onChange={(event, newValue) => handleSignPostsChange(event, newValue)}
                          required
                          error={!!formErrors.optValue}
                          helperText={formErrors.optValue}
                        />
                        <TextField
                          label="Pessimistic Value"
                          variant="outlined"
                          name="pessValue"
                          sx={{ width: "30%", pr: 2 }}
                          value={SignPost.pessValue || ""}
                          onChange={(event, newValue) => handleSignPostsChange(event, newValue)}
                          required
                          error={!!formErrors.pessValue}
                          helperText={formErrors.pessValue}
                        />
                        <MDTypography sx={{ pd: 2, color: "#52009b" }} variant="h6" gutterBottom>
                          Predictive Analysis
                        </MDTypography>
                        <MDBox display="flex">
                          <Autocomplete
                            sx={{ width: "30%", pr: 2 }}
                            value={SignPost.iterations || ""}
                            onChange={(event, newValue) =>
                              handleSignPostChange("iterations", newValue.label)
                            }
                            options={[
                              { label: "5", value: "5" },
                              { label: "10", value: "10" },
                              { label: "20", value: "20" },
                            ]}
                            renderInput={(params) => (
                              <TextField {...params} label="Iterations" variant="outlined" />
                            )}
                          />
                          <Autocomplete
                            sx={{ width: "30%", pr: 2 }}
                            value={SignPost.timeframe || ""}
                            onChange={(event, newValue) =>
                              handleSignPostChange("timeframe", newValue.label)
                            }
                            options={[
                              { label: "Near Term", value: "150" },
                              { label: "Mid Term", value: "300" },
                              { label: "Long Term", value: "450" },
                            ]}
                            renderInput={(params) => (
                              <TextField {...params} label="Timeframes" variant="outlined" />
                            )}
                          />
                        </MDBox>
                      </Grid>
                    </>
                  )}
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Grid container>
              <Grid item md={12}>
                <Divider
                  sx={{
                    m: "10px",
                    borderWidth: "1.5px",
                    color: "black",
                    mt: "15px",
                    width: "100%",
                  }}
                />
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 2,
                    justifyContent: "flex-end",
                  }}
                >
                  <MDButton
                    color="black"
                    variant="outlined"
                    // disabled={activeStep === 0}
                    onClick={handleBack}
                    // onClick={clickedId ? handleNext : handleBack}
                    sx={{ mr: 3.5, color: "#464d60", borderColor: "#3d4458" }}
                  >
                    {activeStep === 1 ? "Cancel" : "Back"}
                  </MDButton>
                  <MDButton
                    onClick={activeStep === 0 ? postRiskData : saveSignPost}
                    backgroundColor="#52009b"
                    variant="contained"
                    color="#52009b"
                    sx={{ color: "#f7f4fb" }}
                  >
                    {activeStep === 0 ? "Save" : "Save SignPost"}
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        ) : !showSignPost ? (
          <Grid
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "15px",
              minHeight: "520px",
            }}
          >
            <Grid item md={12}>
              <MDBox alignItems="center" justifyContent="space-between" px={2} py={2}>
                <MDTypography variant="h5" gutterBottom>
                  Emerging Risks
                </MDTypography>
                <MDTypography variant="body2" gutterBottom>
                  You can click on the emerging risk to view the details
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid container direction="column">
              <Grid item container columns="16">
                <Grid item xs={10}>
                  {riskDetailsData && riskDetailsData.length > 0 ? (
                    <>
                      <MDTypography variant="body2" gutterBottom px={2}>
                        Assigned Response Status
                      </MDTypography>
                      <MDBox display="flex" px={2} justifyContent="left" alignItems="center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          width="15"
                          height="15"
                        >
                          <circle cx="10" cy="10" r="10" fill="green" />
                        </svg>
                        <MDTypography variant="body2" gutterBottom px={2}>
                          PREPARED
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" px={2} justifyContent="left" alignItems="center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          width="15"
                          height="15"
                        >
                          <circle cx="10" cy="10" r="10" fill="#D6B70A" />
                        </svg>
                        <MDTypography variant="body2" gutterBottom px={2}>
                          UNPREPARED
                        </MDTypography>
                      </MDBox>
                      <Plot data={radarData} layout={radarOptions} config={radarconfig} />
                    </>
                  ) : (
                    // <PolarScatterChart />
                    "Loading... "
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    padding: "15px",
                  }}
                >
                  <MDBox
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    justifyContent="space-between"
                    px={2}
                  >
                    <MDTypography variant="body2" gutterBottom>
                      Select Scenario
                    </MDTypography>
                    <RadioGroup
                      row
                      aria-label="scenario"
                      name="scenario"
                      value={scenario}
                      onChange={handleScenarioChange}
                    >
                      <FormControlLabel value="Base" control={<Radio />} label="Base" />
                      <FormControlLabel
                        value="Pessimistic"
                        control={<Radio />}
                        label="Pessimistic"
                      />
                      <FormControlLabel value="Optimistic" control={<Radio />} label="Optimistic" />
                    </RadioGroup>
                  </MDBox>
                  <MDBox alignItems="center" justifyContent="space-between" px={2} py={2}>
                    <MDBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        pt: 2,
                        justifyContent: "flex-end",
                      }}
                    >
                      {" "}
                      <MDButton
                        variant="text"
                        color="black"
                        onClick={() => {
                          setCreateRisk(true);
                          setRiskDetails({});
                        }}
                        sx={{ color: "#52009b" }}
                      >
                        +Create Risk
                      </MDButton>
                    </MDBox>
                    {riskDetailsData.map((item) => (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <MDTypography>{item.Title}</MDTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <MDTypography>{item.Description}</MDTypography>
                          <MDTypography variant="h6">Base Scenario</MDTypography>

                          <MDTypography variant="body2">
                            {"-> "}
                            {item.baseResponseStatus}
                          </MDTypography>
                          <MDTypography variant="body2">
                            {"-> "}
                            {item.baseVelocity}
                          </MDTypography>
                          <MDTypography variant="body2">
                            {"-> "}
                            {item.baseQualitative}
                          </MDTypography>
                          <MDBox display="flex">
                            <MDButton
                              variant="text"
                              color="black"
                              sx={{ color: "#52009b" }}
                              onClick={() => {
                                console.log("Setting risk", item);
                                setRiskDetails(item);
                                setShowSignPost(true);
                                setValue(0);
                              }}
                            >
                              Risk Details
                            </MDButton>
                            <MDButton
                              variant="text"
                              color="black"
                              sx={{ color: "#52009b" }}
                              onClick={() => {
                                handleSignPost(item);
                              }}
                            >
                              Sign Posts
                            </MDButton>
                          </MDBox>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </form>
      {showSignPost ? (
        <Grid
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "15px",
            minHeight: "520px",
          }}
        >
          {" "}
          <MDBox sx={{ width: "100%" }}>
            <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Risk Details" {...a11yProps(0)} />
                <Tab label="Sign Posts" {...a11yProps(1)} />
              </Tabs>
            </MDBox>
            <CustomTabPanel value={value} index={0}>
              <Grid container spacing={4} sx={{ pl: "8px", pb: "90px" }}>
                <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                  <TextField
                    label="Title"
                    variant="outlined"
                    name="Title"
                    sx={{ width: "30%", marginRight: "10px" }}
                    value={RiskDetails.Title || ""}
                    onChange={handleRiskDetailsChange}
                    required
                    error={!!formErrors.Title}
                    helperText={formErrors.Title}
                  />
                  <TextField
                    label="Description"
                    variant="outlined"
                    name="Description"
                    sx={{ width: "60%" }}
                    value={RiskDetails.Description || ""}
                    onChange={handleRiskDetailsChange}
                    required
                    error={!!formErrors.Description}
                    helperText={formErrors.Description}
                  />
                  <br />
                </Grid>
                <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                  {allSubCats ? (
                    <>
                      <MDBox display="flex">
                        <Autocomplete
                          sx={{ width: "30%", marginRight: "10px" }}
                          value={RiskDetails.Category || ""}
                          onChange={(event, newValue) => handleCategoryChange(event, newValue)}
                          options={allSubCats.map((item) => item.Category)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Category"
                              variant="outlined"
                              width="30%"
                            />
                          )}
                        />
                        <Autocomplete
                          sx={{ width: "30%" }}
                          value={RiskDetails.SubCategory || ""}
                          onChange={(event, newValue) => handleSubCategoryChange(event, newValue)}
                          options={subCategories}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sub Category"
                              variant="outlined"
                              width="30%"
                            />
                          )}
                        />
                      </MDBox>
                      <MDBox
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          pt: 2,
                          justifyContent: "flex-end",
                        }}
                      >
                        {" "}
                        <MDButton
                          variant="text"
                          color="black"
                          onClick={() => {
                            handleDeleteRiskDetails(RiskDetails.id);
                          }}
                          sx={{ color: "#52009b" }}
                        >
                          Delete Risk
                        </MDButton>{" "}
                        <MDButton
                          variant="text"
                          color="black"
                          onClick={() => {
                            handleModifyRiskDetails(RiskDetails.id);
                          }}
                          sx={{ color: "#52009b" }}
                        >
                          Modify Risk
                        </MDButton>
                      </MDBox>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={12}>
                  <Divider
                    sx={{
                      m: "10px",
                      borderWidth: "1.5px",
                      color: "black",
                      mt: "15px",
                      width: "100%",
                    }}
                  />
                </Grid>
                <Grid container columns={12} spacing={2}>
                  <Grid item md={4} sm={4} xs={4} lg={4}>
                    <Card sx={{ padding: 2 }}>
                      <CardContent>
                        {" "}
                        <MDTypography variant="h4" sx={{ color: "#52009b" }}>
                          Base Scenario
                        </MDTypography>
                      </CardContent>
                      <MDTypography variant="h6" sx={{ color: "#52009b" }}>
                        Time Horizon and Velocity
                      </MDTypography>
                      <Autocomplete
                        sx={{ pd: 2 }}
                        value={RiskDetails.baseVelocity || ""}
                        onChange={(event, newValue) =>
                          handleBaseVelocityChange("baseVelocity", newValue?.label ?? "")
                        }
                        options={[
                          { label: "Near Term + High", value: "1" },
                          { label: "Near Term + Low", value: "2" },
                          { label: "Long Term + High", value: "3" },
                          { label: "Long Term + Low", value: "4" },
                        ]}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                      <MDTypography variant="h6" sx={{ pd: 2, color: "#52009b" }} gutterBottom>
                        Consequence
                      </MDTypography>
                      <FormGroup>
                        <FormControlLabel
                          sx={{ pd: 2 }}
                          control={<Checkbox defaultChecked />}
                          label="Dollar"
                        />
                      </FormGroup>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          defaultValue="loss"
                          value={RiskDetails.baseThreatOpp || ""}
                          onChange={(event, newValue) =>
                            handleBaseVelocityChange("baseThreatOpp", newValue ?? "")
                          }
                          sx={{ pd: 2 }}
                        >
                          <FormControlLabel value="loss" control={<Radio />} label="Threat/Loss" />
                          <FormControlLabel
                            value="gain"
                            control={<Radio />}
                            label="Oppurtunity/Gain"
                          />
                        </RadioGroup>
                      </FormControl>
                      <TextField
                        label="Enter Amount($)"
                        variant="outlined"
                        name="baseConsequenceAmt"
                        sx={{ width: "60%" }}
                        value={RiskDetails.baseConsequenceAmt || ""}
                        onChange={(event, newValue) => handleRiskDetailsChange(event, newValue)}
                        required
                        error={!!formErrors.baseConsequenceAmt}
                        helperText={formErrors.baseConsequenceAmt}
                      />
                      <FormGroup>
                        <FormControlLabel
                          sx={{ pd: 2 }}
                          control={<Checkbox defaultChecked />}
                          label="Qualitative"
                        />
                      </FormGroup>
                      <Autocomplete
                        sx={{ pd: 2 }}
                        value={RiskDetails.baseQualitative || ""}
                        onChange={(event, newValue) =>
                          handleBaseVelocityChange("baseQualitative", newValue?.label ?? "")
                        }
                        options={[
                          { label: "Level 1", value: "1" },
                          { label: "Level 2", value: "2" },
                          { label: "Level 3", value: "3" },
                          { label: "Level 4", value: "4" },
                          { label: "Level 5", value: "5" },
                        ]}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                      <MDTypography variant="h6" gutterBottom sx={{ pd: 2, color: "#52009b" }}>
                        Response Status
                      </MDTypography>
                      <Autocomplete
                        sx={{ pd: 2 }}
                        value={RiskDetails.baseResponseStatus || ""}
                        onChange={(event, newValue) =>
                          handleBaseVelocityChange("baseResponseStatus", newValue?.label ?? "")
                        }
                        options={[
                          { label: "Prepared and Tested", value: "1" },
                          { label: "Not yet Prepared", value: "2" },
                          { label: "Passive Pursuit", value: "3" },
                        ]}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                    </Card>
                  </Grid>
                  <Grid item md={4} sm={4} xs={4} lg={4}>
                    <Card sx={{ padding: 2 }}>
                      <CardContent>
                        {" "}
                        <MDTypography variant="h4" sx={{ color: "#52009b" }}>
                          Pessimistic Scenario
                        </MDTypography>
                      </CardContent>
                      <MDTypography variant="h6" sx={{ color: "#52009b" }}>
                        Time Horizon and Velocity
                      </MDTypography>
                      <Autocomplete
                        sx={{ pd: 2 }}
                        value={RiskDetails.pessimisticVelocity || ""}
                        onChange={(event, newValue) =>
                          handleBaseVelocityChange("pessimisticVelocity", newValue?.label ?? "")
                        }
                        options={[
                          { label: "Near Term + High", value: "1" },
                          { label: "Near Term + Low", value: "2" },
                          { label: "Long Term + High", value: "3" },
                          { label: "Long Term + Low", value: "4" },
                        ]}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                      <MDTypography variant="h6" gutterBottom sx={{ pd: 2, color: "#52009b" }}>
                        Consequence
                      </MDTypography>
                      <FormGroup>
                        <FormControlLabel
                          sx={{ pd: 2 }}
                          control={<Checkbox defaultChecked />}
                          label="Dollar"
                        />
                      </FormGroup>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          defaultValue="loss"
                          sx={{ pd: 2 }}
                          value={RiskDetails.pessimisticThreatOpp || ""}
                          onChange={(event, newValue) =>
                            handleBaseVelocityChange("pessimisticThreatOpp", newValue ?? "")
                          }
                        >
                          <FormControlLabel value="loss" control={<Radio />} label="Threat/Loss" />
                          <FormControlLabel
                            value="gain"
                            control={<Radio />}
                            label="Oppurtunity/Gain"
                          />
                        </RadioGroup>
                      </FormControl>
                      <TextField
                        label="Enter Amount($)"
                        variant="outlined"
                        name="pessimisticConsequenceAmt"
                        sx={{ width: "60%" }}
                        value={RiskDetails.pessimisticConsequenceAmt || ""}
                        onChange={(event, newValue) => handleRiskDetailsChange(event, newValue)}
                        required
                        error={!!formErrors.pessimisticConsequenceAmt}
                        helperText={formErrors.pessimisticConsequenceAmt}
                      />
                      <FormGroup>
                        <FormControlLabel
                          sx={{ pd: 2 }}
                          control={<Checkbox defaultChecked />}
                          label="Qualitative"
                        />
                      </FormGroup>
                      <Autocomplete
                        sx={{ pd: 2 }}
                        value={RiskDetails.pessimisticQualitative || ""}
                        onChange={(event, newValue) =>
                          handleBaseVelocityChange("pessimisticQualitative", newValue?.label ?? "")
                        }
                        options={[
                          { label: "Level 1", value: "1" },
                          { label: "Level 2", value: "2" },
                          { label: "Level 3", value: "3" },
                          { label: "Level 4", value: "4" },
                          { label: "Level 5", value: "5" },
                        ]}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                      <MDTypography sx={{ pd: 2, color: "#52009b" }} variant="h6" gutterBottom>
                        Response Status
                      </MDTypography>
                      <Autocomplete
                        sx={{ pd: 2 }}
                        value={RiskDetails.pessimisticResponseStatus || ""}
                        onChange={(event, newValue) =>
                          handleBaseVelocityChange(
                            "pessimisticResponseStatus",
                            newValue?.label ?? ""
                          )
                        }
                        options={[
                          { label: "Prepared and Tested", value: "1" },
                          { label: "Not yet Prepared", value: "2" },
                          { label: "Passive Pursuit", value: "3" },
                        ]}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                    </Card>
                  </Grid>
                  <Grid item md={4} sm={4} xs={4} lg={4}>
                    <Card sx={{ padding: 2 }}>
                      <CardContent>
                        {" "}
                        <MDTypography variant="h4" sx={{ color: "#52009b" }}>
                          Optimistic Scenario
                        </MDTypography>
                      </CardContent>
                      <MDTypography variant="h6" sx={{ color: "#52009b" }}>
                        Time Horizon and Velocity
                      </MDTypography>
                      <Autocomplete
                        sx={{ pd: 2 }}
                        value={RiskDetails.optimisticVelocity || ""}
                        onChange={(event, newValue) =>
                          handleBaseVelocityChange("optimisticVelocity", newValue?.label ?? "")
                        }
                        options={[
                          { label: "Near Term + High", value: "1" },
                          { label: "Near Term + Low", value: "2" },
                          { label: "Long Term + High", value: "3" },
                          { label: "Long Term + Low", value: "4" },
                        ]}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                      <MDTypography sx={{ pd: 2, color: "#52009b" }} variant="h6" gutterBottom>
                        Consequence
                      </MDTypography>
                      <FormGroup>
                        <FormControlLabel
                          sx={{ pd: 2 }}
                          control={<Checkbox defaultChecked />}
                          label="Dollar"
                        />
                      </FormGroup>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          defaultValue="loss"
                          sx={{ pd: 2 }}
                          value={RiskDetails.optimisticThreatOpp || ""}
                          onChange={(event, newValue) =>
                            handleBaseVelocityChange("optimisticThreatOpp", newValue ?? "")
                          }
                        >
                          <FormControlLabel value="loss" control={<Radio />} label="Threat/Loss" />
                          <FormControlLabel
                            value="gain"
                            control={<Radio />}
                            label="Oppurtunity/Gain"
                          />
                        </RadioGroup>
                      </FormControl>
                      <TextField
                        label="Enter Amount($)"
                        variant="outlined"
                        name="optimisticConsequenceAmt"
                        sx={{ width: "60%" }}
                        value={RiskDetails.optimisticConsequenceAmt || ""}
                        onChange={(event, newValue) => handleRiskDetailsChange(event, newValue)}
                        required
                        error={!!formErrors.optimisticConsequenceAmt}
                        helperText={formErrors.optimisticConsequenceAmt}
                      />
                      <FormGroup>
                        <FormControlLabel
                          sx={{ pd: 2 }}
                          control={<Checkbox defaultChecked />}
                          label="Qualitative"
                        />
                      </FormGroup>
                      <Autocomplete
                        sx={{ pd: 2 }}
                        value={RiskDetails.optimisticQualitative || ""}
                        onChange={(event, newValue) =>
                          handleBaseVelocityChange("optimisticQualitative", newValue?.label ?? "")
                        }
                        options={[
                          { label: "Level 1", value: "1" },
                          { label: "Level 2", value: "2" },
                          { label: "Level 3", value: "3" },
                          { label: "Level 4", value: "4" },
                          { label: "Level 5", value: "5" },
                        ]}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                      <MDTypography sx={{ pd: 2, color: "#52009b" }} variant="h6" gutterBottom>
                        Response Status
                      </MDTypography>
                      <Autocomplete
                        sx={{ pd: 2 }}
                        value={RiskDetails.optimisticResponseStatus || ""}
                        onChange={(event, newValue) =>
                          handleBaseVelocityChange(
                            "optimisticResponseStatus",
                            newValue?.label ?? ""
                          )
                        }
                        options={[
                          { label: "Prepared and Tested", value: "1" },
                          { label: "Not yet Prepared", value: "2" },
                          { label: "Passive Pursuit", value: "3" },
                        ]}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Grid item md={12}>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 2,
                    justifyContent: "flex-end",
                  }}
                >
                  <MDButton
                    variant="text"
                    color="black"
                    onClick={() => {
                      setShowSignPost(false);
                      setActiveStep(1);
                      setCreateRisk(true);
                    }}
                    sx={{ color: "#52009b" }}
                  >
                    Add Sign Post
                  </MDButton>{" "}
                  <MDButton
                    variant="text"
                    color="black"
                    onClick={() => {
                      setShowSignPost(false);
                      setCreateRisk(false);
                    }}
                    sx={{ color: "#52009b" }}
                  >
                    Back
                  </MDButton>
                </MDBox>
              </Grid>
              <Grid item md={12}>
                <Scenario RiskDetails={RiskDetails} />
              </Grid>
            </CustomTabPanel>
          </MDBox>
        </Grid>
      ) : (
        ""
      )}
    </DashboardLayout>
  );
}

export default AnticipateEmergingRisk;
