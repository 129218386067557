import React, { useEffect } from "react";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import { useMsal } from "@azure/msal-react";

function Logout() {
  const { instance } = useMsal();

  const logout = async () => {
    await instance.logoutRedirect();
  };
  useEffect(() => {
    logout();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="jumbotron">
        <h1>Logging out .....</h1>
        <p className="lead">Thanks for using Incrible!</p>
        {/*  {   <p>
        <button className="btn btn-lg btn-warning" onClick={() => KcUserService.doLogin()}>Login</button>
      </p> } */}
      </div>
    </DashboardLayout>
  );
}

export default Logout;
