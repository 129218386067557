import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import React, { useState, useCallback, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  Button,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Edit, Save, Cancel } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import "./protect.css";
import MDButton from "components/MDButton";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { protectedResources, siteData } from "authConfig";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import MDBox from "components/MDBox";
import SettingCustomTable from "assets/theme/components/table/SettingCustomTable";
import AppetiteType from "./AppetiteType";

function AppetiteSetting() {
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  const [TableData, setTableData] = useState(null);
  // get appetite type for column heading

  // Update the edited data for each row using the index as the key
  const [updatedData, setUpdatedData] = useState([]);
  // appetite type list for column rendering
  const [appetiteType, setAppetiteType] = useState(null);

  const getTableData = () => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/appetitesetting/list`).then(
      (response) => {
        if (response && response.success === true) {
          setTableData(response.data);
        }
      }
    );
  };
  //  get data from api
  useEffect(() => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/appetitesetting/list`).then(
      (response) => {
        if (response && response.success === true && response.data.length > 0) {
          setTableData(response.data);
        }
      }
    );
  }, [execute]);

  useEffect(() => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/appetite/list`).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setAppetiteType(response.data);
      }
    });
  }, [execute]);

  const getColumns = () => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/appetite/list`).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setAppetiteType(response.data);
      }
    });
  };

  // add  dialog controls
  const [open, setOpen] = useState(false);

  // for save button rendering
  const [editRow, setIsEdit] = useState();

  useEffect(() => {
    if (TableData) {
      const tableData = TableData
        ? TableData.map((data) => ({
            id: data.id,
            name: data.name,
            ...data.riskAppetiteSetting[0],
          }))
        : [];
      setUpdatedData(tableData);
    }
  }, [TableData]);

  // handle input change for editing row
  const [validationErrors, setValidationErrors] = useState({});
  const [editObject, setEditObject] = useState(null);
  const [isSuccess, setisSuccess] = useState(true);
  // const validateRequired = (value) => !!value.length;
  const validateMaxLength = (value, maxLength) => value.length >= maxLength;

  // taking input from row in table
  const getCommonEditTextFieldProps = useCallback(
    (cell) => ({
      error: !!validationErrors[cell.id],
      helperText: validationErrors[cell.id],
      onBlur: (event) => {
        const { row, column } = cell;
        const rowId = row.original.id;
        const updatedRowElement = updatedData.find((item) => item.id === rowId);

        if (updatedRowElement) {
          const { value } = event.target;
          const columnName = column.id;

          // Check if the column name is "name" (Considerations)
          if (columnName === "name") {
            const isValueValid = value.trim() !== ""; // Check if non-empty

            const updatedRowData = {
              ...updatedRowElement,
              [columnName]: value,
            };

            setEditObject(updatedRowData);

            setUpdatedData((prevData) =>
              prevData.map((item) => (item.id === rowId ? updatedRowData : item))
            );

            if (!isValueValid) {
              setisSuccess(true);

              setValidationErrors({
                ...validationErrors,
                [cell.id]: `${cell.column.columnDef.header} is required`,
              });
            } else {
              delete validationErrors[cell.id];
              setisSuccess(false);

              setValidationErrors({
                ...validationErrors,
              });
            }
          } else {
            const isValueValid = validateMaxLength(value, 50); // Length validation

            const updatedRowData = {
              ...updatedRowElement,
              [columnName]: value,
            };

            setEditObject(updatedRowData);

            setUpdatedData((prevData) =>
              prevData.map((item) => (item.id === rowId ? updatedRowData : item))
            );

            if (!isValueValid) {
              setisSuccess(true);

              setValidationErrors({
                ...validationErrors,
                [cell.id]: `${cell.column.columnDef.header} should be greater than 50 characters`,
              });
            } else {
              delete validationErrors[cell.id];
              setisSuccess(false);

              setValidationErrors({
                ...validationErrors,
              });
            }
          }
        }
      },
    }),
    [updatedData, setEditObject]
  );

  // dynamic columns

  const [conditionalColumns, setConditionalColumns] = useState();

  useEffect(() => {
    const col = appetiteType
      ? [
          {
            id: "name",
            accessorKey: "name",
            header: "Considerations",
            size: 140,
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
              ...getCommonEditTextFieldProps(cell),
            }),
          },
          ...appetiteType.map((riskType) => ({
            id: riskType.slug,
            accessorKey: riskType.slug,
            header: riskType.name,
            size: 140,
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
              ...getCommonEditTextFieldProps(cell),
            }),
          })),
        ]
      : [];

    setConditionalColumns(col);
  }, [appetiteType, getCommonEditTextFieldProps]);

  // Concatenate the initial columns array with the conditional columns

  // const columns = conditionalColumns && [...fcolumns, ...conditionalColumns];
  const columns = conditionalColumns && [...conditionalColumns];

  // dialog box
  const handleAdd = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setConditionalColumns([]);
    getColumns();
    getTableData();
    // setC
  };

  const [IsNew, setIsNew] = useState(false);

  // Function to check if a string is empty
  const isEmpty = (str) => {
    // setisSuccess(false);
    // return str.trim() === "";
    const isSucc = str.trim() !== "";
    setisSuccess(isSucc);
    return !isSucc;
  };

  const IsEmptyEntry = () => {
    const hasEmptyNames = updatedData.some(
      (obj) =>
        isEmpty(obj.name) ||
        isEmpty(obj.riskAvoidant) ||
        isEmpty(obj.riskBalanced) ||
        isEmpty(obj.riskSeeking)
    );
    // const hasEmptyNames = updatedData.some((item) => hasEmptyValues(item));
    return hasEmptyNames;
  };
  // saving the edited row/ adding new row
  const handleSaveRow = async () => {
    // Check for empty strings in the "name" property
    if (editObject) {
      const riskAppetiteSettingObj = Object.keys(editObject).reduce((acc, key) => {
        if (key !== "name" && key !== "isnew" && key !== "id") {
          acc[key] = editObject[key];
        }
        return acc;
      }, {});
      const riskAppetiteSet = [riskAppetiteSettingObj];

      if (!Object.keys(validationErrors).length) {
        // Wrap the new object inside an array to get the riskAppetiteSetting array

        if (editObject.isnew) {
          try {
            const requestBody = {
              name: editObject.name,
              riskAppetiteSetting: riskAppetiteSet,
            };

            // Make the CREATE API call

            await execute(
              "POST",
              `${protectedResources.apiTodoList.endpoint}/appetitesetting/create`,
              requestBody
            ).then(() => {
              getTableData();
              setIsEdit(null);
              setIsNew(false);
              // setisSuccess(true);
            });
          } catch (error) {
            /* empty */
          }
        } else {
          try {
            const requestBody = {
              name: editObject.name,
              riskAppetiteSetting: riskAppetiteSet,
            };

            // Make the PUT API call

            await execute(
              "PUT",
              `${protectedResources.apiTodoList.endpoint}/appetitesetting/update/${editObject.id}`,
              requestBody
            ).then(() => {
              getTableData();
              setIsEdit(null);
              setIsNew(false);

              // setisSuccess(true);
            });
          } catch (error) {
            /* empty */
          }
        }
      }
    }
  };

  const handleCancelRowEdits = () => {
    getTableData();
    setIsNew(false);
    // setIsEdit(null);
    setValidationErrors({});
  };
  // delete a row
  const handleDeleteRow = (row) => {
    const { id } = row.original;

    if (row.original.isnew) {
      handleCancelRowEdits();
    } else {
      try {
        execute(
          "DELETE",
          `${protectedResources.apiTodoList.endpoint}/appetitesetting/delete/${id}`
        ).then(() => {
          getTableData();
        });
      } catch {
        /* empty */
      }
    }
  };

  // cancel editing row

  // add new empty row
  const handleAddButtonClick = () => {
    const newEmptyRow = {
      id: uuidv4(),
      name: "",
      isnew: true,
    };
    appetiteType.forEach((item) => {
      newEmptyRow[`${item.slug}`] = "";
    });
    // riskAvoidant: "",
    // riskBalanced: "",
    // riskSeeking: "",
    setUpdatedData([...updatedData, newEmptyRow]);
    setIsNew(true);
    setIsEdit(newEmptyRow.id);
    setisSuccess(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        style={{
          borderRadius: "5px",
          padding: "15px",
          backgroundColor: "white",
          MinHeight: "530px",
        }}
      >
        <Grid container spacing={2} md={12} sm={12} xs={12}>
          <Grid item md={12} sm={12} xs={12}>
            <Typography sx={{ pb: "5px", fontWeight: 500 }} fontSize="21px">
              Appetite Setting
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <MDButton
              variant="contained"
              BackgroundColor="#402899"
              color="#402899"
              sx={{
                color: "#f7f4fb",
                borderRadius: "4px",
              }}
              className="add-button"
              onClick={() => handleAdd()}
            >
              Column Setting
            </MDButton>
          </Grid>
          {conditionalColumns && conditionalColumns.length > 0 ? (
            <Grid container sx={{ mt: "10px", ml: "25px" }}>
              <ThemeProvider theme={SettingCustomTable}>
                <Grid item md={12}>
                  {conditionalColumns.length > 0 && (
                    <MaterialReactTable
                      columns={columns}
                      data={updatedData || ""}
                      // muiTableBodyCellProps={{
                      //   sx: {
                      //     backgroundColor: "white !important",
                      //     borderRight: "none !important",
                      //     borderLeft: "none !important",
                      //   },
                      //
                      // }}
                      muiTableHeadCellProps={{
                        sx: {
                          backgroundColor: "#F7F8FF !important",
                        },
                      }}
                      // muiBottomToolbarProps={{
                      //   sx: {
                      //     backgroundColor: "white !important",
                      //   },
                      // }}
                      editingMode="row" // Use "row" editing mode for inline editing
                      //   enableColumnOrdering
                      enableEditing
                      enableColumnActions={false}
                      enableTopToolbar={false}
                      onEditingRowCancel={handleCancelRowEdits}
                      positionActionsColumn="last"
                      renderRowActions={({ row, table }) => {
                        const isRowEditing = row.original.id === editRow;

                        if (IsNew === true) {
                          table.setEditingRow(row);
                          // setIsEdit(row.id);
                        }

                        return (
                          <Box sx={{ display: "flex" }}>
                            {isRowEditing ? (
                              <>
                                <Tooltip arrow title="Save">
                                  <IconButton
                                    onClick={() => {
                                      // handleSaveRow(row);
                                      // // setIsEdit(null);
                                      // if (!isSuccess) {
                                      //   table.setEditingRow(null);
                                      // }
                                      // // table.setEditingRow(null);

                                      // setIsNew(false);
                                      const a = IsEmptyEntry();
                                      // console.log(a);

                                      if (a === false) {
                                        handleSaveRow(row);
                                        if (isSuccess === false) {
                                          // setIsEdit(null);
                                          table.setEditingRow(null);
                                          setIsNew(false);
                                        }
                                      }
                                    }}
                                  >
                                    <Save />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip arrow title="Cancel">
                                  <IconButton
                                    onClick={() => {
                                      handleCancelRowEdits();
                                      setIsEdit(null);
                                      table.setEditingRow(null);
                                      setIsNew(false);
                                    }}
                                  >
                                    <Cancel />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <Tooltip arrow title="Edit">
                                <IconButton
                                  onClick={() => {
                                    table.setEditingRow(row);
                                    setIsEdit(row.original.id);
                                    setIsNew(false);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}

                            <Tooltip arrow title="Delete">
                              <IconButton
                                // color="error"
                                onClick={() => handleDeleteRow(row)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        );
                      }}
                    />
                  )}
                </Grid>
              </ThemeProvider>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {IsNew === false && (
                  <Button
                    variant="outlined"
                    onClick={() => handleAddButtonClick()}
                    sx={{
                      color: "#402899",
                      marginTop: "20px",
                      borderColor: "#402899",
                      borderRadius: "4px",
                    }}
                  >
                    +Add New
                  </Button>
                )}
              </Grid>
            </Grid>
          ) : (
            <span>You dont have default risk type settings configured</span>
          )}
        </Grid>
        <Dialog open={open}>
          <Grid sx={{ p: "20px" }}>
            <AppetiteType />
          </Grid>
          <DialogActions sx={{ p: "1.25rem" }}>
            <MDButton
              color="black"
              variant="outlined"
              onClick={handleClose}
              width="79px"
              height="36px"
              sx={{
                mr: 3.5,
                color: "#263445",
                border: "1px solid #B5BFD0",
                textTransform: "none",
                borderRadius: "4px",
                fontSize: "15px",
              }}
            >
              Cancel
            </MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
    </DashboardLayout>
  );
}

export default AppetiteSetting;
