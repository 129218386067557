/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-useless-fragment */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import MDTypography from "components/MDTypography";

import { Card, Divider, Grid, Skeleton, Stack, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MDButton from "components/MDButton";
// eslint-disable-next-line import/order
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { loginRequest, protectedResources, siteData } from "../../authConfig";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

// css file
import "./Taxonomy.css";
import { useNavigate } from "react-router-dom";
// import Skeleton from "react-loading-skeleton";
// edit button
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
// alert box
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as AccountIcon } from "../../assets/images/icons/projectIcons/account_circle_FILL0_wght400_GRAD0_opsz48.svg";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));
function TaxonomyOverview() {
  const { error, execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  const [allData, setAllData] = useState(null);
  const [noData, setNoData] = useState(false);
  useEffect(() => {
    if (!allData) {
      execute("GET", `${protectedResources.apiTodoList.endpoint}/taxonomy/list`).then(
        (response) => {
          if (response && response.success === true && response.data.length > 0) {
            setAllData(response.data);
          }
          if (response && response.success === true && response.data.length === 0) {
            setNoData(true);
          }
        }
      );
    }
  }, [execute, allData]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const [SingleTaxonomy, setSingleTaxonomy] = useState(null);

  useEffect(() => {
    if (allData && allData.length) {
      setSingleTaxonomy(allData[0]);
    }
  }, [allData]);

  const navigate = useNavigate();
  const [Dialogopen, setOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  // handle card click
  const handleCardClick = (categoryId) => {
    // Store the category ID in an object or perform other actions
    setSelectedCategoryId(categoryId);
    setOpen(true);
  };
  // navigate on card click
  const handleEditConfirmation = () => {
    setOpen(false);
    const categoryId = selectedCategoryId;
    const taxonomyId = SingleTaxonomy.id;
    if (selectedCategoryId) {
      // Perform actions when the user confirms (e.g., navigate to edit page)

      if (selectedCategoryId) {
        navigate("/configure", { state: { categoryId, taxonomyId } });
      }
    } else {
      navigate("/configure", { state: { taxonomyId } });
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // navigate on add category
  const handleAddCategory = () => {
    // const taxonomyId = SingleTaxonomy.id;
    setOpen(true);

    // navigate("/configure", { state: { taxonomyId } });
  };
  // navigate to add taxonomy
  const handleAddTaxonomy = () => {
    const categoryId = null;
    const taxonomyId = null;
    navigate("/configure", { state: { categoryId, taxonomyId } });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = open ? "simple-popover" : undefined;
  // const [loading, setLoading] = useState(isLoading);

  return (
    <>
      <Dialog open={Dialogopen} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{
            color: "red", // Set the color to red
            fontWeight: "bold", // Set the font weight to bold
          }}
        >
          Alert!
        </DialogTitle>
        <DialogContent>
          Are you sure ? Editing Category and Sub Category will re-classify the previously
          classified records. This action is not recommended unless it is justified and approved by
          the senior management.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditConfirmation} color="primary">
            Yes
          </Button>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <>
        {noData ? (
          <Grid
            container
            // spacing={1}
            // justifyContent="space-between"
            className="Taxonomy-overview"
            style={{
              backgroundColor: "white",
              borderRadius: "2px",
              padding: "15px",
            }}
          >
            <Grid item md={12} lg={12} sx={{ mb: "7px" }}>
              <Stack
                direction="row"
                sx={{ display: "flex", justifyContent: "space-between", mb: "7px" }}
              >
                <MDButton
                  variant="contained"
                  BackgroundColor="#402899"
                  color="#402899"
                  sx={{
                    color: "#f7f4fb",
                    width: "156px",
                    height: " fit-content",
                    mr: "15px",
                  }}
                  className="add-button"
                  onClick={() => handleAddTaxonomy()}
                >
                  Add Taxonomy
                </MDButton>
              </Stack>
              <hr className="hr-horizontal" />

              <Grid container columns={{ xs: 3, sm: 8, md: 12 }} sx={{ minHeight: "420px" }}>
                <Grid sx={{ minHeight: "375px" }}>
                  <Typography>No Category Present </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            // spacing={1}
            // justifyContent="space-between"
            className="Taxonomy-overview"
            style={{
              backgroundColor: "white",
              borderRadius: "2px",
              padding: "15px",
            }}
          >
            <Grid item md={12} lg={12} sx={{ mb: "7px" }}>
              <Stack
                direction="row"
                sx={{ display: "flex", justifyContent: "space-between", mb: "7px" }}
              >
                <Stack direction="column">
                  {SingleTaxonomy ? (
                    <MDTypography>{SingleTaxonomy && SingleTaxonomy.name}</MDTypography>
                  ) : (
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={230} height={40} />
                  )}
                  {SingleTaxonomy ? (
                    <MDTypography fontWeight="light" fontSize={13}>
                      {SingleTaxonomy && SingleTaxonomy.description}
                    </MDTypography>
                  ) : (
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={230} height={20} />
                  )}
                </Stack>
                {/* {allData && allData.length > 0 ? (
                <MDButton
                  variant="contained"
                  BackgroundColor="#52009b"
                  color="#52009b"
                  sx={{
                    color: "#f7f4fb",
                    width: "16%",
                    height: " fit-content",
                    mr: " 15px",
                  }}
                  className="add-button"
                  onClick={() => handleAddCategory()}
                >
                  Edit Taxonomy
                </MDButton>
              ) : (
                <MDButton
                  variant="contained"
                  BackgroundColor="#52009b"
                  color="#52009b"
                  sx={{
                    color: "#f7f4fb",
                    width: "16%",
                    height: " fit-content",
                    mr: " 15px",
                  }}
                  className="add-button"
                  onClick={() => handleAddTaxonomy()}
                >
                  Add Taxonomy
                </MDButton>
              )} */}
                {allData ? (
                  <MDButton
                    variant="contained"
                    BackgroundColor="#52009b"
                    color="#52009b"
                    sx={{
                      color: "#f7f4fb",
                      width: "156px",
                      height: " fit-content",
                      mr: " 15px",
                    }}
                    className="add-button"
                    onClick={() => handleAddCategory()}
                  >
                    Edit Taxonomy
                  </MDButton>
                ) : (
                  <Skeleton variant="rectangular" width={155} height={40} />
                )}
              </Stack>
              <hr className="hr-horizontal" />

              <Grid container columns={{ xs: 3, sm: 8, md: 12 }} sx={{ minHeight: "420px" }}>
                {SingleTaxonomy ? (
                  SingleTaxonomy.Categories &&
                  SingleTaxonomy.Categories.map((category, categoryIndex) => (
                    <Grid item xs={4} md={3} lg={4}>
                      <Card
                        sx={{
                          bgcolor: "background.paper",
                          boxShadow: 2,
                          borderRadius: 2,
                          minWidth: 220,
                          maxWidth: 230,
                          my: 2,
                          mr: 2,
                          height: 290,
                          // maxHeight: 200,
                          // overflow: "auto", // Add overflow property to enable scrolling
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            // marginLeft: "4px",
                            // marginRight: "4px",
                            padding: "4px",
                            justifyContent: "space-evenly",
                            height: "50px",
                            position: "sticky",
                            backgroundColor: "#F7F8FF",
                          }}
                        >
                          <div style={{ width: "35px", alignItems: "center", display: "flex" }}>
                            <AccountCircleOutlinedIcon
                              style={{ fontSize: "40px", color: "#402899" }}
                            />
                          </div>
                          {/* <AccountIcon style={{ fontSize: "40px", color: "white" }} /> */}
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: 500,
                              // padding: "10px",
                              color: "#402899",
                              lineHeight: "1.3",
                            }}
                          >
                            {category.name}
                          </span>

                          <LightTooltip title={category.desc}>
                            <InfoOutlinedIcon onClick={handleClick} sx={{ color: "#402899" }} />
                          </LightTooltip>
                          <ModeEditOutlineOutlinedIcon
                            style={{ cursor: "pointer", paddingLeft: "4px", color: "#402899" }}
                            onClick={() => handleCardClick(category.id)}
                          />
                        </div>

                        <Divider sx={{ ml: "5px", mr: "5px" }} />

                        {/* <hr /> */}
                        <div
                          style={{
                            marginLeft: "7px",
                            overflow: "auto", // Add overflow property to enable scrolling for the content below
                            // height: "85%", // Set a fixed height for the scrollable content
                            lineHeight: 1.2,
                          }}
                        >
                          {category.SubCategories &&
                            category.SubCategories.map((subCats, subCategoryIndex) => (
                              <>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "1.9",
                                  }}
                                >
                                  {subCats.name}
                                </span>
                                <br />
                              </>
                            ))}
                        </div>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  // <Grid sx={{ minHeight: "375px" }}>
                  //   <Typography>No Category Present </Typography>
                  // </Grid>
                  <Grid container columns={{ xs: 3, sm: 8, md: 12 }} sx={{ minHeight: "420px" }}>
                    <Skeleton
                      variant="rectangular"
                      width={220}
                      height={285}
                      sx={{ mr: "10px", mt: "10px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={220}
                      height={285}
                      sx={{ mr: "10px", mt: "10px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={220}
                      height={285}
                      sx={{ mr: "10px", mt: "10px" }}
                    />
                    <Skeleton variant="rectangular" width={220} height={285} sx={{ mt: "10px" }} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    </>
  );
}

function Taxonomy() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
        >
          <TaxonomyOverview />
        </MsalAuthenticationTemplate>
      </MDBox>
    </DashboardLayout>
  );
}

export default Taxonomy;
