import { createTheme } from "@mui/material/styles";

const CustomHeightTable = createTheme({
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: "none",
          boxShadow: "none",
          // height: "340px"
          // background: "#F7F8FF",
        },
      },
    },
    MuiTableHeadCell: {
      styleOverrides: { root: { borderBottom: "1px solid #B5BFD0" } },
    },
    MuiTableCell: {
      styleOverrides: { root: { padding: 0, letterSpacing: 0, fontFamily: "red hat display" } },
    },
    MuiDialog: {
      // styleOverrides: { paper: { borderRadius: "none" } },
      styleOverrides: { root: { borderRadius: "none" } },
    },
  },
});
export default CustomHeightTable;
