import { createTheme } from "@mui/material/styles";

const SettingCustomTable = createTheme({
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: "none",
          fontFamily: "red hat display",
        },
      },
    },
  },
});
export default SettingCustomTable;
