import React, { useState, useCallback, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Grid, ThemeProvider } from "@mui/material";
import { Edit, Save, Cancel } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import "./protect.css";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { protectedResources, siteData } from "authConfig";
import SettingCustomTable from "assets/theme/components/table/SettingCustomTable";

function AppetiteType() {
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  // get data from api
  const [TableData, setTableData] = useState(null);
  const getTableData = () => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/appetite/list`).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setTableData(response.data);
      }
    });
  };
  useEffect(() => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/appetite/list`).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setTableData(response.data);
      }
    });
  }, [execute]);

  // for save button rendering

  const [editRow, setIsEdit] = useState();

  // Update the edited data for each row using the index as the key
  const [updatedData, setUpdatedData] = useState([]);
  // useEffect to initialize the state once TableData is available
  useEffect(() => {
    if (TableData) {
      setUpdatedData(Object.fromEntries(TableData.map((row, index) => [index, { ...row }])));
    }
  }, [TableData]);

  const [validationErrors, setValidationErrors] = useState({});

  const [isSuccess, setisSuccess] = useState();

  // saving the edited row
  const handleSaveRow = (row) => {
    const rowData = updatedData[row.index];
    if (!Object.keys(validationErrors).length) {
      try {
        const requestBody = {
          name: `${row.index}_name` in rowData ? rowData[`${row.index}_name`] : rowData.name,
          description:
            `${row.index}_description` in rowData
              ? rowData[`${row.index}_description`]
              : rowData.description,
        };

        // Make the PUT API call
        execute(
          "PUT",
          `${protectedResources.apiTodoList.endpoint}/appetite/update/${rowData.id}`,
          requestBody
        ).then(() => {
          getTableData();
          setIsEdit(null);
        });
      } catch (error) {
        /* empty */
      }
    }
  };

  // cancel editing row
  const handleCancelRowEdits = () => {
    getTableData();
    setValidationErrors({});
  };

  // handle input change for editing row
  // const validateRequired = (value) => !!value.length;
  const validateRequired = (value) => value.trim() !== ""; // Check for non-empty and non-blank input
  const getCommonEditTextFieldProps = useCallback(
    (cell) => ({
      error: !!validationErrors[cell.id],

      helperText: validationErrors[cell.id],
      onBlur: (event) => {
        const isValid = validateRequired(event.target.value);

        setUpdatedData((prevData) => {
          const { row, id } = cell;
          const { index } = row;
          const updatedRowData = {
            ...prevData[index], // Get the previous data for the specific row
            [id]: event.target.value, // Update the value for the specific cell.id
          };
          return {
            ...prevData,
            [index]: updatedRowData, // Update the data for the specific row
          };
        });
        if (!isValid) {
          // set validation error for cell if invalid

          setisSuccess(true);

          setValidationErrors({
            ...validationErrors,

            [cell.id]: `${cell.column.columnDef.header} is required`,
          });
        } else {
          // remove validation error for cell if valid

          // setIsEdit(null);
          delete validationErrors[cell.id];
          setisSuccess(false);

          setValidationErrors({
            ...validationErrors,
          });
        }
      },
    }),
    [setUpdatedData, updatedData]
  );

  const columns = [
    {
      accessorKey: "name",
      header: "Risk Type",
      size: 140,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
  ];

  return (
    <Grid container style={{ borderRadius: "5px", padding: "15px", width: "400px" }} spacing={2}>
      <Grid item md={12}>
        <ThemeProvider theme={SettingCustomTable}>
          <MaterialReactTable
            columns={columns}
            data={TableData || ""}
            editingMode="row" // Use "row" editing mode for inline editing
            //   enableColumnOrdering
            enableEditing
            enableTopToolbar={false}
            enableBottomToolbar={false}
            enablePagination={false}
            enableColumnActions={false}
            onEditingRowCancel={handleCancelRowEdits}
            positionActionsColumn="last"
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: "#F7F8FF !important",
              },
            }}
            renderRowActions={({ row, table }) => {
              const isRowEditing = row.id === editRow;

              return (
                <Box sx={{ display: "flex" }}>
                  {isRowEditing ? (
                    <>
                      <Tooltip arrow title="Save">
                        <IconButton
                          onClick={() => {
                            handleSaveRow(row);
                            // setIsEdit(null);
                            if (!isSuccess) {
                              table.setEditingRow(null);
                            }
                          }}
                        >
                          <Save />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title="Cancel">
                        <IconButton
                          onClick={() => {
                            handleCancelRowEdits();
                            setIsEdit(null);
                            table.setEditingRow(null);
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <Tooltip arrow title="Edit">
                      <IconButton
                        onClick={() => {
                          table.setEditingRow(row);
                          setIsEdit(row.id);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}

                  {/* <Tooltip arrow title="Delete">
                  <IconButton
                    // color="error"
                    onClick={() => handleDeleteRow(row)}
                    disabled={isDeleteDisabled(row.index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip> */}
                </Box>
              );
            }}
          />
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}

export default AppetiteType;
