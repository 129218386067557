/* eslint-disable no-plusplus */
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
  Dialog,
  DialogActions,
  TextField,
  Box,
  Tooltip,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import "./protect.css";
import MDButton from "components/MDButton";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { protectedResources, siteData } from "authConfig";
import { Edit, Save, Cancel } from "@mui/icons-material";
import { MaterialReactTable } from "material-react-table";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
// import RiskSizingSetting from "./RiskSizingSetting";
import MDBox from "components/MDBox";
import SettingCustomTable from "assets/theme/components/table/SettingCustomTable";
import RiskType from "./RiskType";
import IncidentType from "./IncidentType";

function RiskSizing() {
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });
  // Taxonomy lisst
  const [TaxonomyList, setTaxonomyList] = useState(null);
  const [SingleTaxonomy, setSingleTaxonomy] = useState("");
  const [selectedTaxonomyId, setSelectedTaxonomyId] = useState("");
  // for tab selection
  const [selectedOption, setSelectedOption] = useState("risk");
  const [colChanged, setColChanged] = useState(true);

  useEffect(() => {
    if (!TaxonomyList) {
      execute("GET", `${protectedResources.apiTodoList.endpoint}/taxonomy/listall`).then(
        (response) => {
          if (response && response.success === true && response.data.length > 0) {
            setTaxonomyList(response.data);
            setSingleTaxonomy(response.data[0].name);
          }
        }
      );
    }
  }, [execute, TaxonomyList]);

  useEffect(() => {
    setSelectedTaxonomyId(
      TaxonomyList && TaxonomyList.find((taxonomy) => taxonomy.name === SingleTaxonomy)
    );
  }, [SingleTaxonomy]);
  const currency = selectedTaxonomyId && selectedTaxonomyId.currencyData.currencyName;
  const taxonomyId = selectedTaxonomyId && selectedTaxonomyId.id;
  // Extract the 'name' values from the 'TaxonomyList' array and store them in the 'TaxonomyName' array
  const TaxonomyName = TaxonomyList && TaxonomyList.map((nme) => nme.name);
  const handleInputChange = (event) => {
    setSingleTaxonomy(event.target.value);
    setSelectedTaxonomyId(
      TaxonomyList && TaxonomyList.find((taxonomy) => taxonomy.name === SingleTaxonomy)
    );
  };

  //  for tab input change
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  // add  dialog controls
  const [open, setOpen] = useState(false);

  // dialog box
  const handleAdd = () => {
    setOpen(true);
    setColChanged(false);
  };
  const handleClose = () => {
    setOpen(false);
    setColChanged(true);
  };

  // ==========================================================================================================================================================
  // get appetite type for column heading
  const [Sizingimpact, setSizingImpact] = useState(null);
  const [SizingupdatedData, setSizingUpdatedData] = useState([]);

  const getSizingTableData = () => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/sizing/list/${taxonomyId}/${selectedOption}`
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setSizingImpact(response.data);
      }
    });
  };

  //  get data from api
  useEffect(() => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/sizing/list/${taxonomyId}/${selectedOption}`
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setSizingImpact(response.data);
      }
    });
  }, [execute, selectedOption, taxonomyId]);

  useEffect(() => {
    if (Sizingimpact && Sizingimpact.length > 0 && Array.isArray(Sizingimpact[0].impactLevel)) {
      const updatedIType = Sizingimpact[0].impactLevel.map((item) => ({
        name: item.name,
        level: item.level,
      }));
      setSizingUpdatedData(updatedIType);
    }
  }, [Sizingimpact]);

  // for save button rendering
  const [editRow, setIsEdit] = useState();

  // handle input change for editing row
  const [validationErrors, setValidationErrors] = useState({});
  const [isSuccess, setisSuccess] = useState(true);

  //         // Save the updatedRowData in the editObject state

  const validateRequired = (value) => value.trim() !== ""; // Check for non-empty and non-blank input

  const getCommonEditTextFieldProps = useCallback(
    (cell) => ({
      error: !!validationErrors[cell.id],
      helperText: validationErrors[cell.id],
      onBlur: (event) => {
        const { row } = cell;
        const rowIndex = row.index; // Get the index of the row element being updated
        const updatedRowElement = SizingupdatedData[rowIndex]; // Find the array element using the index

        if (updatedRowElement !== undefined) {
          const isValid = validateRequired(event.target.value);
          // Update the edited data for the specific row element

          const updatedRowData = {
            ...updatedRowElement,
            name: event.target.value,
            level: rowIndex,
          };

          // Save the updatedRowData in the editObject state

          setSizingUpdatedData((prevData) =>
            prevData.map((item, index) => (index === rowIndex ? updatedRowData : item))
          );
          if (!isValid) {
            // set validation error for cell if invalid
            setisSuccess(true);

            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            // remove validation error for cell if valid
            delete validationErrors[cell.id];
            setisSuccess(false);

            setValidationErrors({
              ...validationErrors,
            });
          }
        }
      },
    }),
    [SizingupdatedData]
  );

  const columns = [
    {
      id: "name",
      accessorKey: "name",
      header: "Impact Level",
      size: 140,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    // {
    //   id: "level",
    //   accessorKey: "level",
    //   header: "Impact Factor",
    //   size: 140,
    //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
    //     ...getCommonEditTextFieldProps(cell),
    //   }),
    // },
  ];

  const [IsNew, setIsNew] = useState(false);

  // Function to check if a string is empty
  const isEmpty = (str) => {
    // setisSuccess(false);
    // return str.trim() === "";
    const isSucc = str.trim() !== "";
    setisSuccess(isSucc);
    return !isSucc;
  };

  const IsEmptyEntry = () => {
    const hasEmptyNames = SizingupdatedData.some((obj) => isEmpty(obj.name));
    return hasEmptyNames;
  };

  // saving the edited row
  const handleSaveRow = async () => {
    const transformedData = SizingupdatedData.map(({ name, level }) => ({ name, level }));
    for (let i = 0; i < SizingupdatedData.length; i++) {
      transformedData[i].level = i;
    }

    const hasEmptyNames = SizingupdatedData.some((obj) => isEmpty(obj.name));

    if (!Object.keys(validationErrors).length && !hasEmptyNames) {
      if (Sizingimpact && Sizingimpact[0].id) {
        try {
          const requestBody = {
            impactCategory: selectedOption,
            taxonomyId,
            impactLevel: transformedData,
          };
          await execute(
            "PUT",
            `${protectedResources.apiTodoList.endpoint}/sizing/update/${Sizingimpact[0].id}`,
            requestBody
          ).then(() => {
            setIsNew(false);
            setIsEdit(null);
            setisSuccess(false);
            getSizingTableData();
            // setIsSucc(true);
          });
        } catch (e) {
          /* empty */
        }
      } else if (!Object.keys(validationErrors).length && !hasEmptyNames) {
        try {
          const requestBody = {
            impactCategory: selectedOption,
            taxonomyId,
            impactLevel: transformedData,
          };
          await execute(
            "POST",
            `${protectedResources.apiTodoList.endpoint}/sizing/create`,
            requestBody
          ).then(() => {
            setIsNew(false);
            setIsEdit(null);
            setisSuccess(false);
            // setIsSucc(true);
            getSizingTableData();
          });
        } catch (e) {
          /* empty */
        }
      }
    }
  };

  // delete a row
  const handleDeleteRow = (row) => {
    const { id } = row;

    // Find the index of the element to be deleted
    const indexToDelete = SizingupdatedData.findIndex((item, index) => index === id) ? id : 0;

    // Remove the element from the array
    const updatedDataCopy = [...SizingupdatedData];
    updatedDataCopy.splice(indexToDelete, 1);

    // setUpdatedData(updatedDataCopy);

    const transformedData = updatedDataCopy.map(({ name, level }) => ({ name, level }));

    if (Sizingimpact && Sizingimpact[0].id) {
      try {
        const requestBody = {
          impactCategory: selectedOption,
          taxonomyId,
          impactLevel: transformedData,
        };
        execute(
          "PUT",
          `${protectedResources.apiTodoList.endpoint}/sizing/update/${Sizingimpact[0].id}`,
          requestBody
        ).then(() => {
          getSizingTableData();
          setIsEdit(null);
          setIsNew(false);
        });
      } catch (e) {
        /* empty */
      }
    }
  };

  // cancel editing row
  const handleCancelRowEdits = () => {
    getSizingTableData();
    setIsNew(false);
    // setIsEdit(null);
    setValidationErrors({});
  };

  const handleAddButtonClick = () => {
    const newEmptyRow = {
      id: uuidv4(),
      name: "",
      level: "",
      isnew: true,
    };

    setSizingUpdatedData([...SizingupdatedData, newEmptyRow]);
    setIsNew(true);
    setisSuccess(true);
    // setHasEmptyNames(true);
    // Find the index of the newly added row
    const newIndex = SizingupdatedData.length;
    setIsEdit(newIndex);
  };
  // ============================================================================================================================================================
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox style={{ borderRadius: "5px", padding: "15px", backgroundColor: "white" }}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Typography sx={{ pb: "5px", fontWeight: 500 }} fontSize="21px">
              Risk and Incident Type Setting
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
              <Grid item md={8.5} sx={{ display: "flex", flexDirection: "row" }}>
                <Grid item sx={{ mr: "12px" }}>
                  <FormControl required>
                    <InputLabel id="taxonomy">Taxonomy</InputLabel>
                    <Select
                      sx={{
                        width: "280px",
                        height: "44px",
                      }}
                      label="Taxonomy"
                      name="Taxonomy"
                      onChange={handleInputChange}
                      value={(SingleTaxonomy && SingleTaxonomy) || ""}
                    >
                      {TaxonomyName &&
                        TaxonomyName.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField
                    name="currency"
                    label="Currency"
                    value={currency && currency}
                    disabled
                    sx={{
                      width: "280px",
                      height: "49px",
                      color: "black",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                md={12}
                sx={{
                  mt: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                  style={{ height: "70%", width: "33%" }}
                >
                  <Button
                    style={{
                      backgroundColor: selectedOption === "risk" ? "#52009b" : "transparent",
                      color: selectedOption === "risk" ? "white" : "black",
                      width: "50%",
                      // height: "100%",
                      borderRadius: "2px",
                    }}
                    variant={selectedOption === "risk" ? "contained" : "outlined"}
                    onClick={() => handleOptionChange("risk")}
                  >
                    Risk Type
                  </Button>
                  <Button
                    onClick={() => handleOptionChange("incident")}
                    style={{
                      backgroundColor: selectedOption === "incident" ? "#52009b" : "transparent",
                      color: selectedOption === "incident" ? "white" : "black",
                      width: "50%",
                      // height: "100%",
                      borderRadius: "2px",
                    }}
                    variant={selectedOption === "incident" ? "contained" : "outlined"}
                  >
                    Incident Type
                  </Button>
                </ButtonGroup>
                <MDButton
                  variant="contained"
                  BackgroundColor="#402899"
                  color="#402899"
                  sx={{
                    color: "#f7f4fb",
                    borderRadius: "4px",
                  }}
                  className="add-button"
                  onClick={() => handleAdd()}
                >
                  Impact Level Setting
                </MDButton>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item md={12} sx={{ display: "flex", justifyContent: "flex-end", mb: "8px" }}>
            <MDButton
              variant="contained"
              BackgroundColor="#402899"
              color="#402899"
              sx={{
                color: "#f7f4fb",
                borderRadius: "4px",
              }}
              className="add-button"
              onClick={() => handleAdd()}
            >
              Impact Level Setting
            </MDButton>
          </Grid> */}

          <Grid item md={12} sx={{ ml: "5px" }}>
            {selectedOption === "risk" ? (
              <RiskType
                selectedOption={selectedOption}
                taxonomyId={selectedTaxonomyId && selectedTaxonomyId.id}
                columnChange={colChanged}
              />
            ) : (
              <IncidentType
                selectedOption={selectedOption}
                taxonomyId={selectedTaxonomyId && selectedTaxonomyId.id}
                columnChange={colChanged}
              />
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Dialog open={open}>
        <Grid sx={{ p: "20px" }}>
          <Grid item md={12}>
            <ThemeProvider theme={SettingCustomTable}>
              <MaterialReactTable
                columns={columns}
                data={SizingupdatedData || ""}
                editingMode="row" // Use "row" editing mode for inline editing
                //   enableColumnOrdering
                enableEditing
                enableColumnActions={false}
                enablePagination={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                onEditingRowCancel={handleCancelRowEdits}
                positionActionsColumn="last"
                enableRowOrdering
                enableSorting={false}
                muiTableHeadCellProps={{
                  sx: {
                    backgroundColor: "#F7F8FF !important",
                  },
                }}
                muiTableBodyRowDragHandleProps={({ table }) => ({
                  onDragEnd: () => {
                    const { draggingRow, hoveredRow } = table.getState();
                    if (hoveredRow && draggingRow) {
                      SizingupdatedData.splice(
                        hoveredRow.index,
                        0,
                        SizingupdatedData.splice(draggingRow.index, 1)[0]
                      );
                      setSizingUpdatedData([...SizingupdatedData]);
                      handleSaveRow();
                    }
                  },
                })}
                renderRowActions={({ row, table }) => {
                  const isRowEditing = row.index === editRow;

                  if (IsNew === true) {
                    table.setEditingRow(row);
                    // setIsEdit(row.id);
                  }

                  return (
                    <Box sx={{ display: "flex" }}>
                      {isRowEditing ? (
                        <>
                          <Tooltip arrow title="Save">
                            <IconButton
                              onClick={() => {
                                const a = IsEmptyEntry();

                                if (a === false) {
                                  handleSaveRow(row);
                                  if (isSuccess === false) {
                                    // setIsEdit(null);
                                    table.setEditingRow(null);
                                    setIsNew(false);
                                  }
                                }

                                // table.setEditingRow(null);
                              }}
                            >
                              <Save />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Cancel">
                            <IconButton
                              onClick={() => {
                                handleCancelRowEdits();
                                setIsEdit(null);
                                table.setEditingRow(null);
                                setIsNew(false);
                              }}
                            >
                              <Cancel />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip arrow title="Edit">
                          <IconButton
                            onClick={() => {
                              table.setEditingRow(row);
                              setIsEdit(row.index);
                              setIsNew(false);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Tooltip arrow title="Delete">
                        <IconButton
                          // color="error"
                          onClick={() => handleDeleteRow(row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  );
                }}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
        <DialogActions sx={{ p: "1.25rem", alignItems: "flex-end" }}>
          <Grid item md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            {IsNew === false && (
              <Button
                variant="outlined"
                onClick={() => handleAddButtonClick()}
                sx={{
                  color: "#402899",
                  marginTop: "20px",
                  borderColor: "#402899",
                  borderRadius: "4px",
                }}
              >
                +Add New
              </Button>
            )}
          </Grid>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              color: "#402899",
              marginTop: "20px",
              borderColor: "#402899",
              borderRadius: "4px",
              marginLeft: "12px",
            }}
          >
            Cancel
          </Button>
          {/* <MDButton
            color="black"
            variant="outlined"
            width="79px"
            height="34px"
            sx={{
              mr: 3.5,
              color: "#263445",
              border: "1px solid #B5BFD0",
              textTransform: "none",
              borderRadius: "4px",
              fontSize: "15px",
              marginLeft: "12px",
            }}
            onClick={handleClose}
          >
            Cancel
          </MDButton> */}
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default RiskSizing;
