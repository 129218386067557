import { createTheme } from "@mui/material/styles";

const NoborderTable = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: "none",
          boxShadow: "none",
        },
      },
    },
    MuiTableContainer: { styleOverrides: { root: { border: "none", boxShadow: "none" } } },
    MuiTableCell: {
      styleOverrides: { root: { padding: 0, letterSpacing: 0, fontFamily: "red hat display" } },
    },
    MuiTableHeadCell: {
      styleOverrides: { root: { borderBottom: "1px solid #B5BFD0" } },
    },
    MuiDialog: {
      // styleOverrides: { paper: { borderRadius: "none" } },
      styleOverrides: { root: { borderRadius: "none" } },
    },
  },
});
export default NoborderTable;
