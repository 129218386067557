// /**
// =========================================================
// * Material Dashboard 2 React - v2.1.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/material-dashboard-react
// * Copyright 2022 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// // Material Dashboard 2 React base styles
// import colors from "assets/theme/base/colors";
// import boxShadows from "assets/theme/base/boxShadows";
// import borders from "assets/theme/base/borders";

// const { white } = colors;
// const { md } = boxShadows;
// const { borderRadius } = borders;

// const tableContainer = {
//   styleOverrides: {
//     root: {
//       backgroundColor: white.main,
//       boxShadow: md,
//       borderRadius: borderRadius.xl,
//     },
//   },
// };

// export default tableContainer;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
// import boxShadows from "assets/theme/base/boxShadows";
import borders from "assets/theme/base/borders";

// const { white } = colors;
// const { md } = boxShadows;
// const { borderRadius } = borders;
const { borderWidth } = borders;
const { light } = colors;

const tableContainer = {
  styleOverrides: {
    root: {
      // backgroundColor: "#ffff",
      // boxShadow: md,
      // borderRadius: borderRadius.xl,
      border: `${borderWidth[1]} solid ${light.main}`,
    },
  },
};

export default tableContainer;
