/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Scatter,
  LabelList,
  ScatterChart,
  Brush,
} from "recharts";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { MaterialReactTable } from "material-react-table";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { loginRequest, protectedResources, siteData } from "../../authConfig";

function FileOverview() {
  const { error, execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });
  const [allData, setAllData] = useState(null);
  const [fileList, setFileList] = useState(null);
  const [fileStatus, setFileStatus] = useState();
  const [fileDate, setFileDate] = useState();
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const onFileChange = () => (e, newValue) => {
    // values.industryType = newValue;
    console.log("new Value", newValue);
    if (newValue) {
      setFileDate(newValue.datetime);
      setFileStatus(newValue.status);
      execute("GET", `${protectedResources.apiTodoList.endpoint}/getFileData?File=${newValue.File}`)
        .then((response) => {
          if (response && response.length > 0) {
            console.log(response);
            setData(response);
            // Get the column names
            const columnNames = Object.keys(response[0]);

            // Set the column names in the state
            const newColumns = [];
            columnNames.forEach((column) => {
              newColumns.push({ header: column, accessorKey: column, minWidth: "200" });
            });
            console.log(newColumns);
            setColumns(newColumns);
          } else {
            // Handle empty response or error case
          }
        })
        .catch(() => {
          // Handle error
        });
    } else {
      setFileDate();
      setFileStatus();
      setData(null);
    }
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogText("");
  };
  useEffect(() => {
    if (!fileList) {
      execute("GET", `${protectedResources.apiTodoList.endpoint}/filestatus`)
        .then((response) => {
          if (response) {
            const newRows = response.map((row) => ({
              id: row.id,
              File: row.File,
              status: row.status,
              datetime: row.datetime,
            }));
            setFileList(newRows);
          } else {
            // Handle empty response or error case
          }
        })
        .catch(() => {
          // Handle error
        });
    }
  }, [execute, fileList]);
  /* if (error) {
    return <div>Error: {error.message}</div>;
  }
 */
  return (
    <>
      <>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            File Status
          </MDTypography>
        </MDBox>
        <MDBox px={2} py={2}>
          <Grid container spacing={1}>
            {fileList && (
              <Grid item xs={12} md={12} lg={12}>
                <Autocomplete
                  options={fileList}
                  onChange={onFileChange()}
                  style={{
                    width: 500,
                  }}
                  getOptionLabel={(option) =>
                    option.File.substring(option.File.lastIndexOf("/") + 1)
                  }
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" label="Files Uploaded" />
                  )}
                />
              </Grid>
            )}
            {fileStatus && (
              <>
                <Grid item xs={6} md={6} lg={6}>
                  <MDTypography variant="body2">Status : {fileStatus}</MDTypography>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <MDTypography variant="body2">Date : {fileDate}</MDTypography>
                </Grid>
              </>
            )}
            {data && (
              <>
                <MaterialReactTable
                  muiTableProps={{
                    sx: {
                      tableLayout: "fixed",
                    },
                  }}
                  columns={columns}
                  data={data}
                  initialState={{
                    density: "compact",
                  }}
                  muiTableContainerProps={{
                    sx: (theme) => ({
                      // stripe the rows, make odd rows a darker color
                      borderRadius: "0",
                    }),
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    onDoubleClick: (event) => {
                      console.info(event, row);
                      let text = "";
                      columns.forEach((col) => {
                        text += `${col.accessorKey} : ${row.original[col.accessorKey]} \n`;
                      });
                      setDialogText(text);
                      setOpenDialog(true);
                    },
                  })}
                />
                <Dialog
                  open={openDialog}
                  onClose={handleDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="md"
                  fullWidth
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <TextField
                        autoFocus
                        label="Details"
                        type="text"
                        multiline
                        fullWidth
                        value={dialogText}
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <MDButton onClick={handleDialogClose} autoFocus>
                      Close
                    </MDButton>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Grid>
        </MDBox>
      </>
    </>
  );
}

function FileStatus() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
        >
          <FileOverview />
        </MsalAuthenticationTemplate>
      </MDBox>
    </DashboardLayout>
  );
}

export default FileStatus;
