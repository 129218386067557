import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import React, { useState, useCallback, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { Edit, Save, Cancel } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "components/MDButton";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { protectedResources, siteData } from "authConfig";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack } from "react-bootstrap";

function AnticipateSetting() {
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  const [sourceList] = useState(["ALL", "Bing", "Google"]);
  const [TableData, setTableData] = useState(null);
  // get appetite type for column heading

  const getTableData = () => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/anticipateSetting/list`).then(
      (response) => {
        if (response && response.success === true) {
          setTableData(response.data);
        }
      }
    );
  };
  //  get data from api
  useEffect(() => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/anticipateSetting/list`).then(
      (response) => {
        if (response && response.success === true && response.data.length > 0) {
          setTableData(response.data);
        }
      }
    );
  }, [execute]);

  // for save button rendering

  // Update the edited data for each row using the index as the key
  const [updatedData, setUpdatedData] = useState([]);

  const tableData = TableData
    ? TableData.map((data) => ({
        id: data.id,
        name: data.name,
        keyword: data.keyword,
        source: data.source,
      }))
    : [];
  // useEffect to initialize the state once TableData is available
  useEffect(() => {
    setUpdatedData(tableData);
  }, [TableData]);

  // handle input change for editing row
  // const [validationErrors, setValidationErrors] = useState({});
  const [, setEditObject] = useState(null);
  const [editRow, setIsEdit] = useState();
  const [open, setOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: "",
    keyword: "",
    source: "",
    theme: "",
  });
  // add new appetite
  const [Anticipate, setAnticipate] = useState({
    name: "",
    keyword: "",
    source: "",
    theme: "",
  });
  const validateFields = () => {
    let isValid = true;
    const errors = {};

    // Validate Risk Name and desc
    if (!Anticipate.name) {
      isValid = false;
      errors.Name = "Name is required";
    }
    if (!Anticipate.keyword) {
      isValid = false;
      errors.Keyword = "Keyword is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  // const [isSuccess, setisSuccess] = useState(false);
  // const validateRequired = (value) => !!value.length;
  const getCommonEditTextFieldProps = useCallback(
    (cell) => ({
      onBlur: (event) => {
        const { row, column } = cell;
        const rowId = row.original.id; // Get the ID of the row element (array element ID) being updated
        const updatedRowElement = updatedData.find((item) => item.id === rowId); // Find the array element with the matching ID in updatedData

        if (updatedRowElement) {
          // Update the edited data for the specific row element
          const updatedRowData = {
            ...updatedRowElement,
            [column.id]: event.target.value,
          };

          // Save the updatedRowData in the editObject state
          setEditObject(updatedRowData);

          setUpdatedData((prevData) =>
            prevData.map((item) => (item.id === rowId ? updatedRowData : item))
          );
        }
      },
    }),
    [updatedData, setEditObject]
  );

  const columns = [
    {
      id: "name",
      accessorKey: "name",
      header: "Name",
      size: 140,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      id: "keyword",
      accessorKey: "keyword",
      header: "Keyword",
      size: 140,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      id: "source",
      accessorKey: "source",
      header: "Source",
      size: 140,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
  ];

  // saving the edited row
  const handleSaveRow = async () => {};
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAnticipate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSourceChange = () => (event, newValue) => {
    console.log("Source changed", event, newValue);
    setAnticipate((prevState) => ({
      ...prevState,
      source: newValue,
    }));
  };
  // delete a row
  const handleDeleteRow = (row) => {
    const { id } = row.original;

    try {
      execute(
        "DELETE",
        `${protectedResources.apiTodoList.endpoint}/anticipateSetting/delete/${id}`
      ).then(() => {
        getTableData();
      });
    } catch {
      /* empty */
    }
  };

  // cancel editing row
  const handleCancelRowEdits = () => {
    getTableData();
    // setValidationErrors({});
  };

  const postData = async () => {
    const requestBody = {
      name: Anticipate.name,
      keyword: Anticipate.keyword,
      source: Anticipate.source,
      theme: Anticipate.theme,
    };

    const isValid = validateFields();

    if (isValid) {
      try {
        await execute(
          "POST",
          `${protectedResources.apiTodoList.endpoint}/anticipateSetting/create`,
          requestBody
        ).then(() => {
          setOpen(false);
          getTableData();
        });
      } catch (error) {
        /* . empty */
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  // dialog box
  const handleAdd = () => {
    setOpen(true);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container style={{ borderRadius: "5px", padding: "15px" }} spacing={2}>
        <Grid item md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <MDButton
            onClick={() => handleAdd()}
            backgroundColor="#52009b"
            variant="contained"
            color="#52009b"
            sx={{ color: "#f7f4fb" }}
          >
            Add
          </MDButton>
        </Grid>
        <Grid item md={12}>
          <MaterialReactTable
            columns={columns}
            data={updatedData || ""}
            editingMode="row" // Use "row" editing mode for inline editing
            //   enableColumnOrdering
            enableEditing
            onEditingRowCancel={handleCancelRowEdits}
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => {
              const isRowEditing = row.id === editRow;
              // const IsNew = row.original.isnew;

              return (
                <Box sx={{ display: "flex" }}>
                  {isRowEditing ? (
                    <>
                      <Tooltip arrow title="Save">
                        <IconButton
                          onClick={() => {
                            handleSaveRow(row);
                            setIsEdit(null);
                            // if (isSuccess === true) {
                            //   table.setEditingRow(null);
                            // }
                            table.setEditingRow(null);
                          }}
                        >
                          <Save />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title="Cancel">
                        <IconButton
                          onClick={() => {
                            // handleCancelRowEdits();
                            setIsEdit(null);
                            table.setEditingRow(null);
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <Tooltip arrow title="Edit">
                      <IconButton
                        onClick={() => {
                          table.setEditingRow(row);
                          setIsEdit(row.id);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip arrow title="Delete">
                    <IconButton
                      // color="error"
                      onClick={() => handleDeleteRow(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            }}
          />
        </Grid>
      </Grid>
      <Dialog open={open}>
        <DialogTitle textAlign="center">Add New Horizon Scan</DialogTitle>

        <DialogContent sx={{ pt: "10px", width: "600px", mx: "2" }}>
          <form onSubmit={(e) => e.preventDefault()} style={{ paddingTop: "12px" }}>
            <Stack
              sx={{
                width: "100%",

                gap: "2rem",
              }}
            >
              <TextField
                label="Name"
                name="name"
                onChange={handleInputChange}
                required
                error={!!formErrors.name}
                style={{
                  width: 500,
                }}
                helperText={formErrors.name}
              />
              <TextField
                label="Keyword"
                name="keyword"
                onChange={handleInputChange}
                required
                style={{
                  width: 500,
                }}
                error={!!formErrors.keyword}
                helperText={formErrors.keyword}
              />
              <TextField
                label="Theme"
                name="theme"
                onChange={handleInputChange}
                required
                style={{
                  width: 500,
                }}
                error={!!formErrors.theme}
                helperText={formErrors.theme}
              />
              <Autocomplete
                options={sourceList}
                onChange={handleSourceChange()}
                style={{
                  width: 500,
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Source" variant="outlined" />
                )}
              />
            </Stack>
          </form>
        </DialogContent>

        <DialogActions sx={{ p: "1.25rem" }}>
          <MDButton onClick={handleClose}>Cancel</MDButton>

          <MDButton
            backgroundColor="#52009b"
            color="#52009b"
            sx={{ color: "#f7f4fb" }}
            onClick={postData}
            variant="contained"
          >
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default AnticipateSetting;
