/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
} from "@mui/material";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Scatter,
  LabelList,
  ScatterChart,
  Brush,
} from "recharts";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { MaterialReactTable } from "material-react-table";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { loginRequest, protectedResources, siteData } from "../../authConfig";

function ScatterPlot({ data }) {
  const colors = ["purple", "green", "red", "blue", "orange"];
  return (
    <ScatterChart
      width={1000}
      height={400}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <CartesianGrid />
      <XAxis type="number" dataKey="x" hide />
      <YAxis type="number" dataKey="y" hide />
      <Tooltip cursor={{ strokeDasharray: "3 3" }} />
      <Legend />

      {colors.map((color, category) => {
        console.log(color, category, JSON.parse(data[category]));
        const filteredData = JSON.parse(data[category]);
        console.log("filteredData", filteredData);
        const avgX = filteredData.reduce((sum, item) => sum + item.x, 0) / filteredData.length;
        const avgY = filteredData.reduce((sum, item) => sum + item.y, 0) / filteredData.length;
        // const sumX = filteredData.reduce((sum, item) => sum + item.x, 0);
        // console.log(sumX);
        // const avgX = sumX / filteredData.length;
        // const sumY = filteredData.reduce((sum, item) => sum + item.y, 0);
        // const avgY = sumY / filteredData.length;

        return (
          <React.Fragment key={category}>
            <Scatter
              name={`Theme ${category}`}
              data={filteredData}
              fill={color}
              shape="circle"
              opacity={0.4}
            />
            <Scatter data={[{ x: avgX, y: avgY }]} fill={color} shape="star" size={100} />
          </React.Fragment>
        );
      })}
    </ScatterChart>
  );
}

function IndexingOverview() {
  const { error, execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });
  const colors = ["purple", "green", "red", "blue", "orange"];

  const [recordType, setRecordType] = useState("AUDIT");
  const [options, setOptions] = useState(["AUDIT", "INCIDENT", "RISKS"]);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [allSubCats, setAllSubCats] = useState([]);
  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    // if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
    recordData[row.index] = values;
    console.log("NEw value", values);
    console.log(row);
    // send/receive api updates here
    setRecordData([...recordData]);
    verifyRecord(row.original.id, values.subCategory);
    exitEditingMode(); // required to exit editing mode
  };
  const recordColumns = [
    {
      accessorKey: "name", // access nested data with dot notation
      header: "Name",
      minSize: 100, // min size enforced during resizing
      maxSize: 200, // max size enforced during resizing
      size: 100, // medium column
      enableEditing: false,
    },
    {
      accessorKey: "description",
      header: "Description",
      minSize: 100, // min size enforced during resizing
      maxSize: 200, // max size enforced during resizing
      size: 180, // medium column
      enableEditing: false,
    },
    {
      accessorKey: "subCategory", // normal accessorKey
      header: "Sub Category",
      editVariant: "select",
      editSelectOptions: allSubCats,
    },
  ];
  const clusterColumns = [
    {
      accessorKey: "cluster", // access nested data with dot notation
      header: "No.",
      size: 10,
    },
    {
      accessorKey: "theme",
      header: "Theme",
      size: 350,
    },
    {
      accessorKey: "count", // normal accessorKey
      header: "# of Records",
      size: 10,
    },
    {
      accessorKey: "action", // normal accessorKey
      header: "Action",
      size: 100,
      muiTableBodyCellProps: ({ row }) => ({
        onClick: () => {
          console.log(row);
          execute(
            "GET",
            `${protectedResources.apiTodoList.endpoint}/getThemeRecords?recordType=${recordType}&subCategory=${selectedSubCategory}&cluster=${row.original.cluster}`
          )
            .then((response) => {
              setRecordData(response);
              setSelectedCluster(row.original.cluster);
              setStep(2);
            })
            .catch((err) => {});
        },
      }),
      // or in the component override callbacks like this
      Cell: ({ cell }) => <div>Review Indexing</div>,
    },
  ];
  const [allData, setAllData] = useState(null);
  const [themeData, setThemeData] = useState(null);
  const [clusterData, setClusterData] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [selectedCluster, setSelectedCluster] = useState();

  const onChange = (e, newValue) => {
    console.log("Record Ty", newValue);
    setRecordType(newValue);
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/getRecordIndexing?recordType=${newValue}`
    ).then((response) => {
      setAllData(response);
    });
  };
  const handleBarClick = (data, index) => {
    // Handle bar click event here
    setStep(1);
    setLoading(true);
    setSelectedSubCategory(data.SubCategory);
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/getTheme?recordType=${recordType}&subCategory=${data.SubCategory}`
    )
      .then((response) => {
        setLoading(false);
        setThemeData(response.scatterJson);
        setClusterData(response.responseJson);
        console.log(response);
      })
      .catch((err) => {
        setLoading(false);
        setThemeData([]);
        setClusterData([{ cluster: 0, theme: "All Records" }]);
      });
  };
  useEffect(() => {
    if (!allData) {
      execute(
        "GET",
        `${protectedResources.apiTodoList.endpoint}/getRecordIndexing?recordType=${recordType}`
      ).then((response) => {
        setAllData(response);
      });
    }
  }, [execute, allData, recordType]);
  useEffect(() => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/getRecordIndexing?recordType=${recordType}`
    ).then((response) => {
      setAllData(response);
    });
  }, [step]);
  useEffect(() => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/getAllSubCategories`).then(
      (response) => {
        setAllSubCats(response);
      }
    );
  }, [step]);
  const handleFinishRecord = () => {
    const payload = {
      cluster: selectedCluster,
      subCategory: selectedSubCategory,
      recordType,
    };
    execute("POST", `${protectedResources.apiTodoList.endpoint}/updateThemeRecords`, payload).then(
      (response) => {
        setDialogOpen(false);
        setStep(1);
        setSelectedCluster(-1);
      }
    );
  };
  const verifyRecord = (id, newSubCategory) => {
    const payload = {
      cluster: selectedCluster,
      subCategory: selectedSubCategory,
      recordType,
      id,
      newSubCategory,
    };
    execute(
      "POST",
      `${protectedResources.apiTodoList.endpoint}/updateSubCartegoryThemeRecords`,
      payload
    ).then((response) => {});
  };
  /* if (error) {
    return <div>Error: {error.message}</div>;
  }
 */
  return (
    <>
      <>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            Data Indexing and Distribution Overview
          </MDTypography>
        </MDBox>
        {step === 0 ? (
          <Grid container spacing={1}>
            <Grid item xs={10} md={10} lg={10} />
            {allData &&
              allData.map((indexdata) => (
                <Grid item xs={6} md={6} lg={6}>
                  <Card
                    sx={{
                      bgcolor: "background.paper",
                      boxShadow: 1,
                      borderRadius: 2,
                      minWidth: 300,
                      my: 2,
                    }}
                  >
                    <CardHeader component={MDTypography} title={indexdata.CategoryName} />
                    <MDBox>
                      <BarChart
                        data={indexdata.subCategories}
                        width={500}
                        height={300}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="SubCategory" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                          dataKey="UnverifiedCount"
                          fill="#0d47a1"
                          stackId="a"
                          onClick={handleBarClick}
                        />
                        <Bar
                          dataKey="VerifiedCount"
                          fill="#64b5f6"
                          stackId="a"
                          onClick={handleBarClick}
                        />
                      </BarChart>
                    </MDBox>
                    {/* {indexdata.subCategories.map((subCats) => (
                    <CardContent>
                      {subCats.SubCategory}-{subCats.Count}
                    </CardContent>
                  ))} */}
                  </Card>
                </Grid>
              ))}
          </Grid>
        ) : step === 1 ? (
          loading ? (
            <Grid container spacing={1}>
              Loading themes.....Please wait...
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={2} md={2} lg={2}>
                <MDButton color="info" variant="gradient" onClick={() => setStep(0)}>
                  Back
                </MDButton>
              </Grid>
              <Grid item xs={10} md={10} lg={10} />
              <Grid item xs={6} md={6} lg={6}>
                <MDTypography variant="h4">{selectedSubCategory}</MDTypography>
              </Grid>
              <Grid item xs={10} md={10} lg={10} />
              <Grid item xs={12} md={12} lg={12}>
                {themeData.length > 0 ? (
                  <MDBox align="center">
                    <ScatterPlot data={themeData} />
                  </MDBox>
                ) : (
                  <>
                    <MDTypography>
                      {" "}
                      No groupings were found due to dataset not being stastically valid.
                    </MDTypography>
                  </>
                )}
              </Grid>
              {clusterData.length > 0 ? (
                <MaterialReactTable
                  muiTableProps={{
                    sx: {
                      tableLayout: "fixed",
                    },
                  }}
                  muiTableContainerProps={{
                    sx: (theme) => ({
                      // stripe the rows, make odd rows a darker color
                      borderRadius: "0",
                    }),
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    sx: {
                      // stripe the rows, make odd rows a darker color
                      color: colors[row.index],
                      margin: 0,
                      padding: 0,
                    },
                  })}
                  columns={clusterColumns}
                  data={clusterData}
                  initialState={{
                    density: "compact",
                  }}
                  enablePagination={false}
                  enableTopToolbar={false}
                  enableFilters={false}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableBottomToolbar={false}
                  enableColumnActions={false}
                  enableFullScreenToggle={false}
                />
              ) : (
                <div />
              )}
            </Grid>
          )
        ) : step === 2 ? (
          <>
            <Grid container spacing={1}>
              <Grid item xs={2} md={2} lg={2}>
                <MDButton color="info" variant="gradient" onClick={() => setStep(step - 1)}>
                  Back
                </MDButton>
              </Grid>
              <Grid item xs={10} md={10} lg={10} />
              <Grid item xs={6} md={6} lg={6}>
                <MDTypography variant="h4">{selectedSubCategory}</MDTypography>
              </Grid>
              <Grid item xs={2} md={2} lg={2}>
                <MDButton color="info" variant="gradient" onClick={() => setDialogOpen(true)}>
                  Finish Review
                </MDButton>
              </Grid>
              <Grid item xs={12} md={12} lg={12} />
              {recordData.length > 0 ? (
                <MaterialReactTable
                  muiTableProps={{
                    sx: {
                      tableLayout: "fixed",
                    },
                  }}
                  columns={recordColumns}
                  data={recordData}
                  initialState={{
                    density: "compact",
                  }}
                  muiTableContainerProps={{
                    sx: (theme) => ({
                      // stripe the rows, make odd rows a darker color
                      borderRadius: "0",
                    }),
                  }}
                  editingMode="row"
                  enableRowActions
                  enableEditing
                  muiTableBodyCellEditTextFieldProps={{
                    variant: "outlined",
                  }}
                  onEditingRowSave={handleSaveRow}
                />
              ) : (
                <div>
                  <MDTypography> All records verified.</MDTypography>
                </div>
              )}
            </Grid>
            <Dialog open={dialogOpen}>
              <DialogTitle textAlign="center">Confirmation</DialogTitle>
              <DialogContent>
                <MDTypography variant="body2">
                  Are you sure you are done? Once the review is finished you would not be able to
                  view these same records again.
                </MDTypography>
              </DialogContent>
              <DialogActions sx={{ p: "1.25rem" }}>
                <MDButton onClick={() => setDialogOpen(false)}>Cancel</MDButton>
                <MDButton color="info" onClick={handleFinishRecord} variant="gradient">
                  Save
                </MDButton>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={10} md={10} lg={10} />
          </Grid>
        )}
      </>
    </>
  );
}

function DataIndexing() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
        >
          <IndexingOverview />
        </MsalAuthenticationTemplate>
      </MDBox>
    </DashboardLayout>
  );
}

export default DataIndexing;
