/* eslint-disable no-unused-vars */
// /**
// =========================================================
// * Material Dashboard 2 React - v2.1.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/material-dashboard-react
// * Copyright 2022 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// // Material Dashboard 2 React base styles
// import typography from "assets/theme/base/typography";
// import colors from "assets/theme/base/colors";

// // Material Dashboard 2 React helper functions
// import pxToRem from "assets/theme/functions/pxToRem";
// import rgba from "assets/theme/functions/rgba";

// const { size, fontWeightRegular } = typography;
// const { white } = colors;

// const stepLabel = {
//   styleOverrides: {
//     label: {
//       marginTop: `${pxToRem(8)} !important`,
//       fontWeight: fontWeightRegular,
//       fontSize: size.xs,
//       color: "#9fc9ff",
//       textTransform: "uppercase",

//       "&.Mui-active": {
//         fontWeight: `${fontWeightRegular} !important`,
//         color: `${rgba(white.main, 0.8)} !important`,
//       },

//       "&.Mui-completed": {
//         fontWeight: `${fontWeightRegular} !important`,
//         color: `${rgba(white.main, 0.8)} !important`,
//       },
//     },
//   },
// };

// export default stepLabel;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
// import colors from "assets/theme/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import rgba from "assets/theme/functions/rgba";

const { size, fontWeightMedium } = typography;
// const { white } = colors;

const stepLabel = {
  styleOverrides: {
    label: {
      // marginTop: `${pxToRem(8)} `,
      fontWeight: fontWeightMedium,
      // fontSize: size.xs,
      color: "black",
      // textTransform: "uppercase",

      "&.Mui-active": {
        fontWeight: `${fontWeightMedium} `,
        color: `${rgba("black", 0.8)} `,
      },

      "&.Mui-completed": {
        fontWeight: `${fontWeightMedium} `,
        color: `${rgba("black", 0.8)} `,
      },
    },
  },
};

export default stepLabel;
