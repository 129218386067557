/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from "react";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Card,
  Divider,
  FormControl,
  Grid,
  // IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Skeleton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Switch,
  createTheme,
  ThemeProvider,
  Breadcrumbs,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MDButton from "components/MDButton";
import "./Taxonomy.css";

import useFetchWithMsal from "hooks/useFetchWithMsal";

// dialog box
import { Link, useLocation, useNavigate } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

// edit button
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { protectedResources, siteData } from "../../authConfig";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/projectIcons/Component 207 – 45.svg";

// implement stepper steps
const steps = ["Taxonomy Details", "Add Risk and sub categories", "Review and Save"];

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));
function ConfigureTaxonomy() {
  const location = useLocation();
  const Primaryid = location.state.categoryId;
  const Tid = location.state.taxonomyId;

  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });
  const [currency, setCurrency] = useState("");
  // get the single taxonomy that is clicked in overview page
  const [SingleTaxonomy, setSingleTaxonomy] = useState(null);
  const [noData, setNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!SingleTaxonomy && Tid) {
      execute("GET", `${protectedResources.apiTodoList.endpoint}/taxonomy/details/${Tid}`).then(
        (response) => {
          if (response && response.success === true && response.data.length > 0) {
            setSingleTaxonomy(response.data);
            setIsLoading(false);
          }
          if (response && response.success === true && response.data.length === 0) {
            setNoData(true);
          }
        }
      );
    } else {
      setNoData(true);
    }

    execute("GET", `${protectedResources.apiTodoList.endpoint}/common/currencylist`).then(
      (response) => {
        if (response && response.success === true && response.data.length > 0) {
          setCurrency(response.data);
        }
      }
    );
  }, [execute, SingleTaxonomy]);

  // Taxonomy object
  const [Taxonomy, setTaxonomy] = useState({});
  // const initialCountryName =
  //   (currency &&
  //     currency.find((option) => option.countryCode === Taxonomy.countryCode)?.countryName) ||
  //   "";

  //   Category object
  const [Categories, setCategories] = useState([]);

  const [Isdisable] = useState(!!Primaryid);
  useEffect(() => {
    if (SingleTaxonomy) {
      setTaxonomy({
        id: SingleTaxonomy[0].id,
        Taxo_Name: SingleTaxonomy[0].name,
        Taxo_Desc: SingleTaxonomy[0].description,
        countryCode: SingleTaxonomy[0].countryCode,
        evaluationPeriod: SingleTaxonomy[0].evaluationPeriod,
      });

      setCategories(SingleTaxonomy[0].Categories);
    } else {
      setTaxonomy({
        id: uuidv4(),
        Taxo_Name: "",
        Taxo_Desc: "",
        countryCode: "",
        evaluationPeriod: "1 year",
      });
      setCategories([
        {
          id: uuidv4(),
          name: "",
          Owner: "Owner",
          desc: "",
          SubCategories: [
            {
              id: uuidv4(), // Add a UUID property to the subcategory object
              name: "",
              Appetite: "",
              Owner: "",
              Description: "",
            },
          ],
        },
      ]);
    }
  }, [SingleTaxonomy]);

  const [RiskData, setRiskData] = useState(null);

  useEffect(() => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/appetite/list`).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setRiskData(response.data);
      }
    });
  }, [execute]);
  // Extract the 'name' values from the 'riskAppetiteSetting' array and store them in the 'riskOption' array
  const riskAppetiteOptions = RiskData && RiskData.map((setting) => setting.name);
  // card click on review page

  const [clickedId, setClickedId] = useState(null);
  // take input/edit input for primary and sub category
  const handleInputChange = (categoryIndex, subCategoryIndex, field, value) => {
    if (Primaryid || Tid)
      setCategories((prevState) => {
        const updatedCategories = [...prevState];

        if (clickedId) {
          const updatedCategory = updatedCategories.find((category) => category.id === clickedId);

          if (updatedCategory && subCategoryIndex !== null) {
            const updatedSubCategories = [...updatedCategory.SubCategories];
            const updatedSubCategory = { ...updatedSubCategories[subCategoryIndex] };
            updatedSubCategory[field] = value;
            updatedSubCategories[subCategoryIndex] = updatedSubCategory;
            updatedCategory.SubCategories = updatedSubCategories;
          } else {
            updatedCategory[field] = value;
          }
        } else if (Primaryid) {
          const updatedCategory = updatedCategories.find((category) => category.id === Primaryid);

          if (updatedCategory && subCategoryIndex !== null) {
            const updatedSubCategories = [...updatedCategory.SubCategories];
            const updatedSubCategory = { ...updatedSubCategories[subCategoryIndex] };
            updatedSubCategory[field] = value;
            updatedSubCategories[subCategoryIndex] = updatedSubCategory;
            updatedCategory.SubCategories = updatedSubCategories;
          } else {
            updatedCategory[field] = value;
          }
        } else {
          const updatedCategory = { ...updatedCategories[categoryIndex] };

          if (subCategoryIndex !== null) {
            const updatedSubCategories = [...updatedCategory.SubCategories];
            const updatedSubCategory = { ...updatedSubCategories[subCategoryIndex] };
            updatedSubCategory[field] = value;
            updatedSubCategories[subCategoryIndex] = updatedSubCategory;
            updatedCategory.SubCategories = updatedSubCategories;
          } else {
            updatedCategory[field] = value;
          }

          updatedCategories[categoryIndex] = updatedCategory;
        }

        return updatedCategories;
      });

    // new taxonomy case (first time adding taxonomy)
    if (!Primaryid && !Tid) {
      setCategories((prevState) => {
        const updatedCategories = [...prevState];

        if (clickedId) {
          const updatedCategory = updatedCategories.find((category) => category.id === clickedId);
          if (updatedCategory && subCategoryIndex !== null) {
            const updatedSubCategories = [...updatedCategory.SubCategories];
            const updatedSubCategory = { ...updatedSubCategories[subCategoryIndex] };
            updatedSubCategory[field] = value;
            updatedSubCategories[subCategoryIndex] = updatedSubCategory;
            updatedCategory.SubCategories = updatedSubCategories;
          } else {
            updatedCategory[field] = value;
          }
        } else {
          const updatedCategory = { ...updatedCategories[categoryIndex] };

          if (subCategoryIndex !== null) {
            const updatedSubCategories = [...updatedCategory.SubCategories];
            const updatedSubCategory = { ...updatedSubCategories[subCategoryIndex] };
            updatedSubCategory[field] = value;
            updatedSubCategories[subCategoryIndex] = updatedSubCategory;
            updatedCategory.SubCategories = updatedSubCategories;
          } else {
            updatedCategory[field] = value;
          }

          updatedCategories[categoryIndex] = updatedCategory;
        }

        return updatedCategories;
      });
    }
  };

  // =====expand variable======
  const [expanded, setExpanded] = useState();
  useEffect(() => {
    // Check if SingleTaxonomy[0] and Categories exist and the length is greater than 0
    const shouldInitialize =
      SingleTaxonomy &&
      SingleTaxonomy[0] &&
      SingleTaxonomy[0].Categories &&
      SingleTaxonomy[0].Categories.length > 0;

    // Initialize the expanded state based on the condition
    setExpanded(new Array(shouldInitialize ? SingleTaxonomy[0].Categories.length : 0).fill(false));
  }, [SingleTaxonomy]); // Empty dependency array to run this effect only once

  //  add category (new primary risk)
  const handleAddCategory = () => {
    if (!Primaryid) {
      setCategories((prevState) => {
        const updatedCategories = [...prevState];
        const newCategory = {
          id: uuidv4(),
          name: "",
          Owner: "",
          desc: "",
          SubCategories: [
            {
              id: uuidv4(),
              name: "",
              Appetite: "",
              Owner: "",
              Description: "",
            },
          ],
        };
        updatedCategories.push(newCategory);

        return updatedCategories;
      });
    } else {
      setCategories((prevState) => {
        const updatedCategories = [...prevState];
        const newCategory = {
          id: uuidv4(),
          name: "",
          Owner: "",
          desc: "",
          SubCategories: [
            {
              id: uuidv4(),
              name: "",
              Appetite: "",
              Owner: "",
              Description: "",
            },
          ],
        };
        updatedCategories.push(newCategory);

        return updatedCategories;
      });
    }
    // Create a new expanded array that includes all previous values
    // and appends 'true' for the newly added category
    setExpanded((prevExpanded) => [...prevExpanded, true]);
  };

  //   delete a category (delete primary risk)===============================

  const handleDeleteCategory = (categoryIndex) => {
    console.log("categoryIndex", categoryIndex);
    // const newExpand = [...expanded];
    //   newExpanded.splice(categoryIndex, 1);
    const newExpand = expanded.filter((_, index) => index !== categoryIndex);
    console.log("newExpanded", newExpand);
    setExpanded(newExpand);
    // setExpanded((prevState) => {
    //   const newExpanded = [...prevState];

    //   newExpanded.splice(categoryIndex, 1);
    //   // return newExpanded;
    //   return newExpanded;
    // });
    setCategories((prevState) => {
      const updatedCategories = [...prevState];

      if (clickedId) {
        const clickedCategoryIndex = updatedCategories.findIndex(
          (category) => category.id === clickedId
        );

        if (clickedCategoryIndex !== -1) {
          updatedCategories.splice(clickedCategoryIndex, 1);
        }
      } else {
        updatedCategories.splice(categoryIndex, 1);
      }

      return updatedCategories;
    });
  };

  // add sub category (add risk sub category)

  const handleAddSubCategory = (categoryIndex) => {
    setCategories((prevState) => {
      const updatedCategories = [...prevState];

      if (clickedId) {
        const clickedCategoryIndex = updatedCategories.findIndex(
          (category) => category.id === clickedId
        );

        if (clickedCategoryIndex !== -1) {
          const newSubCategory = {
            id: uuidv4(),
            name: "",
            Appetite: "",
            Owner: "",
            Description: "",
          };
          updatedCategories[clickedCategoryIndex].SubCategories.push(newSubCategory);
        }
      }
      // edit single category
      else if (Primaryid) {
        const primaryCategoryIndex = updatedCategories.findIndex(
          (category) => category.id === Primaryid
        );

        if (primaryCategoryIndex !== -1) {
          const newSubCategory = {
            id: uuidv4(),
            name: "",
            Appetite: "",
            Owner: "",
            Description: "",
          };
          updatedCategories[primaryCategoryIndex].SubCategories.push(newSubCategory);
        }
      }

      // new taxonomy
      else {
        const newSubCategory = {
          id: uuidv4(),
          name: "",
          Appetite: "",
          Owner: "",
          Description: "",
        };
        updatedCategories[categoryIndex].SubCategories.push(newSubCategory);
      }

      return updatedCategories;
    });
  };

  const handleDeleteSubCategory = (categoryIndex, subCategoryIndex) => {
    setCategories((prevState) => {
      const updatedCategories = [...prevState];

      if (clickedId) {
        const clickedCategoryIndex = updatedCategories.findIndex(
          (category) => category.id === clickedId
        );

        if (clickedCategoryIndex !== -1) {
          updatedCategories[clickedCategoryIndex].SubCategories.splice(subCategoryIndex, 1);
        }
      } else if (Primaryid) {
        const clickedCategoryIndex = updatedCategories.findIndex(
          (category) => category.id === Primaryid
        );

        if (clickedCategoryIndex !== -1) {
          updatedCategories[clickedCategoryIndex].SubCategories.splice(subCategoryIndex, 1);
        }
      } else {
        updatedCategories[categoryIndex].SubCategories.splice(subCategoryIndex, 1);
      }

      return updatedCategories;
    });
  };

  // Take inputs of Taxonomy details
  const handleTaxonomyChange = (event) => {
    const { name, value } = event.target;
    setTaxonomy((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [activeStep, setActiveStep] = React.useState(0);
  // const [completed, setCompleted] = React.useState({});

  const handleCardClick = (cardId) => {
    // Find the clicked object from the Categories array using the id
    setClickedId(cardId);

    setActiveStep(1);
  };

  // -----------------------------------------Validations-------------------------------------------------------------------
  //  Taxonomy step 1 validation
  const [formErrors, setFormErrors] = useState({
    Taxo_Name: "",
    Taxo_Desc: "",
    Taxo_curr: "",
  });

  const validateTaxonomyFields = () => {
    let isValid = true;
    const errors = {};

    // Validate Taxonomy Name
    if (!Taxonomy.Taxo_Name) {
      isValid = false;
      errors.Taxo_Name = "Taxonomy Name is required";
    } else if (Taxonomy.Taxo_Name.length < 10) {
      isValid = false;
      errors.Taxo_Name = "Taxonomy Name must be greater than 10 characters";
    } else if (/[^\w\s]/.test(Taxonomy.Taxo_Name)) {
      isValid = false;
      errors.Taxo_Name = "Taxonomy Name should not contain special characters";
    }
    if (!Taxonomy.countryCode) {
      isValid = false;
      errors.Taxo_curr = "Currency is required";
    }

    setFormErrors(errors);
    return isValid;
  };
  // ----------------------------------------------------------------------------
  // step2 validation

  const [categoriesErrors, setCategoriesErrors] = useState([]);

  const validateCategories = () => {
    let isValid = true;
    const errors = [];

    if (Primaryid) {
      Categories.filter((category) => category.id === Primaryid).forEach(
        (category, categoryIndex) => {
          const categoryErrors = {
            name: "",
            desc: "",
            subCategories: [],
          };

          // Validate category name
          if (!category.name) {
            isValid = false;
            categoryErrors.name = "Category name is required";
          } else if (category.name.length < 10) {
            isValid = false;
            categoryErrors.name = "Category name must be greater than 10 characters";
          } else {
            categoryErrors.name = ""; // Clear the error if the name is filled
          }

          // Validate category description
          if (!category.desc) {
            isValid = false;
            categoryErrors.desc = "Category description is required";
          } else if (category.desc.length < 20) {
            isValid = false;
            categoryErrors.desc = "Category description must be greater than 20 characters";
          } else if (category.desc.length > 200) {
            isValid = false;
            categoryErrors.desc = "Category description must be less than 200 characters";
          } else {
            categoryErrors.desc = ""; // Clear the error if the name is filled
          }

          category.SubCategories.forEach((subCategory, subCategoryIndex) => {
            const subCategoryErrors = {
              name: "",
              Appetite: "",
              Owner: "",
              Description: "",
            };

            // Validate subcategory name
            if (!subCategory.name) {
              isValid = false;
              subCategoryErrors.name = "Subcategory name is required";
            } else if (subCategory.name.length < 10) {
              isValid = false;
              subCategoryErrors.name = "Subcategory name must be greater than 10 characters";
            } else {
              subCategoryErrors.name = ""; // Clear the error if the name is filled
            }

            // Validate subcategory appetite
            if (!subCategory.Appetite) {
              isValid = false;
              subCategoryErrors.Appetite = "Appetite is required";
            }

            // Validate subcategory description
            if (!subCategory.Description) {
              isValid = false;
              subCategoryErrors.Description = "Description is required";
            } else if (subCategory.Description.length < 100) {
              isValid = false;
              subCategoryErrors.Description = "Description must be greater than 100 characters";
            }

            categoryErrors.subCategories[subCategoryIndex] = subCategoryErrors;
          });

          errors[categoryIndex] = categoryErrors;
        }
      );
    } else {
      Categories.forEach((category, categoryIndex) => {
        const categoryErrors = {
          name: "",
          desc: "",
          subCategories: [],
        };

        // Validate category name
        if (!category.name) {
          isValid = false;
          categoryErrors.name = "Category name is required";
        } else if (category.name.length < 10) {
          isValid = false;
          categoryErrors.name = "Category name must be greater than 10 characters";
        } else {
          categoryErrors.name = ""; // Clear the error if the name is filled
        }

        // Validate category description
        if (!category.desc) {
          isValid = false;
          categoryErrors.desc = "Category description is required";
        } else if (category.desc.length < 20) {
          isValid = false;
          categoryErrors.desc = "Category description must be greater than 20 characters";
        } else if (category.desc.length > 200) {
          isValid = false;
          categoryErrors.desc = "Category description must be less than 200 characters";
        } else {
          categoryErrors.desc = ""; // Clear the error if the name is filled
        }

        category.SubCategories.forEach((subCategory, subCategoryIndex) => {
          const subCategoryErrors = {
            name: "",
            Appetite: "",
            Owner: "",
            Description: "",
          };

          // Validate subcategory name
          if (!subCategory.name) {
            isValid = false;
            subCategoryErrors.name = "Subcategory name is required";
          } else if (subCategory.name.length < 10) {
            isValid = false;
            subCategoryErrors.name = "subcategory name must be greater than 10 characters";
          } else {
            subCategoryErrors.name = ""; // Clear the error if the name is filled
          }

          // Validate subcategory appetite
          if (!subCategory.Appetite) {
            isValid = false;
            subCategoryErrors.Appetite = "Appetite is required";
          }

          // Validate subcategory description
          if (!subCategory.Description) {
            isValid = false;
            subCategoryErrors.Description = "Description is required";
          } else if (subCategory.Description.length < 100) {
            isValid = false;
            subCategoryErrors.Description = "Description must be greater than 100 characters";
          }

          categoryErrors.subCategories[subCategoryIndex] = subCategoryErrors;
        });

        errors[categoryIndex] = categoryErrors;
      });
    }

    setCategoriesErrors(errors);
    return isValid;
  };

  // ==========================save dialog box===========================

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSave = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/Taxonomy");
  };

  // =======================POst data to api=====================================
  const postData = async () => {
    // Create the request body with the form data
    const requestBody = {
      name: Taxonomy.Taxo_Name,
      description: Taxonomy.Taxo_Desc,
      countryCode: Taxonomy.countryCode,
      evaluationPeriod: "1 year",
      Categories: [...Categories],
    };

    try {
      if (Primaryid) {
        await execute(
          "PUT",
          `${protectedResources.apiTodoList.endpoint}/taxonomy/update/${Tid}`,
          requestBody
        ).then(() => {
          handleSave();
        });
      } else if (!Primaryid && Tid) {
        await execute(
          "PUT",
          `${protectedResources.apiTodoList.endpoint}/taxonomy/update/${Tid}`,
          requestBody
        ).then(() => {
          handleSave();
        });
      } else {
        await execute(
          "POST",
          `${protectedResources.apiTodoList.endpoint}/taxonomy/create`,
          requestBody
        ).then(() => {
          handleSave();
        });
      }
    } catch (err) {
      // Handle error
    }
  };

  // --------------------Stepper---------------------------------------------------------------------------------------------

  // go to next step
  useEffect(() => {
    if (Primaryid) {
      setActiveStep(1);
    }
  }, [Primaryid]);

  const handleNext = () => {
    let isValid = true;

    if (activeStep === 0) {
      isValid = validateTaxonomyFields();
    }

    if (activeStep === 1) {
      isValid = validateCategories();
    }

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setClickedId(null);
    if (activeStep === 0) {
      navigate("/Taxonomy");
    }
    if (activeStep === 1 && Primaryid) {
      navigate("/Taxonomy");
    }
    if (Primaryid && clickedId && activeStep === 1) {
      // setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setActiveStep(2);
    }
    if (clickedId && activeStep === 2) {
      setActiveStep(1);
    }
  };

  // add primary key button on review page
  const categoriesContainerRef = useRef(null);

  const [scroll, setScroll] = useState(false);
  const handleAddKey = () => {
    setScroll(true);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setClickedId(null);
    handleAddCategory();
  };
  useEffect(() => {
    const lastChildElement = categoriesContainerRef.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: "smooth" });

    setScroll(false);
  }, [scroll]);

  //= ======================== expand all btn=============================================
  // Define your custom color
  const customColor = "#402899";

  // Create a custom theme with the custom color
  const theme = createTheme({
    palette: {
      secondary: {
        main: customColor,
      },
    },
  });

  const [expandedAll, setExpandedAll] = useState(false);

  const handleExpand = (categoryIndex) => {
    const newExpanded = [...expanded];
    newExpanded[categoryIndex] = !newExpanded[categoryIndex];

    setExpanded(newExpanded);
  };

  const handleExpandAllToggle = () => {
    setExpandedAll(!expandedAll);
    if (!expandedAll) {
      // Create a new array with all values set to true
      setExpanded(new Array(expanded.length).fill(true));
    } else {
      // Create a new array with all values set to false
      setExpanded(new Array(expanded.length).fill(false));
    }
  };

  console.log("expanded", expanded);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <form>
        <Grid
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "15px",
            minHeight: "520px",
          }}
        >
          <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: "10px" }}>
            <Link to="/Taxonomy" underline="hover" color="inherit">
              <span className="breadcrumb-span"> Taxonomy </span>
            </Link>
            <span style={{ fontSize: "15px", color: "#8090AA" }}>Configure Taxonomy</span>
          </Breadcrumbs>
          <Typography sx={{ pb: "5px", fontWeight: 500 }} fontSize="21px">
            Configure Taxonomy
          </Typography>
          <hr style={{ border: "1px solid #B5BFD0" }} />

          {/* --------------Stepper UI --------------------------------------------------------*/}
          <Stepper
            activeStep={activeStep}
            sx={{ p: "20px", pb: "35px", pl: "12%", pr: "12%", textAlign: "center" }}
          >
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel sx={{ fontSize: "15px" }} {...labelProps}>
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <>
            {activeStep === 0 ? (
              // Taxonomy details=================================================

              <Grid container spacing={4} sx={{ pl: "8px", pb: "70px" }}>
                <Grid item md={12} sx={{ p: "5px" }} sm={12} xs={12} lg={12}>
                  <span className="tax-header">Taxonomy Details</span>
                  <h6 className="tax-subheader">
                    Decide the Taxonomy Name, Description and Relationship before adding the risks{" "}
                  </h6>
                </Grid>
                {/* check if taxonomy is present or not */}
                {noData === true ? (
                  <>
                    <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                      <Stack direction="row" spacing={1}>
                        {/* taxonamy name field */}

                        <TextField
                          label="Taxonomy Name"
                          variant="outlined"
                          name="Taxo_Name"
                          sx={{ width: "280px", height: "49px" }}
                          value={Taxonomy.Taxo_Name || ""}
                          disabled={Isdisable}
                          onChange={handleTaxonomyChange}
                          required
                          error={!!formErrors.Taxo_Name}
                          helperText={formErrors.Taxo_Name}
                        />

                        {/* currency select box */}

                        <FormControl required>
                          <InputLabel id="Currency">Currency</InputLabel>

                          <Select
                            sx={{
                              width: "280px",
                              height: "44px",
                              // color: "black",
                            }}
                            label="Currency"
                            name="countryCode"
                            value={Taxonomy.countryCode || ""}
                            onChange={handleTaxonomyChange}
                            error={!!formErrors.Taxo_curr}
                            helperText={formErrors.Taxo_curr}
                          >
                            {currency &&
                              currency.map((option, index) => (
                                <MenuItem key={index} value={option.countryCode}>
                                  {option.countryName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>

                        {/* evaluation period select box */}

                        <FormControl required>
                          <InputLabel id="Evaluation">Evaluation Period</InputLabel>

                          <Select
                            sx={{
                              width: "280px",
                              height: "44px",
                              color: "black",
                            }}
                            label="Evaluation Period"
                            name="evaluationPeriod"
                            value={Taxonomy.evaluationPeriod || ""}
                            // onChange={handleTaxonomyChange}
                            error={!!formErrors.Taxo_curr}
                            helperText={formErrors.Taxo_curr}
                          >
                            <MenuItem value="1 year">1 year</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12} lg={12}>
                      <TextField
                        label="Description"
                        sx={{ width: 520 }}
                        name="Taxo_Desc"
                        multiline
                        value={Taxonomy.Taxo_Desc || ""}
                        disabled={Isdisable}
                        onChange={handleTaxonomyChange}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    {/* data is present from api */}
                    <Grid item md={12} sx={12} sm={12} xs={12} lg={12}>
                      <Stack direction="row" spacing={1}>
                        {/* taxonamy name field */}
                        {SingleTaxonomy ? (
                          <TextField
                            label="Taxonomy Name"
                            variant="outlined"
                            name="Taxo_Name"
                            sx={{ width: "280px", height: "49px" }}
                            value={Taxonomy.Taxo_Name || ""}
                            disabled={Isdisable}
                            onChange={handleTaxonomyChange}
                            required
                            error={!!formErrors.Taxo_Name}
                            helperText={formErrors.Taxo_Name}
                          />
                        ) : (
                          // <Skeleton variant="text" width={230} height={70} sx={{ mb: "15px" }} />
                          <Skeleton>
                            <TextField sx={{ width: 260, height: 49 }} />
                          </Skeleton>
                        )}
                        {/* currency select box */}
                        {SingleTaxonomy ? (
                          <FormControl required>
                            <InputLabel id="Currency">Currency</InputLabel>

                            <Select
                              sx={{
                                width: "280px",
                                height: "44px",
                              }}
                              label="Currency"
                              name="countryCode"
                              value={Taxonomy.countryCode || ""}
                              onChange={handleTaxonomyChange}
                              error={!!formErrors.Taxo_curr}
                              helperText={formErrors.Taxo_curr}
                            >
                              {currency &&
                                currency.map((option, index) => (
                                  <MenuItem key={index} value={option.countryCode}>
                                    {option.countryName}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        ) : (
                          // <Skeleton variant="text" width={230} height={70} sx={{ mb: "15px" }} />
                          <Skeleton>
                            <TextField sx={{ width: 260, height: 44 }} />
                          </Skeleton>
                        )}

                        {/* evaluation period select box */}
                        {SingleTaxonomy ? (
                          <FormControl required>
                            <InputLabel id="Evaluation">Evaluation Period</InputLabel>

                            <Select
                              sx={{
                                width: "280px",
                                height: "44px",
                              }}
                              label="Evaluation Period"
                              name="evaluationPeriod"
                              value={Taxonomy.evaluationPeriod || ""}
                              // onChange={handleTaxonomyChange}
                              error={!!formErrors.Taxo_curr}
                              helperText={formErrors.Taxo_curr}
                            >
                              <MenuItem value="1 year">1 year</MenuItem>
                            </Select>
                          </FormControl>
                        ) : (
                          // <Skeleton variant="text" width={230} height={70} sx={{ mb: "15px" }} />
                          <Skeleton>
                            <TextField sx={{ width: 260, height: 44 }} />
                          </Skeleton>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12} lg={12}>
                      {SingleTaxonomy ? (
                        <TextField
                          label="Description"
                          sx={{ width: 520 }}
                          name="Taxo_Desc"
                          multiline
                          value={Taxonomy.Taxo_Desc || ""}
                          disabled={Isdisable}
                          onChange={handleTaxonomyChange}
                        />
                      ) : (
                        // <Skeleton variant="text" width={230} height={70} sx={{ mb: "15px" }} />
                        <Skeleton>
                          <TextField sx={{ width: 520, height: 40 }} />
                        </Skeleton>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            ) : (
              ""
            )}

            {activeStep === 1 ? (
              // Add-RIsk=================================================================================

              <Grid container spacing={1} sx={{ pl: "10px" }}>
                {noData ? (
                  <Grid item md={12} sm={12} xs={12} lg={12} sx={{ pb: "15px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <span className="tax-header">
                          Add Primary Risk Categories and Sub Categories
                        </span>
                        <h6 className="tax-subheader">
                          You can add a risk category and sub categories under it
                        </h6>
                      </div>
                      <div>
                        {/* edit taxonomy case expand all */}
                        {!Primaryid && Tid && (
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <ThemeProvider theme={theme}>
                                  <Switch
                                    color="secondary"
                                    checked={expandedAll}
                                    onChange={() => handleExpandAllToggle()}
                                  />
                                </ThemeProvider>
                              }
                              label="Expand All"
                            />
                          </FormGroup>
                        )}
                      </div>
                    </div>
                  </Grid>
                ) : (
                  <Grid item md={12} sm={12} xs={12} lg={12} sx={{ pb: "15px" }}>
                    {SingleTaxonomy ? (
                      <span className="tax-header">
                        Add Primary Risk Categories and Sub Categories
                      </span>
                    ) : (
                      <Skeleton width={350} height={30} />
                    )}
                    {SingleTaxonomy ? (
                      <h6 className="tax-subheader">
                        You can add a risk category and sub categories under it
                      </h6>
                    ) : (
                      <Skeleton variant="text" width={330} height={20} />
                    )}
                  </Grid>
                )}

                {/* new taxonomy case */}
                {!clickedId &&
                  !Primaryid &&
                  !Tid &&
                  Categories &&
                  Categories.map((category, categoryIndex) => (
                    <Grid container spacing={1} ref={categoriesContainerRef}>
                      <Accordion sx={{ boxShadow: "none" }} defaultExpanded={categoryIndex === 0}>
                        <Grid
                          item
                          md={12}
                          sx={12}
                          lg={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            backgroundColor: "#F7F8FF",
                            paddingBottom: "6px",
                            margin: "20px",
                            height: "48px",
                            alignItems: "center",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{ fontSize: "15px", fontWeight: 500, marginLeft: "12px" }}
                              >
                                Primary Risk Category {categoryIndex + 1}
                              </Typography>

                              {categoryIndex > 0 && (
                                <DeleteIcon
                                  style={{ marginRight: "12px", cursor: "pointer" }}
                                  onClick={() => handleDeleteCategory(categoryIndex)}
                                />
                              )}
                            </div>
                          </AccordionSummary>
                        </Grid>
                        <AccordionDetails>
                          <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            lg={12}
                            spacing={2}
                            sx={{ pl: "25px" }}
                          >
                            <Stack direction="row" spacing={2} sx={{ pb: "20px", pl: "19px" }}>
                              <TextField
                                label="Name of Primary Risk Category"
                                name="name"
                                value={category.name}
                                multiline
                                onChange={(e) =>
                                  handleInputChange(categoryIndex, null, "name", e.target.value)
                                }
                                variant="outlined"
                                sx={{ width: 250 }}
                                required
                                error={
                                  categoriesErrors[categoryIndex]?.name &&
                                  !!categoriesErrors[categoryIndex].name
                                }
                                helperText={categoriesErrors[categoryIndex]?.name}
                              />
                            </Stack>

                            <Grid item md={12} sm={12} xs={12} lg={12} sx={{ pl: "19px" }}>
                              <TextField
                                label="Description of Primary Risk Category"
                                name="desc"
                                value={category.desc}
                                multiline
                                onChange={(e) =>
                                  handleInputChange(categoryIndex, null, "desc", e.target.value)
                                }
                                sx={{ width: 520 }}
                                required
                                error={
                                  categoriesErrors[categoryIndex]?.desc &&
                                  !!categoriesErrors[categoryIndex].desc
                                }
                                helperText={categoriesErrors[categoryIndex]?.desc}
                              />
                            </Grid>
                          </Grid>

                          {/* ------Risk sub category-------------------- */}
                          <Grid container>
                            {category.SubCategories &&
                              category.SubCategories.map((subCategory, subCategoryIndex) => (
                                // <Grid item md={12} sm={12} xs={12} lg={12}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  <div style={{ width: "60px" }}>
                                    <span style={{ marginLeft: "54px" }} className="dividerCat" />
                                  </div>
                                  <div key={subCategoryIndex}>
                                    <Grid container spacing={1}>
                                      <Grid
                                        item
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        lg={12}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          margin: "10px",
                                          marginLeft: "0px",
                                        }}
                                      >
                                        <Stack direction="row">
                                          <hr
                                            style={{
                                              height: "1.5px",
                                              width: "45px",
                                              backgroundColor: "#8090AA",
                                              border: "none",
                                              marginTop: "10px",
                                            }}
                                          />

                                          <Typography
                                            style={{ fontSize: "17px", color: "#7354CB" }}
                                          >
                                            Risk Sub Category {subCategoryIndex + 1}
                                          </Typography>
                                        </Stack>

                                        {subCategoryIndex > 0 && (
                                          <DeleteIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleDeleteSubCategory(
                                                categoryIndex,
                                                subCategoryIndex
                                              )
                                            }
                                          />
                                        )}
                                      </Grid>
                                      <Grid
                                        item
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        lg={12}
                                        sx={{ pl: "30px" }}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={2}
                                          sx={{ pb: "15px", pl: "30px" }}
                                        >
                                          <TextField
                                            label="Name of Risk Sub Category"
                                            name="name"
                                            value={subCategory.name}
                                            multiline
                                            onChange={(e) =>
                                              handleInputChange(
                                                categoryIndex,
                                                subCategoryIndex,
                                                "name",
                                                e.target.value
                                              )
                                            }
                                            variant="outlined"
                                            sx={{ width: 250 }}
                                            required
                                            error={
                                              categoriesErrors[categoryIndex]?.subCategories[
                                                subCategoryIndex
                                              ]?.name &&
                                              !!categoriesErrors[categoryIndex]?.subCategories[
                                                subCategoryIndex
                                              ].name
                                            }
                                            helperText={
                                              categoriesErrors[categoryIndex]?.subCategories[
                                                subCategoryIndex
                                              ]?.name
                                            }
                                          />

                                          <FormControl required>
                                            <InputLabel id="Appetite">Appetite</InputLabel>

                                            <Select
                                              sx={{
                                                width: 250,
                                                height: 42,
                                                color: "black",
                                              }}
                                              label="Appetite"
                                              name="Appetite"
                                              value={subCategory.Appetite}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  categoryIndex,
                                                  subCategoryIndex,
                                                  "Appetite",
                                                  e.target.value
                                                )
                                              }
                                              error={
                                                categoriesErrors[categoryIndex]?.subCategories[
                                                  subCategoryIndex
                                                ]?.Appetite &&
                                                !!categoriesErrors[categoryIndex]?.subCategories[
                                                  subCategoryIndex
                                                ].Appetite
                                              }
                                              helperText={
                                                categoriesErrors[categoryIndex]?.subCategories[
                                                  subCategoryIndex
                                                ]?.Appetite
                                              }
                                            >
                                              {riskAppetiteOptions.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                  {option}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Stack>
                                      </Grid>
                                      <Grid
                                        item
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        lg={12}
                                        sx={{ ml: "30px" }}
                                      >
                                        <TextField
                                          label="Description of Risk Sub Category"
                                          name="Description"
                                          value={subCategory.Description}
                                          multiline
                                          onChange={(e) =>
                                            handleInputChange(
                                              categoryIndex,
                                              subCategoryIndex,
                                              "Description",
                                              e.target.value
                                            )
                                          }
                                          sx={{ width: 520 }}
                                          required
                                          error={
                                            categoriesErrors[categoryIndex]?.subCategories[
                                              subCategoryIndex
                                            ]?.Description &&
                                            !!categoriesErrors[categoryIndex]?.subCategories[
                                              subCategoryIndex
                                            ].Description
                                          }
                                          helperText={
                                            categoriesErrors[categoryIndex]?.subCategories[
                                              subCategoryIndex
                                            ]?.Description
                                          }
                                        />

                                        <Divider
                                          sx={{
                                            m: "10px",
                                            borderWidth: "1.5px",
                                            color: "#B5BFD0",
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                                // </Grid>
                              ))}

                            <Grid
                              item
                              md={12}
                              sm={12}
                              xs={12}
                              lg={12}
                              sx={{ ml: "45px", display: "flex", flexDirection: "row" }}
                            >
                              <div>
                                <span
                                  style={{ marginLeft: "9px", height: "21px" }}
                                  className="dividerCat"
                                />
                              </div>
                              <Stack direction="row" alignItems="center">
                                <hr
                                  style={{
                                    height: "1.5px",
                                    width: "45px",
                                    backgroundColor: "#8090AA",
                                    border: "none",
                                    // marginTop: "10px",
                                  }}
                                />
                                <Button
                                  variant="text"
                                  onClick={() => handleAddSubCategory(categoryIndex)}
                                  sx={{ color: "#7354CB", p: 0 }}
                                >
                                  +Add Risk Sub Category
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                        <Divider
                          sx={{
                            m: "10px",
                            borderWidth: "1.5px",
                            color: "black",
                            mt: "15px",
                            width: "100%",
                          }}
                        />
                      </Accordion>
                    </Grid>
                  ))}

                {
                  // check if card is clicked on review page and render only that caegory
                  clickedId ? (
                    Categories &&
                    Categories.filter((category) => category.id === clickedId).map(
                      (category, categoryIndex) => (
                        <Grid container spacing={1}>
                          <Accordion
                            sx={{ boxShadow: "none" }}
                            defaultExpanded={categoryIndex === 0}
                          >
                            <Grid
                              item
                              md={12}
                              sx={12}
                              lg={12}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "#F7F8FF",
                                paddingBottom: "6px",
                                margin: "20px",
                                height: "48px",
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: 500,
                                      marginLeft: "12px",
                                    }}
                                  >
                                    Primary Risk Category
                                  </Typography>

                                  <DeleteIcon
                                    onClick={() => handleDeleteCategory()}
                                    style={{ marginRight: "12px", cursor: "pointer" }}
                                  />
                                </div>
                              </AccordionSummary>
                            </Grid>
                            <AccordionDetails>
                              <Grid
                                item
                                md={12}
                                sm={12}
                                xs={12}
                                lg={12}
                                spacing={2}
                                sx={{ pl: "25px" }}
                              >
                                <Stack direction="row" spacing={2} sx={{ pb: "20px", pl: "19px" }}>
                                  <TextField
                                    label="Name of Primary Risk Category"
                                    name="name"
                                    value={category.name}
                                    multiline
                                    onChange={(e) =>
                                      handleInputChange(categoryIndex, null, "name", e.target.value)
                                    }
                                    variant="outlined"
                                    sx={{ width: 250 }}
                                    required
                                    error={
                                      categoriesErrors[categoryIndex]?.name &&
                                      !!categoriesErrors[categoryIndex].name
                                    }
                                    helperText={categoriesErrors[categoryIndex]?.name}
                                  />
                                </Stack>

                                <Grid item md={12} sm={12} xs={12} lg={12} sx={{ pl: "19px" }}>
                                  <TextField
                                    label="Description of Primary Risk Category"
                                    name="desc"
                                    value={category.desc}
                                    multiline
                                    onChange={(e) =>
                                      handleInputChange(categoryIndex, null, "desc", e.target.value)
                                    }
                                    sx={{ width: 520 }}
                                    required
                                    error={
                                      categoriesErrors[categoryIndex]?.desc &&
                                      !!categoriesErrors[categoryIndex].desc
                                    }
                                    helperText={categoriesErrors[categoryIndex]?.desc}
                                  />
                                </Grid>
                              </Grid>

                              {/* ------Risk sub category-------------------- */}

                              <Grid container>
                                {category.SubCategories &&
                                  category.SubCategories.map((subCategory, subCategoryIndex) => (
                                    // <Grid item md={12} sm={12} xs={12} lg={12}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                      <div style={{ width: "60px" }}>
                                        <span
                                          style={{ marginLeft: "54px" }}
                                          className="dividerCat"
                                        />
                                      </div>
                                      <div key={subCategoryIndex}>
                                        <Grid container spacing={1}>
                                          <Grid
                                            item
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            lg={12}
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "space-between",
                                              margin: "10px",
                                              marginLeft: "0px",
                                            }}
                                          >
                                            <Stack direction="row">
                                              <hr
                                                style={{
                                                  height: "1.5px",
                                                  width: "45px",
                                                  backgroundColor: "#8090AA",
                                                  border: "none",
                                                  marginTop: "10px",
                                                }}
                                              />

                                              <Typography
                                                style={{ fontSize: "17px", color: "#7354CB" }}
                                              >
                                                Risk Sub Category {subCategoryIndex + 1}
                                              </Typography>
                                            </Stack>

                                            {subCategoryIndex > 0 && (
                                              <DeleteIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  handleDeleteSubCategory(
                                                    categoryIndex,
                                                    subCategoryIndex
                                                  )
                                                }
                                              />
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            lg={12}
                                            sx={{ pl: "30px" }}
                                          >
                                            <Stack
                                              direction="row"
                                              spacing={2}
                                              sx={{ pb: "15px", pl: "30px" }}
                                            >
                                              <TextField
                                                label="Name of Risk Sub Category"
                                                name="name"
                                                value={subCategory.name}
                                                multiline
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    categoryIndex,
                                                    subCategoryIndex,
                                                    "name",
                                                    e.target.value
                                                  )
                                                }
                                                variant="outlined"
                                                sx={{ width: 250 }}
                                                required
                                                error={
                                                  categoriesErrors[categoryIndex]?.subCategories[
                                                    subCategoryIndex
                                                  ]?.name &&
                                                  !!categoriesErrors[categoryIndex]?.subCategories[
                                                    subCategoryIndex
                                                  ].name
                                                }
                                                helperText={
                                                  categoriesErrors[categoryIndex]?.subCategories[
                                                    subCategoryIndex
                                                  ]?.name
                                                }
                                              />

                                              <FormControl required>
                                                <InputLabel id="Appetite">Appetite</InputLabel>

                                                <Select
                                                  sx={{
                                                    width: 250,
                                                    height: 42,
                                                    color: "black",
                                                  }}
                                                  label="Appetite"
                                                  name="Appetite"
                                                  value={subCategory.Appetite}
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      categoryIndex,
                                                      subCategoryIndex,
                                                      "Appetite",
                                                      e.target.value
                                                    )
                                                  }
                                                  error={
                                                    categoriesErrors[categoryIndex]?.subCategories[
                                                      subCategoryIndex
                                                    ]?.Appetite &&
                                                    !!categoriesErrors[categoryIndex]
                                                      ?.subCategories[subCategoryIndex].Appetite
                                                  }
                                                  helperText={
                                                    categoriesErrors[categoryIndex]?.subCategories[
                                                      subCategoryIndex
                                                    ]?.Appetite
                                                  }
                                                >
                                                  {riskAppetiteOptions.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                      {option}
                                                    </MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>
                                            </Stack>
                                          </Grid>
                                          <Grid
                                            item
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            lg={12}
                                            sx={{ ml: "30px" }}
                                          >
                                            <TextField
                                              label="Description of Risk Sub Category"
                                              name="Description"
                                              value={subCategory.Description}
                                              multiline
                                              onChange={(e) =>
                                                handleInputChange(
                                                  categoryIndex,
                                                  subCategoryIndex,
                                                  "Description",
                                                  e.target.value
                                                )
                                              }
                                              sx={{ width: 520 }}
                                              required
                                              error={
                                                categoriesErrors[categoryIndex]?.subCategories[
                                                  subCategoryIndex
                                                ]?.Description &&
                                                !!categoriesErrors[categoryIndex]?.subCategories[
                                                  subCategoryIndex
                                                ].Description
                                              }
                                              helperText={
                                                categoriesErrors[categoryIndex]?.subCategories[
                                                  subCategoryIndex
                                                ]?.Description
                                              }
                                            />

                                            {/* <Divider
                                              sx={{
                                                m: "10px",
                                                borderWidth: "1px",
                                                color: "#B5BFD0",
                                              }}
                                            /> */}
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </div>
                                    // </Grid>
                                  ))}
                                <Grid
                                  item
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  lg={12}
                                  sx={{ ml: "45px", display: "flex", flexDirection: "row" }}
                                >
                                  <div>
                                    <span
                                      style={{ marginLeft: "9px", height: "21px" }}
                                      className="dividerCat"
                                    />
                                  </div>
                                  <Stack direction="row" alignItems="center">
                                    <hr
                                      style={{
                                        height: "1.5px",
                                        width: "45px",
                                        backgroundColor: "#8090AA",
                                        border: "none",
                                        // marginTop: "10px",
                                      }}
                                    />
                                    <Button
                                      variant="text"
                                      onClick={() => handleAddSubCategory()}
                                      sx={{ color: "#7354CB", p: 0 }}
                                    >
                                      +Add Risk Sub Category
                                    </Button>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                            <Divider
                              sx={{
                                m: "10px",
                                borderWidth: "1.5px",
                                color: "black",
                                mt: "15px",
                                width: "100%",
                              }}
                            />
                          </Accordion>
                        </Grid>
                      )
                    )
                  ) : // check if primary id is present then render only that category single category
                  Primaryid ? (
                    isLoading === false ? (
                      Categories &&
                      Categories.filter((category) => category.id === Primaryid).map(
                        (category, categoryIndex) => (
                          <Grid container spacing={1}>
                            <Accordion
                              sx={{ boxShadow: "none" }}
                              defaultExpanded={categoryIndex === 0}
                            >
                              {Categories ? (
                                <Grid
                                  item
                                  md={12}
                                  sx={12}
                                  lg={12}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    backgroundColor: "#F7F8FF",
                                    paddingBottom: "6px",
                                    margin: "20px",
                                    height: "48px",
                                    alignItems: "center",
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: 500,
                                          marginLeft: "12px",
                                        }}
                                      >
                                        Primary Risk Category
                                      </Typography>

                                      <DeleteIcon
                                        onClick={() => handleDeleteCategory()}
                                        style={{ marginRight: "12px", cursor: "pointer" }}
                                      />
                                    </div>
                                  </AccordionSummary>
                                </Grid>
                              ) : (
                                <Skeleton variant="rectangular" width={260} height={40} />
                              )}
                              <AccordionDetails>
                                <Grid
                                  item
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  lg={12}
                                  spacing={2}
                                  sx={{ pl: "25px" }}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ pb: "20px", pl: "19px" }}
                                  >
                                    <TextField
                                      label="Name of Primary Risk Category"
                                      name="name"
                                      value={category.name}
                                      onChange={(e) =>
                                        handleInputChange(
                                          categoryIndex,
                                          null,
                                          "name",
                                          e.target.value
                                        )
                                      }
                                      multiline
                                      variant="outlined"
                                      sx={{ width: 250 }}
                                      required
                                      error={
                                        categoriesErrors[categoryIndex]?.name &&
                                        !!categoriesErrors[categoryIndex].name
                                      }
                                      helperText={categoriesErrors[categoryIndex]?.name}
                                    />
                                  </Stack>

                                  <Grid item md={12} sm={12} xs={12} lg={12} sx={{ pl: "19px" }}>
                                    <TextField
                                      label="Description of Primary Risk Category"
                                      name="desc"
                                      value={category.desc}
                                      onChange={(e) =>
                                        handleInputChange(
                                          categoryIndex,
                                          null,
                                          "desc",
                                          e.target.value
                                        )
                                      }
                                      sx={{ width: 520 }}
                                      multiline
                                      required
                                      error={
                                        categoriesErrors[categoryIndex]?.desc &&
                                        !!categoriesErrors[categoryIndex].desc
                                      }
                                      helperText={categoriesErrors[categoryIndex]?.desc}
                                    />
                                  </Grid>
                                </Grid>

                                {/* ------Risk sub category-------------------- */}

                                <Grid container>
                                  {
                                    // primaryRisk.riskSubCategory.map((subCategory, subIndex) => (
                                    category.SubCategories &&
                                      category.SubCategories.map(
                                        (subCategory, subCategoryIndex) => (
                                          <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ width: "60px" }}>
                                              <span
                                                style={{ marginLeft: "54px" }}
                                                className="dividerCat"
                                              />
                                            </div>
                                            <div key={subCategoryIndex}>
                                              <Grid container spacing={1}>
                                                <Grid
                                                  item
                                                  md={12}
                                                  sm={12}
                                                  xs={12}
                                                  lg={12}
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    margin: "10px",
                                                    marginLeft: "0px",
                                                  }}
                                                >
                                                  <Stack direction="row">
                                                    <hr
                                                      style={{
                                                        height: "1.5px",
                                                        width: "45px",
                                                        backgroundColor: "#8090AA",
                                                        border: "none",
                                                        marginTop: "10px",
                                                      }}
                                                    />

                                                    <Typography
                                                      style={{ fontSize: "17px", color: "#7354CB" }}
                                                    >
                                                      Risk Sub Category {subCategoryIndex + 1}
                                                    </Typography>
                                                  </Stack>

                                                  {subCategoryIndex > 0 && (
                                                    <DeleteIcon
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() =>
                                                        handleDeleteSubCategory(
                                                          categoryIndex,
                                                          subCategoryIndex
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </Grid>
                                                <Grid
                                                  item
                                                  md={12}
                                                  sm={12}
                                                  xs={12}
                                                  lg={12}
                                                  sx={{ pl: "30px" }}
                                                >
                                                  <Stack
                                                    direction="row"
                                                    spacing={2}
                                                    sx={{ pb: "15px", pl: "30px" }}
                                                  >
                                                    <TextField
                                                      key={subCategory.id}
                                                      label="Name of Risk Sub Category"
                                                      name="name"
                                                      value={subCategory.name}
                                                      multiline
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          categoryIndex,
                                                          subCategoryIndex,
                                                          "name",
                                                          e.target.value
                                                        )
                                                      }
                                                      variant="outlined"
                                                      sx={{ width: 250 }}
                                                      required
                                                      error={
                                                        categoriesErrors[categoryIndex]
                                                          ?.subCategories[subCategoryIndex]?.name &&
                                                        !!categoriesErrors[categoryIndex]
                                                          ?.subCategories[subCategoryIndex].name
                                                      }
                                                      helperText={
                                                        categoriesErrors[categoryIndex]
                                                          ?.subCategories[subCategoryIndex]?.name
                                                      }
                                                    />

                                                    <FormControl required>
                                                      <InputLabel id="Appetite">
                                                        Appetite
                                                      </InputLabel>

                                                      <Select
                                                        sx={{
                                                          width: 250,
                                                          height: 42,
                                                          color: "black",
                                                        }}
                                                        label="Appetite"
                                                        name="Appetite"
                                                        value={subCategory.Appetite}
                                                        onChange={(e) =>
                                                          handleInputChange(
                                                            categoryIndex,
                                                            subCategoryIndex,
                                                            "Appetite",
                                                            e.target.value
                                                          )
                                                        }
                                                        error={
                                                          categoriesErrors[categoryIndex]
                                                            ?.subCategories[subCategoryIndex]
                                                            ?.Appetite &&
                                                          !!categoriesErrors[categoryIndex]
                                                            ?.subCategories[subCategoryIndex]
                                                            .Appetite
                                                        }
                                                        helperText={
                                                          categoriesErrors[categoryIndex]
                                                            ?.subCategories[subCategoryIndex]
                                                            ?.Appetite
                                                        }
                                                      >
                                                        {riskAppetiteOptions &&
                                                          riskAppetiteOptions.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                              {option}
                                                            </MenuItem>
                                                          ))}
                                                      </Select>
                                                    </FormControl>
                                                  </Stack>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md={12}
                                                  sm={12}
                                                  xs={12}
                                                  lg={12}
                                                  sx={{ ml: "30px" }}
                                                >
                                                  <TextField
                                                    label="Description of Risk Sub Category"
                                                    name="Description"
                                                    value={subCategory.Description}
                                                    multiline
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        categoryIndex,
                                                        subCategoryIndex,
                                                        "Description",
                                                        e.target.value
                                                      )
                                                    }
                                                    sx={{ width: 520 }}
                                                    required
                                                    error={
                                                      categoriesErrors[categoryIndex]
                                                        ?.subCategories[subCategoryIndex]
                                                        ?.Description &&
                                                      !!categoriesErrors[categoryIndex]
                                                        ?.subCategories[subCategoryIndex]
                                                        .Description
                                                    }
                                                    helperText={
                                                      categoriesErrors[categoryIndex]
                                                        ?.subCategories[subCategoryIndex]
                                                        ?.Description
                                                    }
                                                  />

                                                  {/* <Divider
                                                    sx={{
                                                      m: "10px",
                                                      borderWidth: "1px",
                                                      color: "#B5BFD0",
                                                    }}
                                                  /> */}
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </div>
                                        )
                                      )
                                  }
                                </Grid>
                                <Grid
                                  item
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  lg={12}
                                  sx={{ ml: "45px", display: "flex", flexDirection: "row" }}
                                >
                                  <div>
                                    <span
                                      style={{ marginLeft: "9px", height: "21px" }}
                                      className="dividerCat"
                                    />
                                  </div>
                                  <Stack direction="row" alignItems="center">
                                    <hr
                                      style={{
                                        height: "1.5px",
                                        width: "45px",
                                        backgroundColor: "#8090AA",
                                        border: "none",
                                        // marginTop: "10px",
                                      }}
                                    />
                                    <Button
                                      variant="text"
                                      onClick={() => handleAddSubCategory()}
                                      sx={{ color: "#7354CB", p: 0 }}
                                    >
                                      +Add Risk Sub Category
                                    </Button>
                                  </Stack>
                                </Grid>
                              </AccordionDetails>
                              {/* <Divider
                                sx={{
                                  m: "10px",
                                  borderWidth: "1.5px",
                                  color: "black",
                                  mt: "15px",
                                  width: "100%",
                                }}
                              /> */}
                            </Accordion>
                          </Grid>
                        )
                      )
                    ) : (
                      <>
                        <Skeleton
                          variant="text"
                          // sx={{ fontSize: "1rem" }}
                          width={940}
                          height={50}
                        />
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                          <Skeleton>
                            <TextField sx={{ width: 260, height: 40 }} />
                          </Skeleton>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                          <Skeleton>
                            <TextField sx={{ width: 520, height: 40 }} />
                          </Skeleton>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                          <Skeleton variant="text" width={330} height={20} />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                          <Stack direction="row" spacing={2}>
                            <Skeleton>
                              <TextField sx={{ width: 260, height: 40 }} />
                            </Skeleton>
                            <Skeleton>
                              <TextField sx={{ width: 260, height: 40 }} />
                            </Skeleton>
                          </Stack>
                        </Grid>
                        <Skeleton
                          variant="text"
                          // sx={{ fontSize: "1rem" }}
                          width={520}
                          height={40}
                        />
                      </>
                    )
                  ) : // else it would be edit (edit taxonomy)
                  !Primaryid && Tid && isLoading === false ? (
                    Categories &&
                    Categories.map((category, categoryIndex) => (
                      <Grid container spacing={1} ref={categoriesContainerRef}>
                        <Accordion
                          sx={{ boxShadow: "none" }}
                          // defaultExpanded={categoryIndex === 0}
                          expanded={expanded[categoryIndex]}
                          // expanded={expandedAll || expanded[categoryIndex]}
                          onChange={() => handleExpand(categoryIndex)}
                          key={`accordion-${categoryIndex}`} // Add a unique key here
                        >
                          <Grid
                            item
                            md={12}
                            sx={12}
                            lg={12}
                            style={{
                              backgroundColor: "#F7F8FF",
                              paddingBottom: "6px",
                              margin: "20px",
                              height: "48px",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  style={{ fontSize: "15px", fontWeight: 500, marginLeft: "12px" }}
                                >
                                  Primary Risk Category- {categoryIndex + 1}
                                </Typography>

                                {categoryIndex > 0 && (
                                  <DeleteIcon
                                    onClick={() => handleDeleteCategory(categoryIndex)}
                                    style={{ marginRight: "12px", cursor: "pointer" }}
                                  />
                                )}
                              </div>
                            </AccordionSummary>
                          </Grid>
                          <AccordionDetails>
                            <Grid
                              item
                              md={12}
                              sm={12}
                              xs={12}
                              lg={12}
                              spacing={2}
                              sx={{ pl: "25px" }}
                            >
                              <Stack direction="row" spacing={2} sx={{ pb: "20px", pl: "19px" }}>
                                <TextField
                                  label="Name of Primary Risk Category"
                                  name="name"
                                  value={category.name}
                                  multiline
                                  onChange={(e) =>
                                    handleInputChange(categoryIndex, null, "name", e.target.value)
                                  }
                                  variant="outlined"
                                  sx={{ width: 250 }}
                                  required
                                  error={
                                    categoriesErrors[categoryIndex]?.name &&
                                    !!categoriesErrors[categoryIndex].name
                                  }
                                  helperText={categoriesErrors[categoryIndex]?.name}
                                />
                              </Stack>

                              <Grid item md={12} sm={12} xs={12} lg={12} sx={{ pl: "19px" }}>
                                <TextField
                                  label="Description of Primary Risk Category"
                                  name="desc"
                                  value={category.desc}
                                  multiline
                                  onChange={(e) =>
                                    handleInputChange(categoryIndex, null, "desc", e.target.value)
                                  }
                                  sx={{ width: 520 }}
                                  required
                                  error={
                                    categoriesErrors[categoryIndex]?.desc &&
                                    !!categoriesErrors[categoryIndex].desc
                                  }
                                  helperText={categoriesErrors[categoryIndex]?.desc}
                                />
                              </Grid>
                            </Grid>

                            {/* ------Risk sub category-------------------- */}

                            <Grid container>
                              {category.SubCategories &&
                                category.SubCategories.map((subCategory, subCategoryIndex) => (
                                  // <Grid item md={12} sm={12} xs={12} lg={12}>
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "60px" }}>
                                      <span style={{ marginLeft: "54px" }} className="dividerCat" />
                                    </div>
                                    <div key={subCategoryIndex}>
                                      <Grid container spacing={1}>
                                        <Grid
                                          item
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          lg={12}
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            margin: "10px",
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <Stack direction="row">
                                            {/* <Divider
                                                    sx={{
                                                      m: "10px",
                                                      borderWidth: "1.5px",
                                                      color: "black",
                                                    }}
                                                  /> */}
                                            <hr
                                              style={{
                                                height: "1.5px",
                                                width: "45px",
                                                backgroundColor: "#8090AA",
                                                border: "none",
                                                marginTop: "10px",
                                              }}
                                            />

                                            <Typography
                                              style={{ fontSize: "17px", color: "#7354CB" }}
                                            >
                                              Risk Sub Category {subCategoryIndex + 1}
                                            </Typography>
                                          </Stack>

                                          {subCategoryIndex > 0 && (
                                            //   >
                                            <DeleteIcon
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                handleDeleteSubCategory(
                                                  categoryIndex,
                                                  subCategoryIndex
                                                )
                                              }
                                            />
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          lg={12}
                                          sx={{ pl: "30px" }}
                                        >
                                          <Stack
                                            direction="row"
                                            spacing={2}
                                            sx={{ pb: "15px", pl: "30px" }}
                                          >
                                            <TextField
                                              label="Name of Risk Sub Category"
                                              name="name"
                                              value={subCategory.name}
                                              multiline
                                              onChange={(e) =>
                                                handleInputChange(
                                                  categoryIndex,
                                                  subCategoryIndex,
                                                  "name",
                                                  e.target.value
                                                )
                                              }
                                              variant="outlined"
                                              sx={{ width: 250 }}
                                              required
                                              error={
                                                categoriesErrors[categoryIndex]?.subCategories[
                                                  subCategoryIndex
                                                ]?.name &&
                                                !!categoriesErrors[categoryIndex]?.subCategories[
                                                  subCategoryIndex
                                                ].name
                                              }
                                              helperText={
                                                categoriesErrors[categoryIndex]?.subCategories[
                                                  subCategoryIndex
                                                ]?.name
                                              }
                                            />

                                            <FormControl required>
                                              <InputLabel id="Appetite">Appetite</InputLabel>

                                              <Select
                                                sx={{
                                                  width: 250,
                                                  height: 42,
                                                  color: "black",
                                                }}
                                                label="Appetite"
                                                name="Appetite"
                                                value={subCategory.Appetite}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    categoryIndex,
                                                    subCategoryIndex,
                                                    "Appetite",
                                                    e.target.value
                                                  )
                                                }
                                                error={
                                                  categoriesErrors[categoryIndex]?.subCategories[
                                                    subCategoryIndex
                                                  ]?.Appetite &&
                                                  !!categoriesErrors[categoryIndex]?.subCategories[
                                                    subCategoryIndex
                                                  ].Appetite
                                                }
                                                helperText={
                                                  categoriesErrors[categoryIndex]?.subCategories[
                                                    subCategoryIndex
                                                  ]?.Appetite
                                                }
                                              >
                                                {riskAppetiteOptions.map((option) => (
                                                  <MenuItem key={option} value={option}>
                                                    {option}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>

                                            {/* <Select sx={{ width: 250, color: "black" }}
                                                                                              labelId="demo-simple-select-label"
                                                                                              id="demo-simple-select">
                                                                                              <MenuItem >Thirty</MenuItem>
                                                                                          </Select> */}
                                          </Stack>
                                        </Grid>
                                        <Grid
                                          item
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          lg={12}
                                          sx={{ ml: "30px" }}
                                        >
                                          <TextField
                                            label="Description of Risk Sub Category"
                                            name="Description"
                                            value={subCategory.Description}
                                            multiline
                                            onChange={(e) =>
                                              handleInputChange(
                                                categoryIndex,
                                                subCategoryIndex,
                                                "Description",
                                                e.target.value
                                              )
                                            }
                                            sx={{ width: 520 }}
                                            required
                                            error={
                                              categoriesErrors[categoryIndex]?.subCategories[
                                                subCategoryIndex
                                              ]?.Description &&
                                              !!categoriesErrors[categoryIndex]?.subCategories[
                                                subCategoryIndex
                                              ].Description
                                            }
                                            helperText={
                                              categoriesErrors[categoryIndex]?.subCategories[
                                                subCategoryIndex
                                              ]?.Description
                                            }
                                          />

                                          <Divider
                                            sx={{
                                              m: "10px",
                                              borderWidth: "1px",
                                              color: "#B5BFD0",
                                              // border: "1.5px soldi #B5BFD0",
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                  // </Grid>
                                ))}
                              <Grid
                                item
                                md={12}
                                sm={12}
                                xs={12}
                                lg={12}
                                sx={{ ml: "45px", display: "flex", flexDirection: "row" }}
                              >
                                <div>
                                  <span
                                    style={{ marginLeft: "9px", height: "21px" }}
                                    className="dividerCat"
                                  />
                                </div>
                                <Stack direction="row" alignItems="center">
                                  <hr
                                    style={{
                                      height: "1.5px",
                                      width: "45px",
                                      backgroundColor: "#8090AA",
                                      border: "none",
                                      // marginTop: "10px",
                                    }}
                                  />
                                  <Button
                                    variant="text"
                                    onClick={() => handleAddSubCategory(categoryIndex)}
                                    sx={{ color: "#7354CB", p: 0 }}
                                  >
                                    +Add Risk Sub Category
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </AccordionDetails>

                          <Divider
                            sx={{
                              m: "10px",
                              borderWidth: "1.5px",
                              color: "black",
                              mt: "15px",
                              width: "100%",
                            }}
                          />
                        </Accordion>
                      </Grid>
                    ))
                  ) : (
                    // add skeleton here
                    // <>
                    //   <Skeleton
                    //     variant="text"
                    //     // sx={{ fontSize: "1rem" }}
                    //     width={940}
                    //     height={50}
                    //   />
                    //   <Grid item md={12} sm={12} xs={12} lg={12}>
                    //     <Skeleton>
                    //       <TextField sx={{ width: 260, height: 40 }} />
                    //     </Skeleton>
                    //   </Grid>
                    //   <Grid item md={12} sm={12} xs={12} lg={12}>
                    //     <Skeleton>
                    //       <TextField sx={{ width: 520, height: 40 }} />
                    //     </Skeleton>
                    //   </Grid>
                    //   <Grid item md={12} sm={12} xs={12} lg={12}>
                    //     <Skeleton variant="text" width={330} height={20} />
                    //   </Grid>
                    //   <Grid item md={12} sm={12} xs={12} lg={12}>
                    //     <Stack direction="row" spacing={2}>
                    //       <Skeleton>
                    //         <TextField sx={{ width: 260, height: 40 }} />
                    //       </Skeleton>
                    //       <Skeleton>
                    //         <TextField sx={{ width: 260, height: 40 }} />
                    //       </Skeleton>
                    //     </Stack>
                    //   </Grid>
                    //   <Skeleton
                    //     variant="text"
                    //     // sx={{ fontSize: "1rem" }}
                    //     width={520}
                    //     height={40}
                    //   />
                    // </>
                    ""
                  )
                }
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {!clickedId && !Primaryid && (
                    <Button
                      variant="outlined"
                      style={{ color: "#464d60", fontWeight: "light" }}
                      onClick={handleAddCategory}
                      sx={{
                        borderColor: "#B5BFD0",
                        width: "231px",
                        borderRadius: "4px",
                        textTransform: "none",
                      }}
                    >
                      + Add Primary Category
                    </Button>
                  )}
                </Grid>
              </Grid>
            ) : (
              ""
            )}

            {/* // Review and save ==================================== */}
            {activeStep === 2 ? (
              <Grid
                container
                sx={{ padding: "25px", paddingLeft: "12px" }}
                style={{ display: "flex" }}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <span className="tax-header">Review and save</span>
                </Grid>
                <Grid item md={12}>
                  <h6 className="tax-subheader">
                    You can review the information added, go back to make changes or save the
                    information
                  </h6>
                </Grid>

                <Grid container item md={12} sx={{ mt: "30px", mb: "30px" }}>
                  <Grid item xs={4} sm={3} md={3}>
                    <Typography variant="subtitle1" color="#8090AA" fontSize="13px" align="left">
                      Taxonomy Name
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={9}>
                    <Typography variant="subtitle1" color="#8090AA" align="left" fontSize="13px">
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} sx={{ alignItems: "flex-start" }}>
                    <Typography variant="body1" fontSize="15px" align="left">
                      {Taxonomy.Taxo_Name}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={9} sx={{ alignItems: "flex-start" }}>
                    <Typography variant="body1" fontSize="15px" align="left">
                      {Taxonomy.Taxo_Desc}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {/* edit button review (single cat edit) */}
                  {Primaryid
                    ? Categories &&
                      Categories.filter((category) => category.id === Primaryid).map(
                        (category, categoryIndex) => (
                          <Grid item xs={6} sm={6} md={3} lg={3}>
                            <Card
                              key={categoryIndex}
                              sx={{
                                bgcolor: "background.paper",
                                boxShadow: 2,
                                borderRadius: 2,
                                minWidth: 220,
                                maxWidth: 230,
                                my: 2,
                                mr: 2,
                                height: 290,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  // marginLeft: "4px",
                                  // marginRight: "4px",
                                  justifyContent: "space-evenly",
                                  height: "50px",
                                  position: "sticky",
                                  backgroundColor: "#F7F8FF",
                                }}
                              >
                                <div
                                  style={{ width: "35px", alignItems: "center", display: "flex" }}
                                >
                                  <AccountCircleOutlinedIcon
                                    style={{ fontSize: "40px", color: "#402899" }}
                                  />
                                </div>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    // padding: "10px",
                                    color: "#402899",
                                    lineHeight: "1.3",
                                  }}
                                >
                                  {category.name}
                                </span>

                                <LightTooltip title={category.desc}>
                                  <InfoOutlinedIcon sx={{ color: "#402899" }} />
                                </LightTooltip>

                                <ModeEditOutlineOutlinedIcon
                                  style={{
                                    cursor: "pointer",
                                    paddingLeft: "4px",
                                    color: "#402899",
                                  }}
                                  onClick={() => handleCardClick(category.id)}
                                />
                              </div>
                              {/* <hr /> */}
                              <Divider sx={{ ml: "5px", mr: "5px" }} />
                              <div
                                style={{
                                  marginLeft: "7px",
                                  overflow: "auto", // Add overflow property to enable scrolling for the content below
                                  // height: "85%", // Set a fixed height for the scrollable content
                                  lineHeight: 1.2,
                                }}
                              >
                                {category.SubCategories.map((subCategory) => (
                                  <>
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        lineHeight: "1.9",
                                      }}
                                    >
                                      {subCategory.name}
                                    </span>
                                    <br />
                                  </>
                                ))}
                              </div>
                            </Card>
                          </Grid>
                        )
                      )
                    : // edit taxonomy button review
                      Categories &&
                      Categories.map((category, categoryIndex) => (
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                          <Card
                            key={categoryIndex}
                            sx={{
                              bgcolor: "background.paper",
                              boxShadow: 2,
                              borderRadius: 2,
                              minWidth: 220,
                              maxWidth: 230,
                              my: 2,
                              mr: 2,
                              height: 290,
                              // maxHeight: 200,
                              // overflow: "auto", // Add overflow property to enable scrolling
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                // marginLeft: "4px",
                                // marginRight: "4px",
                                justifyContent: "space-evenly",
                                height: "50px",
                                position: "sticky",
                                backgroundColor: "#F7F8FF",
                              }}
                            >
                              <div style={{ width: "35px", alignItems: "center", display: "flex" }}>
                                <AccountCircleOutlinedIcon
                                  style={{ fontSize: "40px", color: "#402899" }}
                                />
                              </div>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  // padding: "10px",
                                  color: "#402899",
                                  lineHeight: "1.3",
                                }}
                              >
                                {category.name}
                              </span>
                              <LightTooltip title={category.desc}>
                                <InfoOutlinedIcon sx={{ color: "#402899" }} />
                              </LightTooltip>

                              <ModeEditOutlineOutlinedIcon
                                style={{ cursor: "pointer", paddingLeft: "4px", color: "#402899" }}
                                onClick={() => handleCardClick(category.id)}
                              />
                            </div>
                            {/* <hr /> */}
                            <Divider sx={{ ml: "5px", mr: "5px" }} />

                            <div
                              style={{
                                marginLeft: "7px",
                                overflow: "auto", // Add overflow property to enable scrolling for the content below
                                // height: "85%", // Set a fixed height for the scrollable content
                                lineHeight: 1.2,
                              }}
                            >
                              {category.SubCategories.map((subCategory) => (
                                <>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "1.9",
                                    }}
                                  >
                                    - {subCategory.name}
                                  </span>
                                  <br />
                                </>
                              ))}
                            </div>
                          </Card>
                        </Grid>
                      ))}

                  {/* new taxonomy case */}
                </Grid>
              </Grid>
            ) : (
              ""
            )}

            <Grid container>
              {activeStep === 2 ? (
                <Grid item md={12} sx={{ display: "flex", justifyContent: "end" }}>
                  {!Primaryid && !Tid && (
                    <Button
                      variant="outlined"
                      style={{ color: "#464d60" }}
                      onClick={handleAddKey}
                      sx={{ borderColor: "#3d4458" }}
                    >
                      + Add Primary Category
                    </Button>
                  )}
                </Grid>
              ) : (
                ""
              )}
              <Grid item md={12}>
                <Divider
                  sx={{
                    m: "10px",
                    borderWidth: "1.5px",
                    color: "black",
                    mt: "15px",
                    width: "100%",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 2,
                    justifyContent: "flex-end",
                  }}
                >
                  <MDButton
                    color="black"
                    variant="outlined"
                    // disabled={activeStep === 0}
                    onClick={handleBack}
                    width="79px"
                    height="36px"
                    // onClick={clickedId ? handleNext : handleBack}
                    sx={{
                      mr: 3.5,
                      color: "#263445",
                      border: "1px solid #B5BFD0",
                      textTransform: "none",
                      borderRadius: "4px",
                      fontSize: "15px",
                    }}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    onClick={activeStep === steps.length - 1 ? postData : handleNext}
                    // backgroundColor="#402899"
                    variant="contained"
                    color="#402899"
                    sx={{
                      color: "#f7f4fb",
                      width: "79px",
                      height: "36px",
                      borderRadius: "4px",
                      textTransform: "none",
                      fontSize: "15px",
                    }}
                  >
                    {activeStep === steps.length - 1 ? "Save" : "Next"}
                  </MDButton>
                </Box>
              </Grid>
            </Grid>
          </>
        </Grid>
      </form>

      {/* save dialog box */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Saved Successfully</DialogTitle>
        <DialogContent>{/* You can add additional content here if needed */}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default ConfigureTaxonomy;
