/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Button, Dialog, DialogActions, DialogTitle, Grid, Stack, TextField } from "@mui/material";
import { protectedResources, siteData } from "authConfig";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import profilePhoto from "../../assets/images/icons/projectIcons/Mask Group 1.png";
import logo from "../../assets/images/icons/projectIcons/cropped-A.png";
import Ramsay from "../../assets/images/icons/projectIcons/Ramsay.png";
import Orica from "../../assets/images/icons/projectIcons/orica.png";

function Profile() {
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  // const [formData, setFormData] = useState({
  //   companyLogo: null,
  //   companyName: "",
  //   userName: "",
  //   userPhoto: null,
  // });
  const [formData, setFormData] = useState({
    companyLogo: "",
    companyName: "",
    userName: "",
    userPhoto: "",
    id: "",
  });

  // validation
  const [formErrors, setFormErrors] = useState({
    comp_Name: "",
  });

  const validateNameField = () => {
    let isValid = true;
    const errors = {};

    // Validate Taxonomy Name
    if (!formData.companyName) {
      isValid = false;
      errors.comp_Name = "Company Name is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  // textfield input
  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  useEffect(() => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/profilesetting/list`).then(
      (response) => {
        if (response && response.success === true && response.data.length > 0) {
          setFormData(response.data[0]);
        }
      }
    );
  }, [execute]);

  // img input
  const handleImageChange = (field, e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        handleInputChange(field, reader.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      handleInputChange(field, null);
    }
  };

  // ==========================save dialog box===========================

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate("/home");
  };

  const handleSave = async () => {
    const isValid = validateNameField();
    if (isValid) {
      const requestBody = {
        companyName: formData.companyName,
        companyLogo: formData.companyLogo,
        userName: formData.userName,
        userPhoto: formData.userPhoto,
      };
      if (formData.id === "") {
        try {
          await execute(
            "POST",
            `${protectedResources.apiTodoList.endpoint}/profilesetting/create`,
            requestBody
          ).then(() => {
            setOpen(true);
          });
        } catch (error) {
          /* empty */
        }
      } else {
        try {
          await execute(
            "PUT",
            `${protectedResources.apiTodoList.endpoint}/profilesetting/update/${formData.id}`,
            requestBody
          ).then(() => {
            setOpen(true);
          });
        } catch (error) {
          /* empty */
        }
      }
    }
  };
  // console.log("============", formData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox bgColor="white" padding="15px" borderRadius="8px" MinHeight="500px">
        <Grid container spacing={2}>
          <Grid item md={12}>
            <MDTypography fontWeight="medium" fontSize="21px" color="#263445" opacity="1">
              Profile- Settings
            </MDTypography>
            <hr />
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <MDTypography fontWeight="light" fontSize="17px" color="#263445" opacity="1">
                  Company Settings
                </MDTypography>
              </Grid>
              {/* <Grid item md={3}>
                
                <img
                  src={
                    activeAccount.idTokenClaims.given_name === "Ramsay"
                      ? Ramsay
                      : activeAccount.idTokenClaims.given_name === "Orica"
                      ? Orica
                      : profilePhoto
                  }
                  alt="profilephoto"
                  style={{ width: "100px", height: "100px", marginLeft: "26px" }}
                />
              </Grid> */}
              <Grid item md={9}>
                <Stack spacing={2}>
                  <TextField
                    label="Company Name"
                    sx={{ width: 520 }}
                    name="compName"
                    required
                    value={formData.companyName}
                    error={!!formErrors.comp_Name}
                    helperText={formErrors.comp_Name}
                    onChange={(e) => handleInputChange("companyName", e.target.value)}
                  />
                  <TextField
                    label="Username"
                    sx={{ width: 520 }}
                    name="userName"
                    value={formData.userName}
                    onChange={(e) => handleInputChange("userName", e.target.value)}
                  />
                </Stack>
              </Grid>
            </Grid>
            <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
          </Grid>
          <Grid item md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <MDButton
              color="black"
              variant="outlined"
              // disabled={activeStep === 0}
              // onClick={handleBack}
              width="75px"
              height="36px"
              // onClick={clickedId ? handleNext : handleBack}
              sx={{
                mr: 3.5,
                color: "#263445",
                border: "1px solid #B5BFD0",
                textTransform: "none",
                borderRadius: "4px",
                fontSize: "15px",
              }}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="contained"
              color="#402899"
              sx={{
                color: "#f7f4fb",
                width: "79px",
                height: "36px",
                borderRadius: "4px",
                textTransform: "none",
                fontSize: "15px",
              }}
              onClick={() => handleSave()}
            >
              Save
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      {/* save dialog box */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Saved Successfully</DialogTitle>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
export default Profile;
