// /**
// =========================================================
// * Material Dashboard 2 PRO React - v1.0.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
// * Copyright 2021 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// import { forwardRef } from "react";

// // prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// // Custom styles for MDButton
// import MDButtonRoot from "components/MDButton/MDButtonRoot";

// // Material Dashboard 2 React contexts
// import { useMaterialUIController } from "context";

// const MDButton = forwardRef(
//   ({ color, variant, size, circular, iconOnly, children, ...rest }, ref) => {
//     const [controller] = useMaterialUIController();
//     const { darkMode } = controller;

//     return (
//       <MDButtonRoot
//         {...rest}
//         ref={ref}
//         color="primary"
//         variant={variant === "gradient" ? "contained" : variant}
//         size={size}
//         ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
//       >
//         {children}
//       </MDButtonRoot>
//     );
//   }
// );

// // Setting default values for the props of MDButton
// MDButton.defaultProps = {
//   size: "medium",
//   variant: "contained",
//   color: "white",
//   circular: false,
//   iconOnly: false,
// };

// // Typechecking props for the MDButton
// MDButton.propTypes = {
//   size: PropTypes.oneOf(["small", "medium", "large"]),
//   variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
//   color: PropTypes.oneOf([
//     "white",
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "light",
//     "dark",
//     " #52009b",
//   ]),
//   circular: PropTypes.bool,
//   iconOnly: PropTypes.bool,
//   children: PropTypes.node.isRequired,
// };

// export default MDButton;

/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDButton
import MDButtonRoot from "components/MDButton/MDButtonRoot";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

const MDButton = forwardRef(
  ({ color, variant, size, circular, iconOnly, children, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <MDButtonRoot
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
      >
        {children}
      </MDButtonRoot>
    );
  }
);

// Setting default values for the props of MDButton
MDButton.defaultProps = {
  size: "medium",
  backgroundColor: "#52009b",
  variant: "contained",
  color: "white",
  circular: false,
  iconOnly: false,
};

// Typechecking props for the MDButton
MDButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  backgroundColor: PropTypes.oneOf(["#52009b"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    " #52009b",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default MDButton;
