/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import colors from "assets/theme/base/colors";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
// react-chartjs-2 components
import { Line } from "react-chartjs-2";
import { Paper, Grid, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { protectedResources, siteData } from "authConfig";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const { grey } = colors;
const primary800 = grey["100"];
const options = {
  elements: {
    point: {
      radius: 0,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  interaction: {
    intersect: false,
    mode: "index",
  },
  scales: {
    y: {
      grid: {
        drawBorder: false,
        display: true,
        drawOnChartArea: true,
        drawTicks: false,
        borderDash: [5, 5],
        color: "rgba(255, 255, 255, .2)",
      },
      ticks: {
        display: true,
        color: "#f8f9fa",
        padding: 10,
        font: {
          size: 14,
          weight: 300,
          family: "Roboto",
          style: "normal",
          lineHeight: 2,
        },
      },
    },
    x: {
      grid: {
        drawBorder: false,
        display: false,
        drawOnChartArea: false,
        drawTicks: false,
        borderDash: [5, 5],
      },
      ticks: {
        display: true,
        color: "#f8f9fa",
        padding: 10,
        font: {
          size: 14,
          weight: 300,
          family: "Roboto",
          style: "normal",
          lineHeight: 2,
        },
      },
    },
  },
};
const modaloptions = {
  elements: {
    point: {
      radius: 0,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
  },
  interaction: {
    intersect: true,
    mode: "index",
  },
  scales: {
    y: {
      grid: {
        drawBorder: false,
        display: true,
        drawOnChartArea: true,
        drawTicks: false,
        borderDash: [5, 5],
        color: "rgba(255, 255, 255, .2)",
      },
      ticks: {
        display: true,
        color: "#f8f9fa",
        padding: 10,
        font: {
          size: 14,
          weight: 300,
          family: "Roboto",
          style: "normal",
          lineHeight: 2,
        },
      },
    },
    x: {
      grid: {
        drawBorder: false,
        display: false,
        drawOnChartArea: false,
        drawTicks: false,
        borderDash: [5, 5],
      },
      ticks: {
        display: true,
        color: "#f8f9fa",
        padding: 10,
        font: {
          size: 14,
          weight: 300,
          family: "Roboto",
          style: "normal",
          lineHeight: 2,
        },
      },
    },
  },
};
const combineoptions = {
  elements: {
    point: {
      radius: 0,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
    },
  },
  interaction: {
    intersect: false,
    mode: "index",
  },
  scales: {
    y: {
      type: "linear",
      grid: {
        drawBorder: false,
        display: true,
        drawOnChartArea: true,
        drawTicks: true,
        borderDash: [5, 5],
        color: "#f8f9fa",
      },
      ticks: {
        display: true,
        color: "#f8f9fa",
        padding: 10,
        font: {
          size: 14,
          weight: 300,
          family: "Roboto",
          style: "normal",
          lineHeight: 2,
        },
      },
    },
    x: {
      grid: {
        drawBorder: false,
        display: false,
        drawOnChartArea: false,
        drawTicks: false,
        borderDash: [5, 5],
      },
      ticks: {
        display: true,
        color: "#f8f9fa",
        padding: 10,
        font: {
          size: 14,
          weight: 300,
          family: "Roboto",
          style: "normal",
          lineHeight: 2,
        },
      },
    },
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 1000,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  card: {
    color: theme.palette.primary.contrastText,
  },
  cardInteligence: {
    width: "100%",
    backgroundColor: primary800,
    // color: theme.palette.primary.contrastText
  },
  cardBox: {
    width: "50%",
    backgroundColor: primary800,
    // color: theme.palette.primary.contrastText
  },
  hiddendiv: {
    height: 0,
    backgroundColor: theme.palette.primary.contrastText,
    // color: theme.palette.primary.contrastText
  },
}));

function ModalComponent(props) {
  const { row } = props;
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles();

  return (
    <div key={row.id}>
      <Typography variant="body2">
        <MDButton
          onClick={() => {
            setShowModal(true);
          }}
        >
          Details
        </MDButton>
      </Typography>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // className={classes.modal}
        open={showModal}
        onClose={() => setShowModal(false)}
        fullWidth
        maxWidth="lg"
        disableEscapeKeyDown
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle>Snapshot</DialogTitle>
        <DialogContent>
          <div>
            <div>
              <MDBox
                variant="gradient"
                // bgColor="secondary"
                sx={{ backgroundColor: "#abadb0" }}
                borderRadius="lg"
                coloredShadow="#67bb6a"
                width="600"
              >
                {row.snapShot.simulatedjson && (
                  <>
                    <Line
                      data={addTracesModal(
                        JSON.parse(row.snapShot.simulatedjson).array,
                        JSON.parse(row.snapShot.historicjson).data,
                        10,
                        12,
                        row.snapShot.freq,
                        row.signPost
                      )}
                      options={modaloptions}
                    />
                    <MDTypography variant="h6" textTransform="capitalize">
                      No of Iterations : {row.no_of_iterations} &nbsp; No of Intervals :{" "}
                      {row.no_of_intervals} &nbsp; Overall Mean : {row.mean} &nbsp; Overall Std
                      Deviation : {row.stddev}
                    </MDTypography>
                  </>
                )}
              </MDBox>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={() => {
              setShowModal(false);
            }}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function addTraces2(data, data2, noOfIterations, noOfIntervals, freq, signPostData) {
  const traces = [];

  const dates = [];
  const dates2 = [];
  // let dates3 = [];
  let lastHistDate = "";
  let lastValue = 0;
  const thisDate = "";
  const line = [];

  const linedata = [];
  const avglinedata = [];
  const lines = new Array(noOfIterations + 1).fill(0).map(() => new Array(noOfIntervals).fill(0));
  const linesdata = new Array(noOfIterations + 1)
    .fill(0)
    .map(() => new Array(data2.length + noOfIntervals).fill(0));

  data2.sort((a, b) => new Date(a.Date) - new Date(b.Date));
  data2.map((each, index) => {
    const result = new Date(each.Date).toLocaleDateString();
    dates.push(result);
    line.push(each.Value);
    lastHistDate = each.Date;
    lastValue = each.Value;
    linedata.push({ x: result, y: each.Value });
    avglinedata.push({ x: result, y: null });
    for (let i = 0; i < noOfIterations; i++) {
      linesdata[i][index] = { x: result, y: null };
    }
  });
  traces.push({
    borderColor: "#52009b",
    tension: 0,
    data: linedata,
    label: "Historical",
  });

  let avg = 0;
  const result = new Date(lastHistDate);
  avglinedata[data2.length - 1] = { x: result.toLocaleDateString(), y: lastValue };
  for (let i = 0; i < noOfIterations; i++) {
    linesdata[i][data2.length - 1] = { x: result.toLocaleDateString(), y: lastValue };
  }
  data.map((each, index) => {
    console.log(result.toLocaleDateString());
    dates.push(result.toLocaleDateString());
    avg = 0;

    for (let i = 0; i < noOfIterations; i++) {
      avg += each[i];

      lines[i][index + data2.length] = each[i];
      linesdata[i][index + data2.length] = { x: result.toLocaleDateString(), y: each[i] };
    }

    lines[noOfIterations][index + data2.length] = avg / noOfIterations;
    avglinedata.push({ x: result.toLocaleDateString(), y: avg / noOfIterations });
    /* if (freq === "Q") {
      result.setDate(result.getDate() + index * 90);
    } else if (freq === "M") {
      result.setMonth(result.getMonth() + index);
    } else {
      result.setMonth(result.getMonth() + index);
    } */
    result.setMonth(result.getMonth() + index);
  });
  /*   console.log("avglinedata", linesdata);
  for (let i = 0; i < noOfIterations; i++) {
    traces.push({
      fill: false,
      borderColor: "rgba(255, 255, 255, .1)",
      tension: 0.1,
      label: `Iteration${i}`,
      data: linesdata[i],
    });
  } */
  traces.push({
    borderColor: "#9048cf",
    tension: 0,
    label: "Average",
    data: avglinedata,
    spanGaps: true, // Connect lines across gaps
  });

  // adding guardrails

  const limitLines = new Array(2).fill(0).map(() => new Array(dates.length).fill(0));

  dates.map((each, index) => {
    const result = new Date(each.Date);
    limitLines[0][index] = signPostData.optValue;
    limitLines[1][index] = signPostData.pessValue;
  });

  traces.push({
    borderColor: "rgba(255, 0, 255, .2)",
    tension: 0.1,
    borderDash: [10, 10],
    label: "Optimistic Case",
    data: limitLines[0],
  });

  traces.push({
    borderColor: "rgba(255, 255, 0, .2)",
    tension: 0.1,
    borderDash: [10, 10],
    label: "Pessimistic Case",
    data: limitLines[1],
  });
  dates.sort((a, b) => new Date(a) - new Date(b));

  data = {
    labels: dates,
    datasets: traces,
  };
  return data;
}
function addTracesModal(data, data2, noOfIterations, noOfIntervals, freq, signPostData) {
  const traces = [];
  let lastValue = 0;

  const dates = [];
  const dates2 = [];
  // let dates3 = [];
  let lastHistDate = "";
  const thisDate = "";
  const line = [];

  const linedata = [];
  const avglinedata = [];
  const lines = new Array(noOfIterations + 1).fill(0).map(() => new Array(noOfIntervals).fill(0));
  const linesdata = new Array(noOfIterations + 1)
    .fill(0)
    .map(() => new Array(data2.length + noOfIntervals).fill(0));

  data2.sort((a, b) => new Date(a.Date) - new Date(b.Date));
  data2.map((each, index) => {
    const result = new Date(each.Date).toLocaleDateString();
    dates.push(result);
    line.push(each.Value);
    lastHistDate = each.Date;
    lastValue = each.Value;
    linedata.push({ x: result, y: each.Value });
    avglinedata.push({ x: result, y: null });
    for (let i = 0; i < noOfIterations; i++) {
      linesdata[i][index] = { x: result, y: null };
    }
  });
  traces.push({
    borderColor: "#52009b",
    tension: 0,
    data: linedata,
    label: "Historical",
  });

  let avg = 0;
  const result = new Date(lastHistDate);
  avglinedata[data2.length - 1] = { x: result.toLocaleDateString(), y: lastValue };
  for (let i = 0; i < noOfIterations; i++) {
    linesdata[i][data2.length - 1] = { x: result.toLocaleDateString(), y: lastValue };
  }
  data.map((each, index) => {
    console.log(result.toLocaleDateString());
    dates.push(result.toLocaleDateString());
    avg = 0;

    for (let i = 0; i < noOfIterations; i++) {
      avg += each[i];

      lines[i][index + data2.length] = each[i];
      linesdata[i][index + data2.length] = { x: result.toLocaleDateString(), y: each[i] };
    }

    lines[noOfIterations][index + data2.length] = avg / noOfIterations;
    avglinedata.push({ x: result.toLocaleDateString(), y: avg / noOfIterations });
    /* if (freq === "Q") {
      result.setDate(result.getDate() + index * 90);
    } else if (freq === "M") {
      result.setMonth(result.getMonth() + index);
    } else {
      result.setMonth(result.getMonth() + index);
    } */
    result.setMonth(result.getMonth() + index);
  });
  console.log("avglinedata", linesdata);
  for (let i = 0; i < noOfIterations; i++) {
    traces.push({
      fill: false,
      borderColor: "rgba(255, 255, 255, .1)",
      tension: 0.1,
      label: `Iteration${i}`,
      data: linesdata[i],
    });
  }
  traces.push({
    borderColor: "#9048cf",
    tension: 0,
    label: "Average",
    data: avglinedata,
  });

  // adding guardrails

  const limitLines = new Array(2).fill(0).map(() => new Array(dates.length).fill(0));

  dates.map((each, index) => {
    const result = new Date(each.Date);
    limitLines[0][index] = signPostData.optValue;
    limitLines[1][index] = signPostData.pessValue;
  });

  traces.push({
    borderColor: "rgba(255, 0, 255, .2)",
    tension: 0.1,
    borderDash: [10, 10],
    label: "Optimistic Case",
    data: limitLines[0],
  });

  traces.push({
    borderColor: "rgba(255, 255, 0, .2)",
    tension: 0.1,
    borderDash: [10, 10],
    label: "Pessimistic Case",
    data: limitLines[1],
  });
  dates.sort((a, b) => new Date(a) - new Date(b));

  data = {
    labels: dates,
    datasets: traces,
  };
  return data;
}
function getRandomColor() {
  const minColorValue = 0; // Minimum value for other components
  const maxColorValue = 100; // Maximum value for other components
  const blueComponent = Math.floor(Math.random() * (50 - 0 + 1)) + 0; // Adjust this value for darkness

  const r = Math.floor(Math.random() * (maxColorValue - minColorValue + 1)) + minColorValue;
  const g = Math.floor(Math.random() * (maxColorValue - minColorValue + 1)) + minColorValue;

  return `rgb(${r}, ${g}, ${blueComponent})`;
}

function getRandomAlpha() {
  return Math.random().toFixed(2); // Generates a random alpha value between 0 and 1 with two decimal places
}
function addTracesCombine(snapShotList) {
  const traces = [];

  const dates = [];
  const dates2 = [];
  const dates3 = [];
  let lastHistDate = "";
  const thisDate = "";
  const line = [];

  let linedata = [];
  let avglinedata = [];
  const uniqueDates = new Set();

  console.log("snapShotList", snapShotList);
  snapShotList.map((snapShot, index) => {
    linedata = [];
    avglinedata = [];
    if (snapShot.snapShot && snapShot.snapShot.historicjson) {
      JSON.parse(snapShot.snapShot.historicjson).data.map((each) => {
        const result = new Date(each.Date).toLocaleDateString();
        // dates.push(result);
        if (!uniqueDates.has(result)) {
          dates.push(result);
          uniqueDates.add(result); // Add the date to the set to mark it as seen
          lastHistDate = each.Date;
        }
        linedata.push({ x: result, y: each.Value });
      });
    }

    const randomBorderColor = getRandomColor();
    const randomBackgroundColor = `rgba(${randomBorderColor}, ${getRandomAlpha()})`;
    // linedata.sort((a, b) => new Date(a.x) - new Date(b.x));
    linedata.sort((a, b) => new Date(a.x) - new Date(b.x));

    traces.push({
      // type: 'scatter',
      // mode: 'lines',
      // x: dates,
      // fill: false,
      borderColor: randomBorderColor,
      backgroundColor: randomBackgroundColor,
      tension: 0,
      data: linedata,
      label: `${snapShot.signPost?.desc ?? ""}`,
      yAxisID: `y${index}`,
    });

    console.log(snapShot);
    // const noOfIterations = snapShot.snapShot.no_of_iterations;
    // const noOfIntervals = snapShot.snapShot.no_of_intervals;

    // const lines = new Array(noOfIterations + 1).fill(0).map(() => new Array(noOfIntervals).fill(0));
    // const linesdata = new Array(noOfIterations + 1)
    //   .fill(0)
    //   .map(() => new Array(noOfIntervals).fill(0));
    // let avg = 0;

    // JSON.parse(snapShot.snapShot.simulatedjson).array.map((each, index) => {
    //   const result = new Date(lastHistDate);

    //   result.setDate(result.getDate() + index);

    //   avg = 0;
    //   for (let i = 0; i < noOfIterations; i++) {
    //     avg += each[i];

    //     lines[i][index] = each[i];
    //     linesdata[i][index] = { x: result.toLocaleDateString(), y: each[i] };
    //   }
    //   lines[noOfIterations][index] = avg / noOfIterations;
    //   avglinedata.push({
    //     x: result.toLocaleDateString(),
    //     y: avg / noOfIterations,
    //   });
    // });
    // avglinedata.sort((a, b) => {
    //   const dateA = new Date(a.x.split("/").reverse().join("-"));
    //   const dateB = new Date(b.x.split("/").reverse().join("-"));
    //   return dateA - dateB;
    // });
    // console.log(avglinedata);
    // //   for (let i = 0; i < noOfIterations; i++) {
    // //     traces.push({
    // //       // type: 'scatter',
    // //       // mode: 'lines',
    // //       //  opacity: 0.2,
    // //       //           x: dates2,
    // //       fill: false,
    // //       borderColor: "rgba(255, 255, 255, .1)",
    // //       tension: 0.1,
    // //       label: `Iteration${i}`,
    // //       data: linesdata[i],
    // //     });
    // //   }
    // traces.push({
    //   // type: 'scatter',
    //   // mode: 'lines',
    //   // marker: { color: 'red' },
    //   // x: dates2,
    //   // fill: false,
    //   borderColor: randomBorderColor,
    //   backgroundColor: randomBackgroundColor,
    //   tension: 0,
    //   label: "Average",
    //   data: avglinedata,
    // });

    //   // adding guardrails

    //   dates3 = dates.concat(dates2);
    //   const limitLines = new Array(2).fill(0).map(() => new Array(dates3.length).fill(0));

    //   dates3.map((each, index) => {
    //     const result = new Date(each.Date);
    //     dates3.push(result);
    //     limitLines[0][index] = signPostData.optValue;
    //     limitLines[1][index] = signPostData.pessValue;
    //   });

    //   traces.push({
    //     // type: 'scatter',
    //     // mode: 'lines',
    //     // line: { 'dash': 'dash' },
    //     // x: dates3,
    //     // opacity: 1,
    //     // y: limitLines[0],
    //     // name: 'Optimistic Case'
    //     borderColor: "rgba(255, 0, 255, .2)",
    //     tension: 0.1,
    //     borderDash: [10, 10],
    //     label: "Optimistic Case",
    //     data: limitLines[0],
    //   });

    //   traces.push({
    //     /*         type: 'scatter',
    //         mode: 'lines',
    //         line: { 'dash': 'dash' },

    //         x: dates3,
    //         opacity: 1,
    //         y: limitLines[1],
    //         name: 'Pessimistic Case'
    //  */
    //     borderColor: "rgba(255, 255, 0, .2)",
    //     tension: 0.1,
    //     borderDash: [10, 10],
    //     label: "Pessimistic Case",
    //     data: limitLines[1],
    //   });
  });
  console.log(traces);
  dates.sort((a, b) => {
    const dateA = new Date(a.split("/").reverse().join("-"));
    const dateB = new Date(b.split("/").reverse().join("-"));
    return dateA - dateB;
  });
  const data = {
    labels: dates,
    datasets: traces,
  };
  return data;
}
export default function Scenario({ RiskDetails }) {
  const [isloaded, setIsloaded] = useState(false);
  const [riskCategoryList, setRiskCategoryList] = useState([]);
  const [signPostList, setSignPostList] = useState([]);
  const [snapShotList, setSnapShotList] = useState([]);
  const [show, setShow] = useState(false);
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  const [trendCount, setTrendCount] = useState(0);
  let tc = 0;
  const classes = useStyles();
  const defaultProps = {
    options: riskCategoryList,
    getOptionLabel: (option) => option.data.description,
  };
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };
  const [open, setOpen] = React.useState([]);
  const handleOpen = (id) => setOpen({ id, open: true });
  const handleClose = (id) => setOpen({ open: false });
  useEffect(() => {
    console.log("SCENARIO USE EFFECT", RiskDetails, RiskDetails.id);
    // value.forEach(risk => {
    setSignPostList([]);
    setSnapShotList([]);
    setIsloaded(false);

    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/anticipateEmergingRisks/getSignPostByRiskId?riskCategoryId=${RiskDetails.id}`
    ).then((response) => {
      console.log(response);
      if (response?.data) {
        setSignPostList(response.data);
        setSnapShotList([]);
        const promises = response.data.map((signPost) =>
          execute(
            "GET",
            `${protectedResources.apiTodoList.endpoint}/anticipateEmergingRisks/getLatestSnapshots?signPostId=${signPost.id}`
          )
        );
        Promise.all(promises).then((responses) => {
          tc = 0;
          /*         setSnapShotList((snapShotList) => {
          console.log("here", snapShotList);
          const copy = snapShotList.slice();
          copy[index] = response.data;
          return copy;
        }); */
          const snapshotData = responses.map((response) => response.data);
          console.log("snapshotData", snapshotData);
          setSnapShotList(snapshotData);
          setIsloaded(true);
          /*   snapShotList.forEach( snapshot => {
                          console.log("trenCoun"+tc+"scenario"+ snapshot.scenario)
                          if ( snapshot.scenario ==="Optimistic" ) {
                              tc++                    
                          }
                          else
                          tc--
                      })
                      setTrendCount(tc) */
        });
      }
    });

    //    })
  }, [execute]);

  return (
    <div>
      {isloaded ? (
        <div>
          <Paper sx={{ p: 2, margin: "auto", flexGrow: 1 }}>
            <MDBox py={3}>
              <div>
                <div>
                  <MDBox mt={4.5}>
                    <Grid container spacing={3}>
                      {snapShotList &&
                        snapShotList.map((row, index) => (
                          <Grid item xs={12} md={6} lg={6}>
                            {row.snapShot ? (
                              <MDBox mb={3}>
                                <Card sx={{ height: "100%" }}>
                                  <MDBox padding="1rem">
                                    <MDBox
                                      variant="gradient"
                                      sx={{ backgroundColor: "#abadb0" }}
                                      borderRadius="lg"
                                      coloredShadow="#67bb6a"
                                      py={2}
                                      pr={0.5}
                                      mt={-5}
                                      height="21.5rem"
                                    >
                                      {row.snapShot.simulatedjson && (
                                        <Line
                                          data={addTraces2(
                                            JSON.parse(row.snapShot.simulatedjson).array ?? [],
                                            JSON.parse(row.snapShot.historicjson).data ?? [],
                                            10,
                                            12,
                                            row.snapShot.freq,
                                            row.signPost
                                          )}
                                          options={options}
                                        />
                                      )}
                                    </MDBox>

                                    <MDBox pt={3} pb={1} px={1}>
                                      <MDTypography variant="h6" textTransform="capitalize">
                                        {row.signPost.desc ?? ""}
                                      </MDTypography>

                                      <MDTypography variant="subtitle2">
                                        <b>Source : </b> {row.signPost.desc2 ?? ""}
                                      </MDTypography>
                                      <MDTypography
                                        component="div"
                                        variant="button"
                                        color="text"
                                        fontWeight="light"
                                      >
                                        {row.signPost.type}
                                      </MDTypography>
                                      <Divider />
                                      <MDBox display="flex" alignItems="center">
                                        <MDTypography
                                          variant="button"
                                          color="text"
                                          lineHeight={1}
                                          sx={{ mt: 0.15, mr: 0.5 }}
                                        >
                                          <Icon>schedule</Icon>
                                        </MDTypography>
                                        <MDTypography
                                          variant="button"
                                          color="text"
                                          fontWeight="light"
                                        >
                                          {new Date(
                                            row.signPost.date_next_run
                                          ).toLocaleDateString()}
                                        </MDTypography>
                                      </MDBox>
                                    </MDBox>
                                    <ModalComponent key={row.id} row={row} />
                                  </MDBox>
                                </Card>
                              </MDBox>
                            ) : (
                              <div />
                            )}
                          </Grid>
                        ))}
                    </Grid>
                  </MDBox>
                </div>
                <Grid item xs={12} md={6} lg={6} />
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox py={2} mb={3}>
                    <Card sx={{ height: "100%" }}>
                      <MDBox padding="1rem">
                        <MDBox
                          variant="gradient"
                          sx={{ backgroundColor: "#abadb0" }}
                          borderRadius="lg"
                          coloredShadow="#67bb6a"
                          py={2}
                          pr={0.5}
                          mt={-5}
                          height="21.5rem"
                        >
                          <Line data={addTracesCombine(snapShotList)} options={combineoptions} />
                        </MDBox>
                      </MDBox>
                    </Card>
                  </MDBox>
                </Grid>
                <div>
                  <Typography variant="h7" component="div">
                    Inteligence
                  </Typography>
                  <Card raised="true" width="100%" className={classes.cardInteligence}>
                    <Grid container cols={2}>
                      <Card raised="true" width="100%" className={classes.cardBox}>
                        <Grid item>
                          <CardContent>
                            <Typography variant="body2">Trending Towards</Typography>

                            <Card raised="true" width="100%" className={classes.cardInteligence}>
                              {tc > 0 ? (
                                <Typography variant="h4">Optimistic scenario</Typography>
                              ) : (
                                <Typography variant="h4">Pessimistic scenario</Typography>
                              )}
                            </Card>
                          </CardContent>
                        </Grid>
                      </Card>
                      <Card raised="true" width="100%" className={classes.cardBox}>
                        <Grid item>
                          <CardContent>
                            <Typography variant="body2">Priority Ranking</Typography>

                            <Card raised="true" width="100%" className={classes.cardInteligence}>
                              {tc > 0 ? (
                                <Typography variant="h4">
                                  Prepare for Optimistic scenario
                                </Typography>
                              ) : (
                                <Typography variant="h4">
                                  Prepare for Pessimistic scenario
                                </Typography>
                              )}
                            </Card>
                          </CardContent>
                        </Grid>
                      </Card>
                    </Grid>
                  </Card>
                </div>
              </div>
            </MDBox>
          </Paper>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
