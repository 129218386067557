/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */

import {
  Button,
  Card,
  Checkbox,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Stack,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import "./ProtectDashboard.css";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { siteData, protectedResources } from "authConfig";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled, ThemeProvider } from "@mui/material/styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { MaterialReactTable } from "material-react-table";
import CustomHeightTable from "assets/theme/components/table/CustomHeightTable";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import CancelIcon from "@mui/icons-material/Cancel";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import NoborderTable from "../../assets/theme/components/table/NoborderTable";
import arrow from "../../assets/images/icons/projectIcons/icon-navigation-arrow_drop_down_24px.svg";
import settingIcon from "../../assets/images/icons/projectIcons/icon-content-add_24px.svg";

// toggle button css
const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: selectedColor,
  },
}));
function KibanaEmbed() {
  return (
    <div className="App">
      <iframe
        src="https://thsenseriskelastic.kb.australiaeast.azure.elastic-cloud.com:9243/app/dashboards?auth_provider_hint=anonymous1#/view/4d220550-5d0c-11ee-a8a5-4d9c68a2f940?embed=true&_g=(refreshInterval:(pause:!t,value:60000),time:(from:now-15m,to:now))&_a=()&hide-filter-bar=true"
        height="600"
        width="100%"
      />
    </div>
  );
}
function ProtectDashboard() {
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  const [allData, setAllData] = useState(null);

  // Report date input
  const [ReportDate, setReportDate] = useState();
  // Create a Day.js object for today's date
  const today = dayjs();

  const [AssurProportionality, setAssurProportionality] = useState([]);
  const [TempAssurProportionality, setTempAssurProportionality] = useState([]);
  const [AggExposure, setAggExposure] = useState([]);
  const [TempAggExposure, setTempAggExposure] = useState([]);
  const [Trend, setTrend] = useState([]);
  const [TempTrend, setTempTrend] = useState([]);
  const [withinAppetite, setWithinAppetite] = useState([]);
  const [TempwithinAppetite, setTempWithinAppetite] = useState([]);

  // within appetite checkboxes
  const handleCheckboxChange = (name) => {
    if (withinAppetite.indexOf(name) > -1) {
      // If the item is already in the array, remove it
      setWithinAppetite(withinAppetite.filter((item) => item !== name));
    } else {
      // If the item is not in the array, add it
      setWithinAppetite([...withinAppetite, name]);
    }
  };

  //  Assurance checkboxes
  const handleAssuranceCheckboxChange = (name) => {
    if (AssurProportionality.indexOf(name) > -1) {
      // If the item is already in the array, remove it
      setAssurProportionality(AssurProportionality.filter((item) => item !== name));
    } else {
      // If the item is not in the array, add it
      setAssurProportionality([...AssurProportionality, name]);
    }
  };

  // Aggregate exposure checkboxes
  const handleAggregateCheckboxChange = (name) => {
    if (AggExposure.indexOf(name) > -1) {
      // if the item is already in the array, remove it
      setAggExposure(AggExposure.filter((item) => item !== name));
    } else {
      // if item is not in the array , add it
      setAggExposure([...AggExposure, name]);
    }
  };

  // trend checkboxes
  const handleTrendsCheckboxChange = (name) => {
    if (Trend.indexOf(name) > -1) {
      // if the item is already in the array, remove it
      setTrend(Trend.filter((item) => item !== name));
    } else {
      // if item is not in the array , add it
      setTrend([...Trend, name]);
    }
  };

  // get categories from taxonomy
  useEffect(() => {
    if (!allData) {
      execute("GET", `${protectedResources.apiTodoList.endpoint}/taxonomy/list`).then(
        (response) => {
          if (response && response.success === true && response.data.length > 0) {
            setAllData(response.data);
          }
        }
      );
    }
  }, [execute, allData]);

  // for risk appetite filter values
  const [RiskData, setRiskData] = useState(null);

  useEffect(() => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/appetite/list`).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setRiskData(response.data);
      }
    });
  }, [execute]);
  // Extract the 'name' values from the 'riskAppetiteSetting' array and store them in the 'riskOption' array
  const riskAppetiteOptions = RiskData && RiskData.map((setting) => setting.name);

  // get taxonomy
  const [SingleTaxonomy, setSingleTaxonomy] = useState(null);
  const [Taxonomy, setTaxonomy] = useState(null);

  const [TaxonomyList, setTaxonomyList] = useState(null);

  useEffect(() => {
    if (allData && allData.length) {
      setTaxonomy(allData[0]);
    }
  }, [allData]);
  useEffect(() => {
    if (!TaxonomyList) {
      execute("GET", `${protectedResources.apiTodoList.endpoint}/taxonomy/listall`).then(
        (response) => {
          if (response && response.success === true && response.data.length > 0) {
            setTaxonomyList(response.data);
            setSingleTaxonomy(response.data[0].name);
          }
        }
      );
    }
  }, [execute, TaxonomyList]);

  const TaxonomyName = TaxonomyList && TaxonomyList.map((nme) => nme.name);
  const handleTaxonomyChange = (event) => {
    setSingleTaxonomy(event.target.value);
  };

  // get overview data
  const TaxonomyId = Taxonomy && Taxonomy.id;

  const [overView, setOverView] = useState([]);
  useEffect(() => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/protect/overview/${TaxonomyId}`
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setOverView(response.data);
      }
    });
  }, [TaxonomyId]);

  // get details data

  const [details, setDetails] = useState([]);
  const [CatDetails, setCatDetails] = useState([]);
  useEffect(() => {
    const requestbody = {
      appetiteType: [],
      filter: {
        appetite: [],
        assuranceProportionality: [],
        aggregateExposure: [],
        trends: [],
      },
    };
    execute(
      "POST",
      `${protectedResources.apiTodoList.endpoint}/protect/details/${TaxonomyId}`,
      requestbody
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setDetails(response.data);
        setCatDetails(response.data);
      }
    });
  }, [TaxonomyId]);

  // values to show in filer box
  // response.data was not array it was obj so did not check the length
  const [filterData, setFilterData] = useState(null);
  useEffect(() => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/protect/details/filterdata/${TaxonomyId}`
    ).then((response) => {
      if (response && response.success === true && response.data) {
        setFilterData(response.data);
      }
    });
  }, [TaxonomyId]);

  // const hardcodedOverview = [
  //   {
  //     categoryId: "05a37b48-7716-458f-a002-15b0782ca084",
  //     categoryName: "Strategy Business Development",
  //     colorFlag: "green",
  //     appetite: {
  //       count: "00",
  //       name: "Over Appetite",
  //     },
  //     assuranceProportionality: {
  //       count: "01",
  //       name: "DisProportionate",
  //     },
  //     aggregateExposure: {
  //       count: "00",
  //       name: "Top Quartile Exposures",
  //     },
  //     trends: {
  //       count: "00",
  //       name: "Not Available",
  //     },
  //   },
  //   {
  //     categoryId: "44355483-4237-4e1e-8156-3e387a7b16bd",
  //     categoryName: "Operations - Hospitals",
  //     colorFlag: "red",
  //     appetite: {
  //       count: "02",
  //       name: "Over Appetite",
  //     },
  //     assuranceProportionality: {
  //       count: "03",
  //       name: "DisProportionate",
  //     },
  //     aggregateExposure: {
  //       count: "02",
  //       name: "Priority 1 Exposures",
  //     },
  //     trends: {
  //       count: "00",
  //       name: "Not Available",
  //     },
  //   },
  //   {
  //     categoryId: "16adbc9f-7e59-482a-b7d5-991186be750f",
  //     categoryName: "Operations - Out of Hosptails",
  //     colorFlag: "green",
  //     appetite: {
  //       count: "00",
  //       name: "Over Appetite",
  //     },
  //     assuranceProportionality: {
  //       count: "00",
  //       name: "DisProportionate",
  //     },
  //     aggregateExposure: {
  //       count: "00",
  //       name: "Priority 1 Exposures",
  //     },
  //     trends: {
  //       count: "00",
  //       name: "Not Available",
  //     },
  //   },
  //   {
  //     categoryId: "f59f73c1-6b6d-4c6c-b77e-9ba0765234de",
  //     categoryName: "People and Culture",
  //     colorFlag: "amber",
  //     appetite: {
  //       count: "01",
  //       name: "Over Appetite",
  //     },
  //     assuranceProportionality: {
  //       count: "01",
  //       name: "DisProportionate",
  //     },
  //     aggregateExposure: {
  //       count: "00",
  //       name: "Priority 1 Exposures",
  //     },
  //     trends: {
  //       count: "00",
  //       name: "Not Available",
  //     },
  //   },
  //   {
  //     categoryId: "aea8b801-dcba-4c5f-bcaa-186d15ad7134",
  //     categoryName: "Clinical Safety and Quality",
  //     colorFlag: "amber",
  //     appetite: {
  //       count: "01",
  //       name: "Over Appetite",
  //     },
  //     assuranceProportionality: {
  //       count: "01",
  //       name: "DisProportionate",
  //     },
  //     aggregateExposure: {
  //       count: "02",
  //       name: "Priority 1 Exposures",
  //     },
  //     trends: {
  //       count: "01",
  //       name: "Not Available",
  //     },
  //   },
  //   {
  //     categoryId: "832acf3c-7e14-4e94-9464-49a870c19c59",
  //     categoryName: "Property and Infrastructure",
  //     colorFlag: "red",
  //     appetite: {
  //       count: "01",
  //       name: "Over Appetite",
  //     },
  //     assuranceProportionality: {
  //       count: "02",
  //       name: "DisProportionate",
  //     },
  //     aggregateExposure: {
  //       count: "01",
  //       name: "Priority 1 Exposures",
  //     },
  //     trends: {
  //       count: "00",
  //       name: "Not Available",
  //     },
  //   },
  //   {
  //     categoryId: "01218b61-e910-478b-8efa-1d788b8f5092",
  //     categoryName: "Data and Digital",
  //     colorFlag: "green",
  //     appetite: {
  //       count: "00",
  //       name: "Over Appetite",
  //     },
  //     assuranceProportionality: {
  //       count: "00",
  //       name: "DisProportionate",
  //     },
  //     aggregateExposure: {
  //       count: "00",
  //       name: "Priority 1 Exposures",
  //     },
  //     trends: {
  //       count: "00",
  //       name: "Not Available",
  //     },
  //   },
  //   {
  //     categoryId: "9b6dadb8-5c76-47b7-afc3-007701ff5f7f",
  //     categoryName: "Marketing | Payor Relations",
  //     colorFlag: "green",
  //     appetite: {
  //       count: "00",
  //       name: "Over Appetite",
  //     },
  //     assuranceProportionality: {
  //       count: "00",
  //       name: "DisProportionate",
  //     },
  //     aggregateExposure: {
  //       count: "00",
  //       name: "Priority 1 Exposures",
  //     },
  //     trends: {
  //       count: "00",
  //       name: "Not Available",
  //     },
  //   },
  //   {
  //     categoryId: "745b83d0-56ee-4f8b-ab10-ecbe015a9422",
  //     categoryName: "Financial Management",
  //     colorFlag: "green",
  //     appetite: {
  //       count: "00",
  //       name: "Over Appetite",
  //     },
  //     assuranceProportionality: {
  //       count: "01",
  //       name: "DisProportionate",
  //     },
  //     aggregateExposure: {
  //       count: "01",
  //       name: "Priority 1 Exposures",
  //     },
  //     trends: {
  //       count: "00",
  //       name: "Not Available",
  //     },
  //   },
  //   {
  //     categoryId: "6a72f742-8c6e-4b8f-b4de-6c49c35c9f98",
  //     categoryName: "Legal and Regulatory",
  //     colorFlag: "amber",
  //     appetite: {
  //       count: "01",
  //       name: "Over Appetite",
  //     },
  //     assuranceProportionality: {
  //       count: "02",
  //       name: "DisProportionate",
  //     },
  //     aggregateExposure: {
  //       count: "01",
  //       name: "Priority 1 Exposures",
  //     },
  //     trends: {
  //       count: "00",
  //       name: "Not Available",
  //     },
  //   },
  //   {
  //     categoryId: "a1aee6aa-517d-4f12-8c4e-cc3ffad4c76a",
  //     categoryName: "Ramsay Cares",
  //     colorFlag: "green",
  //     appetite: {
  //       count: "00",
  //       name: "Over Appetite",
  //     },
  //     assuranceProportionality: {
  //       count: "03",
  //       name: "DisProportionate",
  //     },
  //     aggregateExposure: {
  //       count: "00",
  //       name: "Priority 1 Exposures",
  //     },
  //     trends: {
  //       count: "00",
  //       name: "Not Available",
  //     },
  //   },
  // ];

  // const hardcodedDetailsData = [
  //   {
  //     categoryId: "05a37b48-7716-458f-a002-15b0782ca084",
  //     categoryName: "Strategy Business Development",
  //     subCategories: [
  //       {
  //         subCategoryId: "23b10c2e-4b5b-4ca1-be81-6ddb2d0a84b1",
  //         subCategoryName: "Mergers, acquisitions, divestments, & integration",
  //         appetiteType: "Risk Balanced",
  //         appetite: "Within",
  //         assuranceProportionality: "Within",
  //         aggregateExposure: "Bottom Quartile",
  //         trends: "Not available",
  //       },
  //       {
  //         aggregateExposure: "Top Quartile",
  //         appetite: "Under",
  //         appetiteType: "Risk Avoidant",
  //         assuranceProportionality: "Over",
  //         subCategoryId: "406f38ef-5f41-493c-b192-11c076c78fa3",
  //         subCategoryName: "Brownfield Developments",
  //         trends: "Not available",
  //       },
  //       {
  //         aggregateExposure: "2nd Quartile",
  //         appetite: "Over",
  //         appetiteType: "Risk Balanced",
  //         assuranceProportionality: "Within",
  //         subCategoryId: "732dd306-61d2-4052-aaed-0c6d06554e8d",
  //         subCategoryName: "Business Development and Growth",
  //         trends: "Not available",
  //       },
  //       {
  //         aggregateExposure: "Top Quartile",
  //         appetite: "Within",
  //         appetiteType: "Risk Seeking",
  //         assuranceProportionality: "Within",
  //         subCategoryId: "dc7a3395-f496-4cbf-90a2-dad7f0a8a6e0",
  //         subCategoryName: "Competitor activity and market disruption",
  //         trends: "Not available",
  //       },
  //       {
  //         aggregateExposure: "3rd Quartile",
  //         appetite: "Under",
  //         appetiteType: "Risk Avoidant",
  //         assuranceProportionality: "Within",
  //         subCategoryId: "6932fa78-6250-4d93-a9da-4862c904cc5f",
  //         subCategoryName:
  //           "Major Puplic Private Partnerships (PPP) Contracts, Joint ventures and strategic partnerships",
  //         trends: "Not available",
  //       },
  //       {
  //         aggregateExposure: "2nd Quartile",
  //         appetite: "Within",
  //         appetiteType: "Risk Seeking",
  //         assuranceProportionality: "Within",
  //         subCategoryId: "02171963-cd1e-40b2-89a8-cca031d298f5",
  //         subCategoryName: "Third Party Technology Providers and Licences",
  //         trends: "Not available",
  //       },
  //       {
  //         aggregateExposure: "Bottom Quartile",
  //         appetite: "Over",
  //         appetiteType: "Risk Avoidant",
  //         assuranceProportionality: "Under",
  //         subCategoryId: "e6994fed-a8e6-4a6c-89fb-38dc98b351f6",
  //         subCategoryName: "Brand, communication and marketing",
  //         trends: "Not available",
  //       },
  //     ],
  //   },
  //   {
  //     categoryId: "44355483-4237-4e1e-8156-3e387a7b16bd",
  //     categoryName: "Operations - Hospitals",
  //     subCategories: [
  //       {
  //         subCategoryId: "de41753a-b94f-47f3-961b-1e335b3ffc30",
  //         subCategoryName: "Research and Innovation",
  //         appetiteType: "Risk Seeking",
  //         appetite: "Under",
  //         assuranceProportionality: "Over",
  //         aggregateExposure: "Bottom Quartile",
  //         trends: "Not available",
  //       },
  //       {
  //         aggregateExposure: "Top Quartile",
  //         appetite: "Over",
  //         appetiteType: "Risk Averse",
  //         assuranceProportionality: "Under",
  //         subCategoryId: "22423643-4887-4148-bb54-55c1ef8bdc98",
  //         subCategoryName: "Clinical/ Medical Performance",
  //         trends: "Not available",
  //       },
  //       {
  //         aggregateExposure: "Top Quartile",
  //         appetite: "Over",
  //         appetiteType: "Risk Averse",
  //         assuranceProportionality: "Under",
  //         subCategoryId: "558f777c-0301-4afa-846b-88e4e3b902ff",
  //         subCategoryName: "Nursing / Patient Experience",
  //         trends: "Not available",
  //       },
  //       {
  //         aggregateExposure: "Second Quartile",
  //         appetite: "Within",
  //         appetiteType: "Risk Balanced",
  //         assuranceProportionality: "Within",
  //         subCategoryId: "7377f2be-5774-4900-ae74-4a0da2f6ccd3",
  //         subCategoryName: "Hospital Group Profit and Loss (Revenue) Management",
  //         trends: "Not available",
  //       },
  //     ],
  //   },
  //   {
  //     categoryId: "16adbc9f-7e59-482a-b7d5-991186be750f",
  //     categoryName: "Operations - Out of Hosptails",
  //     subCategories: [],
  //   },
  //   {
  //     categoryId: "f59f73c1-6b6d-4c6c-b77e-9ba0765234de",
  //     categoryName: "People and Culture",
  //     subCategories: [],
  //   },
  //   {
  //     categoryId: "aea8b801-dcba-4c5f-bcaa-186d15ad7134",
  //     categoryName: "Clinical Safety and Quality",
  //     subCategories: [],
  //   },
  //   {
  //     categoryId: "832acf3c-7e14-4e94-9464-49a870c19c59",
  //     categoryName: "Property and Infrastructure",
  //     subCategories: [],
  //   },
  //   {
  //     categoryId: "01218b61-e910-478b-8efa-1d788b8f5092",
  //     categoryName: "Data and Digital",
  //     subCategories: [],
  //   },
  //   {
  //     categoryId: "9b6dadb8-5c76-47b7-afc3-007701ff5f7f",
  //     categoryName: "Marketing | Payor Relations",
  //     subCategories: [],
  //   },
  //   {
  //     categoryId: "745b83d0-56ee-4f8b-ab10-ecbe015a9422",
  //     categoryName: "Financial Management",
  //     subCategories: [],
  //   },
  //   {
  //     categoryId: "6a72f742-8c6e-4b8f-b4de-6c49c35c9f98",
  //     categoryName: "Legal and Regulatory",
  //     subCategories: [],
  //   },
  //   {
  //     categoryId: "a1aee6aa-517d-4f12-8c4e-cc3ffad4c76a",
  //     categoryName: "Ramsay Cares",
  //     subCategories: [],
  //   },
  // ];

  // tab selection
  const [selectedOption, setSelectedOption] = useState("Overview");
  //  for tab input change
  const handleOptionChange = (event, option) => {
    if (option !== null) {
      setSelectedOption(option);
    }
  };

  const [Dialogopen, setOpen] = useState(false); // State to control dialog visibility

  // Function to open the dialog
  const handleOpenDialog = () => {
    setOpen(true);
  };

  // ====================manage category filter dropwon=================================
  // manage categories
  const [manageCategory, setManageCategory] = useState([]);

  const [anchorCatEl, setAnchorCatEl] = React.useState(null);
  const Mopen = Boolean(anchorCatEl);

  // handle category change
  const handleCategoriesChange = (name) => {
    if (manageCategory.indexOf(name) > -1) {
      // if the item is already in the array, remove it
      setManageCategory(manageCategory.filter((item) => item !== name));
    } else {
      // if item is not in the array , add it
      setManageCategory([...manageCategory, name]);
    }
  };

  // filter the categories in manage categories

  const applyFilter = () => {
    if (manageCategory.includes("showall")) {
      // If "showall" is in manageCategory, set details to CatDetails
      setDetails(CatDetails);
    } else if (manageCategory.length > 0) {
      // Filter details based on selected manageCategory values
      const filtered = details.filter((detail) => manageCategory.includes(detail.categoryName));
      setDetails(filtered);
    } else {
      // If manageCategory is empty, show all details
      setDetails(CatDetails);
    }
    // setIsCategoryOpen(false);

    setAnchorCatEl(null);
  };

  const handleManageClick = (event) => {
    setAnchorCatEl(event.currentTarget);
  };
  const handleManageClose = () => {
    setAnchorCatEl(null);
  };

  // ====================appetite filter dropwon=================================
  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
  const [RiskAppetiteFilter, setRiskAppetiteFilter] = useState([]);
  const [TempRiskAppetiteFilter, setTempRiskAppetiteFilter] = useState([]);
  const filterOpen = Boolean(filterAnchorEl);
  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };
  const handleFilterChange = (name) => {
    if (RiskAppetiteFilter.indexOf(name) > -1) {
      // If the item is already in the array, remove it
      // setRiskAppetiteFilter(RiskAppetiteFilter.filter((item) => item !== name));
      setTempRiskAppetiteFilter(TempRiskAppetiteFilter.filter((item) => item !== name));
    } else {
      // If the item is not in the array, add it
      // setRiskAppetiteFilter([...RiskAppetiteFilter, name]);
      setTempRiskAppetiteFilter([...TempRiskAppetiteFilter, name]);
    }
  };

  // risk appetite filter
  const applyAppetiteFilter = () => {
    setRiskAppetiteFilter(TempRiskAppetiteFilter);
    const requestbody = {
      appetiteType: TempRiskAppetiteFilter,
      filter: {
        appetite: withinAppetite,
        assuranceProportionality: AssurProportionality,
        aggregateExposure: AggExposure,
        trends: Trend,
      },
    };
    execute(
      "POST",
      `${protectedResources.apiTodoList.endpoint}/protect/details/${TaxonomyId}`,
      requestbody
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setDetails(response.data);
        // setCatDetails(response.data);
      }
    });

    setFilterAnchorEl(null);
    setManageCategory([]);
  };

  // ====================risk limit filter dropwon=================================
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // risk limit filters
  const applyRiskFilter = () => {
    setTempWithinAppetite(withinAppetite);
    setTempAssurProportionality(AssurProportionality);
    setTempAggExposure(AggExposure);
    setTempTrend(Trend);
    const requestbody = {
      appetiteType: RiskAppetiteFilter,
      filter: {
        appetite: withinAppetite,
        assuranceProportionality: AssurProportionality,
        aggregateExposure: AggExposure,
        trends: Trend,
      },
    };
    execute(
      "POST",
      `${protectedResources.apiTodoList.endpoint}/protect/details/${TaxonomyId}`,
      requestbody
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setDetails(response.data);
        // setCatDetails(response.data);
      }
    });
    setAnchorEl(null);
    setManageCategory([]);
  };

  // clear risk limit filters
  const handleClearFilters = () => {
    setRiskAppetiteFilter([]);
    setWithinAppetite([]); // Clear withinAppetite array
    setTempWithinAppetite([]);
    setAssurProportionality([]); // Clear Assurance Proportionality array
    setTempAssurProportionality([]);
    setAggExposure([]); // Clear Aggregate Exposure array
    setTempAggExposure([]);
    setTrend([]); // Clear Trend array
    setTempTrend([]);
    // setDetails(CatDetails);
    const requestbody = {
      appetiteType: [],
      filter: {
        appetite: [],
        assuranceProportionality: [],
        aggregateExposure: [],
        trends: [],
      },
    };
    execute(
      "POST",
      `${protectedResources.apiTodoList.endpoint}/protect/details/${TaxonomyId}`,
      requestbody
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setDetails(response.data);
        setCatDetails(response.data);
      }
    });
  };

  // cross icon in bubble
  const handleCross = (identifier) => {
    // let requestbody = {};
    switch (identifier) {
      case "riskAppetite":
        setRiskAppetiteFilter([]); // Clear withinAppetite array
        setTempRiskAppetiteFilter([]);
        const request = {
          appetiteType: [],
          filter: {
            appetite: withinAppetite,
            assuranceProportionality: AssurProportionality,
            aggregateExposure: AggExposure,
            trends: Trend,
          },
        };
        execute(
          "POST",
          `${protectedResources.apiTodoList.endpoint}/protect/details/${TaxonomyId}`,
          request
        ).then((response) => {
          if (response && response.success === true && response.data.length > 0) {
            setDetails(response.data);
            setCatDetails(response.data);
          }
        });
        break;
      case "withinAppetite":
        setWithinAppetite([]); // Clear withinAppetite array
        setTempWithinAppetite([]);
        const requestbody = {
          appetiteType: RiskAppetiteFilter,
          filter: {
            appetite: [],
            assuranceProportionality: AssurProportionality,
            aggregateExposure: AggExposure,
            trends: Trend,
          },
        };
        execute(
          "POST",
          `${protectedResources.apiTodoList.endpoint}/protect/details/${TaxonomyId}`,
          requestbody
        ).then((response) => {
          if (response && response.success === true && response.data.length > 0) {
            setDetails(response.data);
            setCatDetails(response.data);
          }
        });
        break;
      case "assuranceProportionality":
        setAssurProportionality([]); // Clear Assurance Proportionality array
        setTempAssurProportionality([]);
        const reqestbody = {
          appetiteType: RiskAppetiteFilter,
          filter: {
            appetite: withinAppetite,
            assuranceProportionality: [],
            aggregateExposure: AggExposure,
            trends: Trend,
          },
        };
        execute(
          "POST",
          `${protectedResources.apiTodoList.endpoint}/protect/details/${TaxonomyId}`,
          reqestbody
        ).then((response) => {
          if (response && response.success === true && response.data.length > 0) {
            setDetails(response.data);
            setCatDetails(response.data);
          }
        });
        break;
      case "aggExposure":
        setAggExposure([]); // Clear Aggregate Exposure array
        setTempAggExposure([]);
        const reqstbody = {
          appetiteType: RiskAppetiteFilter,
          filter: {
            appetite: withinAppetite,
            assuranceProportionality: AssurProportionality,
            aggregateExposure: [],
            trends: Trend,
          },
        };
        execute(
          "POST",
          `${protectedResources.apiTodoList.endpoint}/protect/details/${TaxonomyId}`,
          reqstbody
        ).then((response) => {
          if (response && response.success === true && response.data.length > 0) {
            setDetails(response.data);
            setCatDetails(response.data);
          }
        });
        break;
      case "trend":
        setTrend([]); // Clear Trend array
        setTempTrend([]);
        const reqbody = {
          appetiteType: RiskAppetiteFilter,
          filter: {
            appetite: withinAppetite,
            assuranceProportionality: AssurProportionality,
            aggregateExposure: AggExposure,
            trends: [],
          },
        };
        execute(
          "POST",
          `${protectedResources.apiTodoList.endpoint}/protect/details/${TaxonomyId}`,
          reqbody
        ).then((response) => {
          if (response && response.success === true && response.data.length > 0) {
            setDetails(response.data);
            setCatDetails(response.data);
          }
        });
        break;
      default:
        // Handle other cases if needed
        break;
    }
    setManageCategory([]);
  };

  //= ========== get only single ca data to display in table dialog box===================================================
  const [SingleCategoryNav, setSingleCategoryNav] = useState("");
  const [SingleCategoryName, setSingleCategoryName] = useState("");
  const [SingleCategory, setSingleCategory] = useState([]);
  const setCategoryData = (categoryId) => {
    // Find the category that matches the categoryId
    const categoryMatch = details.find((det) => det.categoryId === categoryId);
    // for hardcoded values
    // const categoryMatch = hardcodedDetailsData.find((det) => det.categoryId === categoryId);

    // setSingleCategory(categoryMatch.subCategories);

    //
    setSingleCategoryNav(categoryMatch);
    setSingleCategory(categoryMatch.subCategories);
    setSingleCategoryName(categoryMatch.categoryName);
    handleOpenDialog();
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setOpen(false);
  };

  // get bubble color of risk appetite
  function getAppetiteClassName(appetite) {
    switch (appetite) {
      case "Risk Avoidant":
        return "dialog-appetitie-avoidant";
      case "Risk Averse":
        return "dialog-appetitie-avoidant";
      case "Risk Balanced":
        return "dialog-appetitie-balanced";
      case "Risk Seeking":
        return "dialog-appetitie-seeking";
      default:
        return "dialog-appetitie-default"; // Default class name if none of the conditions match
    }
  }

  // get appetite color
  function getTextColorClassName(appetite) {
    switch (appetite) {
      case "Over":
        return "table-over";
      case "Within":
        return "table-within";
      case "Under":
        return "table-under";
      default:
        return "dialog-appetitie-default"; // Default class name if none of the conditions match
    }
  }

  // navigate to category page
  const navigate = useNavigate();
  // const [categ, setCat] = useState();
  const navigateCategory = (category) => {
    const cat = CatDetails.find((c) => c.categoryId === category.categoryId);
    // hardcoded
    // const cat = hardcodedDetailsData.find((c) => c.categoryId === category.categoryId);

    navigate("/overview/category", { state: cat });
  };

  // navigate to sub category
  // const navigateSubCategory = (subCategory) => {
  //   const cat = categ;

  //   navigate("/overview/category/subcategory", { state: { cat, subCategory } });
  // };
  // const [cid, setCid] = useState();
  const findCategoryId = (subCategoryId) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const category of CatDetails) {
      // hardcoded
      // for (const category of hardcodedDetailsData) {
      if (category.subCategories && Array.isArray(category.subCategories)) {
        // Find the subCategory that matches the subCategoryId
        const matchingSubCategory = category.subCategories.find(
          // eslint-disable-next-line no-loop-func
          (subCat) => subCat && subCat.subCategoryId === subCategoryId
        );

        if (matchingSubCategory) {
          // If a matching subCategory is found, print its categoryId
          // setCid(category.categoryId);
          return category.categoryId;

          // break; // Exit the loop if you found a match
        }
      }
    }
    // If no matching subCategory was found, return a default value (e.g., null)
    return null;
  };
  // const navigateSubCatDetails = (subCategory) => {
  //   const id = findCategoryId(subCategory.subCategoryId);

  //   navigate("/overview/category/subcategory", { state: { subCategory, id } });
  // };
  const navigateSubCatDetails = (subCategory) => {
    const id = findCategoryId(subCategory.subCategoryId);
    const cat = CatDetails.find((c) => c.categoryId === id);

    // hardcoded
    // const cat = hardcodedDetailsData.find((c) => c.categoryId === id);

    navigate("/overview/category/subcategory", { state: { subCategory, cat } });
  };

  // dialog box table columns
  const Dialogcolumns = [
    {
      id: "subCategoryName",
      accessorKey: "subCategoryName",
      size: 150,
      header: (
        <div style={{ whiteSpace: "normal", paddingTop: "9px" }}>
          <span
            style={{
              fontSize: "19px",
              cursor: "pointer",
              fontWeight: "bold",
              // textDecoration: "underline",
            }}
            className="dialog-head-title"
            onClick={() => navigateCategory(SingleCategoryNav)}
          >
            {SingleCategoryName}
          </span>
        </div>
      ),
      Cell: ({ cell }) => (
        <div
          // style={{ display: "flex", flexDirection: "row", alignItems: "end"  }}
          style={{ width: "330px" }}
        >
          <span
            style={{ fontSize: "15px", fontWeight: 500 }}
            className="table-head-title"
            onClick={() => navigateSubCatDetails(cell.row.original)}
          >
            {cell.row.original.subCategoryName}
          </span>
        </div>
      ),
    },
    {
      id: "appetiteType",
      accessorKey: "appetiteType",
      size: 10,
      // header: "Appetite Type",
      header: (
        <div style={{ width: "54px", whiteSpace: "normal", marginLeft: "5px" }}>
          <span>Appetite Type</span>
        </div>
      ),
      Cell: ({ cell }) => (
        <div
          // style={{ marginLeft: "7px" }}
          className={`dialog-appetitie ${getAppetiteClassName(cell.row.original.appetiteType)}`}
        >
          <span className="dialog-appetitie-text">{cell.row.original.appetiteType}</span>
        </div>
        // </div>
      ),
    },
    {
      id: "appetite",
      accessorKey: "appetite",
      // header: "Within Appetite",
      header: (
        <div style={{ width: "60px", whiteSpace: "normal", marginLeft: "5px" }}>
          <span>Within Appetite</span>
        </div>
      ),
      size: 10,
      Cell: ({ cell }) => (
        <div style={{ paddingLeft: "10px", width: "10px" }}>
          <span className={`${getTextColorClassName(cell.row.original.appetite)}`}>
            {cell.row.original.appetite}
          </span>
        </div>
      ),
    },
    {
      id: "assuranceProportionality",
      accessorKey: "assuranceProportionality",
      // header: "Assurance          Proportionality",
      size: 10,
      header: (
        <div style={{ whiteSpace: "normal", width: "95px" }}>
          <span>Assurance Proportionality</span>
        </div>
      ),
      Cell: ({ cell }) => (
        <div style={{ paddingLeft: "10px" }}>
          <span className={`${getTextColorClassName(cell.row.original.assuranceProportionality)}`}>
            {cell.row.original.assuranceProportionality}
          </span>
        </div>
      ),
    },
    {
      id: "aggregateExposure",
      accessorKey: "aggregateExposure",
      // header: "Aggregrate Exposure",
      header: (
        <div style={{ width: "74px", whiteSpace: "normal", marginLeft: "5px" }}>
          <span>Aggregrate Exposure</span>
        </div>
      ),
      size: 10,
      Cell: ({ cell }) => (
        <div style={{ paddingLeft: "7px" }}>
          <span>{cell.row.original.aggregateExposure}</span>
        </div>
      ),
    },
    {
      id: "trends",
      accessorKey: "trends",
      // header: "Trends",
      header: (
        <div style={{ width: "54px", whiteSpace: "normal", marginLeft: "5px", paddingTop: "9px" }}>
          <span>Trends</span>
        </div>
      ),
      size: 10,
      Cell: ({ cell }) => (
        <div style={{ paddingLeft: "7px" }}>
          <span>{cell.row.original.trends}</span>
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox bgColor="white" padding="15px" borderRadius="8px">
        <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
          <Grid item md={6}>
            <MDTypography fontWeight="medium" fontSize="21px" color="#263445" opacity="1">
              Overview- Risk at a glance
            </MDTypography>
            <MDTypography fontWeight="light" fontSize="15px" color="#8090AA" opacity="1">
              You can click on risk category to view the details.
            </MDTypography>
          </Grid>
          <Grid item md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <ToggleButtonGroup
              value={selectedOption}
              exclusive
              onChange={handleOptionChange}
              sx={{ height: "30px", width: "159px" }}
            >
              <ToggleButton
                value="Overview"
                selectedColor="#52009b"
                sx={{ textTransform: "capitalize", color: "#402899", borderColor: "#402899" }}
              >
                Overview
              </ToggleButton>
              <ToggleButton
                value="Details"
                selectedColor="#52009b"
                sx={{ textTransform: "capitalize", color: "#402899", borderColor: "#402899" }}
              >
                Details
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {selectedOption === "Overview" && (
            <Grid item>
              <Stack direction="row" spacing={2} sx={{ alignItems: "baseline" }}>
                <FormControl required>
                  <InputLabel id="taxonomy">Taxonomy</InputLabel>
                  <Select
                    sx={{
                      width: 230,
                      height: 42,
                      color: "black",
                    }}
                    label="Taxonomy"
                    name="Taxonomy"
                    onChange={handleTaxonomyChange}
                    value={(SingleTaxonomy && SingleTaxonomy) || ""}
                  >
                    {TaxonomyName &&
                      TaxonomyName.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Report Date"
                      value={ReportDate || today}
                      onChange={(newValue) => setReportDate(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Stack>
            </Grid>
          )}
        </Grid>

        {selectedOption === "Overview" ? (
          <>
            <hr className="hr-horizontal" />
            <Grid container columns={{ xs: 3, sm: 8, md: 12 }} sx={{ minHeight: "420px" }}>
              {/* {hardcodedOverview && hardcodedOverview.length > 0 ? (
                hardcodedOverview.map((category) => ( */}
              {overView && overView.length > 0 ? (
                overView.map((category) => (
                  <Grid item xs={4} md={4} lg={4}>
                    <Card
                      sx={{
                        bgcolor: "background.paper",
                        boxShadow: 2,
                        borderRadius: 2,
                        // minWidth: "331px",
                        maxWidth: 330,
                        my: 2,
                        mr: 2,
                        height: "250px",
                        opacity: 1,
                        paddingLeft: "19px",
                        // paddingTop: "10px",
                        // maxHeight: 200,
                        // overflow: "auto", // Add overflow property to enable scrolling
                      }}
                    >
                      <div className="card-header">
                        <div className="card-category-name">
                          {/* <Link href="#" onClick={handleOpenDialog}> */}
                          <span
                            className="card-category-span"
                            onClick={() => setCategoryData(category.categoryId)}
                          >
                            {category.categoryName}
                          </span>
                          {/* </Link> */}
                        </div>
                        {category.colorFlag === "green" && (
                          <div className="triangle-topright-green" />
                        )}
                        {category.colorFlag === "red" && <div className="triangle-topright-red" />}
                        {category.colorFlag === "amber" && (
                          <div className="triangle-topright-amber" />
                        )}
                        {category.colorFlag === "" && <div className="triangle-topright-green" />}

                        {/* <div className="triangle-topright-red" /> */}
                      </div>

                      {/* <Divider sx={{ ml: "5px", mr: "5px" }} /> */}

                      <div className="card-category">
                        <div className="card-category-num-div">
                          <span className="card-category-num">{category.appetite.count}</span>
                        </div>
                        <div style={{ height: "38px" }}>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <span className="card-categories">Subcategories</span>
                            <span className="card-appetite">{category.appetite.name}</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-category">
                        <div className="card-category-num-div">
                          <span className="card-category-num">
                            {category.assuranceProportionality.count}
                          </span>
                        </div>
                        <div style={{ height: "38px" }}>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <span className="card-categories">Assurance Proportionality</span>
                            <span className="card-appetite">
                              {category.assuranceProportionality.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card-category">
                        <div className="card-category-num-div">
                          <span className="card-category-num">
                            {category.aggregateExposure.count}
                          </span>
                        </div>
                        <div style={{ height: "38px" }}>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <span className="card-categories">Subcategories Having</span>
                            <span className="card-appetite">{category.aggregateExposure.name}</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-category">
                        <div className="card-category-num-div">
                          <span className="card-category-num">{category.trends.count}</span>
                        </div>
                        <div style={{ height: "38px" }}>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <span className="card-categories">Trend </span>
                            <span className="card-appetite">{category.trends.name}</span>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid container columns={{ xs: 3, sm: 8, md: 12 }}>
                  <Skeleton
                    variant="rectangular"
                    width={300}
                    height={250}
                    sx={{ mr: "8px", mt: "10px", borderRadius: 2 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={300}
                    height={250}
                    sx={{ mr: "8px", mt: "10px", borderRadius: 2 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={300}
                    height={250}
                    sx={{ mr: "8px", mt: "10px", borderRadius: 2 }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid>
              <KibanaEmbed />
            </Grid>
          </>
        ) : (
          <Grid container>
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mb: "10px",
              }}
            >
              <Stack direction="row" alignItems="baseline" spacing={2}>
                {/* appetite filter */}
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={filterOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={filterOpen ? "true" : undefined}
                    onClick={handleFilterClick}
                    variant="outlined"
                    // color="#402899"
                    sx={{
                      marginTop: " 8px",
                      width: "280px",
                      height: "44px",
                      borderColor: "#B5BFD0",
                      borderRadius: "4px",
                      color: "#402899",
                      textTransform: "capitalize",
                      padding: 0,
                    }}
                  >
                    Risk Appetite
                    <img
                      style={{ width: "23px", height: "23px", marginLeft: "56%" }}
                      src={arrow}
                      alt="img"
                    />
                    {/* <ArrowDropDownIcon sx={{ fontSize: "32px !important", ml: "130px" }} /> */}
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={filterAnchorEl}
                    open={filterOpen}
                    // onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{ style: { width: "240px" } }}
                  >
                    <div style={{ padding: "5px" }}>
                      <span style={{ fontWeight: "bold", color: "#263445" }}>
                        All Risk appetite
                      </span>
                    </div>
                    <hr className="hr-selectbox" style={{ marginBottom: "10px" }} />
                    {riskAppetiteOptions &&
                      riskAppetiteOptions.map((name) => (
                        <>
                          <Checkbox
                            checked={TempRiskAppetiteFilter.indexOf(name) > -1}
                            onChange={() => handleFilterChange(name)}
                          />
                          <span style={{ color: "#263445" }}>{name}</span>
                          <br />
                        </>
                      ))}
                    <hr
                      className="hr-selectbox"
                      style={{ marginBottom: "13px", marginTop: "13px" }}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        // height: "13px",
                        marginBottom: "5px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          color: "#402899",
                          width: "70px",
                          // height: "12px",
                          mr: "12px",
                          borderColor: "#402899",
                          borderRadius: "4px",
                          fontWeight: 500,
                        }}
                        size="small"
                        onClick={handleFilterClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          // width: "65px",
                          // height: "36px",
                          backgroundColor: "#402899",
                          color: "white !important",
                          fontWeight: 500,
                          borderRadius: "4px",
                        }}
                        size="small"
                        onClick={() => applyAppetiteFilter()}
                      >
                        Save
                      </Button>
                    </div>
                  </Menu>
                </div>

                {/* Risk limit filter */}
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    variant="outlined"
                    // color="#402899"
                    sx={{
                      marginTop: " 8px",
                      width: "280px",
                      height: "44px",
                      borderColor: "#B5BFD0",
                      borderRadius: "4px",
                      color: "#402899",
                      textTransform: "capitalize",
                      padding: "2px",
                    }}
                  >
                    <span> Risk Limit </span>
                    <img
                      style={{ width: "23px", height: "23px", marginLeft: "63%" }}
                      src={arrow}
                      alt="img"
                    />
                    {/* <ArrowDropDownIcon sx={{ fontSize: "32px !important", ml: "108px" }} /> */}
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    // onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{ style: { width: "740px" } }}
                  >
                    <div style={{ padding: "5px" }}>
                      <span style={{ fontWeight: "bold", color: "#263445" }}>
                        Select Risk Limit
                      </span>
                    </div>
                    <hr className="hr-selectbox" style={{ marginBottom: "10px" }} />
                    <Grid container>
                      <Grid item md={3}>
                        <span style={{ fontSize: "15px", color: "#7354CB" }}>Within Appetite</span>
                        {filterData &&
                          filterData.appetite.map((name) => (
                            <>
                              <br />
                              <Checkbox
                                checked={withinAppetite.indexOf(name) > -1}
                                onChange={() => handleCheckboxChange(name)}
                              />
                              <span style={{ color: "#263445", fontSize: "16px" }}>{name}</span>
                            </>
                          ))}
                      </Grid>
                      <Grid item md={3.5}>
                        <span style={{ fontSize: "15px", color: "#7354CB" }}>
                          Assurance Proportionality
                        </span>
                        {filterData &&
                          filterData.assuranceProportionality.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              sx={{
                                fontSize: "16px",
                                height: "22px",
                                padding: "19px",
                                paddingLeft: "00px",
                              }}
                            >
                              <Checkbox
                                checked={AssurProportionality.indexOf(name) > -1}
                                onChange={() => handleAssuranceCheckboxChange(name)}
                              />
                              <span style={{ color: "#263445" }}>{name}</span>
                              {/* <ListItemText primary={name} sx={{ fontSize: "14px" }} /> */}
                            </MenuItem>
                          ))}
                      </Grid>
                      <Grid item md={3}>
                        <span style={{ fontSize: "15px", color: "#7354CB" }}>
                          Aggregrate Exposure
                        </span>
                        {filterData &&
                          filterData.aggregateExposure.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              sx={{
                                fontSize: "16px",
                                height: "22px",
                                padding: "19px",
                                paddingLeft: "00px",
                              }}
                            >
                              <Checkbox
                                checked={AggExposure.indexOf(name) > -1}
                                onChange={() => handleAggregateCheckboxChange(name)}
                              />
                              <span style={{ color: "#263445" }}>{name}</span>
                              {/* <ListItemText primary={name} sx={{ fontSize: "14px" }} /> */}
                            </MenuItem>
                          ))}
                      </Grid>
                      <Grid item md={2.5}>
                        <span style={{ fontSize: "15px", color: "#7354CB" }}> Trends</span>
                        {filterData &&
                          filterData.trends.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              sx={{
                                fontSize: "16px",
                                height: "22px",
                                padding: "19px",
                                paddingLeft: "00px",
                              }}
                            >
                              <Checkbox
                                checked={Trend.indexOf(name) > -1}
                                onChange={() => handleTrendsCheckboxChange(name)}
                              />
                              <span style={{ color: "#263445" }}>{name}</span>
                              {/* <ListItemText primary={name} sx={{ fontSize: "14px" }} /> */}
                            </MenuItem>
                          ))}
                      </Grid>
                    </Grid>
                    <hr
                      className="hr-selectbox"
                      style={{ marginBottom: "13px", marginTop: "13px" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // height: "30px",
                      }}
                    >
                      <Button
                        sx={{ fontSize: "12px", color: "#402899" }}
                        onClick={() => handleClearFilters()}
                      >
                        Clear Filter
                      </Button>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          height: "13px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          sx={{
                            color: "#402899",
                            width: "70px",
                            // height: "12px",
                            mr: "12px",
                            borderColor: "#402899",
                            borderRadius: "4px",
                            fontWeight: 500,
                          }}
                          size="small"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            // width: "65px",
                            // height: "36px",
                            backgroundColor: "#402899",
                            color: "white !important",
                            fontWeight: 500,
                            borderRadius: "4px",
                          }}
                          size="small"
                          onClick={() => applyRiskFilter()}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Menu>
                </div>
              </Stack>
              {/* manage categories */}

              <div>
                <Button
                  id="basic-button"
                  aria-controls={Mopen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Mopen ? "true" : undefined}
                  onClick={handleManageClick}
                  variant="outlined"
                  // color="#402899"
                  // textTransform="normal"
                  sx={{
                    marginTop: " 8px",
                    width: "169px",
                    height: "30px",
                    borderColor: "#402899",
                    borderRadius: "4px",
                    color: "#402899",
                    textTransform: "capitalize",
                    padding: 0,
                  }}
                >
                  <img
                    style={{ width: "23px", height: "23px", marginRight: "10px" }}
                    src={settingIcon}
                    alt="img"
                  />
                  Manage Categories
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorCatEl}
                  open={Mopen}
                  // onClose={handleManageClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{ style: { width: "280px" } }}
                >
                  <div style={{ padding: "5px" }}>
                    <span style={{ fontWeight: "bold", color: "#263445" }}>
                      All Risk Categories
                    </span>
                  </div>
                  <hr className="hr-selectbox" style={{ marginBottom: "10px" }} />

                  {/* <Grid item md={3}> */}
                  {/* <span style={{ fontSize: "15px", color: "#7354CB" }}>Within Appetite</span> */}
                  {CatDetails &&
                    CatDetails.map((name) => (
                      <>
                        <br />
                        <Checkbox
                          checked={manageCategory.indexOf(name.categoryName) > -1}
                          onChange={() => handleCategoriesChange(name.categoryName)}
                        />
                        <span style={{ color: "#263445", fontSize: "16px" }}>
                          {name.categoryName}
                        </span>
                      </>
                    ))}
                  {/* </Grid> */}

                  <hr
                    className="hr-selectbox"
                    style={{ marginBottom: "13px", marginTop: "13px" }}
                  />
                  <div
                    style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#402899",
                        width: "70px",
                        // height: "12px",
                        mr: "12px",
                        borderColor: "#402899",
                        borderRadius: "4px",
                        fontWeight: 500,
                      }}
                      size="small"
                      onClick={handleManageClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        // width: "65px",
                        // height: "36px",
                        backgroundColor: "#402899",
                        color: "white !important",
                        fontWeight: 500,
                        borderRadius: "4px",
                      }}
                      size="small"
                      onClick={() => applyFilter()}
                    >
                      Save
                    </Button>
                  </div>
                </Menu>
              </div>
            </Grid>
            {/* <div style={{ marginTop: "10px", display: "flex", flexDirection: "row" }}> */}
            {/* within appetite filter bubble */}
            {RiskAppetiteFilter && RiskAppetiteFilter.length > 0 && (
              <div className="filter-bubble">
                <span>Risk Appetite: {RiskAppetiteFilter.join(", ")}</span>
                <CancelIcon
                  fontSize="large"
                  onClick={() => handleCross("riskAppetite")}
                  style={{ cursor: "pointer" }}
                  sx={{ width: "20px", height: "20px", color: "#40289999", mr: "7px" }}
                />
              </div>
            )}
            {TempwithinAppetite && TempwithinAppetite.length > 0 && (
              <div className="filter-bubble">
                <span>Within Appetite: {TempwithinAppetite.join(", ")}</span>
                <CancelIcon
                  fontSize="large"
                  onClick={() => handleCross("withinAppetite")}
                  style={{ cursor: "pointer" }}
                  sx={{ width: "20px", height: "20px", color: "#40289999", mr: "7px" }}
                />
              </div>
            )}
            {/* assurance proportionality filter bubble */}
            {TempAssurProportionality && TempAssurProportionality.length > 0 && (
              <div className="filter-bubble">
                <span>Assurance Proportionality: {TempAssurProportionality.join(", ")}</span>
                <CancelIcon
                  fontSize="large"
                  onClick={() => handleCross("assuranceProportionality")}
                  style={{ cursor: "pointer" }}
                  sx={{ width: "20px", height: "20px", color: "#40289999", mr: "7px" }}
                />
              </div>
            )}
            {/* aggregate exposure filter buble  */}
            {TempAggExposure && TempAggExposure.length > 0 && (
              <div className="filter-bubble">
                <span>Aggregate Exposure: {TempAggExposure.join(", ")}</span>
                <CancelIcon
                  fontSize="large"
                  onClick={() => handleCross("aggExposure")}
                  style={{ cursor: "pointer" }}
                  sx={{ width: "20px", height: "20px", color: "#40289999", mr: "7px" }}
                />
              </div>
            )}
            {/* trend filter bubble */}
            {TempTrend && TempTrend.length > 0 && (
              <div className="filter-bubble">
                <span>Trend : {TempTrend.join(", ")}</span>
                <CancelIcon
                  fontSize="large"
                  onClick={() => handleCross("trend")}
                  style={{ cursor: "pointer" }}
                  sx={{ width: "20px", height: "20px", color: "#40289999", mr: "7px" }}
                />
              </div>
            )}

            {((TempwithinAppetite && TempwithinAppetite.length > 0) ||
              (TempAssurProportionality && TempAssurProportionality.length > 0) ||
              (TempAggExposure && TempAggExposure.length > 0) ||
              (TempTrend && TempTrend.length > 0)) && (
              <Button
                sx={{ fontSize: "12px", color: "#402899" }}
                onClick={() => handleClearFilters()}
              >
                Clear Filter
              </Button>
            )}
            {/* </div> */}
            <hr className="hr-horizontal" style={{ width: "inherit", marginBottom: "20px" }} />

            {/* hardcoded */}
            {/* {hardcodedDetailsData &&
              hardcodedDetailsData.map((category) => ( */}
            {details &&
              details.map((category) => (
                <ThemeProvider theme={CustomHeightTable}>
                  <Grid item md={12}>
                    <Card sx={{ mb: "20px" }}>
                      <MaterialReactTable
                        // columns={Detailscolumns}
                        columns={[
                          {
                            id: "subCategoryName",
                            accessorKey: "subCategoryName",
                            header: (
                              <div style={{ width: "225px", whiteSpace: "normal" }}>
                                <span
                                  style={{
                                    fontSize: "19px",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    // textDecoration: "underline",
                                  }}
                                  className="dialog-head-title"
                                  onClick={() => navigateCategory(category)}
                                >
                                  {category.categoryName}
                                </span>
                              </div>
                            ),
                            size: 133,
                            Cell: ({ cell }) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "240px",
                                  alignItems: "end",
                                }}
                              >
                                <span
                                  style={{ fontSize: "15px", fontWeight: 500 }}
                                  className="table-head-title"
                                  onClick={() => navigateSubCatDetails(cell.row.original)}
                                >
                                  {cell.row.original.subCategoryName}
                                </span>
                              </div>
                            ),
                          },
                          {
                            id: "subCategoryName",
                            accessorKey: "subCategoryName",
                            size: 10,
                            // header: "Appetite Type",
                            header: (
                              <div style={{ width: "65px", whiteSpace: "normal" }}>
                                <span>Appetite Type</span>
                              </div>
                            ),
                            Cell: ({ cell }) => (
                              <div
                                style={{ marginLeft: "0px" }}
                                className={`dialog-appetitie ${getAppetiteClassName(
                                  cell.row.original.appetiteType
                                )}`}
                              >
                                <span className="dialog-appetitie-text">
                                  {cell.row.original.appetiteType}
                                </span>
                              </div>
                              // </div>
                            ),
                          },
                          {
                            id: "appetite",
                            accessorKey: "appetite",
                            // header: "Within Appetite",
                            header: (
                              <div
                                style={{ width: "60px", whiteSpace: "normal", marginLeft: "5px" }}
                              >
                                <span>Within Appetite</span>
                              </div>
                            ),
                            size: 10,
                            Cell: ({ cell }) => (
                              <div style={{ paddingLeft: "0px", width: "10px" }}>
                                <span
                                  className={`${getTextColorClassName(cell.row.original.appetite)}`}
                                >
                                  {cell.row.original.appetite}
                                </span>
                              </div>
                            ),
                          },
                          {
                            id: "assuranceProportionality",
                            accessorKey: "assuranceProportionality",
                            // header: "Assurance Proportionality",
                            header: (
                              <div style={{ width: "95px", whiteSpace: "normal" }}>
                                <span>Assurance Proportionality</span>
                              </div>
                            ),
                            size: 20,
                            Cell: ({ cell }) => (
                              <div style={{ paddingLeft: "0px" }}>
                                <span
                                  className={`${getTextColorClassName(
                                    cell.row.original.assuranceProportionality
                                  )}`}
                                >
                                  {cell.row.original.assuranceProportionality}
                                </span>
                              </div>
                            ),
                          },
                          {
                            id: "aggregateExposure",
                            accessorKey: "aggregateExposure",
                            // header: "Aggregrate Exposure",
                            header: (
                              <div style={{ width: "68px", whiteSpace: "normal" }}>
                                <span>Aggregate Exposure</span>
                              </div>
                            ),
                            size: 10,
                          },
                          {
                            id: "trends",
                            accessorKey: "trends",
                            header: "Trends",
                            size: 10,
                          },
                        ]}
                        data={category.subCategories}
                        enableTopToolbar={false}
                        enableColumnFilters={false}
                        enableColumnActions={false}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableSorting
                        // enableStickyHeader
                        sx={{
                          boxShadow: "2px",
                          // padding: "20px",
                          height: "100px !important",
                        }}
                        muiTableHeadCellProps={{
                          sx: {
                            // border: "none",
                            backgroundColor: "white !important",
                            fontWeight: "bold",
                            fontSize: "13px",
                            color: "#263445",
                            // textAlign: "center",
                            whiteSpace: "normal",
                          },
                        }}
                        muiTableBodyCellProps={{
                          sx: {
                            border: "none",
                            backgroundColor: "white !important",
                            fontSize: "13px",
                            padding: "5px !important",
                            paddingLeft: "15px !important",
                            textAlign: "left",
                          },
                        }}
                      />
                    </Card>
                  </Grid>
                </ThemeProvider>
              ))}
          </Grid>
        )}
      </MDBox>

      {/* dialog box code */}
      <ThemeProvider theme={NoborderTable}>
        <Dialog
          open={Dialogopen}
          onClose={handleCloseDialog}
          maxWidth="xl"
          fullWidth
          sx={{ pt: "10px", borderRadius: "none", paddingBottom: "1px" }}
          borderRadius="0px"
        >
          <Grid container sx={{ p: "20px", pb: "0px", display: "initial", pt: "10px" }}>
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              // sx={{ mt: "10px", ml: "15px" }}
            >
              {/* <Grid item>
                <span
                  className="dialog-head-title"
                  onClick={() => navigateCategory(SingleCategoryNav)}
                >
                  {SingleCategoryName}
                </span>
               
              </Grid> */}
              <Grid item>
                <CancelOutlinedIcon
                  fontSize="large"
                  onClick={handleCloseDialog}
                  style={{ cursor: "pointer" }}
                  sx={{ width: "24px", height: "24px", color: "#7354CB" }}
                />
              </Grid>
            </Grid>
            <MaterialReactTable
              columns={Dialogcolumns}
              data={SingleCategory || ""}
              enableTopToolbar={false}
              enableColumnFilters={false}
              enableColumnActions={false}
              enablePagination
              enableBottomToolbar
              initialState={{ pagination: { pageSize: 10 } }}
              enableSorting
              sx={{
                boxShadow: "none !important",
                width: "100%",
                // padding: "20px",
              }}
              muiTableHeadCellProps={{
                sx: {
                  // border: "none",
                  backgroundColor: "white !important",
                  fontWeight: "bold",
                  fontSize: "13px",
                  color: "#263445",
                  padding: "0px !important",
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  border: "none",
                  backgroundColor: "white !important",
                  fontSize: "13px",
                  padding: "1px !important",
                  pb: "7px !important",
                  pt: "2px !important",

                  // textAlign: "left",
                },
              }}
            />
          </Grid>
        </Dialog>
      </ThemeProvider>
    </DashboardLayout>
  );
}

export default ProtectDashboard;
