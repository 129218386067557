/* eslint-disable no-restricted-syntax */
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import React, { useState, useCallback, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Edit, Save, Cancel } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import "./protect.css";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { protectedResources, siteData } from "authConfig";
import DeleteIcon from "@mui/icons-material/Delete";
import MDBox from "components/MDBox";
import SettingCustomTable from "assets/theme/components/table/SettingCustomTable";

function AssProportionality() {
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });
  const [TaxonomyList, setTaxonomyList] = useState(null);
  const [SingleTaxonomy, setSingleTaxonomy] = useState("");
  const [selectedTaxonomyId, setSelectedTaxonomyId] = useState("");
  const [taxonomyId, setTaxonomyId] = useState(null);

  useEffect(() => {
    if (!TaxonomyList) {
      execute("GET", `${protectedResources.apiTodoList.endpoint}/taxonomy/listall`).then(
        (response) => {
          if (response && response.success === true && response.data.length > 0) {
            setTaxonomyList(response.data);
            setSingleTaxonomy(response.data[0].name);
          }
        }
      );
    }
  }, [execute, TaxonomyList]);

  useEffect(() => {
    setSelectedTaxonomyId(
      TaxonomyList && TaxonomyList.find((taxonomy) => taxonomy.name === SingleTaxonomy)
    );
  }, [SingleTaxonomy]);

  useEffect(() => {
    setTaxonomyId(selectedTaxonomyId && selectedTaxonomyId.id);
  }, [selectedTaxonomyId]);

  const TaxonomyName = TaxonomyList && TaxonomyList.map((nme) => nme.name);
  const handleInputChange = (event) => {
    setSingleTaxonomy(event.target.value);
  };

  const [TableData, setTableData] = useState([]);
  // get appetite type for column heading

  // appetite type list for column rendering
  const [appetiteType, setAppetiteType] = useState(null);

  const getTableData = () => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/assuranceProportionality/list/${taxonomyId}`
    ).then((response) => {
      if (response && response.success === true) {
        setTableData(response.data);
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  const [noData, setNoData] = useState(false);

  // create two entries
  const createData = () => {
    // if (noData === true) {
    try {
      const requestBody = {
        assuranceResourceName: "Allocation Range (%)",
        taxonomyId,
        assuranceResourceSetting: [],
      };

      // Make the CREATE API call

      execute(
        "POST",
        `${protectedResources.apiTodoList.endpoint}/assuranceProportionality/create`,
        requestBody
      ).then(() => {
        // getTableData();
      });
    } catch (error) {
      /* empty */
    }
    try {
      const requestBody = {
        assuranceResourceName: "Match Sensitivity Range",
        taxonomyId,
        assuranceResourceSetting: [],
      };

      // Make the CREATE API call

      execute(
        "POST",
        `${protectedResources.apiTodoList.endpoint}/assuranceProportionality/create`,
        requestBody
      ).then(() => {
        getTableData();
        // setNoData(true);
      });
    } catch (error) {
      /* empty */
    }
    // }
  };

  //  get data from api
  useEffect(() => {
    // if (noData === true) {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/assuranceProportionality/list/${taxonomyId}`
    ).then((response) => {
      setNoData(true);
      if (response && response.success === true && response.data.length > 0) {
        console.log(response.data);
        setTableData(response.data);
      } else {
        createData();
      }
    });
    // }
  }, [taxonomyId, execute]);

  useEffect(() => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/appetite/list`).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setAppetiteType(response.data);
      }
    });
  }, [execute]);

  // Update the edited data for each row using the index as the key
  const [updatedData, setUpdatedData] = useState([]);

  // for save button rendering
  const [editRow, setIsEdit] = useState();
  useEffect(() => {
    if (TableData) {
      //   setUpdatedData(TableData);
      const tableData = TableData
        ? TableData.map((data) => ({
            assuranceResourceName: data.assuranceResourceName,
            id: data.id,

            ...data.assuranceResourceSetting[0],
          }))
        : [];
      setUpdatedData(tableData);
    }
  }, [TableData]);

  const [IsNew, setIsNew] = useState(false);

  console.log("TableData", TableData);

  // handle input change for editing row
  const [validationErrors, setValidationErrors] = useState({});
  const [editObject, setEditObject] = useState(null);
  const [isSuccess, setisSuccess] = useState(false);

  // taking input from row in table
  const getCommonEditTextFieldProps = useCallback(
    (cell) => ({
      error: !!validationErrors[cell.id],
      helperText: validationErrors[cell.id],
      onBlur: (event) => {
        const { row, column } = cell;
        const rowId = row.original.id;
        const updatedRowElement = updatedData.find((item) => item.id === rowId);

        if (updatedRowElement) {
          const { value } = event.target;
          const columnName = column.id;

          // Check if the column name is "name" (Considerations)

          const isValueValid = value.trim() !== ""; // Check if non-empty

          const updatedRowData = {
            ...updatedRowElement,
            [columnName]: value,
          };

          setEditObject(updatedRowData);

          setUpdatedData((prevData) =>
            prevData.map((item) => (item.id === rowId ? updatedRowData : item))
          );

          if (!isValueValid) {
            setisSuccess(true);

            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            delete validationErrors[cell.id];
            setisSuccess(false);

            setValidationErrors({
              ...validationErrors,
            });
          }
        }
      },
    }),
    [updatedData, setEditObject]
  );

  // dynamic columns

  const [conditionalColumns, setConditionalColumns] = useState();

  useEffect(() => {
    const col = appetiteType
      ? [
          {
            id: "assuranceResourceName",
            accessorKey: "assuranceResourceName",
            header: "Assurance Resource",
            size: 140,
            muiTableBodyCellEditTextFieldProps: {
              editable: false,
              disabled: true,
            },
            // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            //   ...getCommonEditTextFieldProps(cell),
            // }),
          },
          ...appetiteType.map((riskType) => ({
            id: riskType.slug,
            accessorKey: riskType.slug,
            header: riskType.name,
            size: 140,
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
              ...getCommonEditTextFieldProps(cell),
            }),
          })),
        ]
      : [];

    setConditionalColumns(col);
  }, [appetiteType, getCommonEditTextFieldProps]);

  // Concatenate the initial columns array with the conditional columns

  // const columns = conditionalColumns && [...fcolumns, ...conditionalColumns];
  const columns = conditionalColumns && [...conditionalColumns];

  // Function to check if a string is empty
  const isEmpty = (value) => {
    if (typeof value === "string") {
      const isSucc = value.trim() !== "";
      return !isSucc;
    }
    return false;
  };

  const hasEmptyValues = (obj) => {
    for (const key in obj) {
      if (isEmpty(obj[key])) {
        return true;
      }
    }
    return false;
  };

  const IsEmptyEntry = () => {
    const hasEmptyValuesInData = updatedData.some((obj) => hasEmptyValues(obj));

    return hasEmptyValuesInData;
  };

  // saving the edited row/ adding new row
  const handleSaveRow = async () => {
    if (editObject) {
      const riskAppetiteSettingObj =
        editObject &&
        Object.keys(editObject).reduce((acc, key) => {
          if (key !== "assuranceResourceName" && key !== "isnew" && key !== "id") {
            acc[key] = editObject[key];
          }
          return acc;
        }, {});

      // Wrap the new object inside an array to get the riskAppetiteSetting array
      const riskAppetiteSet = [riskAppetiteSettingObj];

      if (!Object.keys(validationErrors).length) {
        try {
          const requestBody = {
            assuranceResourceName: editObject.assuranceResourceName,
            taxonomyId,
            assuranceResourceSetting: riskAppetiteSet,
          };

          // Make the PUT API call

          await execute(
            "PUT",
            `${protectedResources.apiTodoList.endpoint}/assuranceProportionality/update/${editObject.id}`,
            requestBody
          ).then(() => {
            getTableData();
            setIsEdit(null);
            setIsNew(false);
            setEditObject(null);

            // setisSuccess(true);
          });
        } catch (error) {
          /* empty */
        }
      }
    } else {
      setIsEdit(null);
      setIsNew(false);
    }
  };

  const handleCancelRowEdits = () => {
    getTableData();
    setIsNew(false);
    // setIsEdit(null);
    setValidationErrors({});
  };
  // delete a row
  const handleDeleteRow = (row) => {
    const { id } = row.original;

    if (row.original.isnew) {
      handleCancelRowEdits();
    } else {
      try {
        execute(
          "DELETE",
          `${protectedResources.apiTodoList.endpoint}/assuranceProportionality/delete/${id}`
        ).then(() => {
          getTableData();
        });
      } catch {
        /* empty */
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        style={{ borderRadius: "5px", padding: "15px", backgroundColor: "white", height: "520px" }}
      >
        <Grid container spacing={2} md={12} sm={12} xs={12}>
          <Grid item md={12} sm={12} xs={12}>
            <Typography sx={{ pb: "5px", fontWeight: 500 }} fontSize="21px">
              Assurance Proportionality Setting
            </Typography>
          </Grid>
          <Grid item sx={{ mr: "12px" }}>
            <FormControl required>
              <InputLabel id="taxonomy">Taxonomy</InputLabel>
              <Select
                sx={{
                  width: "280px",
                  height: "44px",
                }}
                label="Taxonomy"
                name="Taxonomy"
                onChange={handleInputChange}
                value={(SingleTaxonomy && SingleTaxonomy) || ""}
              >
                {TaxonomyName &&
                  TaxonomyName.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          {conditionalColumns && conditionalColumns.length > 0 ? (
            <Grid container sx={{ mt: "20px", ml: "14px" }}>
              <Grid item md={12}>
                <ThemeProvider theme={SettingCustomTable}>
                  {conditionalColumns.length > 0 && (
                    <MaterialReactTable
                      columns={columns}
                      data={updatedData || ""}
                      editingMode="row" // Use "row" editing mode for inline editing
                      //   enableColumnOrdering
                      enableEditing
                      muiTableHeadCellProps={{
                        sx: {
                          backgroundColor: "#F7F8FF !important",
                        },
                      }}
                      enableColumnActions={false}
                      enableTopToolbar={false}
                      onEditingRowCancel={handleCancelRowEdits}
                      positionActionsColumn="last"
                      renderRowActions={({ row, table }) => {
                        const isRowEditing = row.original.id === editRow;

                        if (IsNew === true) {
                          table.setEditingRow(row);
                          // setIsEdit(row.id);
                        }

                        return (
                          <Box sx={{ display: "flex" }}>
                            {isRowEditing ? (
                              <>
                                <Tooltip arrow title="Save">
                                  <IconButton
                                    onClick={() => {
                                      // handleSaveRow(row);
                                      // // setIsEdit(null);
                                      // if (!isSuccess) {
                                      //   table.setEditingRow(null);
                                      // }
                                      // // table.setEditingRow(null);

                                      // setIsNew(false);
                                      const a = IsEmptyEntry();

                                      if (a === false) {
                                        handleSaveRow(row);
                                        if (isSuccess === false) {
                                          table.setEditingRow(null);
                                          setIsNew(false);
                                        }
                                      }
                                    }}
                                  >
                                    <Save />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip arrow title="Cancel">
                                  <IconButton
                                    onClick={() => {
                                      handleCancelRowEdits();
                                      setIsEdit(null);
                                      table.setEditingRow(null);
                                      setIsNew(false);
                                    }}
                                  >
                                    <Cancel />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <Tooltip arrow title="Edit">
                                <IconButton
                                  onClick={() => {
                                    table.setEditingRow(row);
                                    setIsEdit(row.original.id);
                                    setIsNew(false);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}

                            <Tooltip arrow title="Delete">
                              <IconButton
                                // color="error"
                                onClick={() => handleDeleteRow(row)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        );
                      }}
                    />
                  )}
                </ThemeProvider>
              </Grid>
            </Grid>
          ) : (
            <div style={{ padding: "40px", marginTop: "30px" }}>
              <span>You dont have default Assurance Proportionality settings configured</span>
            </div>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AssProportionality;
