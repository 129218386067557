/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { MaterialReactTable } from "material-react-table";
import MDTypography from "components/MDTypography";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { loginRequest, protectedResources, siteData } from "../../authConfig";

function KibanaEmbed() {
  return (
    <div className="App">
      <iframe
        src="https://thsenseriskelastic.kb.australiaeast.azure.elastic-cloud.com:9243/app/dashboards?auth_provider_hint=anonymous1#/view/e8d05760-50fd-11ee-bf00-015113ecf216?embed=true&_g=(refreshInterval:(pause:!t,value:60000),time:(from:now-15w,to:now))&_a=()"
        height="800"
        width="100%"
        frameBorder="0"
      />
    </div>
  );
}

function AuditListContent() {
  console.log("siteData?.apiScopes", siteData?.apiScopes);
  const { error, execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  const [auditData, setAuditData] = useState(null);
  const columns = [
    {
      accessorKey: "name", // access nested data with dot notation
      header: "Audit",
      size: 150,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 150,
    },
    {
      accessorKey: "rating", // normal accessorKey
      header: "Rating",
      size: 200,
    },
    {
      accessorKey: "Sub Category",
      header: "Sub Category",
      size: 150,
    },
    {
      accessorKey: "File",
      header: "File",
      size: 150,
    },
  ];
  useEffect(() => {
    if (!auditData) {
      execute("GET", `${protectedResources.apiTodoList.endpoint}/auditdata`).then((response) => {
        setAuditData(response);
      });
    }
  }, [execute, auditData]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {auditData ? (
        <>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Audit Records
            </MDTypography>
          </MDBox>
          <MaterialReactTable
            columns={columns}
            data={auditData}
            initialState={{
              density: "compact",
            }}
          />
        </>
      ) : null}
    </>
  );
}

function Dashboard() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
        >
          <KibanaEmbed />
        </MsalAuthenticationTemplate>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
