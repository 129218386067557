/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useCallback, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  ThemeProvider,
  Typography,
  //   Button,
} from "@mui/material";
import { Edit, Save, Cancel } from "@mui/icons-material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "./protect.css";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { protectedResources, siteData } from "authConfig";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingCustomTable from "assets/theme/components/table/SettingCustomTable";
import MDBox from "components/MDBox";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));
function LikelihoodCriteria() {
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });
  const [updatedData, setUpdatedData] = useState([]);

  const [TaxonomyList, setTaxonomyList] = useState(null);
  const [SingleTaxonomy, setSingleTaxonomy] = useState("");
  const [selectedTaxonomyId, setSelectedTaxonomyId] = useState("");
  const [taxonomyId, setTaxonomyId] = useState("");
  const [editRow, setIsEdit] = useState();
  const [IsNew, setIsNew] = useState(false);

  useEffect(() => {
    if (!TaxonomyList) {
      execute("GET", `${protectedResources.apiTodoList.endpoint}/taxonomy/listall`).then(
        (response) => {
          if (response && response.success === true && response.data.length > 0) {
            setTaxonomyList(response.data);
            setSingleTaxonomy(response.data[0].name);
          }
        }
      );
    }
  }, [execute, TaxonomyList]);

  useEffect(() => {
    setSelectedTaxonomyId(
      TaxonomyList && TaxonomyList.find((taxonomy) => taxonomy.name === SingleTaxonomy)
    );
  }, [SingleTaxonomy]);

  useEffect(() => {
    setTaxonomyId(selectedTaxonomyId && selectedTaxonomyId.id);
  }, [selectedTaxonomyId]);

  const getTableData = () => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/likelihoodcriteria/list/${taxonomyId}`
    ).then((response) => {
      if (response && response.success === true) {
        setUpdatedData(response.data);
      }
    });
  };
  //    get data from api
  useEffect(() => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/likelihoodcriteria/list/${taxonomyId}`
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setUpdatedData(response.data);
      }
    });
  }, [execute, taxonomyId]);

  const handleInputChange = (event) => {
    setSingleTaxonomy(event.target.value);
    setSelectedTaxonomyId(
      TaxonomyList && TaxonomyList.find((taxonomy) => taxonomy.name === SingleTaxonomy)
    );
  };

  const TaxonomyName = TaxonomyList && TaxonomyList.map((nme) => nme.name);
  // handle input change for editing row
  const [validationErrors, setValidationErrors] = useState({});
  const [isSuccess, setisSuccess] = useState(false);

  // Save the updatedRowData in the editObject state

  const validateRequired = (value) => value.trim() !== ""; // Check for non-empty and non-blank input

  const getCommonEditTextFieldProps = useCallback(
    (cell) => ({
      error: !!validationErrors[cell.id],
      helperText: validationErrors[cell.id],

      onBlur: (event) => {
        const { row, column } = cell;
        const rowIndex = row.index; // Get the index of the row element being updated
        const updatedRowElement = updatedData[rowIndex]; // Find the array element using the index

        // Update the edited data for the specific row element
        const isValid = validateRequired(event.target.value);
        if (updatedRowElement !== undefined) {
          const updatedRowData = {
            ...updatedRowElement,
            [column.id]: event.target.value,
          };

          // Save the updatedRowData in the editObject state

          setUpdatedData((prevData) =>
            prevData.map((item, index) => (index === rowIndex ? updatedRowData : item))
          );
          if (!isValid) {
            // set validation error for cell if invalid
            setisSuccess(true);
            if (cell.column.id === "name" || cell.column.id === "factor") {
              setValidationErrors({
                ...validationErrors,

                [cell.id]: `${cell.column.columnDef.header} is required`,
              });
            } else {
              setValidationErrors({
                ...validationErrors,

                [cell.id]: `${cell.column.columnDef.header.props.children[0]} is required`,
              });
            }
          } else {
            // remove validation error for cell if valid
            delete validationErrors[cell.id];
            setisSuccess(false);

            setValidationErrors({
              ...validationErrors,
            });
          }
        }
      },
    }),
    [updatedData, validationErrors]
  );

  const checkValid = (row) => {
    const validationMessages = [];
    if (row.incidentData === "") {
      validationMessages.push("Incident data is empty");
    }
    if (row.inspectionData === "") {
      validationMessages.push("Inspection data is empty");
    }
    if (row.riskData === "") {
      validationMessages.push("Risk data is empty");
    }
    if (row.name === "") {
      validationMessages.push("Name is empty");
    }
  };

  const columns = [
    {
      id: "name",
      accessorKey: "name",
      header: "Likelihood Name",
      size: 10,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      id: "factor",
      accessorKey: "factor",
      header: "Likelihood Factor",
      size: 50,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      id: "riskData",
      accessorKey: "riskData",
      //   header: "Risk Description",
      header: (
        <div style={{ display: "flex", alignItems: "center" }}>
          Risk Description
          <LightTooltip title="message">
            <InfoOutlinedIcon
              //   onClick={handleClick}
              sx={{ ml: "10px", height: "25px", width: "22px" }}
            />
          </LightTooltip>
        </div>
      ),
      size: 260,

      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      id: "incidentData",
      accessorKey: "incidentData",
      //   header: "Incident Description",
      header: (
        <div style={{ display: "flex", alignItems: "center" }}>
          Incident Description
          <LightTooltip title="message">
            <InfoOutlinedIcon
              //   onClick={handleClick}
              sx={{ ml: "10px", height: "25px", width: "22px" }}
            />
          </LightTooltip>
        </div>
      ),
      size: 260,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      id: "inspectionData",
      accessorKey: "inspectionData",
      //   header: "Inspection Description",
      header: (
        <div style={{ display: "flex", alignItems: "center" }}>
          Inspection Description
          <LightTooltip title="message">
            <InfoOutlinedIcon
              //   onClick={handleClick}
              sx={{ ml: "10px", height: "25px", width: "22px" }}
            />
          </LightTooltip>
        </div>
      ),
      size: 260,
      required: true,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
  ];

  //   const handleSelectChange = (event) => {};
  // saving the edited row
  const handleAddButtonClick = () => {
    const newEmptyRow = {
      id: uuidv4(),
      name: "",
      isnew: true,
      factor: "",
      riskData: "",
      incidentData: "",
      inspectionData: "",
    };

    // Update the state of data
    checkValid(newEmptyRow);
    setUpdatedData((prevData) => [...prevData, newEmptyRow]);
    setIsNew(true);
    setIsEdit(newEmptyRow.id);
  };

  // Function to check if a string is empty
  const isEmpty = (str) => {
    const isSucc = str.trim() !== "";
    setisSuccess(isSucc);
    return !isSucc;
  };

  const IsEmptyEntry = () => {
    const hasEmptyNames = updatedData.some(
      (obj) =>
        isEmpty(obj.name) ||
        isEmpty(obj.factor) ||
        isEmpty(obj.inspectionData) ||
        isEmpty(obj.riskData) ||
        isEmpty(obj.incidentData)
    );
    return hasEmptyNames;
  };

  const handleSaveRow = async (row) => {
    const rowData = updatedData[row.index];
    if (rowData.isnew) {
      if (!Object.keys(validationErrors).length) {
        try {
          const requestBody = {
            name: rowData.name,
            factor: rowData.factor,
            taxonomyId,
            riskData: rowData.riskData,
            incidentData: rowData.incidentData,
            inspectionData: rowData.inspectionData,
          };

          // Make the POST API call
          execute(
            "POST",
            `${protectedResources.apiTodoList.endpoint}/likelihoodcriteria/create`,
            requestBody
          ).then(() => {
            getTableData();
            setIsEdit(null);
          });
        } catch (error) {
          /* empty */
        }
      }
    } else if (!Object.keys(validationErrors).length) {
      try {
        const requestBody = {
          name: rowData.name,
          factor: rowData.factor,
          taxonomyId,
          riskData: rowData.riskData,
          incidentData: rowData.incidentData,
          inspectionData: rowData.inspectionData,
        };

        execute(
          "PUT",
          `${protectedResources.apiTodoList.endpoint}/likelihoodcriteria/update/${rowData.id}`,
          requestBody
        ).then(() => {
          getTableData();
          setIsEdit(null);
        });
      } catch (error) {
        /* empty */
      }
    }
  };

  // cancel editing row
  const handleCancelRowEdits = () => {
    getTableData();
    setIsNew(false);

    setValidationErrors({});
  };
  // delete a row
  const handleDeleteRow = (row) => {
    const { id } = row.original;
    if (row.original.isnew) {
      handleCancelRowEdits();
    } else {
      try {
        execute(
          "DELETE",
          `${protectedResources.apiTodoList.endpoint}/likelihoodcriteria/delete/${id}`
        ).then(() => {
          getTableData();
        });
      } catch {
        /* empty */
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        style={{ borderRadius: "5px", padding: "15px", backgroundColor: "white", height: "510px" }}
      >
        <Grid container spacing={2} md={12} sm={12} xs={12}>
          <Grid item md={12} sm={12} xs={12}>
            <Typography sx={{ pb: "5px", fontWeight: 500 }} fontSize="21px">
              Likelihood Criteria Setting
            </Typography>
          </Grid>
          <Grid item md={12}>
            <FormControl required>
              <InputLabel id="taxonomy">Taxonomy</InputLabel>
              <Select
                sx={{
                  width: "280px",
                  height: "44px",
                }}
                label="Taxonomy"
                name="Taxonomy"
                onChange={handleInputChange}
                value={(SingleTaxonomy && SingleTaxonomy) || ""}
              >
                {TaxonomyName &&
                  TaxonomyName.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <ThemeProvider theme={SettingCustomTable}>
              <MaterialReactTable
                columns={columns}
                data={updatedData || ""}
                editingMode="row" // Use "row" editing mode for inline editing
                //   enableColumnOrdering
                enableColumnFilters={false}
                enableColumnActions={false}
                enableEditing
                enableTopToolbar={false}
                //   onEditingRowCancel={handleCancelRowEdits}
                muiTableHeadCellProps={{
                  sx: {
                    backgroundColor: "#F7F8FF !important",
                  },
                }}
                positionActionsColumn="last"
                renderRowActions={({ row, table }) => {
                  const isRowEditing = row.original.id === editRow;
                  if (IsNew === true) {
                    table.setEditingRow(row);
                    // setIsEdit(row.id);
                  }
                  return (
                    <Box sx={{ display: "flex" }}>
                      {isRowEditing ? (
                        <>
                          <Tooltip arrow title="Save">
                            <IconButton
                              onClick={() => {
                                const a = IsEmptyEntry();
                                // console.log(a);

                                if (a === false) {
                                  handleSaveRow(row);
                                  if (isSuccess === false) {
                                    // setIsEdit(null);
                                    table.setEditingRow(null);
                                    setIsNew(false);
                                  }
                                }
                              }}
                            >
                              <Save />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Cancel">
                            <IconButton
                              onClick={() => {
                                handleCancelRowEdits();
                                setIsEdit(null);
                                table.setEditingRow(null);
                                setIsNew(false);
                              }}
                            >
                              <Cancel />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip arrow title="Edit">
                          <IconButton
                            onClick={() => {
                              table.setEditingRow(row);
                              setIsEdit(row.original.id);
                              setIsNew(false);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip arrow title="Delete">
                        <IconButton onClick={() => handleDeleteRow(row)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  );
                }}
              />
            </ThemeProvider>
          </Grid>
          <Grid item md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            {IsNew === false && (
              <Button
                variant="outlined"
                onClick={() => handleAddButtonClick()}
                sx={{
                  color: "#402899",
                  marginTop: "20px",
                  borderColor: "#402899",
                  borderRadius: "4px",
                  mr: "40px",
                }}
              >
                +Add New
              </Button>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default LikelihoodCriteria;
