/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import MDBox from "components/MDBox";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomHeightTable from "assets/theme/components/table/CustomHeightTable";
import "./ProtectDashboard.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  // Card,
  Grid,
  Slider,
  //   Slider,
  Stack,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled, ThemeProvider } from "@mui/material/styles";
import MDTypography from "components/MDTypography";
import MuiToggleButton from "@mui/material/ToggleButton";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { siteData, protectedResources } from "authConfig";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import upperLimit from "../../assets/images/icons/projectIcons/Path 87.svg";
import diamond from "../../assets/images/icons/projectIcons/Rectangle 1011.svg";
// toggle button css
const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: selectedColor,
  },
}));

// tooltip and info btn in influential factor
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

function CategoryDetails() {
  const location = useLocation();
  const category = location.state;

  // hardcoded data for clinical sub category - KRI
  // const clinicalKRI = [
  //   {
  //     categoryId: "44355483-4237-4e1e-8156-3e387a7b16bd",
  //     subCategoryId: "22423643-4887-4148-bb54-55c1ef8bdc98",
  //     name: "Adverse Events Rate",
  //     subCategoryName: "Clinical/ Medical Performance",
  //     description: "The number of adverse incidents reported per patient admitted",
  //   },
  //   {
  //     categoryId: "44355483-4237-4e1e-8156-3e387a7b16bd",
  //     subCategoryId: "22423643-4887-4148-bb54-55c1ef8bdc98",
  //     name: "Readmission Rate",
  //     description: "The rate at which patients are readmitted after discharge",
  //   },
  //   {
  //     categoryId: "44355483-4237-4e1e-8156-3e387a7b16bd",
  //     subCategoryId: "22423643-4887-4148-bb54-55c1ef8bdc98",
  //     name: "Length of stay(LOS)",
  //     description: "Average length of stay rates for all admitted patients",
  //   },
  // ];

  // hardcoded data for hospital  category- influential factor
  // const hospitalData = [
  //   {
  //     taxonomyId: "64b51c6008fa7be5080de7e4",
  //     categoryId: "44355483-4237-4e1e-8156-3e387a7b16bd",
  //     subCategoryId: "22423643-4887-4148-bb54-55c1ef8bdc98",
  //     exposure_noOfRisks: "343",
  //     exposure_maximumExposure: ">$3M",
  //     exposure_aggregateExposure: "40M",
  //     exposure_percentageOfRisk: "7.5%",
  //     assurance_noOfRecords: "15",
  //     assurance_companyWideAssurance: "2.75%",
  //     assurance_finding: {
  //       Good: "01",
  //       Satisfactory: "05",
  //       Marginal: "08",
  //       Weak: "01",
  //     },
  //     incident_count: "9849",
  //     incident_companyWideReport: "53.8%",
  //     incident_incidentLevel: {
  //       1: "72",
  //       2: "142",
  //       3: "4310",
  //       4: "5326",
  //       // 5: "4",
  //     },
  //     resource_totalHeadCount: "00",
  //     resource_companyWideHeadcount: "00",
  //     resource_totalBudget: "00",
  //     resource_companyWideBudget: "00",
  //     level_rating: "Partially prescriptive",
  //     level_ratingSymbol: "checkmark",
  //     id: "6502da2d61aea71a68fa5c3a",
  //   },
  // ];

  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  // tab selection
  const [selectedOption, setSelectedOption] = useState("Key Risk Indicator");
  //  for tab input change
  const handleOptionChange = (event, option) => {
    if (option !== null) {
      setSelectedOption(option);
    }
  };

  const [taxonomyId, setTaxonomyId] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(null);

  useEffect(() => {
    if (!taxonomyId) {
      execute("GET", `${protectedResources.apiTodoList.endpoint}/taxonomy/listall`).then(
        (response) => {
          if (response && response.success === true && response.data.length > 0) {
            setTaxonomyId(response.data[0].id);
            setCurrencySymbol(response.data[0].currencyData.currencySymbol);
          }
        }
      );
    }
  }, [execute, taxonomyId]);

  // kri api call
  const [KriDetails, setKriDetails] = useState([]);
  const [KriDet, setKriDet] = useState([]);

  useEffect(() => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/protect/KRI/list/${taxonomyId}/${category.categoryId}`
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setKriDet(response.data);
      }
    });
  }, [taxonomyId, category.categoryId]);

  useEffect(() => {
    if (KriDet) {
      setKriDetails(KriDet.filter((det) => det.categoryId === category.categoryId));
    }
  }, [KriDet]);

  // useEffect(() => {
  //   if (category.categoryId === clinicalKRI[0].categoryId) {
  //     setKriDetails(clinicalKRI);
  //   }
  // }, [clinicalKRI, category.categoryId]);

  const [InfluentialFactors, setInfluentialFactors] = useState(null);
  // const [InfDet, setInfDet] = useState([]);

  useEffect(() => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/protect/influentialfactor/list/${taxonomyId}/${category.categoryId}`
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        // setInfDet(response.data);
        setInfluentialFactors(response.data[0]);
      }
    });
  }, [taxonomyId, category.categoryId]);

  // useEffect(() => {
  //   if (hospitalData[0].categoryId === category.categoryId) {
  //     setInfluentialFactors(hospitalData[0]);
  //   }
  // }, [hospitalData, category.categoryId]);

  // get bubble color of risk appetite
  function getAppetiteClassName(appetite) {
    switch (appetite) {
      case "Risk Avoidant":
        return "dialog-appetitie-avoidant";
      case "Risk Averse":
        return "dialog-appetitie-avoidant";
      case "Risk Balanced":
        return "dialog-appetitie-balanced";
      case "Risk Seeking":
        return "dialog-appetitie-seeking";
      default:
        return "dialog-appetitie-default"; // Default class name if none of the conditions match
    }
  }

  // get appetite color
  function getTextColorClassName(appetite) {
    switch (appetite) {
      case "Over":
        return "table-over";
      case "Within":
        return "table-within";
      case "Under":
        return "table-under";
      default:
        return "dialog-appetitie-default"; // Default class name if none of the conditions match
    }
  }

  // navigate to sub category details page
  const navigate = useNavigate();
  const navigateSubCategory = (subCategory) => {
    const cat = category;
    // const categName = category.categoryName;
    navigate("/overview/category/subcategory", { state: { cat, subCategory } });
  };

  // details card view table column definition
  const Detailscolumns = [
    {
      id: "subCategoryName",
      accessorKey: "subCategoryName",
      header: (
        <span
          style={{
            fontSize: "21px",
            color: "#263445",
            fontWeight: "bold",
            // textDecoration: "underline",
          }}
        >
          Strategic Position
        </span>
      ),
      //   size: 400,
      Cell: ({ cell }) => (
        <div style={{ display: "flex", flexDirection: "row", width: "250px", alignItems: "end" }}>
          <span className="table-head-title" onClick={() => navigateSubCategory(cell.row.original)}>
            {cell.row.original.subCategoryName}
          </span>
        </div>
      ),
    },
    {
      id: "subCategoryName",
      accessorKey: "subCategoryName",
      size: 10,
      header: "Appetite Type",
      Cell: ({ cell }) => (
        <div
          style={{ marginLeft: "7px" }}
          className={`dialog-appetitie ${getAppetiteClassName(cell.row.original.appetiteType)}`}
        >
          <span className="dialog-appetitie-text">{cell.row.original.appetiteType}</span>
        </div>
        // </div>
      ),
    },
    {
      id: "appetite",
      accessorKey: "appetite",
      header: "Within Appetite",
      size: 10,
      Cell: ({ cell }) => (
        <div style={{ paddingLeft: "20px", width: "10px" }}>
          <span className={`${getTextColorClassName(cell.row.original.appetite)}`}>
            {cell.row.original.appetite}
          </span>
        </div>
      ),
    },
    {
      id: "assuranceProportionality",
      accessorKey: "assuranceProportionality",
      header: "Assurance Proportionality",
      size: 20,
      Cell: ({ cell }) => (
        <div style={{ paddingLeft: "20px" }}>
          <span className={`${getTextColorClassName(cell.row.original.assuranceProportionality)}`}>
            {cell.row.original.assuranceProportionality}
          </span>
        </div>
      ),
    },
    {
      id: "aggregateExposure",
      accessorKey: "aggregateExposure",
      header: "Aggregrate        Exposure",
      size: 10,
    },
    {
      id: "trends",
      accessorKey: "trends",
      header: "Trends",
      size: 10,
    },
  ];

  // navigate to exposure page
  const handleExposureNavigate = (name) => {
    // const catId = category.categoryId;
    // const catName = category.categoryName;
    const cat = category;
    const expType = name;
    const TaxonomyId = taxonomyId;
    navigate("/overview/category/exposure", { state: { TaxonomyId, expType, cat } });
  };

  // slider code
  // const marks = [
  //   {
  //     value: 0,
  //     label: "0°C",
  //   },
  //   {
  //     value: 20,
  //     label: "20°C",
  //   },
  //   {
  //     value: 37,
  //     label: "37°C",
  //   },
  //   {
  //     value: 100,
  //     label: "100°C",
  //   },
  // ];

  const CustomSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
    height: 2,
    padding: "15px 0",
    "& .MuiSlider-thumb": {
      height: "11px",
      width: "11px",
      // backgroundColor: "#fff",
      background: diamond,
      // transform: rota(45),
      borderRadius: "none !important",
    },
    "& .MuiSlider-valueLabel": {
      fontSize: 12,
      fontWeight: "normal",
      top: -6,
      backgroundColor: "unset",
      color: theme.palette.text.primary,
      "&:before": {
        display: "none",
      },
      "& *": {
        background: "transparent",
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
      },
    },
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-rail": {
      opacity: 0.5,
      backgroundColor: "#bfbfbf",
    },
    "& .MuiSlider-mark": {
      backgroundColor: "#bfbfbf",
      height: 8,
      width: 1,
      "&.MuiSlider-markActive": {
        opacity: 1,
        backgroundColor: "currentColor",
      },
    },
  }));

  const [expandedIndex, setExpandedIndex] = useState(0);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox bgColor="white" padding="15px" paddingTop="10px" borderRadius="8px">
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: "10px" }}>
          <Link to="/overview" underline="hover" color="inherit">
            <span className="breadcrumb-span"> Overview </span>
          </Link>
          <span style={{ fontSize: "15px", color: "#8090AA" }}>{category.categoryName} </span>
        </Breadcrumbs>

        <ThemeProvider theme={CustomHeightTable}>
          <Grid item md={12}>
            <MDTypography fontWeight="medium" fontSize="21px" color="#263445" opacity="1">
              {category.categoryName}
            </MDTypography>
            <MDTypography fontWeight="light" fontSize="15px" color="#8090AA" opacity="1">
              You can click on risk Subcategory to view the details.
            </MDTypography>
          </Grid>
          <hr className="hr-selectbox" style={{ marginTop: "10px", marginBottom: "15px" }} />

          <Grid item md={12}>
            {/* <Card sx={{ p: "7px", mb: "20px" }}> */}
            {/* <MDTypography sx={{ fontSize: "21px" }} className="dialog-title">
                    Category Details
                  </MDTypography> */}

            <MaterialReactTable
              columns={Detailscolumns}
              data={category.subCategories || ""}
              enableTopToolbar={false}
              enableColumnFilters={false}
              enableColumnActions={false}
              enablePagination={false}
              enableBottomToolbar={false}
              enableSorting
              // enableStickyHeader
              sx={{
                boxShadow: "none",
                // padding: "20px",
                //   height: "100px !important",
              }}
              muiTableHeadCellProps={{
                sx: {
                  // borderBottom: "1px solid black",
                  backgroundColor: "#F7F8FF !important",
                  fontWeight: "bold",
                  fontSize: "13px",
                  color: "#263445",
                  marginRight: "2px",
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  border: "none",
                  backgroundColor: "#F7F8FF !important",
                  fontSize: "13px",
                  padding: "5px !important",
                  paddingLeft: "15px !important",
                },
              }}
            />
            {/* </Card> */}
          </Grid>
        </ThemeProvider>
        <hr className="hr-selectbox" style={{ marginTop: "15px", marginBottom: "15px" }} />
        <ToggleButtonGroup
          value={selectedOption}
          exclusive
          onChange={handleOptionChange}
          sx={{ height: "46px", width: "273px", backgroundColor: "#F7F8FF", mb: "10px" }}
        >
          <ToggleButton
            value="Key Risk Indicator"
            selectedColor="#52009b"
            sx={{
              textTransform: "capitalize",
              color: "#402899",
              // borderColor: "#402899",
              fontWeight: 500,
              border: "none",
            }}
          >
            Key Risk Indicator
          </ToggleButton>
          <ToggleButton
            value="Influential Factors"
            selectedColor="#52009b"
            sx={{
              textTransform: "capitalize",
              color: "#402899",
              // borderColor: "#402899",
              fontWeight: 500,
              border: "none",
            }}
          >
            Influential Factors
          </ToggleButton>
        </ToggleButtonGroup>
        {/* key Risk indicator */}
        {selectedOption === "Key Risk Indicator" && (
          <>
            {/* <Grid item md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <span style={{ fontSize: "12px" }}>Actual Target</span>
            </Grid> */}
            {KriDetails && KriDetails.length > 0 ? (
              KriDetails.map((subCat) => (
                <>
                  <Grid item md={12} sx={{ pl: "20px", mb: "8px" }}>
                    <span
                      style={{
                        fontSize: "17px",
                        color: "#7354CB",
                        fontWeight: 500,
                      }}
                    >
                      {subCat.subCategoryName}
                    </span>
                  </Grid>
                  {/* <Grid item md={12} sx={{ backgroundColor: "#F7F8FF", p: "10px", mb: "20px" }}> */}

                  {subCat.kriSubCategoryData &&
                    subCat.kriSubCategoryData.map((kri, index) => (
                      <Accordion
                        sx={{
                          key: { index },
                          backgroundColor: "#F7F8FF",
                          p: "10px",
                          mb: "20px",
                          position: "inherit",
                          boxShadow: "none",
                        }}
                        defaultExpanded={index === 0} // Set expanded state based on the current index
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <span style={{ fontSize: "14.5px", fontWeight: 500 }}>{kri.name}</span>
                            {/* <Slider sx={{ width: 300, mr: 10 }} /> */}
                            <div
                              className="slider-div"
                              style={{ display: "flex", flexDirection: "row", marginRight: "21px" }}
                            >
                              <div className="slider-div-target" />
                            </div>
                            {/* <CustomSlider
                           className="slider-div"
                           sx={{
                             width: "203px",
                             borderRadius: "0px",
                           }}
                           aria-label="Always visible"
                           defaultValue={subCat.actualValue}
                           step={10}
                           disabled
                           marks={[
                             // {
                             //   value: 0,
                             //   label: "",
                             // },
                             {
                               value: subCat.lowerLimit,
                               label: subCat.lowerLimit,
                               icon: <img src={upperLimit} alt="up" />,
                             },
                             {
                               value: subCat.upperLimit,
                               label: subCat.upperLimit,
                               icon: upperLimit,
                             },
                             {
                               value: subCat.actualValue,
                               label: subCat.actualValue,
                               icon: upperLimit,
                             },
                             {
                               value: subCat.targetValue,
                               label: subCat.targetValue,
                               icon: upperLimit,
                             },
                           ]}
                         /> */}
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <span style={{ fontSize: "13px" }}>{kri.description}</span>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  {/* </Grid> */}
                </>
              ))
            ) : (
              <Grid sx={{ pt: "10px", pl: "20px" }}>
                <br />
                <span
                  style={{
                    fontSize: "20px",
                    color: "#7354CB",
                    fontWeight: 500,
                  }}
                >
                  No KRI details found
                </span>
              </Grid>
            )}
          </>
        )}
        {selectedOption === "Influential Factors" &&
          // influential factors

          (InfluentialFactors != null ? (
            <div>
              {/* ===============Exposure========================= */}
              <Grid item md={12} sx={{ mt: "10px", mb: "10px" }}>
                <Stack
                  direction="row"
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <span
                    className="influential-factor-titles"
                    onClick={() => handleExposureNavigate("exposure")}
                  >
                    Exposure
                  </span>
                  <LightTooltip title="Exposure info">
                    <InfoOutlinedIcon />
                  </LightTooltip>
                </Stack>
              </Grid>
              <Grid item sm={12} xs={12} lg={12} md={12}>
                <Grid container sm={12} xs={12} lg={12} md={12} spacing={2}>
                  {/* <Stack direction="row" spacing={2}> */}
                  <Grid item sm={3} xs={6} lg={3} md={3}>
                    <div className="Influential-div1">
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-num">
                          <span>{InfluentialFactors && InfluentialFactors.exposure_noOfRisks}</span>
                        </div>
                        <div className="influential-factor-div-text1">
                          <span>Number of Risks</span>
                        </div>
                        <span className="influential-factor-div-text2">Total Count</span>
                      </Stack>
                    </div>
                  </Grid>
                  <Grid item sm={3} xs={6} lg={3} md={3}>
                    <div className="Influential-div1">
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-num">
                          <span>
                            &gt;{currencySymbol && currencySymbol}
                            {InfluentialFactors && InfluentialFactors.exposure_maximumExposure}
                            {/* {formatNumber(
                              InfluentialFactors && InfluentialFactors.exposure_maximumExposure,
                              {
                                style: "unit",
                                unit: "million",
                                maximumFractionDigits: 2,
                              }
                            )} */}
                          </span>
                        </div>
                        <div className="influential-factor-div-text1">
                          <span>Maximum exposure</span>
                        </div>
                        <span className="influential-factor-div-text2">Largest single risk</span>
                      </Stack>
                    </div>
                  </Grid>

                  <Grid item sm={3} xs={6} lg={3} md={3}>
                    <div className="Influential-div1">
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-num">
                          <span>
                            &gt;{currencySymbol && currencySymbol}
                            {InfluentialFactors && InfluentialFactors.exposure_aggregateExposure}
                          </span>
                        </div>
                        <div className="influential-factor-div-text1">
                          <span>Aggregrate exposure</span>
                        </div>
                        <span className="influential-factor-div-text2">
                          Addition of risks in this category
                        </span>
                      </Stack>
                    </div>
                  </Grid>

                  <Grid sm={3} xs={6} lg={3} item md={3}>
                    <div className="Influential-div1">
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-num">
                          <span>
                            {InfluentialFactors && InfluentialFactors.exposure_percentageOfRisk}%
                          </span>
                        </div>
                        <div className="influential-factor-div-text1">
                          <span>Percentage of risk</span>
                        </div>
                        <span className="influential-factor-div-text2">
                          % against all categories
                        </span>
                      </Stack>
                    </div>
                  </Grid>
                </Grid>
                {/* </Stack> */}
              </Grid>
              <hr className="hr-selectbox" style={{ marginTop: "10px", marginBottom: "15px" }} />
              {/* ===========Assurance==================================== */}
              <Grid item md={12} sx={{ mt: "10px", mb: "10px" }}>
                <Stack
                  direction="row"
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <span
                    className="influential-factor-titles"
                    onClick={() => handleExposureNavigate("assurance")}
                  >
                    Assurance
                  </span>
                  <LightTooltip title="Assurance info">
                    <InfoOutlinedIcon />
                  </LightTooltip>
                </Stack>
              </Grid>
              <Grid item sm={12} xs={12} lg={12} md={12}>
                {/* <Stack direction="row" spacing={2}> */}
                <Grid container sm={12} xs={12} lg={12} md={12} spacing={2}>
                  <Grid item sm={3} xs={6} lg={3} md={3}>
                    <div className="Influential-div1">
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-num">
                          <span>
                            {InfluentialFactors && InfluentialFactors.assurance_noOfRecords}
                          </span>
                        </div>
                        <div className="influential-factor-div-text1">
                          <span>Number of records</span>
                        </div>
                        <span className="influential-factor-div-text2">Total Count</span>
                      </Stack>
                    </div>
                  </Grid>

                  <Grid item sm={3} xs={6} lg={3} md={3}>
                    <div className="Influential-div1">
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-num">
                          <span>
                            {InfluentialFactors &&
                              InfluentialFactors.assurance_companyWideAssurance}
                            %
                          </span>
                        </div>
                        <div className="influential-factor-div-text1">
                          <span>Company wide assurance</span>
                        </div>
                        <span className="influential-factor-div-text2">
                          % against all categories
                        </span>
                      </Stack>
                    </div>
                  </Grid>

                  <Grid item sm={6} xs={12} lg={6} md={6}>
                    <div className="Influential-div2">
                      <Stack direction="row" spacing={3}>
                        <Stack direction="column" sx={{ ml: "15px" }}>
                          <div className="influential-factor-div-text4">
                            <span>Assurance findings</span>
                          </div>
                          {/* <span className="influential-factor-div-text2">
                        Addition of risks in this category
                      </span> */}
                        </Stack>
                        <Stack direction="row" spacing={4.5} sx={{ mt: "7px" }}>
                          <Stack direction="column" sx={{ alignItems: "center" }}>
                            <div className="influential-factor-num2">
                              <span>
                                {InfluentialFactors && InfluentialFactors.assurance_finding.Good}
                              </span>
                            </div>
                            <div className="influential-factor-div-text3">
                              <span>Good</span>
                            </div>
                          </Stack>
                          <Stack direction="column" sx={{ ml: "13px", alignItems: "center" }}>
                            <div className="influential-factor-num2">
                              <span>
                                {InfluentialFactors &&
                                  InfluentialFactors.assurance_finding.Satisfactory}
                              </span>
                            </div>
                            <div className="influential-factor-div-text3">
                              <span>Satisfactory</span>
                            </div>
                          </Stack>
                          <Stack direction="column" sx={{ ml: "13px", alignItems: "center" }}>
                            <div className="influential-factor-num2">
                              <span>
                                {InfluentialFactors &&
                                  InfluentialFactors.assurance_finding.Marginal}
                              </span>
                            </div>
                            <div className="influential-factor-div-text3">
                              <span>Marginal</span>
                            </div>
                          </Stack>
                          <Stack direction="column" sx={{ ml: "13px", alignItems: "center" }}>
                            <div className="influential-factor-num2">
                              <span>
                                {InfluentialFactors && InfluentialFactors.assurance_finding.Weak}
                              </span>
                            </div>
                            <div className="influential-factor-div-text3">
                              <span>Weak</span>
                            </div>
                          </Stack>
                        </Stack>
                      </Stack>
                      <div style={{ marginLeft: "15px" }}>
                        <span className="influential-factor-div-text2">
                          Significance of findings
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {/* </Stack> */}
              </Grid>
              <hr className="hr-selectbox" style={{ marginTop: "10px", marginBottom: "15px" }} />
              {/* ==============Incident Exposure============================================= */}
              <Grid item md={12} sx={{ mt: "10px", mb: "10px" }}>
                <Stack
                  direction="row"
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <span
                    className="influential-factor-titles"
                    onClick={() => handleExposureNavigate("incident")}
                  >
                    Incident experience
                  </span>
                  <LightTooltip title="Incident experience info">
                    <InfoOutlinedIcon />
                  </LightTooltip>
                </Stack>
              </Grid>
              <Grid item sm={12} xs={12} lg={12} md={12}>
                {/* <Stack direction="row" spacing={2}> */}
                <Grid container sm={12} xs={12} lg={12} md={12} spacing={2}>
                  <Grid item sm={3} xs={6} lg={3} md={3}>
                    <div className="Influential-div1">
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-num">
                          <span>{InfluentialFactors && InfluentialFactors.incident_count}</span>
                        </div>
                        <div className="influential-factor-div-text1">
                          <span>Number of records</span>
                        </div>
                        <span className="influential-factor-div-text2">Total Count</span>
                      </Stack>
                    </div>
                  </Grid>

                  <Grid item sm={3} xs={6} lg={3} md={3}>
                    <div className="Influential-div1">
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-num">
                          <span>
                            {InfluentialFactors && InfluentialFactors.incident_companyWideReport}%
                          </span>
                        </div>
                        <div className="influential-factor-div-text1">
                          <span>Company wide report</span>
                        </div>
                        <span className="influential-factor-div-text2">
                          % against all categories
                        </span>
                      </Stack>
                    </div>
                  </Grid>

                  <Grid item sm={6} xs={12} lg={6} md={6}>
                    <div className="Influential-div2">
                      <Stack direction="row" spacing={3}>
                        <Stack direction="column" sx={{ ml: "15px" }}>
                          <div className="influential-factor-div-text4">
                            <span>Incident Level</span>
                          </div>
                          {/* <span className="influential-factor-div-text2">
                        Addition of risks in this category
                      </span> */}
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={
                            InfluentialFactors &&
                            InfluentialFactors.incident_incidentLevel[`4`] &&
                            Object.keys(InfluentialFactors.incident_incidentLevel[`4`]).length >= 4
                              ? 3
                              : 6
                          }
                          sx={{ mt: "7px" }}
                        >
                          <Stack direction="column" sx={{ alignItems: "center" }}>
                            <div className="influential-factor-num2">
                              <span>
                                {/* 1 */}
                                {InfluentialFactors &&
                                  InfluentialFactors.incident_incidentLevel["1"]}
                              </span>
                            </div>
                            <div className="influential-factor-div-text3">
                              <span>1</span>
                            </div>
                          </Stack>
                          <Stack direction="column" sx={{ alignItems: "center" }}>
                            <div className="influential-factor-num2">
                              <span>
                                {InfluentialFactors &&
                                  InfluentialFactors.incident_incidentLevel["2"]}
                              </span>
                            </div>
                            <div className="influential-factor-div-text3">
                              <span>2</span>
                            </div>
                          </Stack>
                          <Stack direction="column" sx={{ alignItems: "center" }}>
                            <div className="influential-factor-num2">
                              <span>
                                {InfluentialFactors &&
                                  InfluentialFactors.incident_incidentLevel["3"]}
                              </span>
                            </div>
                            <div className="influential-factor-div-text3">
                              <span>3</span>
                            </div>
                          </Stack>
                          <Stack direction="column" sx={{ alignItems: "center" }}>
                            <div className="influential-factor-num2">
                              <span>
                                {InfluentialFactors &&
                                  InfluentialFactors.incident_incidentLevel["4"]}
                              </span>
                            </div>
                            <div className="influential-factor-div-text3">
                              <span>4</span>
                            </div>
                          </Stack>
                          {/* <Stack direction="column" sx={{ alignItems: "center" }}>
                            <div className="influential-factor-num2">
                              <span>
                                {InfluentialFactors &&
                                  InfluentialFactors.incident_incidentLevel["5"]}
                              </span>
                            </div>
                            <div className="influential-factor-div-text3">
                              <span>5</span>
                            </div>
                          </Stack> */}
                        </Stack>
                      </Stack>
                      <div style={{ marginLeft: "15px" }}>
                        <span className="influential-factor-div-text2">
                          Significance of findings
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                {/* </Stack> */}
              </Grid>
              <hr className="hr-selectbox" style={{ marginTop: "10px", marginBottom: "15px" }} />

              {/* =============Resource allocation============================================= */}
              <Grid item md={12} sx={{ mt: "10px", mb: "10px" }}>
                <Stack
                  direction="row"
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <div>
                    <span className="influential-factor-titles">Resource allocation</span>
                    {InfluentialFactors &&
                      InfluentialFactors.resource_totalHeadCount === 0 &&
                      InfluentialFactors.resource_companyWideHeadcount === 0 &&
                      InfluentialFactors.resource_totalBudget === 0 &&
                      InfluentialFactors.resource_totalBudget === 0 && (
                        <span className="influential-factor-titles"> : No Records to display</span>
                      )}
                  </div>
                  <LightTooltip title="Resource allocation info">
                    <InfoOutlinedIcon />
                  </LightTooltip>
                </Stack>
              </Grid>
              <Grid item sm={12} xs={12} lg={12} md={12}>
                <Grid container sm={12} xs={12} lg={12} md={12} spacing={2}>
                  <Grid item sm={3} xs={6} lg={3} md={3}>
                    <div className="Influential-div1">
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-num">
                          <span>
                            {InfluentialFactors && InfluentialFactors.resource_totalHeadCount}
                          </span>
                        </div>
                        <div className="influential-factor-div-text1">
                          <span>Total headcount</span>
                        </div>
                        <span className="influential-factor-div-text2">Total Count</span>
                      </Stack>
                    </div>
                  </Grid>

                  <Grid item sm={3} xs={6} lg={3} md={3}>
                    <div className="Influential-div1">
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-num">
                          <span>
                            {InfluentialFactors && InfluentialFactors.resource_companyWideHeadcount}
                            %
                          </span>
                        </div>
                        <div className="influential-factor-div-text1">
                          <span>Company wide headcount</span>
                        </div>
                        <span className="influential-factor-div-text2">
                          % against all categories
                        </span>
                      </Stack>
                    </div>
                  </Grid>

                  <Grid item sm={3} xs={6} lg={3} md={3}>
                    <div className="Influential-div1">
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-num">
                          <span>
                            {currencySymbol && currencySymbol}
                            {InfluentialFactors && InfluentialFactors.resource_totalBudget}
                          </span>
                        </div>
                        <div className="influential-factor-div-text1">
                          <span>Total budget</span>
                        </div>
                        <span className="influential-factor-div-text2">Total count</span>
                      </Stack>
                    </div>
                  </Grid>

                  <Grid item sm={3} xs={6} lg={3} md={3}>
                    <div className="Influential-div1">
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-num">
                          <span>
                            {InfluentialFactors && InfluentialFactors.resource_companyWideBudget}%
                          </span>
                        </div>
                        <div className="influential-factor-div-text1">
                          <span>Company wide budget</span>
                        </div>
                        <span className="influential-factor-div-text2">
                          % against all categories
                        </span>
                      </Stack>
                    </div>
                  </Grid>

                  {/* </Stack> */}
                </Grid>
              </Grid>
            </div>
          ) : (
            <Grid sx={{ pt: "10px", pl: "20px" }}>
              <br />
              <span
                style={{
                  fontSize: "20px",
                  color: "#7354CB",
                  fontWeight: 500,
                }}
              >
                No Influential Factors details found
              </span>
            </Grid>
          ))}
      </MDBox>
    </DashboardLayout>
  );
}

export default CategoryDetails;
