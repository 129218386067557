/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Breadcrumbs from "pages/Breadcrumbs";
import NotificationItem from "pages/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "pages/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import MDTypography from "components/MDTypography";
import { useMsal } from "@azure/msal-react";
import Taxonomy from "pages/Taxonomy";
import { Stack } from "@mui/material";
import { siteData, protectedResources } from "authConfig";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { ReactComponent as NotificationIcon } from "../../../assets/images/icons/projectIcons/icon-social-notifications_24px.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/images/icons/projectIcons/icon-action-settings_24px.svg";
import profilePhoto from "../../../assets/images/icons/projectIcons/Mask Group 1.png";
import Ramsay from "../../../assets/images/icons/projectIcons/Ramsay.png";
import Orica from "../../../assets/images/icons/projectIcons/orica.png";
import { ReactComponent as LogoutIcon } from "../../../assets/images/icons/projectIcons/icon-navigation-expand_more_24px.svg";

function DashboardNavbar({ absolute, light, isMini }) {
  const { instance } = useMsal();
  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });
  const activeAccount = instance.getActiveAccount();
  console.log("activeAccount.idTokenClaim", activeAccount.idTokenClaims.given_name);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [logoutOpenMenu, setLogoutOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  const [companyName, setCompanyName] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    execute("GET", `${protectedResources.apiTodoList.endpoint}/profilesetting/list`).then(
      (response) => {
        if (response && response.success === true && response.data.length > 0) {
          setCompanyName(response.data[0].companyName);
          setUserName(response.data[0].userName);
        }
      }
    );
  }, [execute]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleLogoutOpenMenu = (event) => setLogoutOpenMenu(event.currentTarget);
  const handleLogoutCloseMenu = () => setLogoutOpenMenu(false);
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );
  const renderLogoutMenu = () => (
    <Menu
      anchorEl={logoutOpenMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(logoutOpenMenu)}
      onClose={handleLogoutCloseMenu}
      sx={{ mt: 2 }}
    >
      <Link to="/authentication/logout">
        <NotificationItem icon={<Icon>logout</Icon>} title="Logout" />
      </Link>
    </Menu>
  );
  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/Taxonomy");
  };
  // const titleWithSpaces = decodeURIComponent(route[route.length - 1]);
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      // sx={{ p: "2px" }}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {/* <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={titleWithSpaces} route={route} light={light} />
        </MDBox> */}
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {/* <Breadcrumbs icon="home" title={titleWithSpaces} route={route} light={light} /> */}
          <Stack direction="column">
            <span style={{ fontSize: "25px", color: "#263445", lineHeight: 1 }}>
              {route[0] === "home" && "Home"} {route[0] === "overview" && "Protect"}
              {route[0] === "check" && "Check"}
              {route[0] === "Taxonomy" && "Configuration - Taxonomy"}
              {route[0] === "AppetiteSetting" && "Configuration - Protect"}
              {route[0] === "ConfigureSizingCriteria" && "Configuration - Protect"}
              {route[0] === "AssuranceSizingCriteria" && "Configuration - Protect"}
              {route[0] === "AssuranceProportionality" && "Configuration - Protect"}
              {route[0] === "LikelihoodCriteria" && "Configuration - Protect"}
            </span>
            <span style={{ fontSize: "17px", color: "#8090AA", paddingLeft: "2px" }}>
              {route[0] === "home" && "Your Risk Snapshot"}
              {route[0] === "check" &&
                "Insights to proactively detect trends and improve management of risks"}
              {route[0] === "overview" && "Internal intelligence to protect existing value"}
              {route[0] === "Taxonomy" && "Configure Taxonomy"}
              {route[0] === "AppetiteSetting" && "Protect Settings"}
              {route[0] === "ConfigureSizingCriteria" && "Protect Settings"}
              {route[0] === "AssuranceSizingCriteria" && "Protect Settings"}
              {route[0] === "AssuranceProportionality" && "Protect Settings"}
              {route[0] === "LikelihoodCriteria" && "Protect Settings"}
            </span>
          </Stack>
        </MDBox>
        {isMini ? null : (
          // <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
          <MDBox
            sx={{
              backgroundColor: "white !important",
              borderRadius: "35px",
              // paddingRight: "0px !important",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px",

              justifyContent: "space-around",
            }}
            width="400px"
            height="60px"
            backgroundColor={light ? "white" : "inherit"}
          >
            <MDTypography fontSize="15px">
              {companyName ? companyName : "Company Name"}
            </MDTypography>

            <MDBox color={light ? "white" : "inherit"} sx={{ display: "flex" }}>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={() => {
                  handleConfiguratorOpen();
                  handleNavigate();
                }}
                route="/Taxonomy"
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              <NotificationIcon onClick={handleOpenMenu} style={{ cursor: "pointer" }} />
              {renderMenu()}
              <SettingsIcon
                onClick={() => {
                  handleConfiguratorOpen();
                  handleNavigate();
                }}
                route="/Taxonomy"
                style={{ cursor: "pointer" }}
              />

              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton> */}

              {/* <IconButton
                size="small"
                disableRipple
                color="black"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton> */}

              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleLogoutOpenMenu}
              >
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton> */}
            </MDBox>
            <MDTypography fontSize="15px">
              {"Hi ! "}
              {/* {activeAccount?.idTokenClaims ? activeAccount.idTokenClaims.given_name : ""} */}
              {userName
                ? userName
                : activeAccount?.idTokenClaims
                ? activeAccount.idTokenClaims.name
                : ""}
            </MDTypography>
            {/* <div style={{ width: "59px", marginTop: "6px" }}> */}
            <img
              alt="profile"
              // style={{ width: "100%", height: "80%" }}
              src={profilePhoto}
              // src={
              //   activeAccount.idTokenClaims.given_name === "Ramsay"
              //     ? Ramsay
              //     : activeAccount.idTokenClaims.given_name === "Orica"
              //     ? Orica
              //     : profilePhoto
              // }
            />
            {/* </div> */}
            <LogoutIcon onClick={handleLogoutOpenMenu} />
            {renderLogoutMenu()}
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
