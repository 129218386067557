/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Grid,
  Stack,
  ToggleButtonGroup,
} from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import "./ProtectDashboard.css";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { siteData, protectedResources } from "authConfig";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MDTypography from "components/MDTypography";

// toggle button css
const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: selectedColor,
  },
}));

// tooltip and info btn in influential factor
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

function SubCategoryDetails() {
  const location = useLocation();
  const { subCategory } = location.state;
  const catId = location.state.cat.categoryId;
  const categName = location.state.cat.categoryName;

  const { execute } = useFetchWithMsal({
    scopes: [siteData?.apiScopes],
  });

  // hardcoded data for nursing sub category
  // const nursingData = [
  //   {
  //     taxonomyId: "64b51c6008fa7be5080de7e4",
  //     categoryId: "44355483-4237-4e1e-8156-3e387a7b16bd",
  //     subCategoryId: "558f777c-0301-4afa-846b-88e4e3b902ff",
  //     exposure_noOfRisks: "163",
  //     exposure_maximumExposure: "$1.5-3M",
  //     exposure_aggregateExposure: "12.25M",
  //     exposure_percentageOfRisk: "2.5%",
  //     assurance_noOfRecords: "08",
  //     assurance_companyWideAssurance: "1.5%",
  //     assurance_finding: {
  //       Good: "01",
  //       Satisfactory: "03",
  //       Marginal: "04",
  //       Weak: "00",
  //     },
  //     incident_count: "1888",
  //     incident_companyWideReport: "10.3%",
  //     incident_incidentLevel: {
  //       1: "12",
  //       2: "42",
  //       3: "1142",
  //       4: "692",
  //       // 5: "4",
  //     },
  //     resource_totalHeadCount: "00",
  //     resource_companyWideHeadcount: "00",
  //     resource_totalBudget: "00",
  //     resource_companyWideBudget: "00",
  //     level_rating: "Partially prescriptive",
  //     level_ratingSymbol: "checkmark",
  //     id: "6502da2d61aea71a68fa5c3a",
  //   },
  // ];

  // hardcoded data for clinical medical  sub category
  // const clinicalData = [
  //   {
  //     taxonomyId: "64b51c6008fa7be5080de7e4",
  //     categoryId: "44355483-4237-4e1e-8156-3e387a7b16bd",
  //     subCategoryId: "22423643-4887-4148-bb54-55c1ef8bdc98",
  //     exposure_noOfRisks: "180",
  //     exposure_maximumExposure: ">$3M",
  //     exposure_aggregateExposure: "27.5M",
  //     exposure_percentageOfRisk: "5%",
  //     assurance_noOfRecords: "07",
  //     assurance_companyWideAssurance: "1.25%",
  //     assurance_finding: {
  //       Good: "00",
  //       Satisfactory: "02",
  //       Marginal: "04",
  //       Weak: "01",
  //     },
  //     incident_count: "7961",
  //     incident_companyWideReport: "43.5%",
  //     incident_incidentLevel: {
  //       1: "60",
  //       2: "100",
  //       3: "3168",
  //       4: "4634",
  //       // 5: "4",
  //     },
  //     resource_totalHeadCount: "00",
  //     resource_companyWideHeadcount: "00",
  //     resource_totalBudget: "00",
  //     resource_companyWideBudget: "00",
  //     level_rating: "Partially prescriptive",
  //     level_ratingSymbol: "checkmark",
  //     id: "6502da2d61aea71a68fa5c3a",
  //   },
  // ];

  // hardcoded data for clinical sub category - KRI
  // const clinicalKRI = [
  //   {
  //     categoryId: "44355483-4237-4e1e-8156-3e387a7b16bd",
  //     subCategoryId: "22423643-4887-4148-bb54-55c1ef8bdc98",
  //     name: "Adverse Events Rate",
  //     description: "The number of adverse incidents reported per patient admitted",
  //   },
  //   {
  //     categoryId: "44355483-4237-4e1e-8156-3e387a7b16bd",
  //     subCategoryId: "22423643-4887-4148-bb54-55c1ef8bdc98",
  //     name: "Readmission Rate",
  //     description: "The rate at which patients are readmitted after discharge",
  //   },
  //   {
  //     categoryId: "44355483-4237-4e1e-8156-3e387a7b16bd",
  //     subCategoryId: "22423643-4887-4148-bb54-55c1ef8bdc98",
  //     name: "Length of stay(LOS)",
  //     description: "Average length of stay rates for all admitted patients",
  //   },
  // ];

  // tab selection
  const [selectedOption, setSelectedOption] = useState("Key Risk Indicator");
  //  for tab input change
  const handleOptionChange = (event, option) => {
    if (option !== null) {
      setSelectedOption(option);
    }
  };

  const [taxonomyId, setTaxonomyId] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(null);

  useEffect(() => {
    if (!taxonomyId) {
      execute("GET", `${protectedResources.apiTodoList.endpoint}/taxonomy/listall`).then(
        (response) => {
          if (response && response.success === true && response.data.length > 0) {
            setTaxonomyId(response.data[0].id);
            setCurrencySymbol(response.data[0].currencyData.currencySymbol);
          }
        }
      );
    }
  }, [execute, taxonomyId]);

  // kri api call
  const [KriDetails, setKriDetails] = useState([]);
  const [KriDet, setKriDet] = useState([]);

  useEffect(() => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/protect/KRI/list/${taxonomyId}/${catId}/${subCategory.subCategoryId}`
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setKriDet(response.data);
      }
    });
  }, [taxonomyId, catId, subCategory.subCategoryId]);

  useEffect(() => {
    if (KriDet) {
      setKriDetails(KriDet.filter((det) => det.subCategoryId === subCategory.subCategoryId));
    }
  }, [KriDet]);

  const [InfluentialFactors, setInfluentialFactors] = useState([]);
  const [InfDet, setInfDet] = useState([]);

  useEffect(() => {
    execute(
      "GET",
      `${protectedResources.apiTodoList.endpoint}/protect/influentialfactor/list/${taxonomyId}/${catId}/${subCategory.subCategoryId}`
    ).then((response) => {
      if (response && response.success === true && response.data.length > 0) {
        setInfDet(response.data);
        // setInfluentialFactors(response.data[0]);
      }
    });
  }, [taxonomyId, catId, subCategory.subCategoryId]);

  useEffect(() => {
    if (InfDet && InfDet.filter((det) => det.subCategoryId === subCategory.subCategoryId)) {
      setInfluentialFactors(InfDet[0]);
    }
  }, [InfDet, subCategory.subCategoryId]);

  // useEffect(() => {
  //   if (subCategory.subCategoryId === nursingData[0].subCategoryId) {
  //     setInfluentialFactors(nursingData[0]);
  //   }
  //   if (subCategory.subCategoryId === clinicalData[0].subCategoryId) {
  //     setInfluentialFactors(clinicalData[0]);
  //     setKriDetails(clinicalKRI);
  //   }
  // }, [subCategory.subCategoryId, nursingData, clinicalData, clinicalKRI]);

  // exposure info navigate
  const navigate = useNavigate();
  const handleExposureNavigate = (name) => {
    const expType = name;
    const { cat } = location.state;
    const subCat = subCategory;
    const TaxonomyId = taxonomyId;
    // const catName = subCategory.subCategoryName;
    navigate("/overview/category/subcategory/exposure", {
      state: { TaxonomyId, cat, subCat, expType },
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox bgColor="white" padding="15px" paddingTop="10px" borderRadius="8px">
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: "10px" }}>
          <Link to="/overview" underline="hover">
            <span className="breadcrumb-span"> Overview </span>
          </Link>
          <Link to="/overview/category" state={location.state.cat}>
            <span className="breadcrumb-span"> {categName} </span>
          </Link>
          <span style={{ fontSize: "15px", color: "#8090AA" }}>{subCategory.subCategoryName} </span>
        </Breadcrumbs>
        <Grid item md={12}>
          <MDTypography fontWeight="bold" fontSize="21px" color="#263445" opacity="1">
            {subCategory.subCategoryName}
          </MDTypography>
        </Grid>
        <hr className="hr-selectbox" style={{ marginTop: "10px", marginBottom: "20px" }} />
        <Grid container md={12} sx={{ mb: "12px" }}>
          <Grid item md={12}>
            <MDTypography fontWeight="medium" fontSize="16px" color="#263445" opacity="1">
              Stategic Position
            </MDTypography>
          </Grid>
          <Grid item md={6}>
            <span className="influential-factor-titles">Appetite Performance</span>
            {/* <div className="Influential-div1"> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "259px",
              }}
            >
              <span style={{ fontSize: "13px" }}>Under</span>
              <span style={{ fontSize: "13px" }}>Over</span>
            </div>
            <div
              className="sub-slider-div"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              {/* <div
                            className="slider-div-upper-limit"
                            style={{ marginLeft: "3px", marginRight: "10px" }}
                          > */}
              {/* <span>[</span> */}
              {/* </div> */}
              <div className="sub-slider-div-red" />
              <div className="sub-slider-div-blue" />
              <div className="sub-slider-div-green" />
              <div className="sub-slider-div-grey" />
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div className="sub-slider-green" />
                <span style={{ fontSize: "15px" }}> : Optimal value</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "66px",
                }}
              >
                <div className="sub-slider-div-red" />
                <span style={{ fontSize: "15px" }}> : Risk</span>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div className="sub-slider-blue" />
                <span style={{ fontSize: "15px", marginLeft: "2px" }}> : Incident </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "95px",
                }}
              >
                <div className="sub-slider-grey" />
                <span style={{ fontSize: "15px" }}> : Assurance</span>
              </div>
            </div>
            {/* </div> */}
          </Grid>
          <Grid item md={6}>
            <span className="influential-factor-titles">Assurance Proportionality</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "259px",
              }}
            >
              <span style={{ fontSize: "13px" }}>0%</span>
              <span style={{ fontSize: "13px" }}>100%</span>
            </div>
            <div
              className="sub-slider-div2"
              style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            >
              <div className="sub-slider-div-white" />
            </div>
            <div style={{ marginLeft: "61px", marginTop: "-10px" }}>
              <span style={{ fontSize: "13px" }}>20%</span>
              <span style={{ fontSize: "13px", marginLeft: "126px" }}>-&gt; over</span>
            </div>
          </Grid>
        </Grid>
        <hr className="hr-selectbox" style={{ marginTop: "10px", marginBottom: "15px" }} />

        <Grid>
          <ToggleButtonGroup
            value={selectedOption}
            exclusive
            onChange={handleOptionChange}
            sx={{ height: "46px", width: "273px", backgroundColor: "#F7F8FF", mb: "10px" }}
          >
            <ToggleButton
              value="Key Risk Indicator"
              selectedColor="#52009b"
              sx={{
                textTransform: "capitalize",
                color: "#402899",
                // borderColor: "#402899",
                border: "none",
                fontWeight: 500,
              }}
            >
              Key Risk Indicator
            </ToggleButton>
            <ToggleButton
              value="Influential Factors"
              selectedColor="#52009b"
              sx={{
                textTransform: "capitalize",
                color: "#402899",
                border: "none",
                fontWeight: 500,
              }}
            >
              Influential Factors
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {/* key Risk indicator */}
        {selectedOption === "Key Risk Indicator" && (
          <>
            {/* <Grid item md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <span style={{ fontSize: "12px" }}>Actual Target</span>
            </Grid> */}
            {KriDetails && KriDetails.length > 0 ? (
              KriDetails.map((subCat) => (
                <>
                  {/* <Grid item md={12} sx={{ pl: "20px", mb: "8px" }}>
                    <span
                      style={{
                        fontSize: "17px",
                        color: "#7354CB",
                        fontWeight: 500,
                      }}
                    >
                      {subCat.subCategoryName}
                    </span>
                  </Grid> */}

                  {subCat.kriSubCategoryData &&
                    subCat.kriSubCategoryData.length > 0 &&
                    subCat.kriSubCategoryData.map((kri, index) => (
                      <Accordion
                        sx={{
                          backgroundColor: "#F7F8FF",
                          p: "10px",
                          mb: "20px",
                          position: "inherit",
                          boxShadow: "none",
                        }}
                        defaultExpanded={index === 0} // Set expanded state based on the current index
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <span style={{ fontSize: "14.5px", fontWeight: 500 }}>{kri.name}</span>
                            <div
                              className="slider-div"
                              style={{ display: "flex", flexDirection: "row", marginRight: "21px" }}
                            >
                              {/* <div
                          className="slider-div-upper-limit"
                          style={{ marginLeft: "3px", marginRight: "10px" }}
                        > */}
                              {/* <span>[</span> */}
                              {/* </div> */}
                              <div className="slider-div-target" />
                              {/* <div className="slider-div-actual" /> */}
                              {/* <div className="slider-div-lower-limit" /> */}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <span style={{ fontSize: "13px" }}>{kri.description}</span>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  {/* </Grid> */}
                </>
              ))
            ) : (
              <span
                style={{
                  fontSize: "16   px",
                  color: "#7354CB",
                  fontWeight: 500,
                }}
              >
                No KRI details found
              </span>
            )}
          </>
        )}
        {selectedOption === "Influential Factors" && (
          // influential factors

          <div>
            {/* ===============Exposure========================= */}
            <Grid item md={12} sx={{ mt: "10px", mb: "10px" }}>
              <Stack
                direction="row"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <span
                  className="influential-factor-titles"
                  onClick={() => handleExposureNavigate("exposure")}
                >
                  Exposure
                </span>
                <LightTooltip title="Exposure info">
                  <InfoOutlinedIcon />
                </LightTooltip>
              </Stack>
            </Grid>
            <Grid item sm={12} xs={12} lg={12} md={12}>
              <Grid container sm={12} xs={12} lg={12} md={12} spacing={2}>
                {/* <Stack direction="row" spacing={2}> */}
                <Grid item sm={3} xs={6} lg={3} md={3}>
                  <div className="Influential-div1">
                    <Stack direction="column" sx={{ ml: "15px" }}>
                      <div className="influential-factor-num">
                        <span>{InfluentialFactors && InfluentialFactors.exposure_noOfRisks}</span>
                      </div>
                      <div className="influential-factor-div-text1">
                        <span>Number of Risks</span>
                      </div>
                      <span className="influential-factor-div-text2">Total Count</span>
                    </Stack>
                  </div>
                </Grid>

                <Grid item sm={3} xs={6} lg={3} md={3}>
                  <div className="Influential-div1">
                    <Stack direction="column" sx={{ ml: "15px" }}>
                      <div className="influential-factor-num">
                        {/* <span>
                            {currencySymbol && currencySymbol}
                            {InfluentialFactors && InfluentialFactors.exposure_maximumExposure}
                          </span> */}
                        {InfluentialFactors &&
                        InfluentialFactors.subCategoryId ===
                          "2063c709-60ef-48ae-90a8-8e847c3bdedd" ? (
                          <span>{currencySymbol && currencySymbol}1.5-3M</span>
                        ) : InfluentialFactors &&
                          InfluentialFactors.subCategoryId ===
                            "8d0e4575-bd2f-4169-9b30-b89e3676d3e9" ? (
                          <span>&gt;{currencySymbol && currencySymbol}3M</span>
                        ) : (
                          <span>
                            &gt;{currencySymbol && currencySymbol}
                            {InfluentialFactors && InfluentialFactors.exposure_maximumExposure}
                          </span>
                        )}
                      </div>
                      <div className="influential-factor-div-text1">
                        <span>Maximum exposure</span>
                      </div>
                      <span className="influential-factor-div-text2">Largest single risk</span>
                    </Stack>
                  </div>
                </Grid>

                <Grid item sm={3} xs={6} lg={3} md={3}>
                  <div className="Influential-div1">
                    <Stack direction="column" sx={{ ml: "15px" }}>
                      <div className="influential-factor-num">
                        <span>
                          &gt;{currencySymbol && currencySymbol}
                          {InfluentialFactors && InfluentialFactors.exposure_aggregateExposure}
                        </span>
                      </div>
                      <div className="influential-factor-div-text1">
                        <span>Aggregrate exposure</span>
                      </div>
                      <span className="influential-factor-div-text2">
                        Addition of risks in this category
                      </span>
                    </Stack>
                  </div>
                </Grid>

                <Grid item sm={3} xs={6} lg={3} md={3}>
                  <div className="Influential-div1">
                    <Stack direction="column" sx={{ ml: "15px" }}>
                      <div className="influential-factor-num">
                        <span>
                          {InfluentialFactors && InfluentialFactors.exposure_percentageOfRisk}%
                        </span>
                      </div>
                      <div className="influential-factor-div-text1">
                        <span>Percentage of risk</span>
                      </div>
                      <span className="influential-factor-div-text2">% against all categories</span>
                    </Stack>
                  </div>
                </Grid>
              </Grid>
              {/* </Stack> */}
            </Grid>
            <hr className="hr-selectbox" style={{ marginTop: "10px", marginBottom: "15px" }} />
            {/* ===========Assurance==================================== */}
            <Grid item md={12} sx={{ mt: "10px", mb: "10px" }}>
              <Stack
                direction="row"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <span
                  className="influential-factor-titles"
                  onClick={() => handleExposureNavigate("assurance")}
                >
                  Assurance
                </span>
                <LightTooltip title="Assurance info">
                  <InfoOutlinedIcon />
                </LightTooltip>
              </Stack>
            </Grid>
            <Grid item sm={12} xs={12} lg={12} md={12}>
              {/* <Stack direction="row" spacing={2}> */}
              <Grid container sm={12} xs={12} lg={12} md={12} spacing={2}>
                <Grid item sm={3} xs={6} lg={3} md={3}>
                  <div className="Influential-div1">
                    <Stack direction="column" sx={{ ml: "15px" }}>
                      <div className="influential-factor-num">
                        <span>
                          {InfluentialFactors && InfluentialFactors.assurance_noOfRecords}
                        </span>
                      </div>
                      <div className="influential-factor-div-text1">
                        <span>Number of records</span>
                      </div>
                      <span className="influential-factor-div-text2">Total Count</span>
                    </Stack>
                  </div>
                </Grid>

                <Grid item sm={3} xs={6} lg={3} md={3}>
                  <div className="Influential-div1">
                    <Stack direction="column" sx={{ ml: "15px" }}>
                      <div className="influential-factor-num">
                        <span>
                          {InfluentialFactors && InfluentialFactors.assurance_companyWideAssurance}%
                        </span>
                      </div>
                      <div className="influential-factor-div-text1">
                        <span>Company wide assurance</span>
                      </div>
                      <span className="influential-factor-div-text2">% against all categories</span>
                    </Stack>
                  </div>
                </Grid>

                <Grid item sm={6} xs={12} lg={6} md={6}>
                  <div className="Influential-div2">
                    <Stack direction="row" spacing={3}>
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-div-text4">
                          <span>Assurance findings</span>
                        </div>
                      </Stack>
                      <Stack direction="row" spacing={4.5} sx={{ mt: "7px" }}>
                        <Stack direction="column" sx={{ alignItems: "center" }}>
                          <div className="influential-factor-num2">
                            <span>
                              {InfluentialFactors && InfluentialFactors.assurance_finding.Good}
                            </span>
                          </div>
                          <div className="influential-factor-div-text3">
                            <span>Good</span>
                          </div>
                        </Stack>
                        <Stack direction="column" sx={{ alignItems: "center" }}>
                          <div className="influential-factor-num2">
                            <span>
                              {InfluentialFactors &&
                                InfluentialFactors.assurance_finding.Satisfactory}
                            </span>
                          </div>
                          <div className="influential-factor-div-text3">
                            <span>Satisfactory</span>
                          </div>
                        </Stack>
                        <Stack direction="column" sx={{ alignItems: "center" }}>
                          <div className="influential-factor-num2">
                            <span>
                              {InfluentialFactors && InfluentialFactors.assurance_finding.Marginal}
                            </span>
                          </div>
                          <div className="influential-factor-div-text3">
                            <span>Marginal</span>
                          </div>
                        </Stack>
                        <Stack direction="column" sx={{ alignItems: "center" }}>
                          <div className="influential-factor-num2">
                            <span>
                              {InfluentialFactors && InfluentialFactors.assurance_finding.Weak}
                            </span>
                          </div>
                          <div className="influential-factor-div-text3">
                            <span>Weak</span>
                          </div>
                        </Stack>
                      </Stack>
                    </Stack>
                    <div style={{ marginLeft: "15px" }}>
                      <span className="influential-factor-div-text2">Significance of findings</span>
                    </div>
                  </div>
                </Grid>
              </Grid>

              {/* </Stack> */}
            </Grid>
            <hr className="hr-selectbox" style={{ marginTop: "10px", marginBottom: "15px" }} />
            {/* ==============Incident Exposure============================================= */}

            <Grid item md={12} sx={{ mt: "10px", mb: "10px" }}>
              <Stack
                direction="row"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <span
                  className="influential-factor-titles"
                  onClick={() => handleExposureNavigate("incident")}
                >
                  Incident experience
                </span>
                <LightTooltip title="Incident experience info">
                  <InfoOutlinedIcon />
                </LightTooltip>
              </Stack>
            </Grid>
            <Grid item sm={12} xs={12} lg={12} md={12}>
              {/* <Stack direction="row" spacing={2}> */}
              <Grid container sm={12} xs={12} lg={12} md={12} spacing={2}>
                <Grid item sm={3} xs={6} lg={3} md={3}>
                  <div className="Influential-div1">
                    <Stack direction="column" sx={{ ml: "15px" }}>
                      <div className="influential-factor-num">
                        <span>{InfluentialFactors && InfluentialFactors.incident_count}</span>
                      </div>
                      <div className="influential-factor-div-text1">
                        <span>Number of records</span>
                      </div>
                      <span className="influential-factor-div-text2">Total Count</span>
                    </Stack>
                  </div>
                </Grid>

                <Grid item sm={3} xs={6} lg={3} md={3}>
                  <div className="Influential-div1">
                    <Stack direction="column" sx={{ ml: "15px" }}>
                      <div className="influential-factor-num">
                        <span>
                          {InfluentialFactors && InfluentialFactors.incident_companyWideReport}%
                        </span>
                      </div>
                      <div className="influential-factor-div-text1">
                        <span>Company wide report</span>
                      </div>
                      <span className="influential-factor-div-text2">% against all report</span>
                    </Stack>
                  </div>
                </Grid>

                <Grid item sm={6} xs={12} lg={6} md={6}>
                  <div className="Influential-div2">
                    <Stack direction="row" spacing={2}>
                      <Stack direction="column" sx={{ ml: "15px" }}>
                        <div className="influential-factor-div-text4">
                          <span>Incident Level</span>
                        </div>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={
                          InfluentialFactors &&
                          Object.keys(InfluentialFactors.incident_incidentLevel[`4`]).length >= 4
                            ? 3
                            : 6
                        }
                        sx={{ mt: "7px" }}
                      >
                        <Stack direction="column" sx={{ alignItems: "center" }}>
                          <div className="influential-factor-num2">
                            <span>
                              {/* 1 */}
                              {InfluentialFactors && InfluentialFactors.incident_incidentLevel["1"]}
                            </span>
                          </div>
                          <div className="influential-factor-div-text3">
                            <span>1</span>
                          </div>
                        </Stack>
                        <Stack direction="column" sx={{ alignItems: "center" }}>
                          <div className="influential-factor-num2">
                            <span>
                              {InfluentialFactors && InfluentialFactors.incident_incidentLevel["2"]}
                            </span>
                          </div>
                          <div className="influential-factor-div-text3">
                            <span>2</span>
                          </div>
                        </Stack>
                        <Stack direction="column" sx={{ alignItems: "center" }}>
                          <div className="influential-factor-num2">
                            <span>
                              {InfluentialFactors && InfluentialFactors.incident_incidentLevel["3"]}
                            </span>
                          </div>
                          <div className="influential-factor-div-text3">
                            <span>3</span>
                          </div>
                        </Stack>
                        <Stack direction="column" sx={{ alignItems: "center" }}>
                          <div className="influential-factor-num2">
                            <span>
                              {InfluentialFactors && InfluentialFactors.incident_incidentLevel["4"]}
                            </span>
                          </div>
                          <div className="influential-factor-div-text3">
                            <span>4</span>
                          </div>
                        </Stack>
                        {/* <Stack direction="column" sx={{ ml: "15px", alignItems: "center" }}>
                          <div className="influential-factor-num2">
                            <span>
                              {InfluentialFactors && InfluentialFactors.incident_incidentLevel["5"]}
                            </span>
                          </div>
                          <div className="influential-factor-div-text3">
                            <span>5</span>
                          </div>
                        </Stack> */}
                      </Stack>
                    </Stack>
                    <div style={{ marginLeft: "15px" }}>
                      <span className="influential-factor-div-text2">Significance of findings</span>
                    </div>
                  </div>
                </Grid>
              </Grid>

              {/* </Stack> */}
            </Grid>
            <hr className="hr-selectbox" style={{ marginTop: "10px", marginBottom: "15px" }} />
            {/* =============Resource allocation============================================= */}

            <Grid item md={12} sx={{ mt: "10px", mb: "10px" }}>
              <Stack
                direction="row"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <div>
                  <span className="influential-factor-titles">Resource allocation</span>
                  {InfluentialFactors &&
                    InfluentialFactors.resource_totalHeadCount === "00" &&
                    InfluentialFactors.resource_companyWideHeadcount === "00" &&
                    InfluentialFactors.resource_totalBudget === "00" && (
                      <span className="influential-factor-titles"> : No Records to display</span>
                    )}
                </div>
                <LightTooltip title="Resource allocation info">
                  <InfoOutlinedIcon />
                </LightTooltip>
              </Stack>
            </Grid>
            <Grid item sm={12} xs={12} lg={12} md={12}>
              {/* <Stack direction="row" spacing={2}> */}
              <Grid container sm={12} xs={12} lg={12} md={12} spacing={2}>
                <Grid item sm={3} xs={6} lg={3} md={3}>
                  <div className="Influential-div1">
                    <Stack direction="column" sx={{ ml: "15px" }}>
                      <div className="influential-factor-num">
                        <span>
                          {InfluentialFactors && InfluentialFactors.resource_totalHeadCount}
                        </span>
                      </div>
                      <div className="influential-factor-div-text1">
                        <span>Total headcount</span>
                      </div>
                      <span className="influential-factor-div-text2">Total Count</span>
                    </Stack>
                  </div>
                </Grid>

                <Grid item sm={3} xs={6} lg={3} md={3}>
                  <div className="Influential-div1">
                    <Stack direction="column" sx={{ ml: "15px" }}>
                      <div className="influential-factor-num">
                        <span>
                          {InfluentialFactors && InfluentialFactors.resource_companyWideHeadcount}%
                        </span>
                      </div>
                      <div className="influential-factor-div-text1">
                        <span>Company wide headcount</span>
                      </div>
                      <span className="influential-factor-div-text2">% against all categories</span>
                    </Stack>
                  </div>
                </Grid>

                <Grid item sm={3} xs={6} lg={3} md={3}>
                  <div className="Influential-div1">
                    <Stack direction="column" sx={{ ml: "15px" }}>
                      <div className="influential-factor-num">
                        <span>
                          {currencySymbol && currencySymbol}
                          {InfluentialFactors && InfluentialFactors.resource_totalBudget}
                        </span>
                      </div>
                      <div className="influential-factor-div-text1">
                        <span>Total budget</span>
                      </div>
                      <span className="influential-factor-div-text2">Total count</span>
                    </Stack>
                  </div>
                </Grid>

                <Grid item sm={3} xs={6} lg={3} md={3}>
                  <div className="Influential-div1">
                    <Stack direction="column" sx={{ ml: "15px" }}>
                      <div className="influential-factor-num">
                        <span>
                          {InfluentialFactors && InfluentialFactors.resource_companyWideBudget}%
                        </span>
                      </div>
                      <div className="influential-factor-div-text1">
                        <span>Company wide budget</span>
                      </div>
                      <span className="influential-factor-div-text2">% against all categories</span>
                    </Stack>
                  </div>
                </Grid>
              </Grid>

              {/* </Stack> */}
            </Grid>
          </div>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default SubCategoryDetails;
