/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
// @mui icons
import Icon from "@mui/material/Icon";
import DashboardPage from "layouts/dashboard/components/DashboardPage";

import AnticipateSetting from "pages/Anticipate/AnticipateSetting";
import DataIndexing from "pages/DataIndexing";
import FileStatus from "pages/FileStatus";
import AppetiteSetting from "pages/Protect/AppetiteSetting";
import AssProportionality from "pages/Protect/AssProportionality";
// import AppetiteType from "pages/Protect/AppetiteType";
import AssuranceSizing from "pages/Protect/AssuranceSizing";
import LikelihoodCriteria from "pages/Protect/LikelihoodCriteria";
import RiskSizing from "pages/Protect/RiskSizing";
import Taxonomy from "pages/Taxonomy";
import { ReactComponent as TaxIcon } from "./assets/images/icons/projectIcons/tenancy_FILL0_wght400_GRAD0_opsz48.svg";
import { ReactComponent as ProtectIcon } from "./assets/images/icons/projectIcons/speed_black_24dp.svg";
import { ReactComponent as AnticipateIcon } from "./assets/images/icons/projectIcons/pie_chart_outline_black_24dp.svg";
import { ReactComponent as ConnectIcon } from "./assets/images/icons/projectIcons/hub_black_24dp.svg";
import { ReactComponent as CheckIcon } from "./assets/images/icons/projectIcons/health_and_safety_black_24dp.svg";

const routes = [
  {
    type: "collapse",
    name: "Exit Settings",
    key: "dashboard",
    icon: <Icon fontSize="small">left</Icon>,
    route: "/home",
    noCollapse: true,
    component: <DashboardPage />,
  },
  // { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Taxonomy",
    key: "taxonomy",
    route: "/Taxonomy",
    component: <Taxonomy />,
    noCollapse: true,
    // icon: <Icon fontSize="small">speed</Icon>,
    icon: <TaxIcon />,
  },
  // { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Protect Settings",
    key: "protect",
    route: "/home",
    icon: <ProtectIcon />,
    collapse: [
      // nested route inside "Protect Setting" submenu
      // {
      //   type: "collape",
      //   name: "Appetite Type",
      //   key: "appetite type",
      //   route: "/dashboard/Appetite Type",
      //   component: <AppetiteType />,
      //   icon: <Icon fontSize="small">speed</Icon>,
      //   noCollapse: true,
      // },
      {
        type: "collape",
        name: "Appetite Setting",
        key: "appetite setting",
        route: "/AppetiteSetting",
        component: <AppetiteSetting />,
        icon: <Icon fontSize="small">speed</Icon>,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Sizing Config",
        key: "Sizing",
        route: "/home",
        collapse: [
          // nested route inside "Protect Setting" submenu
          {
            type: "collape",
            name: "Risk and Incident Sizing",
            key: "Configure Sizing Criteria",
            route: "/ConfigureSizingCriteria",
            component: <RiskSizing />,
            icon: <Icon fontSize="small">speed</Icon>,
            noCollapse: true,
          },
          {
            type: "collape",
            name: "Assurance Sizing",
            key: "Assurance Sizing",
            route: "/AssuranceSizingCriteria",
            component: <AssuranceSizing />,
            icon: <Icon fontSize="small">speed</Icon>,
            noCollapse: true,
          },
          {
            type: "collape",
            name: "Assurance Proportionality",
            key: "Assurance Proportionality",
            route: "/AssuranceProportionality",
            component: <AssProportionality />,
            icon: <Icon fontSize="small">speed</Icon>,
            noCollapse: true,
          },
        ],
        icon: <Icon fontSize="small">speed</Icon>,
      },
      {
        type: "collape",
        name: "Likelihood Criteria",
        key: "Likelihood Criteria",
        route: "/LikelihoodCriteria",
        component: <LikelihoodCriteria />,
        icon: <Icon fontSize="small">speed</Icon>,
        noCollapse: true,
      },
    ],
    // icon: <Icon fontSize="small">speed</Icon>,
  },
  // { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Anticipate Settings",
    key: "anticipate",
    noCollapse: true,
    route: "/AnticpateSetting",
    component: <AnticipateSetting />,
    // icon: <Icon fontSize="small">pie</Icon>,
    icon: <AnticipateIcon />,
  },
  // { type: "divider", key: "divider-3" },
  {
    type: "collapse",
    name: "Connect Settings",
    noCollapse: true,
    key: "connect",
    route: "/home",
    // icon: <Icon fontSize="small">hub</Icon>,
    icon: <ConnectIcon />,
  },
  // { type: "divider", key: "divider-4" },
  {
    type: "collapse",
    name: "Check Settings",
    noCollapse: true,
    key: "check",
    route: "/home",
    // icon: <Icon fontSize="small">shield</Icon>,
    icon: <CheckIcon />,
  },
  {
    type: "collapse",
    name: "Processing",
    key: "dataindexing",
    icon: <Icon fontSize="small">settings</Icon>,
    collapse: [
      {
        name: "File Status",
        key: "filestatus1",
        route: "/filestatus",
        component: <FileStatus />,
      },
      {
        name: "Data Indexing",
        key: "dataindexing1",
        route: "/dataindexing",
        component: <DataIndexing />,
      },
    ],
  },
];

export default routes;
